import types from "./types";
import { AppChatState } from "./";

const actions = {
  appChatStateMutate: (mutate: (state: AppChatState) => any) => {
    return {
      type: types.APP_CHAT_MUTATE,
      mutate,
    };
  },
};

export default actions;
