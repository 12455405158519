import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import store from "stores/interfaces";
import styled from "styled-components";
import { Form, FieldArray, useFormikContext, useField } from "formik";
import {
  TextField,
  ButtonBase,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Avatar,
  TextFieldProps,
  // ListItemAvatar,
} from "@mui/material";
import FileListDialog from "../FileListDialog";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";

import useFastField from "hooks/fastField";
import useDialog from "hooks/dialog";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { Character } from "stores/modules/entities.room.characters";
// import { getDiceSkin } from "modules/diceSkin";
// import { DiceSkinSettings } from "stores/modules/entities.user.setting";
// import DiceSkinSelectDialog from "containers/DiceSkinSelect/DiceSkinSelectDialog";
// import { ReactComponent as CcfoliaProIcon } from "containers/svg/CcfoliaPro.svg";
// import theme from "theme";
// import { getIsPro } from "stores/modules/app.user/selectors";

const FormikTextField = memo<TextFieldProps & { name: string }>(
  ({ name, type, ...props }) => {
    const [field] = useFastField({ name, type });
    return <TextField variant="standard" {...field} {...props} />;
  }
);
const FormikArrayTextField = memo<TextFieldProps & { name: string }>(
  ({ name, ...props }) => {
    const inputProps = { ...props };
    const [field] = useField({ name, type: inputProps.type });
    return <TextField variant="standard" {...field} {...inputProps} />;
  }
);

const CharacterForm = () => {
  const dispatch = useDispatch();
  const { values, setFieldValue } =
    useFormikContext<Omit<Character, "active">>();
  // const isPro = useSelector(getIsPro);

  const dialog = useDialog();
  const dialog2 = useDialog();
  const onOpenDialog = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelectDir = "characters";
      })
    );
    dialog.open();
  }, [dispatch, dialog]);
  const onOpenDialog2 = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelectDir = "characters";
      })
    );
    dialog2.open();
  }, [dispatch, dialog2]);
  const onSelectImage = useCallback(
    (url) => {
      setFieldValue("iconUrl", url);
    },
    [setFieldValue]
  );
  const [faceIndex, setFaceIndex] = useState(-1);
  const [t] = useTranslation();

  // const diceSkin = useMemo(
  //   () => getDiceSkin(values.diceSkin.d4),
  //   [values.diceSkin.d4]
  // );
  // const [openDiceSkinSelect, setOpenDiceSkinSelect] = useState(false);
  // const onOpenDiceSkinSelect = useCallback(() => {
  //   setOpenDiceSkinSelect(true);
  // }, [setOpenDiceSkinSelect]);
  // const onCloseDiceSkinSelect = useCallback(() => {
  //   setOpenDiceSkinSelect(false);
  // }, [setOpenDiceSkinSelect]);
  // const onSelectDiceSkin = useCallback(
  //   (diceSkinId: string) => {
  //     const diceSkin: DiceSkinSettings = {
  //       d4: diceSkinId,
  //       d6: diceSkinId,
  //       d8: diceSkinId,
  //       d10: diceSkinId,
  //       d12: diceSkinId,
  //       d20: diceSkinId,
  //       d100: diceSkinId,
  //     };
  //     setFieldValue("diceSkin", diceSkin);
  //   },
  //   [setFieldValue]
  // );

  return (
    <Form autoComplete="off">
      <Styled.Section>
        <Styled.Group>
          <IconButton size="small" onClick={onOpenDialog}>
            <Avatar src={toCDNUrl(values.iconUrl)} />
          </IconButton>
          <FileListDialog {...dialog.dialogProps} onSelect={onSelectImage} />
          <FormikTextField
            label={t("名前")}
            name="name"
            margin="dense"
            fullWidth
          />
          <FormikArrayTextField
            label={t("イニシアティブ")}
            name="initiative"
            margin="dense"
            type="number"
            fullWidth
          />
        </Styled.Group>
        <FormikTextField
          label={t("キャラクターメモ")}
          name="memo"
          variant="filled"
          margin="dense"
          fullWidth
          multiline
          rows={4}
        />
        <Styled.Group>
          <FormikTextField
            label={t("駒サイズ")}
            name="width"
            margin="dense"
            type="number"
            fullWidth
            inputProps={{ min: "1", max: "30" }}
          />
          <FormikTextField
            label={t("x")}
            name="x"
            margin="dense"
            type="number"
            fullWidth
          />
          <FormikTextField
            label={t("y")}
            name="y"
            margin="dense"
            type="number"
            fullWidth
          />
        </Styled.Group>
        <FormikTextField
          label={t("参照URL")}
          name="externalUrl"
          margin="dense"
          fullWidth
          placeholder={t("キャラクターシートなどの参照URL")}
        />
      </Styled.Section>
      <Styled.Section>
        <FieldArray name="faces">
          {({ push, remove, replace }) => (
            <>
              <Toolbar variant="dense" disableGutters>
                <Styled.Title variant="subtitle2">
                  {t("立ち絵・差分")}
                </Styled.Title>
                <IconButton
                  onClick={() => {
                    setFaceIndex(-1);
                    onOpenDialog2();
                  }}
                  size="small"
                  edge="end"
                >
                  <AddIcon />
                </IconButton>
              </Toolbar>
              <Typography
                variant="caption"
                display="block"
                style={{ marginBottom: 16 }}
              >
                {t(
                  "発言時にメッセージボックスに表示される画像を設定します。例えば、「＠笑顔」とラベルに設定した場合、発言時に「…@笑顔」のように付け加えることで差分を切り替えます。"
                )}
              </Typography>
              <FileListDialog
                {...dialog2.dialogProps}
                onSelect={(url) => {
                  if (faceIndex >= 0) {
                    replace(faceIndex, {
                      ...values.faces[faceIndex],
                      iconUrl: url,
                    });
                  } else {
                    push({ label: "", iconUrl: url });
                  }
                }}
              />
              {values.faces.map((face, index) => (
                <Styled.Group key={index}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setFaceIndex(index);
                      onOpenDialog2();
                    }}
                  >
                    <Avatar src={toCDNUrl(face.iconUrl)} />
                  </IconButton>
                  <FormikArrayTextField
                    label={t("ラベル")}
                    name={`faces[${index}].label`}
                    margin="dense"
                    fullWidth
                    placeholder={t("@表情名やファンブル・クリティカルなど")}
                  />
                  <IconButton onClick={() => remove(index)} size="small">
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    disabled={face.iconUrl === values.iconUrl}
                    size="small"
                    onClick={() => setFieldValue("iconUrl", face.iconUrl)}
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                </Styled.Group>
              ))}
            </>
          )}
        </FieldArray>
      </Styled.Section>
      <Styled.Section>
        <FieldArray name="status">
          {({ push, remove }) => (
            <>
              <Toolbar variant="dense" disableGutters>
                <Styled.Title variant="subtitle2">
                  {t("ステータス")}
                </Styled.Title>
                <IconButton
                  onClick={() => remove(values.status.length - 1)}
                  disabled={values.status.length < 1}
                  size="small"
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton
                  onClick={() => push({ label: "", max: 0, value: 0 })}
                  size="small"
                  edge="end"
                >
                  <AddIcon />
                </IconButton>
              </Toolbar>
              <Typography
                variant="caption"
                display="block"
                style={{ marginBottom: 16 }}
              >
                {t(
                  "HPやMPなどのキャラクターに連動して変動するステータスを設定します。{ラベル名}のように発言するとチャットから現在値を参照することができます。"
                )}
              </Typography>
              {values.status.map((_, index) => (
                <Styled.Group key={index}>
                  <FormikTextField
                    label={t("ラベル")}
                    name={`status[${index}].label`}
                    margin="dense"
                    fullWidth
                  />
                  <FormikTextField
                    label={t("現在値")}
                    name={`status[${index}].value`}
                    margin="dense"
                    fullWidth
                  />
                  <FormikTextField
                    label={t("最大値")}
                    name={`status[${index}].max`}
                    margin="dense"
                    fullWidth
                  />
                </Styled.Group>
              ))}
            </>
          )}
        </FieldArray>
      </Styled.Section>
      <Styled.Section>
        <FieldArray name="params">
          {({ push, remove }) => (
            <>
              <Toolbar variant="dense" disableGutters>
                <Styled.Title variant="subtitle2">
                  {t("パラメータ")}
                </Styled.Title>
                <IconButton
                  onClick={() => remove(values.params.length - 1)}
                  disabled={values.params.length < 1}
                  size="small"
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton
                  onClick={() => push({ label: "", value: 0 })}
                  size="small"
                  edge="end"
                >
                  <AddIcon />
                </IconButton>
              </Toolbar>
              <Typography
                variant="caption"
                display="block"
                style={{ marginBottom: 16 }}
              >
                {t(
                  "キャラクターに対してめったに変動しないパラメータを設定します。{ラベル名}のように発言するとチャットから値を参照することができます。"
                )}
              </Typography>
              {values.params.map((_, index) => (
                <Styled.Group key={index}>
                  <FormikTextField
                    label={t("ラベル")}
                    name={`params[${index}].label`}
                    margin="dense"
                    fullWidth
                  />
                  <FormikTextField
                    label={t("値")}
                    name={`params[${index}].value`}
                    margin="dense"
                    fullWidth
                  />
                </Styled.Group>
              ))}
            </>
          )}
        </FieldArray>
      </Styled.Section>
      <Styled.Section>
        <Toolbar variant="dense" disableGutters>
          <Styled.Title variant="subtitle2">
            {t("チャットパレット")}
          </Styled.Title>
        </Toolbar>
        <Typography
          variant="caption"
          display="block"
          style={{ marginBottom: 16 }}
        >
          {t(
            "1d100 などのダイスコマンドやキャラクターに紐づくチャットコマンドを改行区切りで登録します。"
          )}
        </Typography>
        <FormikTextField
          label={t("チャットパレット")}
          name="commands"
          variant="filled"
          margin="dense"
          fullWidth
          multiline
          rows={6}
        />
      </Styled.Section>
      <List>
        <ListItem>
          <ListItemText
            primary={t("ステータスを非公開にする")}
            secondary={t("秘匿NPC・敵キャラクターなど")}
          />
          <ListItemSecondaryAction>
            <Switch
              name="secret"
              checked={values.secret}
              onChange={(_, checked) => setFieldValue("secret", checked)}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("発言時キャラクターを表示しない")}
            secondary={t("駒自体を立ち絵のように見せる場合")}
          />
          <ListItemSecondaryAction>
            <Switch
              name="invisible"
              checked={values.invisible}
              onChange={(_, checked) => setFieldValue("invisible", checked)}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("盤面キャラクター一覧に表示しない")}
            secondary={t("GM駒などステータス管理が必要ないもの")}
          />
          <ListItemSecondaryAction>
            <Switch
              name="hideStatus"
              checked={values.hideStatus}
              onChange={(_, checked) => setFieldValue("hideStatus", checked)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {/* <Styled.Section>
        <Toolbar variant="dense" disableGutters>
          <Styled.Title variant="subtitle2">
            {t("ダイススキン")} <StyledCcfoliaProIcon />
          </Styled.Title>
        </Toolbar>
        <Typography variant="caption" display="block">
          {t(
            "このキャラクターでダイスロールをした際のダイスの種類を変更できます。"
          )}
        </Typography>
        <List>
          <ListItem button disabled={!isPro} onClick={onOpenDiceSkinSelect}>
            <ListItemAvatar>
              <StyledAvatar src={diceSkin?.thumbnailUrl} />
            </ListItemAvatar>
            <ListItemText primary={diceSkin?.name || "未設定"} />
            <ListItemSecondaryAction>
              {isPro && <EditIcon />}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <DiceSkinSelectDialog
          open={openDiceSkinSelect}
          target="character"
          diceSkinId={diceSkin?.id}
          onClose={onCloseDiceSkinSelect}
          onSelect={onSelectDiceSkin}
        />
      </Styled.Section> */}
    </Form>
  );
};

const Styled = {
  Group: styled.div`
    display: flex;
    align-items: center;
    .MuiTextField-root {
      flex: 1;
    }
    > * + * {
      margin-left: 16px;
    }
    .MuiAvatar-root {
      background: rgba(0, 0, 0, 0.2);
      img {
        object-position: 50% 0%;
      }
      &.MuiAvatar-colorDefault {
        color: #363636;
      }
    }
  `,

  Section: styled.div`
    margin-top: 16px;
  `,
  Title: styled(Typography)`
    flex-grow: 1;
  `,
  ImageButton: styled(ButtonBase)`
    margin: 16px 0;
    display: block;
    height: 200px;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  `,
};

// const StyledAvatar = styled(Avatar)`
//   background: rgba(0, 0, 0, 0.4);
//   .MuiAvatar-img {
//     max-width: 100%;
//     max-height: 100%;
//     width: auto;
//     height: auto;
//     object-fit: unset;
//   }
// `;

// const StyledCcfoliaProIcon = styled(CcfoliaProIcon)`
//   margin-left: 4px;
//   fill: ${theme.palette.text.primary};
// `;

export default memo(CharacterForm);
