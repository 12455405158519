import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "initializer";
import { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getAppState } from "stores/modules/app.state/selectors";
import { getUid } from "stores/modules/app.user/selectors";
import { getUserMediumDirectory } from "stores/modules/entities.user.mediumDirectories/selectors";

type MigrationMap = {
  bgm: string;
  sound: string;
  effect: string;
};

const replaceOldDirectory = async (uid: string, migration: MigrationMap) => {
  const mediumRef = collection(db, "users", uid, "media");

  const media = await getDocs(
    query(mediumRef, where("dir", "in", ["bgm", "sound", "effect"]), limit(500))
  );
  if (media.size === 0) {
    return;
  }

  const batch = writeBatch(db);
  for (const medium of media.docs) {
    const oldDir = medium.data().dir;
    batch.update(doc(mediumRef, medium.id), "dir", migration[oldDir] || oldDir);
  }
  await batch.commit();
};

const OldMediumDirectoryReplacer = () => {
  const uid = useSelector(getUid);
  const isAnonymous = useSelector((state) => getAppState(state, "isAnonymous"));
  const dirIds = useSelector(getUserMediumDirectory);
  const replaced = useRef(false);

  useEffect(() => {
    if (uid == null || isAnonymous || dirIds.length === 0 || replaced.current) {
      return;
    }

    const migrationMap: MigrationMap = {
      bgm: dirIds.includes("bgm01") ? "bgm01" : dirIds[0],
      sound: dirIds.includes("bgm02") ? "bgm02" : dirIds[0],
      effect: dirIds.includes("se01") ? "se01" : dirIds[0],
    };

    replaceOldDirectory(uid, migrationMap).then(() => {
      replaced.current = true;
    });
  }, [uid, dirIds, isAnonymous]);
  return null;
};

export default memo(OldMediumDirectoryReplacer);
