import React, { ReactNode } from "react";
import styled from "styled-components";
import theme from "theme";
import {
  Typography,
  DialogContent,
  Portal,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Rnd } from "react-rnd";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

const dialogContentStyle = { padding: 0 };
const stopPropagation = (e) => e.stopPropagation();

const FloatWindow: React.FC<{
  open: boolean;
  title: string;
  onEdit?: () => any;
  onClose: () => any;
  onAdd?: () => any;
  children: ReactNode;
}> = (props) => {
  const { open, title, onEdit, onClose, onAdd, children } = props;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultWidth = 320;
  const defaultHeight = matches ? 400 : 280;
  const gridSize = 16;
  const x =
    ~~((window.innerWidth / 2 - defaultWidth / 2) / gridSize) * gridSize;
  const y =
    ~~((window.innerHeight / 2 - defaultHeight / 2) / gridSize) * gridSize;
  if (!open) return null;
  return (
    <Portal>
      <Rnd
        disableDragging={matches}
        default={{
          x,
          y,
          width: defaultWidth,
          height: defaultHeight,
        }}
        bounds="window"
        minWidth={320}
        minHeight={280}
        style={{ zIndex: theme.zIndex.drawer + 1 }}
        resizeGrid={[gridSize, gridSize]}
      >
        <Container square>
          <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar variant="dense">
              <Typography variant="subtitle2">{title}</Typography>
              <DialogHeadActions>
                {onAdd ? (
                  <IconButton onClick={onAdd} size="small">
                    <AddIcon />
                  </IconButton>
                ) : null}
                {onEdit ? (
                  <IconButton onClick={onEdit} size="small">
                    <EditIcon />
                  </IconButton>
                ) : null}
                <IconButton onClick={onClose} size="small" edge="end">
                  <CloseIcon />
                </IconButton>
              </DialogHeadActions>
            </Toolbar>
          </AppBar>
          <DialogContent
            style={dialogContentStyle}
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
          >
            {children}
          </DialogContent>
          {/* <DialogActions style={dialogActionsStyle}>
            <Button fullWidth onClick={onClose} size="small">
              Close
            </Button>
          </DialogActions> */}
        </Container>
      </Rnd>
    </Portal>
  );
};

const Container = styled(Paper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(44, 44, 44, 0.87);
  z-index: ${theme.zIndex.drawer + 1};
`;

// const DialogCloseButton = styled(Fab)``;

// const DialogHeadToolbar = styled(Toolbar)`
//   padding: 0 16px;
//   display: flex;
//   align-items: center;
// `;

const DialogHeadActions = styled.div`
  margin-left: auto;
  > .MuiIconButton-root + .MuiIconButton-root {
    margin-left: 4px;
  }
`;

export default FloatWindow;
