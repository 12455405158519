import types from "./types";
import { AppState } from "./";

const actions = {
  appStateMutate: (mutate: (state: AppState) => any) => {
    return {
      type: types.APP_STATE_MUTATE,
      mutate,
    };
  },
};

export default actions;
