import { ActionTypes, ActionGenerators } from "./types";

export const createTypes = <Name extends string>(
  name: Name
): ActionTypes<Name> => {
  return {
    init: `entities/${name}/INIT`,
    add: `entities/${name}/ADD`,
    update: `entities/${name}/UPDATE`,
    remove: `entities/${name}/REMOVE`,
    reorder: `entities/${name}/REORDER`,
    sort: `entities/${name}/SORT`,
    groupReorder: `entities/${name}/GROUP_REORDER`,
    groupRemove: `entities/${name}/GROUP_REMOVE`,
  } as const;
};

export const createActions =
  <Data>() =>
  <Name extends string>(
    types: ActionTypes<Name>
  ): ActionGenerators<Data, Name> => {
    return {
      init() {
        return {
          type: types.init,
        };
      },
      add(id, data) {
        return {
          type: types.add,
          id,
          data,
        };
      },
      update(id, data) {
        return {
          type: types.update,
          id,
          data,
        };
      },
      remove(id) {
        return {
          type: types.remove,
          id,
        };
      },
      groupRemove(id) {
        return {
          type: types.groupRemove,
          id,
        };
      },
      reorder(startIndex, endIndex) {
        return {
          type: types.reorder,
          startIndex,
          endIndex,
        };
      },
      sort(ids) {
        return {
          type: types.sort,
          ids,
        };
      },
      groupReorder(field, key, startIndex, endIndex) {
        return {
          type: types.groupReorder,
          field,
          key,
          startIndex,
          endIndex,
        };
      },
    };
  };
