import { memo, RefObject, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import { Formik } from "formik";
import { Dialog } from "@mui/material";
import { Form, useField } from "formik";
import { DialogActions, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TimerRef } from "containers/Timer";

const FormikTextField = memo((props: any) => {
  const { name, type } = props;
  const [field] = useField({ name, type });
  return <TextField variant="standard" {...field} {...props} />;
});

const TimerSettingDialog = (props: { timerRef: RefObject<TimerRef> }) => {
  const d = useDispatch();
  const [t] = useTranslation();
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomTimerSetting")
  );
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomTimerSetting = false;
      })
    );
  }, []);
  const onSubmit = useCallback(
    (values) => {
      if (values.min || values.sec) {
        const duration = ~~values.min * 60 + ~~values.sec;
        d(
          store.updateCurrentRoom({
            timer: {
              duration,
              startTime: 0,
              current: 0,
            },
          })
        );
        if (props.timerRef.current) {
          props.timerRef.current.start(duration * 1000);
        }
      }
      onClose();
    },
    [onClose]
  );
  return (
    <Formik onSubmit={onSubmit} initialValues={{ min: 0, sec: 0 }}>
      {({ submitForm, dirty }) => (
        <Dialog open={open} onClose={onClose}>
          <Form autoComplete="off">
            <FieldGroup>
              <FormikTextField label={t("分")} name="min" type="number" />
              <FormikTextField label={t("秒")} name="sec" type="number" />
            </FieldGroup>
            <DialogActions style={{ background: "rgba(0, 0, 0, 0.24)" }}>
              <Button fullWidth onClick={submitForm} color="primary">
                {t("START")}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

const FieldGroup = styled.div`
  padding: 16px;
  display: flex;
  > * + * {
    margin-left: 16px;
  }
`;

export default memo(TimerSettingDialog);
