import types from "./types";
import { combineReducers } from "redux";
import {
  createByIdReducer,
  createIndexReducer,
  // createGroupByReducer
} from "../firestoreModuleUtils";
import { Effect } from "./records";

const byId = createByIdReducer<Effect>()(types);
const allIds = createIndexReducer(types);

const effectsReducer = combineReducers({
  byId,
  allIds,
});

export default effectsReducer;
