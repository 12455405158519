import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import store from "stores/interfaces";
import { TextField, Box, IconButton, TextFieldProps } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Formik, Form, useField } from "formik";
import { useTranslation } from "react-i18next";

const RoomSelectionForm: React.FC = () => {
  const [t] = useTranslation();
  const $ = useEnhance();

  return (
    <Formik onSubmit={$.onSubmit} initialValues={{ url: "" }}>
      {(formikBag) => (
        <Form>
          <Box padding={2} display="flex" justifyContent="flex-start">
            <FormikTextField
              label={t("部屋IDを指定して入室")}
              fullWidth
              name="roomId"
              type="text"
              placeholder={t("9文字の半角英数字")}
            />
            <IconButton type="submit" size="large">
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

type FormikHooksProps = { name: string; type: string };
const _FormikTextField: React.FC<TextFieldProps & FormikHooksProps> = ({
  name,
  type,
  ...props
}) => {
  const [field] = useField({ name, type });
  return <TextField variant="standard" {...field} {...props} />;
};

const FormikTextField = memo(_FormikTextField);

const useEnhance = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback((values) => {
    dispatch(store.loginRoom(values.roomId));
  }, []);

  return {
    onSubmit,
  };
};

export default memo(RoomSelectionForm);
