import { memo } from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";
import MediumListOfficialPlaylistTabs from "./MediumListOfficialPlaylistTabs";
import MediumListLibraryTabs from "./MediumListLibraryTabs";

const MediumListTabs = () => {
  const group = useSelector((state) => {
    return store.getAppState(state, "userMediumListGroup");
  });

  switch (group) {
    case "officialPlaylist":
      return <MediumListOfficialPlaylistTabs />;
    case "library":
    default:
      return <MediumListLibraryTabs />;
  }
};

export default memo(MediumListTabs);
