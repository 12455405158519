import styled from "styled-components";
import {
  Typography,
  AppBar,
  Toolbar,
  Avatar,
  IconButton,
  Menu,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuList,
  MenuItem,
  Divider,
} from "@mui/material";
import version from "version";
import { Link } from "react-router-dom";
import Spacer from "components/Spacer";
import { useDispatch, useSelector } from "react-redux";
import { getAppState } from "stores/modules/app.state/selectors";
import toCDNUrl from "modules/toCDNUrl";
import { RefObject, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { signOut } from "stores/modules/app.user/operations";

const Header = () => {
  const photoUrl = useSelector((state) => getAppState(state, "photoUrl"));
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <StyledAppBar elevation={0}>
      <Toolbar>
        <Link to="/">
          <Avatar src="/android-chrome-192x192.png" />
        </Link>
        <StyledAppBarTitle variant="h6">CCFOLIA</StyledAppBarTitle>
        <StyledAppBarCaption variant="caption">{version}</StyledAppBarCaption>
        <Spacer />
        <IconButton
          size="small"
          edge="end"
          ref={ref}
          onClick={() => setOpen(true)}
        >
          <Avatar src={toCDNUrl(photoUrl) || "/ccfolia.png"} />
        </IconButton>
        <HeaderMenu open={open} onClose={() => setOpen(false)} anchorEl={ref} />
      </Toolbar>
    </StyledAppBar>
  );
};

type HeaderMenuProps = {
  open: boolean;
  onClose: () => void;
  anchorEl: RefObject<HTMLButtonElement>;
};

const HeaderMenu = ({ open, onClose, anchorEl }: HeaderMenuProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const displayName = useSelector((state) => getAppState(state, "displayName"));
  const photoUrl = useSelector((state) => getAppState(state, "photoUrl"));
  const isAnonymous = useSelector((state) => getAppState(state, "isAnonymous"));
  const uid = useSelector((state) => getAppState(state, "uid"));
  const authed = uid != null && !isAnonymous;

  const onClickLogout = useCallback(() => {
    dispatch(signOut());
    onClose();
  }, [dispatch]);

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl.current}
      style={{ zIndex: theme.zIndex.modal + 2 }}
    >
      <List disablePadding>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={
                isAnonymous
                  ? t("ゲストユーザー")
                  : displayName || t("匿名ユーザー")
              }
              src={photoUrl || "/ccfolia.png"}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              isAnonymous
                ? t("ゲストユーザー")
                : displayName || t("匿名ユーザー")
            }
            secondary={isAnonymous ? t("SNS未認証") : t("SNS認証済み")}
          />
        </ListItem>
      </List>
      <MenuList>
        <MenuItem component={Link} to="/">
          {t("ホームに戻る")}
        </MenuItem>
        {authed && (
          <MenuItem onClick={onClickLogout}>{t("ログアウト")}</MenuItem>
        )}
      </MenuList>
      <Divider />
      <MenuList>
        <MenuItem
          component="a"
          href="https://docs.ccfolia.com/"
          target="_blank"
        >
          {t("ヘルプ")}
        </MenuItem>
        <MenuItem component="a" href="/termsOfService.html" target="_blank">
          {t("利用規約")}
        </MenuItem>
        <MenuItem component="a" href="/privacyPolicy.html" target="_blank">
          {t("プライバシーポリシー")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const StyledAppBar = styled(AppBar)`
  background: transparent;
`;
const StyledAppBarTitle = styled(Typography)`
  margin-left: 8px;
`;
const StyledAppBarCaption = styled(Typography)`
  flex-grow: 1;
`;

export default Header;
