import types from "./types";
import { Diceroll } from ".";

const actions = {
  add: (diceroll: Diceroll) => {
    return {
      type: types.add,
      id: diceroll.id,
      data: diceroll,
    };
  },
  remove: (id: string) => {
    return {
      type: types.remove,
      id,
    };
  },
};

export default actions;
