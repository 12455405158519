import { memo, useCallback } from "react";
import { Form, Formik } from "formik";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createApi } from "unsplash-js";
import store from "stores/interfaces";

const unsplash = createApi({
  apiUrl: "https://ccfolia-ws-ogjjhc5pra-an.a.run.app/unsplash-proxy",
});

const HTTP_STATUS_CODE_TOO_MANY_REQUESTS = 429;

const UnsplashSearchForm = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const lastQuery = useSelector((state) =>
    store.getAppState(state, "unsplashQuery")
  );

  const onSubmit = useCallback(
    (values: { query: string }) => {
      if (values.query.length === 0) {
        return;
      }

      dispatch(store.appStateMutate((state) => (state.unsplashLoading = true)));

      unsplash.search
        .getPhotos({
          query: values.query,
          orientation: "landscape",
          perPage: 30,
        })
        .then((result) => {
          if (result.type === "success") {
            dispatch(
              store.appStateMutate((state) => {
                state.unsplashSearchResult = result.response.results;
                state.unsplashError = null;
              })
            );
          } else if (result.type === "error") {
            dispatch(
              store.appStateMutate((state) => {
                state.unsplashSearchResult = null;
                state.unsplashError =
                  result.status === HTTP_STATUS_CODE_TOO_MANY_REQUESTS
                    ? "reached-rate-limit"
                    : "unknown";
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            store.appStateMutate((state) => {
              state.unsplashSearchResult = null;
              state.unsplashError = "unknown";
            })
          );
        })
        .finally(() =>
          dispatch(
            store.appStateMutate((state) => {
              state.unsplashQuery = values.query;
              state.unsplashLoading = false;
            })
          )
        );
    },
    [dispatch]
  );

  return (
    <Box
      paddingLeft={3}
      paddingRight={2}
      paddingTop={1}
      minHeight="48px"
      boxSizing="border-box"
    >
      <Formik onSubmit={onSubmit} initialValues={{ query: lastQuery }}>
        {(props) => (
          <Form>
            <FormControl fullWidth variant="standard">
              <Input
                type="text"
                name="query"
                placeholder={t("キーワード（英語）")}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.query}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton type="submit" size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default memo(UnsplashSearchForm);
