import React, { memo, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import store from "stores/interfaces";

import { Dialog } from "@mui/material";
import { Formik } from "formik";
import SceneEdit from "../SceneEdit";

const SceneEditDialog = () => {
  const d = useDispatch();
  const { open, sceneId } = useSelector(
    (state) => ({
      open: store.getAppState(state, "openRoomScene"),
      sceneId: store.getAppState(state, "openRoomSceneId"),
    }),
    shallowEqual
  );
  const scene = useSelector((state) => {
    if (!sceneId) return null;
    return store.getRoomSceneById(state, sceneId);
  });
  // todo
  const roomId = useSelector((state) => store.getAppState(state, "roomId"));
  const onSubmit = useCallback(
    (values) => {
      if (!roomId || !sceneId) return;
      d(store.updateRoomScene(roomId, sceneId, values));
      d(
        store.appStateMutate((state) => {
          state.openRoomScene = false;
        })
      );
    },
    [roomId, sceneId, d]
  );
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: scene?.name || "",
        text: scene?.text || "",
      }}
      enableReinitialize
    >
      {({ submitForm }) => (
        <Dialog open={open} onClose={submitForm} maxWidth="xs" fullWidth>
          <SceneEdit />
        </Dialog>
      )}
    </Formik>
  );
};

export default memo(SceneEditDialog);
