import actions from "./actions";
import { db } from "initializer";
import {
  createSubscribeCollection,
  createSubscribeDocument,
} from "../firestoreModuleUtils";
import { getUserCharacterByName, getCharacterCountByName } from "./selectors";
import {
  Character,
  CharacterRecord,
  CharacterRecord_V2,
  UpdateCharacter,
} from "./records";
import { appStateMutate } from "../app.state/operations";
import { DefaultThunk } from "stores";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import i18next from "i18next";
import {
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  writeBatch,
} from "firebase/firestore";

const characterConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(character: UpdateCharacter) {
    return character;
  },
  fromFirestore(snapshot, options): Character {
    const data = snapshot.data(options)!;
    return CharacterRecord(data);
  },
};

export const charactersRef = (roomId: string) =>
  collection(db, "rooms", roomId, "characters").withConverter(
    characterConverter
  );

export const subscribeRoomCharacters = createSubscribeCollection(
  actions,
  (roomId: string) => charactersRef(roomId)
);
export const subscribeRoomCharacter = createSubscribeDocument(
  actions,
  (props: { roomId: string; characterId: string }) =>
    doc(charactersRef(props.roomId), props.characterId)
);

export const addCharacter = (roomId: string, data: UpdateCharacter) => () => {
  return addDoc(charactersRef(roomId), {
    ...CharacterRecord_V2(data),
    updatedAt: Date.now(),
    createdAt: Date.now(),
  });
};

export const importRoomCharacters =
  (charactersData: { [characterId: string]: UpdateCharacter }): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid) return;
    const characterIds = Object.keys(charactersData);
    const batch = writeBatch(db);
    const ref = charactersRef(roomId);
    characterIds.forEach((characterId) => {
      batch.set(
        doc(ref, characterId),
        CharacterRecord_V2({
          ...charactersData[characterId],
          owner: uid,
        })
      );
    });
    return batch.commit();
  };

const parseClipboardTextIfCharacter = (text) => {
  try {
    const parsed = JSON.parse(text);
    return parsed.kind === "character" ? parsed.data : null;
  } catch (_) {
    return null;
  }
};

export const importRoomCharacterFromClipboardText =
  (text: string): DefaultThunk =>
  (dispatch) => {
    const character = parseClipboardTextIfCharacter(text);
    if (character != null) {
      character.active = true;
      dispatch(addRoomCharacterToCenter(character));
    } else {
      alert(i18next.t("クリップボードからのデータ読み込みに失敗しました"));
    }
  };

export const addRoomCharacter =
  (data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid) return null;
    return addDoc(charactersRef(roomId), {
      ...CharacterRecord_V2(data),
      owner: uid,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
  };

const addRoomCharacterToCenter =
  (data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);

    if (!roomId || !uid) return null;

    const cellSize = state.app.state.roomScreenCellSize;
    const record = CharacterRecord_V2(data);
    record.x = (-record.width * cellSize) / 2;
    record.y = (-record.height * cellSize) / 2;

    return addDoc(charactersRef(roomId), {
      ...record,
      owner: uid,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
  };

export const addRoomCharacterWithEdit =
  (data: UpdateCharacter): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid) return null;
    return addDoc(charactersRef(roomId), {
      ...CharacterRecord_V2(data),
      owner: uid,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    }).then((docRef: any) => {
      dispatch(
        appStateMutate((state: any) => {
          state.openRoomCharacter = true;
          state.openRoomCharacterId = docRef.id;
        })
      );
    });
  };

export const updateCharacter =
  (roomId: string, characterId: string, data: UpdateCharacter) => () => {
    // format
    if (data.width !== undefined) {
      const size = Math.min(Math.max(data.width, 1), 100);
      data.width = size;
      data.height = size;
    }
    if (data.x !== undefined && data.y !== undefined) {
      data.x = ~~data.x;
      data.y = ~~data.y;
    }
    return setDoc(
      doc(charactersRef(roomId), characterId),
      {
        ...data,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateRoomCharacter =
  (characterId: string, data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return null;
    // format
    if (data.width !== undefined) {
      const size = Math.min(Math.max(data.width, 1), 100);
      data.width = size;
      data.height = size;
    }
    if (data.x !== undefined && data.y !== undefined) {
      data.x = ~~data.x;
      data.y = ~~data.y;
    }
    return setDoc(
      doc(charactersRef(roomId), characterId),
      {
        ...data,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateCurrentRoomCharacter =
  (data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const characterId = state.app.state.openRoomCharacterId;
    if (!roomId || !characterId) return null;
    // format
    if (data.width !== undefined) {
      const size = Math.min(Math.max(data.width, 1), 100);
      data.width = size;
      data.height = size;
    }
    if (data.x !== undefined && data.y !== undefined) {
      data.x = ~~data.x;
      data.y = ~~data.y;
    }

    return setDoc(
      doc(charactersRef(roomId), characterId),
      {
        ...data,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateRoomCharacterState =
  (
    roomId: string,
    uid: string,
    characterName: string,
    speaking: boolean
  ): DefaultThunk =>
  (_, getState) => {
    const character = getUserCharacterByName(getState(), {
      name: characterName,
      uid,
    });
    if (!character) return null;
    return setDoc(
      doc(charactersRef(roomId), character._id),
      {
        speaking,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const deleteCharacter = (roomId: string, characterId: string) => () => {
  return deleteDoc(doc(charactersRef(roomId), characterId));
};

export const deleteRoomCharacter =
  (characterId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return null;
    return deleteDoc(doc(charactersRef(roomId), characterId));
  };

export const duplicateCharacter =
  (roomId: string, characterId: string) => () => {
    return getDoc(doc(charactersRef(roomId), characterId)).then(
      (docSnapshot) => {
        const data = docSnapshot.data();
        if (!data) return;
        addDoc(charactersRef(roomId), {
          ...CharacterRecord(data),
          updatedAt: Date.now(),
          createdAt: Date.now(),
        });
      }
    );
  };

export const originalName = (name: string): string =>
  name.replace(/^(.+)\(\d+\)$/, "$1");
export const duplicateRoomCharacter =
  (characterId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const character = state.entities.roomCharacters.byId[characterId];
    if (!roomId || !character) return null;
    const clone = CharacterRecord(character);
    const duplicateNum = getCharacterCountByName(state, clone);
    clone.name = originalName(clone.name) + "(" + duplicateNum + ")";
    return addDoc(charactersRef(roomId), {
      ...clone,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
  };
