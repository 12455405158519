import { memo, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import store from "stores/interfaces";
import { ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";

const PanelListItem = ({ markerId, roomId }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const marker = useSelector(
    (state) => store.getRoomMarkerById(state, roomId, markerId),
    shallowEqual
  );

  const onEdit = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMarkerDetail = true;
        state.openRoomMarkerDetailId = markerId;
      })
    );
  }, [markerId, dispatch]);

  return (
    <ListItem button onClick={onEdit}>
      <ListItemAvatar>
        <Avatar src={toCDNUrl(marker.imageUrl)} />
      </ListItemAvatar>
      <ListItemText
        primary={marker.text || t("NOTEXT")}
        primaryTypographyProps={{ noWrap: true, display: "block" }}
        secondary={`[${marker.z}] ${marker.width} × ${marker.height}`}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  );
};

export default memo(PanelListItem);
