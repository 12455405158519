import React from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";

import DiceSymbol from "./DiceSymbol";

type DiceSymbolsProps = {
  roomId: string;
};

const DiceSymbols: React.FC<DiceSymbolsProps> = (props) => {
  const diceIds = useSelector((state) => {
    return store.getRoomDiceIds(state);
  });
  return (
    <>
      {diceIds.map((diceId) => (
        <DiceSymbol key={diceId} diceId={diceId} />
      ))}
    </>
  );
};

export default React.memo(DiceSymbols);
