import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";

import MyCharacterList from "../MyCharacterList";
import FloatWindow from "components/FloatWindow";

import { useTranslation } from "react-i18next";

const MyCharacterListDrawer = () => {
  const d = useDispatch();
  const [t] = useTranslation();
  const open = useSelector(
    (state) => store.getAppState(state, "openRoomControlWindows").character.open
  );
  // const width = useSelector(store.getMenuWidth);
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomControlWindows.character.open = false;
        state.openRoomControls = null;
      })
    );
  }, [d]);
  // todo
  const onAdd = useCallback(() => {
    d(store.addRoomCharacterWithEdit({}));
  }, [d]);
  return (
    <FloatWindow
      onClose={onClose}
      onAdd={onAdd}
      open={open}
      title={t("マイキャラクター一覧")}
    >
      <MyCharacterList />
    </FloatWindow>
  );
};

export default memo(MyCharacterListDrawer);
