import { memo } from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";
import { List } from "@mui/material";

import PanelListItem from "./MarkerListItem";

const PanelList = () => {
  const roomId =
    useSelector((state) => store.getAppState(state, "roomId")) || "";
  const markerIds = useSelector((state) => {
    return store.getRoomMarkerIds(state, { roomId });
  });

  return (
    <List dense>
      {markerIds.map((markerId) => (
        <PanelListItem key={markerId} markerId={markerId} roomId={roomId} />
      ))}
    </List>
  );
};

export default memo(PanelList);
