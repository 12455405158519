import React, { useCallback } from "react";
import { connect, DefaultRootState, ResolveThunks } from "react-redux";
import store from "stores/interfaces";

import Dialog from "@mui/material/Dialog";
import DeckDetail, { FormData } from "./DeckDetail";
import { FormProvider, useForm } from "react-hook-form";

type OriginProps = {
  roomId: string;
  uid: string;
};
type Props = OriginProps &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

const DeckDetailDialog = (props: Props) => {
  const $ = useEnhance(props);
  return (
    <Dialog open={!!props.open} onClose={$.onClose} fullWidth maxWidth="xs">
      <FormProvider {...$.formMethods}>
        <DeckDetail
          roomId={props.roomId}
          deckId={props.deckId}
          uid={props.uid}
          onClose={$.onClose}
          onSubmit={$.onSubmit}
        />
      </FormProvider>
    </Dialog>
  );
};

const useEnhance = (props: Props) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      width: 1,
      height: 1,
      locked: false,
      freezed: false,
    },
  });

  const onSubmit = useCallback(
    (values: FormData) => {
      if (props.deckId) {
        props.updateRoomDeck(props.deckId, {
          width: Math.min(~~values.width, 1000),
          height: Math.min(~~values.height, 1000),
          locked: values.locked,
          freezed: values.freezed,
        });
      }
    },
    [props.deckId]
  );

  const onClose = useCallback(() => {
    onSubmit(formMethods.getValues());
    props.appStateMutate((state) => {
      state.openRoomDeckDetail = false;
    });
  }, [onSubmit, formMethods.getValues]);

  return {
    formMethods,
    onSubmit: formMethods.handleSubmit(onSubmit),
    onClose,
  };
};

const mapStateToProps = (state: DefaultRootState, props: OriginProps) => {
  return {
    open: store.getAppState(state, "openRoomDeckDetail"),
    deckId: store.getAppState(state, "openRoomDeckDetailId") || "",
  };
};

const mapDispatchToProps = {
  ...store,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeckDetailDialog);
