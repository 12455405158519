import { useCallback, useState, useRef, memo, MouseEvent } from "react";
import styled from "styled-components";
import theme from "theme";
import { Fab, IconButton, Popover } from "@mui/material";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import StarIcon from "@mui/icons-material/Star";
import SmileIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import { socket } from "modules/socket";
import throttle from "lodash/throttle";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { addEmote } from "stores/modules/app.emotes/operations";
import store from "stores/interfaces";

const sendEmote = throttle(
  (roomId: string, emoteKind: string, dispatch: Dispatch<any>) => {
    const posPercent = Math.floor(Math.random() * 90) + 5;
    const internalId = Math.random().toString(32).substring(2);

    socket.emit("emote", { roomId, emoteKind, posPercent });
    dispatch(addEmote({ id: internalId, kind: emoteKind, posPercent }));
  },
  100
);

const stopPropagation = (event: MouseEvent) => event.stopPropagation();

const EmoteMenu = (props: { roomId: string }) => {
  const $ = useEnhance(props);
  const enabledEmote = useSelector(
    (state) => store.getCurrentRoom(state)?.features.emote || false
  );

  if (!enabledEmote) {
    return null;
  }

  return (
    <>
      <Popover
        anchorEl={$.anchorEl.current}
        open={$.open}
        onClose={$.onToggle}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onDoubleClick={stopPropagation}
      >
        <IconButton onClick={$.onSendThumbUp} size="large">
          <ThumbUpIcon />
        </IconButton>
        <IconButton onClick={$.onSendStar} size="large">
          <StarIcon />
        </IconButton>
        <IconButton onClick={$.onSendSmile} size="large">
          <SmileIcon />
        </IconButton>
      </Popover>
      <FloatFab onClick={$.onToggle} ref={$.anchorEl}>
        <ThumbUpIcon />
      </FloatFab>
    </>
  );
};

const FloatFab = styled(Fab)`
  position: absolute;
  right: 16px;
  bottom: 84px;
  z-index: 103;
  background: ${theme.palette.grey[100]};
`;

const useEnhance = (props) => {
  const dispatch = useDispatch();
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const onSendThumbUp = useCallback(() => {
    sendEmote(props.roomId, "thumbup", dispatch);
  }, [props.roomId]);
  const onSendStar = useCallback(() => {
    sendEmote(props.roomId, "star", dispatch);
  }, [props.roomId]);
  const onSendSmile = useCallback(() => {
    sendEmote(props.roomId, "smile", dispatch);
  }, [props.roomId]);

  return {
    onSendThumbUp,
    onSendStar,
    onSendSmile,
    onToggle,
    open,
    anchorEl,
  };
};

export default memo(EmoteMenu);
