import { fixSelectionOnDragStop } from "modules/drag";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const useFieldRnd = (
  initialPosition: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
  },
  onUpdate: (position: Position) => any
) => {
  const previousPosition = useRef({
    x: 0,
    y: 0,
    width: 1,
    height: 1,
    ...initialPosition,
  });
  const cellSize = useSelector((state) =>
    store.getAppState(state, "roomScreenCellSize")
  );
  const scale = useSelector((state) =>
    store.getAppState(state, "roomScreenScale")
  );
  const alignWithGrid = useSelector(
    (state) => store.getCurrentRoom(state)?.alignWithGrid ?? true
  );

  const [position, setPosition] = useState(
    getDisplayPosition(
      {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
        ...initialPosition,
      },
      cellSize
    )
  );
  useEffect(() => {
    const pos = {
      x: 0,
      y: 0,
      width: 1,
      height: 1,
      ...initialPosition,
    };
    setPosition(getDisplayPosition(pos, cellSize));
    previousPosition.current = pos;
  }, [
    setPosition,
    cellSize,
    initialPosition.x,
    initialPosition.y,
    initialPosition.width,
    initialPosition.height,
  ]);
  const onDragStop = useCallback(
    (_, data) => {
      fixSelectionOnDragStop();
      const [nextPosition, basePosition] = getNormalizedDisplayPosition(
        {
          ...position,
          x: alignWithGrid ? Math.round(data.lastX) : data.lastX,
          y: alignWithGrid ? Math.round(data.lastY) : data.lastY,
        },
        cellSize,
        alignWithGrid
      );
      setPosition(nextPosition);
      if (
        previousPosition.current.x !== basePosition.x ||
        previousPosition.current.y !== basePosition.y ||
        previousPosition.current.width !== basePosition.width ||
        previousPosition.current.height !== basePosition.height
      ) {
        previousPosition.current = basePosition;
        onUpdate(basePosition);
      }
    },
    [setPosition, position, cellSize, alignWithGrid]
  );
  const onResizeStop = useCallback(
    (_a, _b, ref, _d, position) => {
      const [nextPosition, basePosition] = getNormalizedDisplayPosition(
        {
          ...position,
          width: ~~ref.offsetWidth,
          height: ~~ref.offsetHeight,
        },
        cellSize,
        alignWithGrid
      );
      setPosition(nextPosition);
      if (
        previousPosition.current.x !== basePosition.x ||
        previousPosition.current.y !== basePosition.y ||
        previousPosition.current.width !== basePosition.width ||
        previousPosition.current.height !== basePosition.height
      ) {
        previousPosition.current = basePosition;
        onUpdate(basePosition);
      }
    },
    [setPosition, cellSize, onUpdate, alignWithGrid]
  );
  return useMemo(
    () => ({
      position: { x: position.x, y: position.y },
      size: { width: position.width, height: position.height },
      scale,
      minWidth: cellSize,
      minHeight: cellSize,
      onDragStop,
      onResizeStop,
    }),
    [
      cellSize,
      scale,
      onDragStop,
      onResizeStop,
      position.width,
      position.height,
      position.x,
      position.y,
    ]
  );
};

const getDisplayPosition = (
  { x, y, width, height }: Position,
  cellSize: number
): Position => {
  return {
    x: x * cellSize,
    y: y * cellSize,
    width: width * cellSize,
    height: height * cellSize,
  };
};
const getBasePosition = (
  { x, y, width, height }: Position,
  cellSize: number,
  alignWithGrid: boolean
): Position => {
  const cellX = x / cellSize;
  const cellY = y / cellSize;
  const cellWidth = Math.round(width / cellSize);
  const cellHeight = Math.round(height / cellSize);
  return {
    x: alignWithGrid ? Math.round(cellX) : cellX,
    y: alignWithGrid ? Math.round(cellY) : cellY,
    width: cellWidth,
    height: cellHeight,
  };
};
const getNormalizedDisplayPosition = (
  position: Position,
  cellSize: number,
  alignWithGrid: boolean
): [Position, Position] => {
  const basePosition = getBasePosition(position, cellSize, alignWithGrid);
  return [getDisplayPosition(basePosition, cellSize), basePosition];
};

export default useFieldRnd;
