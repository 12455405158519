import React, { memo } from "react";
import { Snackbar, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import store from "stores/interfaces";

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const [text, setText] = React.useState<string>("");
  const notifications = useSelector(store.getNotifications);
  const onClose = React.useCallback(() => {
    dispatch(store.shiftNotifications());
  }, [dispatch]);
  React.useLayoutEffect(() => {
    if (notifications.length > 0) {
      setText(notifications[0]);
    }
  }, [setText, notifications]);
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={notifications.length > 0}
      message={text}
      action={
        <Button color="secondary" size="small" onClick={onClose}>
          OK
        </Button>
      }
    />
  );
};

export default memo(Notifications);
