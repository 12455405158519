import actions from "./actions";
import { db } from "initializer";
import sendEvent from "modules/sendEvent";
import shortid from "shortid";
import {
  createSubscribeCollection,
  createSubscribeDocument,
} from "../firestoreModuleUtils";
import { appStateMutate } from "../app.state/operations";
import { Room, Marker, UpdateRoom, UpdateMarker } from "./records";
import { RoomRecord } from "./records";
import { DefaultRootState } from "react-redux";
import { createSnapshot, duplicateRoomFunc } from "api";
import { DefaultThunk } from "stores";
import { getUid } from "../app.user/selectors";
import {
  getCurrentRoom,
  getMaxZIndexInMarkers,
  getRoomById,
  getRoomMarkerById,
} from "./selectors";
import { MarkerRecord, MessageGroup } from ".";
import mesureImage from "modules/mesureImage";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  FirestoreDataConverter,
  query,
  where,
  writeBatch,
  deleteField,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { produce } from "immer";

const roomConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(room: UpdateRoom): DocumentData {
    return room;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return RoomRecord(data);
  },
};

export const roomsRef = collection(db, "rooms").withConverter(roomConverter);
const keysRef = collection(db, "keys"); // todo

export const subscribeUserRooms = createSubscribeCollection(
  actions,
  (_: unknown, state: DefaultRootState) => {
    const uid = getUid(state);
    if (!uid) return null;
    return query(
      roomsRef,
      where("owner", "==", uid),
      where("archived", "==", false)
    );
  }
);
export const subscribeRoom = createSubscribeDocument(actions, (id: string) =>
  doc(roomsRef, id)
);
export const addRoomWithRoomSettings =
  (): DefaultThunk => (dispatch, getState) => {
    sendEvent("addRoom");
    const batch = writeBatch(db);
    const id = shortid.generate();
    const state = getState();
    const uid = getUid(state);
    if (!uid) return Promise.reject();
    // todo dupricate check
    dispatch(
      appStateMutate((state) => {
        state.roomId = id;
        // Homeコンポーネント内のRoomSettingsでroomIdを使うために設定
        state.openRoomSettings = true;
      })
    );
    batch.set(
      doc(roomsRef, id),
      RoomRecord({
        owner: uid,
        diceBotName: "DiceBot",
        diceBotSystem: "DiceBot",
      })
    );
    batch.set(doc(keysRef, id), {
      keys: {
        master: shortid.generate(),
        player: shortid.generate(),
        audience: shortid.generate(),
      },
      owner: uid,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    });
    return batch.commit();
  };
export const updateRoom = (id: string, data: UpdateRoom) => () => {
  return updateDoc(doc(roomsRef, id), {
    updatedAt: Date.now(),
    ...data,
  });
};

export const updateCurrentRoomFeature =
  (name: keyof Room["features"], flag: boolean): DefaultThunk =>
  (dispatch, getState) => {
    const room = getCurrentRoom(getState());
    if (!room) return;
    dispatch(
      updateCurrentRoom({ features: { ...room.features, [name]: flag } })
    );
  };

export const updateCurrentRoom =
  (data: UpdateRoom): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    await updateDoc(doc(roomsRef, roomId), {
      updatedAt: Date.now(),
      ...data,
    });
  };

export const archiveRoom = (roomId: string) => () => {
  sendEvent("archiveRoom");
  return setDoc(
    doc(roomsRef, roomId),
    {
      updatedAt: Date.now(),
      archived: true,
    },
    { merge: true }
  );
};

export const updateRoomMarker =
  (roomId: string, markerId: string, data: UpdateMarker) => () => {
    return setDoc(
      doc(roomsRef, roomId),
      {
        markers: {
          [markerId]: {
            ...data,
          },
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const upsertRoomMarker =
  (roomId: string, markers: Record<string, Marker>) => () => {
    return updateDoc(doc(roomsRef, roomId), {
      markers,
      updatedAt: Date.now(),
    });
  };

export const updateCurrentRoomMarker =
  (data: UpdateMarker): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    const markerId = getState().app.state.openRoomMarkerDetailId;
    if (!roomId || !markerId) return;
    return setDoc(
      doc(roomsRef, roomId),
      {
        markers: {
          [markerId]: {
            ...data,
          },
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const addRoomMarker =
  (data: UpdateMarker): DefaultThunk =>
  async (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const maxZIndex = getMaxZIndexInMarkers(getState());
    const cellSize = 24; // todo
    if (data.imageUrl && !data.width && !data.height) {
      const image = await mesureImage(data.imageUrl, cellSize, {
        width: data.width || 2,
        height: data.height || 2,
      });
      data.width = image.width;
      data.height = image.height;
      data.x = Math.ceil(-image.width / 2);
      data.y = Math.ceil(-image.height / 2);
    }
    return setDoc(
      doc(roomsRef, roomId),
      {
        markers: {
          [Date.now().toString(16)]: MarkerRecord({
            ...data,
            z: maxZIndex,
          }),
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const cloneRoomMarker =
  (markerId): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    const marker = getRoomMarkerById(state, roomId, markerId);
    return setDoc(
      doc(roomsRef, roomId),
      {
        markers: {
          [Date.now().toString(16)]: MarkerRecord(marker),
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const migrationMessageChannels = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const room = getCurrentRoom(state);
  if (!room || room.messageChannels.length == 0) return;

  const messageGroups = room.messageChannels.map((channel): MessageGroup => {
    return {
      id: channel,
      name: channel,
      kind: "public",
    };
  });

  updateDoc(doc(roomsRef, room._id), {
    messageChannels: arrayRemove(...room.messageChannels),
    messageGroups: arrayUnion(...messageGroups),
    updatedAt: Date.now(),
  });
};

export const addRoomMessageGroup =
  (data: MessageGroup): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) {
      return;
    }

    updateDoc(doc(roomsRef, roomId), {
      messageGroups: arrayUnion(data),
      updatedAt: Date.now(),
    });
  };

export const updateRoomMessageGroup =
  (data: MessageGroup): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const room = getCurrentRoom(state);
    if (!roomId || !room) {
      return;
    }

    const idx = room.messageGroups.findIndex((group) => group.id === data.id);
    if (idx < 0) {
      return;
    }

    const messageGroups = produce(room.messageGroups, (draft) => {
      draft[idx] = data;
    });

    updateDoc(doc(roomsRef, roomId), {
      messageGroups,
      updatedAt: Date.now(),
    });
  };

export const removeRoomMessageGroup =
  (groupId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const room = getRoomById(state, roomId || "");
    if (!roomId || !room) return;

    const index = room.messageGroups.findIndex((group) => group.id === groupId);
    const group = room.messageGroups[index];

    if (!group) return;

    dispatch(
      appStateMutate((state) => {
        state.roomChatTab = "main";
      })
    );

    updateDoc(doc(roomsRef, roomId), {
      messageGroups: arrayRemove(group),
      updatedAt: Date.now(),
    });
  };

export const deleteRoomMarker = (id: string, markerId: string) => () => {
  return setDoc(
    doc(roomsRef, id),
    {
      markers: {
        [markerId]: deleteField(),
      },
      updatedAt: Date.now(),
    },
    { merge: true }
  );
};

export const saveInitialRoomState = async (roomId: string) => {
  const { snapshotId } = await createSnapshot(roomId);
  const roomDoc = await getDoc(doc(roomsRef, roomId));

  if (!roomDoc.exists()) {
    return;
  }

  const room = RoomRecord(roomDoc.data());
  const thumbnail = room.foregroundUrl;
  const data: UpdateRoom = {
    updatedAt: Date.now(),
    initialSavedata: { thumbnail, snapshotId },
  };
  await updateDoc(doc(roomsRef, roomId), data);
};

export const deleteRoom = (id: string) => () => {
  const batch = writeBatch(db);
  batch.delete(doc(roomsRef, id));
  batch.delete(doc(keysRef, id));
  return batch.commit();
};

export const duplicateRoom =
  (roomId: string): DefaultThunk =>
  () => {
    sendEvent("duplicateRoom");
    const subcollections = [
      "characters",
      "effects",
      "items",
      "scenes",
      "notes",
      "dices",
      "commands",
      "decks",
    ];
    return duplicateRoomFunc({
      roomId,
      subcollections,
    });
  };

export const loginRoom = (id: string) => () => {
  return getDoc(doc(roomsRef, id)).then((doc) => {
    if (doc.exists()) {
      window.location.href = `/rooms/${id}`;
    } else {
      window.alert("存在しないルームIDです");
    }
  });
};
