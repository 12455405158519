import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { child, ref, update } from "firebase/database";
import { rtdb } from "initializer";
import { DefaultRootState } from "react-redux";

export interface Member {
  id: string;
  uid: string;
  displayName: string;
  photoUrl: string;
  isAnonymous: boolean;
  typing: string | null;
  updatedAt: Number;
}

const membersAdapter = createEntityAdapter<Member>({
  selectId: (member) => member.uid,
  sortComparer: (a, b) => Number(a.updatedAt) - Number(b.updatedAt),
});

export const roomMemberUpdate = createAsyncThunk(
  "room/members/update",
  async (typing: string, { getState }) => {
    const state = getState() as DefaultRootState;
    const displayName = state.app.user.displayName;
    const uid = state.app.user.uid;
    const roomId = state.app.state.roomId;
    const isAnonymous = state.app.user.isAnonymous;
    if (!uid || !roomId) return;
    update(child(child(ref(rtdb, "room-members"), roomId), uid), {
      uid: uid,
      displayName: displayName,
      isAnonymous,
      typing: typing,
      updatedAt: Date.now(),
    });
  }
);

const getInitialState = () => {
  return {
    members: membersAdapter.getInitialState(),
  };
};

const roomSlice = createSlice({
  name: "room",
  initialState: getInitialState(),
  reducers: {
    roomMemberAdded: (state, action: PayloadAction<Member>) => {
      membersAdapter.addOne(state.members, action.payload);
    },
    roomMemberUpdated: (state, action: PayloadAction<Member>) => {
      membersAdapter.upsertOne(state.members, action.payload);
    },
    roomMemberRemoved: (state, action: PayloadAction<string>) => {
      membersAdapter.removeOne(state.members, action.payload);
    },
  },
});

export const { roomMemberAdded, roomMemberUpdated, roomMemberRemoved } =
  roomSlice.actions;
export default roomSlice.reducer;
