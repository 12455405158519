import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";

import Dialog from "@mui/material/Dialog";
import MarkerDetail, { FormData } from "./MarkerDetail";

import { FormProvider, useForm } from "react-hook-form";
import { toClickAction } from "containers/PanelDetail/FormClickAction";

type MarkerDetailDialogProps = {
  roomId: string;
};

const MarkerDetailDialog = ({ roomId }: MarkerDetailDialogProps) => {
  const dispatch = useDispatch();
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomMarkerDetail")
  );
  const markerId = useSelector((state) =>
    store.getAppState(state, "openRoomMarkerDetailId")
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      width: 1,
      height: 1,
      z: 0,
      text: "",
      locked: false,
      freezed: false,
      clickAction: "none",
      linkUrl: "",
      messageText: "",
    },
  });

  const onSubmit = useCallback(
    (values: FormData) => {
      if (markerId) {
        dispatch(
          store.updateRoomMarker(roomId, markerId, {
            text: values.text || "",
            width: Math.min(~~values.width, 1000),
            height: Math.min(~~values.height, 1000),
            z: Math.min(~~values.z, 999),
            locked: values.locked,
            freezed: values.freezed,
            clickAction: toClickAction(values),
          })
        );
      }
    },
    [roomId, markerId, dispatch]
  );

  const onClose = useCallback(() => {
    formMethods.handleSubmit(onSubmit)();
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMarkerDetail = false;
      })
    );
  }, [onSubmit, formMethods.handleSubmit, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <FormProvider {...formMethods}>
        <MarkerDetail
          roomId={roomId}
          markerId={markerId || ""}
          onClose={onClose}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </Dialog>
  );
};

export default memo(MarkerDetailDialog);
