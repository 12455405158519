import { createSelector } from "reselect";
import { Room, Marker } from "./records";
import { DefaultRootState } from "react-redux";
import { RoomRecord } from "./records";
import { getUid } from "../app.user/selectors";
import { getHasEditableRole } from "../entities.room.members/selectors";
import {
  getAppState,
  getRoomMemberDirectMessageTabs,
} from "../app.state/selectors";
import i18n from "i18next";

const getRooms = (state: DefaultRootState) => {
  return state.entities.rooms.byId;
};

export const getCurrentRoom = (state: DefaultRootState) => {
  const roomId = state.app.state.roomId;
  if (!roomId) return;
  return state.entities.rooms.byId[roomId];
};

export const getCurrentRoomIsMonitored = (state: DefaultRootState) => {
  const room = getCurrentRoom(state);
  const hasEditableRole = getHasEditableRole(state);
  if (room == null || !hasEditableRole) {
    return false;
  }
  return room.monitored;
};

export const getCurrentRoomTimer = (state: DefaultRootState) => {
  const roomId = state.app.state.roomId;
  if (!roomId) return emptyRoom.timer;
  return state.entities.rooms.byId[roomId].timer;
};

const emptyRoom: Room = RoomRecord({});
export const getRoomById = (state: DefaultRootState, roomId: string) => {
  return state.entities.rooms.byId[roomId] || emptyRoom;
};

export const getUserRoomIds = (state: DefaultRootState) => {
  const uid = getUid(state);
  if (!uid) return [];
  return state.entities.rooms.groupByUser[uid] || [];
};

export const getUserSortedRoomIds = createSelector(
  [getRooms, getUserRoomIds],
  (rooms, ids) => {
    const sortedIds = [...ids];
    sortedIds.sort((id1, id2) => {
      const room1 = rooms[id1];
      const room2 = rooms[id2];
      if (room1.createdAt < room2.createdAt) {
        return 1;
      } else if (room1.createdAt > room2.createdAt) {
        return -1;
      } else {
        return 0;
      }
    });
    return sortedIds;
  }
);

const emptyMarker: Marker = {
  x: 0,
  y: 0,
  z: 1,
  width: 2,
  height: 2,
  locked: false,
  freezed: false,
  imageUrl: null,
  text: "",
  clickAction: null,
};
export const getRoomMarkerById = (
  state: DefaultRootState,
  roomId: string,
  markerId: string
): Marker => {
  const room = state.entities.rooms.byId[roomId];
  if (!room || !room.markers) return emptyMarker;
  return room.markers[markerId] || emptyMarker;
};

const getProps = (_: unknown, props: { roomId: string }) => props.roomId;
export const getRoomMarkerIds = createSelector(
  [getRooms, getProps],
  (rooms, roomId) => {
    if (rooms[roomId]) {
      const roomMarkerIds = Object.keys(rooms[roomId].markers || {});
      return roomMarkerIds.sort((a, b) => a.localeCompare(b));
    } else {
      return [];
    }
  }
);

export const getMaxZIndexInMarkers = createSelector(
  [getCurrentRoom],
  (room) => {
    const markers = room?.markers || {};
    let maxZIndex = 1;

    for (let key in markers) {
      if (markers.hasOwnProperty(key)) {
        maxZIndex = Math.max(maxZIndex, markers[key].z);
      }
    }
    return Math.min(999, maxZIndex);
  }
);

export const getIsAppliedExtentionProduct = createSelector(
  [getCurrentRoom, (_, productId: string) => productId],
  (room, productId) => {
    if (room == null) {
      return false;
    }

    return room.appliedExtentionProductIds.includes(productId);
  }
);

const getMessageGroups = (state: DefaultRootState) => {
  const room = getCurrentRoom(state);
  return room?.messageGroups;
};

export const getJoinedMessageGroups = createSelector(
  [getMessageGroups, getUid],
  (groups, uid) => {
    if (!groups || !uid) {
      return [];
    }

    return groups.filter(
      (group) => group.kind === "public" || group.uids.includes(uid)
    );
  }
);

const getChatTab = (state: DefaultRootState) =>
  getAppState(state, "roomChatTab");

export const getCurrentMessageGroup = createSelector(
  [getMessageGroups, getChatTab],
  (gropus, tab) => {
    return gropus?.find(({ id }) => id === tab);
  }
);

export const getCurrentTabName = (state: DefaultRootState) => {
  const tab = getChatTab(state);
  const group = getCurrentMessageGroup(state);
  if (group) {
    return group.name;
  }

  const dmChannels = getRoomMemberDirectMessageTabs(state);
  const dm = dmChannels.find((dm) => dm.key === tab);
  if (dm) {
    return dm.name || i18n.t("匿名さん") || "匿名さん";
  }

  return tab;
};
