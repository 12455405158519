import {
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onDisconnect,
  ref,
  remove,
} from "firebase/database";
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  connect,
  DefaultRootState,
  ResolveThunks,
  useDispatch,
  useSelector,
} from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";

import Chat from "./Chat";
import Frame from "../Screen/Frame";
import Screen from "../Screen/Screen";
import Header from "./Header";
import Background from "./Background";
import PanelDetail from "../PanelDetail";
import CharacterMenu from "../CharacterMenu";
import ItemMenu from "../ItemMenu";
import DeckMenu from "../DeckMenu";
import DeckDetail from "../DeckDetail";
import MarkerMenu from "../MarkerMenu";
import MarkerDetail from "./MarkerDetail";
import DiceDetail from "./DiceDetail";
import MyCharacterListDrawer from "../MyCharacterListDrawer";
import CutList from "../CutList";
import NoteList from "../NoteList";
import FieldDetail from "./FieldDetail";
import NoteDetail from "../NoteDetail";
import EffectDetail from "../EffectDetail";
import Media from "../Media";
import Timer, { TimerRef } from "../Timer";
import TimerSettingDialog from "../TimerSettingDialog";
import Inspector from "../Inspector";
import Chatpalet from "./Chatpalet";
import ColorPicker from "./ColorPicker";
import DiceBotTypeSelect from "../DiceBotTypeSelect";
import DiceBotInfo from "./DiceBotInfo";
import MemberList from "./MemberList";
import NoteMessageDetail from "../NoteMessageDetail";
import ActiveCharacterList from "../ActiveCharacterList";
import Effects from "./Effects";
import FloatMenu from "./FloatMenu";
import EmoteMenu from "./EmoteMenu";
import MessageBox from "./MessageBox";
import Loader from "./Loader";
import RoomLoader from "./RoomLoader";
import ZoomControls from "./ZoomControls";
import ContextMenu from "../Screen/ContextMenu";
import UserSettings from "../UserSettings";

// new directories
import DynamicFileListDialog from "containers/DynamicFileListDialog";
import ExportRoomDialog from "../ExportRoomDialog";
import SceneListDrawer from "../SceneListDrawer";
import SceneEditDialog from "../SceneEditDialog";
import CharacterFormDialog from "../CharacterFormDialog";
import MessageChannelFormDialog from "../MessageChannelFormDialog";
import MediumListDialog from "../MediumListDialog";
import PanelListDrawer from "../PanelListDrawer";
// import MarkerListDrawer from "containers/MarkerListDrawer";
import Information from "containers/Information";
import CommandLine from "containers/CommandLine";
import Notification from "containers/Notification";
import Login from "containers/Login";
import PlanSelect from "containers/PlanSelect";

import ChatPopupWindow from "containers/ChatPopupWindow";
import UnderConstruction from "./UnderConstruction";
import UserDeleteDialog from "containers/UserDeleteDialog";

import StableHeader from "containers/Header";

// next
// import RoomHeader from "./_next/RoomHeader";
// import RoomChatBar from "./_next/RoomChatBar";

import { rtdb } from "initializer";
import {
  roomMemberAdded,
  roomMemberUpdated,
  roomMemberRemoved,
  roomMemberUpdate,
} from "./roomSlice";
import { connectSocket } from "modules/socket";
import Emotes from "containers/Emotes";
import MarkerListDrawer from "containers/MarkerListDrawer";
import ConfirmTermsAgreement from "containers/ConfirmTermsAgreement";
import VideoPlayer from "containers/VideoPlayer/VideoPlayer";
import ErrorSnackbar from "containers/ErrorSnackbar";
import OldMediumDirectoryReplacer from "containers/OldMediumDirectoryReplacer";
// import PDFViewer from "containers/PDFViewer";
import SavedataDialog from "containers/SavedataDialog/SavedataDialog";
import SaveConfirm from "containers/SavedataDialog/SaveConfirm";
import DeleteConfirm from "containers/SavedataDialog/DeleteConfirm";
import LoadDialog from "containers/SavedataDialog/LoadDialog";
import LoadConfirm from "containers/SavedataDialog/LoadConfirm";
// import DiceTray from "containers/DiceTray";
import RoomVariablesDialog from "containers/RoomVariablesDialog/RoomVariablesDialog";
// import DiceSkinSelect from "containers/DiceSkinSelect";
import RoomSettingsDialog from "containers/RoomSettingsDialog";
import InviteDialog from "containers/InviteDialog";
import AccessDenied from "containers/AccessDenied/AccessDenied";
import { getAppState } from "stores/modules/app.state/selectors";
import { setCurrentOwnRoomMember } from "stores/modules/entities.room.members/operations";
import { getRoomMemberById } from "stores/modules/entities.room.members/selectors";
import DeletedMember from "./DeletedMember";
import ExtentionsDialog from "containers/ExtentionsDialog";
import { useObserveSigninError } from "modules/signin";
import ChatTabSettingsDialog from "containers/ChatTabSettingsDialog";
import MessageNotifier from "containers/MessageNotifier";
// import RoomMessageBox from "./_next/RoomMessageBox";
// import RoomSetting from "./_next/RoomSetting";
// import RoomWindows from "./_next/RoomWindows";

const DiceTray = lazy(() => import("containers/DiceTray"));
const PDFViewer = lazy(() => import("containers/PDFViewer"));

const membersRef = (roomId: string) => ref(rtdb, `room-members/${roomId}`);

const disableScroll = (node: HTMLDivElement | null) => {
  if (node) {
    node.addEventListener("touchmove", (e) => e.preventDefault(), {
      passive: false,
    });
  }
};

type UseRoomMemberProps = {
  roomId: string;
  uid: string;
};

const useRoomMember = ({ roomId, uid }: UseRoomMemberProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!roomId || !uid) return;
    dispatch(roomMemberUpdate("none"));
    const disconnect = onDisconnect(child(membersRef(roomId), uid));
    disconnect.remove();
    const unsubscribeOnChildAdded = onChildAdded(membersRef(roomId), (data) => {
      dispatch(
        roomMemberAdded({
          uid: data.key,
          ...data.val(),
        })
      );
    });
    const unsubscribeOnChildChanged = onChildChanged(
      membersRef(roomId),
      (data) => {
        dispatch(
          roomMemberUpdated({
            uid: data.key,
            ...data.val(),
          })
        );
      }
    );
    const unsubscribeOnChildRemoved = onChildRemoved(
      membersRef(roomId),
      (data) => {
        data.key && dispatch(roomMemberRemoved(data.key));
      }
    );
    return () => {
      disconnect.cancel();
      unsubscribeOnChildAdded();
      unsubscribeOnChildChanged();
      unsubscribeOnChildRemoved();
      remove(child(membersRef(roomId), uid));
    };
  }, [roomId, uid, dispatch]);
};

type OriginProps = {
  roomId: string;
  uid: string;
};
type Props = OriginProps &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

const Room = (props: Props) => {
  const screenCenterRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<TimerRef>(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const onLoginOpen = useCallback(() => {
    setLoginOpen(true);
  }, [setLoginOpen]);
  const onLoginClose = useCallback(() => {
    setLoginOpen(false);
  }, [setLoginOpen]);
  useMounted(props);
  useRoomMember({
    roomId: props.roomId,
    uid: props.uid,
  });
  useObserveSigninError({ openLoginDialog: onLoginOpen });

  const { deletedMember } = useObserveOwnMember();

  if (deletedMember) {
    return (
      <Container>
        <StableHeader />
        <DeletedMember />
      </Container>
    );
  }

  if (
    !props.uid ||
    !props.roomId ||
    !props.room ||
    !props.settingsUid ||
    !props.role ||
    props.uid !== props.settingsUid
  ) {
    return <RoomLoader loading={true} />;
  }

  if (props.role === "denied") {
    return (
      <Container>
        <StableHeader />
        <AccessDenied />
      </Container>
    );
  }

  if (!props.hasEditableRole && props.room.underConstruction) {
    return (
      <Container>
        <Header
          roomId={props.roomId}
          setLoginOpen={setLoginOpen}
          underConstruction={true}
        />
        <Login open={loginOpen} onClose={onLoginClose} />
        <UserSettings uid={props.uid} />
        <PlanSelect />
        <Information />
        <Notification />
        <UnderConstruction />
      </Container>
    );
  }

  return (
    <Container>
      <Header roomId={props.roomId} setLoginOpen={setLoginOpen} />
      <Background backgroundUrl={props.room.backgroundUrl} />
      <Frame>
        <ContextMenu roomId={props.roomId} centerRef={screenCenterRef}>
          <View ref={disableScroll}>
            <Screen
              roomId={props.roomId}
              uid={props.uid}
              centerRef={screenCenterRef}
            />
          </View>
        </ContextMenu>
        <Media roomId={props.roomId} uid={props.uid} />
        {props.room.features?.timer ? (
          <Timer roomId={props.roomId} ref={timerRef} />
        ) : null}
        <FloatMenu timerRef={timerRef} />
        <EmoteMenu roomId={props.roomId} />
        <ZoomControls />
        <MessageBox roomId={props.roomId} uid={props.uid} />
        <ActiveCharacterList roomId={props.roomId} uid={props.uid} />
        <Effects />
        <Emotes roomId={props.roomId} />
        <Suspense fallback={null}>
          <DiceTray />
        </Suspense>
      </Frame>

      <Chat roomId={props.roomId} uid={props.uid} />
      <ChatPopupWindow />
      <MessageChannelFormDialog />
      <ChatTabSettingsDialog />
      <TimerSettingDialog timerRef={timerRef} />
      <MyCharacterListDrawer />
      <SceneListDrawer />
      <PanelListDrawer />
      <MarkerListDrawer />
      <NoteList roomId={props.roomId} uid={props.uid} />
      <CutList roomId={props.roomId} uid={props.uid} />
      {/* Dialogs */}
      <DynamicFileListDialog />
      <ExportRoomDialog />
      <CharacterFormDialog roomId={props.roomId} uid={props.uid} />
      <FieldDetail />
      <NoteDetail roomId={props.roomId} />
      <PanelDetail roomId={props.roomId} />
      <DeckDetail roomId={props.roomId} uid={props.uid} />
      <CharacterMenu />
      <ItemMenu />
      <DeckMenu />
      <MarkerMenu />
      <MarkerDetail roomId={props.roomId} />
      <SceneEditDialog />
      <Chatpalet roomId={props.roomId} />
      <ColorPicker roomId={props.roomId} uid={props.uid} />
      <DiceBotTypeSelect roomId={props.roomId} uid={props.uid} />
      <DiceBotInfo />
      <MediumListDialog />
      <MemberList />
      <RoomVariablesDialog />
      <Login open={loginOpen} onClose={onLoginClose} />
      <UserSettings uid={props.uid} />
      <PlanSelect />
      <UserDeleteDialog />
      <NoteMessageDetail />
      <EffectDetail roomId={props.roomId} />
      <DiceDetail />
      <SavedataDialog />
      {/* <DiceSkinSelect /> */}
      <SaveConfirm />
      <DeleteConfirm />
      <LoadDialog />
      <LoadConfirm />
      {/* <MessageSoundEffect roomId={props.roomId} uid={props.uid} /> */}
      <Inspector />
      <Information />
      <CommandLine />
      <ErrorSnackbar />
      <VideoPlayer id={props.room.video.id} url={props.room.video.url} />
      <Notification />
      <ConfirmTermsAgreement />
      <OldMediumDirectoryReplacer />
      <Suspense fallback={null}>
        <PDFViewer />
      </Suspense>
      <InviteDialog />
      <RoomSettingsDialog />
      <ExtentionsDialog />
      <MessageNotifier />
      <Loader />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* border: solid 5px green; */
  overflow: hidden;
`;

const View = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const useMounted = (props: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    props.appStateMutate((state) => (state.roomId = props.roomId));
  }, [props.roomId]);

  // priority - high
  useEffect(() => {
    return props.subscribeRoom(props.roomId);
  }, [props.roomId]);
  useEffect(() => {
    return props.subscribeRoomMessages(props.roomId);
  }, [props.roomId]);

  // priority - mid
  useEffect(() => {
    if (props.room?._id) {
      return props.subscribeRoomCharacters(props.room?._id);
    }
  }, [props.room?._id]);
  useEffect(() => {
    if (props.room?._id) {
      return props.subscribeRoomItems(props.room._id);
    }
  }, [props.room?._id]);
  useEffect(() => {
    if (props.room?._id) {
      return props.subscribeRoomEffects(props.room._id);
    }
  }, [props.room?._id]);
  useEffect(() => {
    if (props.room?._id) {
      return props.subscribeRoomDices(props.room._id);
    }
  }, [props.room?._id]);
  useEffect(() => {
    if (props.room?._id) {
      return props.subscribeRoomDecks(props.room._id);
    }
  }, [props.room?._id]);
  useEffect(() => {
    return props.subscribeUserSetting(props.uid);
  }, [props.uid]);
  useEffect(() => {
    if (props.room?._id && props.room?.features?.timer) {
      return connectSocket(props.roomId, props.uid);
    }
  }, [props.room?._id, props.uid, props.room?.features?.timer]);
  useEffect(() => {
    if (props.room?._id) {
      dispatch(store.migrationMessageChannels());
    }
  }, [props.room?._id]);

  // owner only
  useEffect(() => {
    if (props.hasEditableRole) {
      return props.subscribeRoomNotes(props.roomId);
    }
  }, [props.roomId, props.hasEditableRole]);
  useEffect(() => {
    if (props.hasEditableRole) {
      return props.subscribeRoomScenes(props.roomId);
    }
  }, [props.roomId, props.hasEditableRole]);
  useEffect(() => {
    if (props.hasEditableRole) {
      return props.subscribeRoomSavedatas(props.roomId);
    }
  }, [props.roomId, props.hasEditableRole]);

  // notification
  useEffect(() => {
    if (props.room?.monitored) {
      props.addNotification(
        t(
          "このルームでは監視モードが有効になっています。ルームマスターに全てのスクリーンパネル、ダイスシンボル、キャラクター駒が見えています。"
        )
      );
    }
  }, [props.room?.monitored]);

  useEffect(() => {
    if (props.room?.underConstruction && props.hasEditableRole) {
      props.addNotification(
        t(
          "このルームでは工事中モードが有効になっています。ルームマスター以外の入室ができません。"
        ) +
          t(
            "保護は完全ではなく、旧バージョンのユーザーからは閲覧されてしまう可能性があります。"
          )
      );
    }
  }, [props.room?.underConstruction, props.hasEditableRole]);

  useEffect(() => {
    return props.subscribeUserFiles({ uid: props.uid, roomId: props.roomId });
  }, [props.uid, props.roomId]);

  // lazyload
  useEffect(() => {
    if (!props.enabledUserFilesLoader) return;
    props.getAllUserFiles(props.uid, props.roomId);
  }, [props.uid, props.roomId, props.enabledUserFilesLoader]);

  useEffect(() => {
    if (!props.enabledUserMediaLoader) {
      return;
    }

    if (props.subscribedUserMediumDir.length === 0) {
      return props.subscribeUserMedia({
        uid: props.uid,
        dirs: [props.userMediumListLibraryTab],
      });
    }

    return props.subscribeUserMedia({
      uid: props.uid,
      dirs: props.subscribedUserMediumDir,
    });
  }, [props.uid, props.enabledUserMediaLoader, props.subscribedUserMediumDir]);

  useEffect(() => {
    return props.subscribeUserMediumDirectory(props.uid);
  }, [props.uid]);

  useEffect(() => {
    return props.subscribeUserHistories(props.uid);
  }, [props.uid]);
  useEffect(() => {
    if (props.room && props.uid && props.room.owner !== props.uid) {
      props.recordCurrentUserHistory();
    }
  }, [props.uid, props.room?.owner]);

  useEffect(() => {
    if (props.uid) {
      const unsubscribe = props.subscribeRoomMembers(props.roomId);
      return () => {
        unsubscribe();
      };
    }
  }, [props.roomId, props.uid]);

  useEffect(() => {
    if (props.room?.archived) {
      window.alert("この部屋は既に削除されています。");
      window.location.href = "/";
    }
  }, [props.room?.archived]);
};

const HOUR_MS = 1000 * 60 * 60;

const useObserveOwnMember = () => {
  const dispatch = useDispatch();
  const displayName = useSelector((state) => getAppState(state, "displayName"));
  const photoUrl = useSelector((state) => getAppState(state, "photoUrl"));
  const isAnonymous = useSelector((state) => getAppState(state, "isAnonymous"));
  const existsMember = useSelector((state) => {
    const uid = getAppState(state, "uid");
    return getRoomMemberById(state, uid || "") != null;
  });

  const [deleted, setDeleted] = useState(false);
  const initialized = useRef<boolean>(false);

  // 変更があった場合に更新する
  useEffect(() => {
    if (initialized.current && !deleted) {
      dispatch(setCurrentOwnRoomMember());
    }
  }, [displayName, photoUrl, isAnonymous, initialized, deleted, dispatch]);

  // 自分のメンバー情報の初期化
  useEffect(() => {
    if (initialized.current && !existsMember) {
      setDeleted(true);
      return;
    }

    if (!existsMember) {
      dispatch(setCurrentOwnRoomMember());
    }
    initialized.current = true;

    const timer = setInterval(() => {
      dispatch(setCurrentOwnRoomMember());
    }, HOUR_MS); // health check every 1hour
    return () => clearInterval(timer);
  }, [existsMember, initialized, dispatch, setDeleted]);

  return { deletedMember: deleted && !existsMember };
};

const mapStateToProps = (state: DefaultRootState, props: OriginProps) => {
  return {
    displayName: store.getAppState(state, "displayName"),
    photoUrl: store.getAppState(state, "photoUrl"),
    room: store.getCurrentRoom(state),
    settingsUid: state.entities.userSetting.uid,
    role: store.getRole(state),
    isAnonymous: store.getAppState(state, "isAnonymous"),
    hasEditableRole: store.getHasEditableRole(state),
    enabledUserFilesLoader: store.getAppState(state, "enabledUserFilesLoader"),
    enabledUserMediaLoader: store.getAppState(state, "enabledUserMediaLoader"),
    subscribedUserMediumDir: store.getAppState(
      state,
      "subscribedUserMediumDir"
    ),
    userMediumListLibraryTab: store.getAppState(
      state,
      "userMediumListLibraryTab"
    ),
  };
};

const mapDispatchToProps = {
  ...store,
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
