import React, { useState, useCallback, useRef, memo, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  Popover,
  InputBase,
  ButtonBase,
  List,
  Divider,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import CharacterNameSelectItem from "./CharacterNameSelectItem";
import toCDNUrl from "modules/toCDNUrl";
import { appStateMutate } from "stores/modules/app.state/operations";

type CharacterNameSelectProps = {
  uid: string;
};

const CharacterNameSelect = ({ uid }: CharacterNameSelectProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const anchorEl = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const name = useSelector((state) => store.getAppState(state, "roomChatName"));
  const character = useSelector((state) =>
    store.getUserCharacterByName(state, {
      name,
      uid,
    })
  );
  const activeCharacterIds = useSelector(store.getMyRoomActiveCharacterIds);
  const inactiveCharacterIds = useSelector(store.getMyRoomIncativeCharacterIds);
  const isRoleAudience = useSelector(store.getIsRoleAudience);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(
        appStateMutate((state) => {
          state.roomChatName = e.currentTarget.value;
        })
      );
    },
    [dispatch]
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onSelect = useCallback(
    (name: string) => {
      dispatch(
        appStateMutate((state) => {
          state.roomChatName = name;
        })
      );
      setOpen(false);
    },
    [setOpen, dispatch]
  );

  return (
    <>
      <Popover open={open} anchorEl={anchorEl.current} onClose={onClose}>
        <List disablePadding>
          {activeCharacterIds.map((characterId) => (
            <CharacterNameSelectItem
              key={characterId}
              characterId={characterId}
              onSelect={onSelect}
              isActive
            />
          ))}
          <Divider variant="middle" />
          {inactiveCharacterIds.map((characterId) => (
            <CharacterNameSelectItem
              key={characterId}
              characterId={characterId}
              onSelect={onSelect}
            />
          ))}
        </List>
      </Popover>
      <Container ref={anchorEl}>
        <Tooltip title={t("キャラクター選択")}>
          <SquareAvatar onClick={onOpen} disabled={isRoleAudience}>
            {character ? (
              <img
                src={toCDNUrl(character.iconUrl) || "/ccfolia.png"}
                draggable={false}
              />
            ) : (
              <FaceIcon />
            )}
          </SquareAvatar>
        </Tooltip>
        <NameTextInput
          name="name"
          value={name}
          onChange={onChange}
          placeholder="noname"
          disabled={isRoleAudience}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const SquareAvatar = styled(ButtonBase)`
  &.MuiButtonBase-root {
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    width: 40px;
    min-width: 40px;
    height: 40px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;

const NameTextInput = styled(InputBase)`
  /* dirty */
  margin-right: 8px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.2);
  flex-grow: 1;
`;

export default memo(CharacterNameSelect);
