import React, { memo, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import theme from "theme";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
  Slider,
} from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import store from "stores/interfaces";

type VideoPlayerProps = {
  id: string | null;
  url: string | null;
};

const VideoPlayer = ({ id, url }: VideoPlayerProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    store.getAppState(state, "openVideoPlayer")
  );
  const blocked = useSelector((state) => store.getOpenInformation(state));
  const prevId = useRef<string | null>(id);
  const muted = useSelector((state) => store.getAppState(state, "muted"));
  const toggleMuted = useCallback(() => {
    dispatch(store.appStateMutate((state) => (state.muted = !state.muted)));
  }, [dispatch]);

  const volume = useSelector((state) =>
    store.getAppState(state, "videoPlayerVolume")
  );
  const onChangeVolume = useCallback(
    (event: unknown, value: number | number[]) => {
      if (typeof value === "number") {
        dispatch(
          store.appStateMutate((state) => (state.videoPlayerVolume = value))
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (id && prevId.current !== id) {
      if (!blocked) {
        dispatch(
          store.appStateMutate((state) => {
            state.openVideoPlayer = true;
          })
        );
      }
      prevId.current = id;
    }
  }, [prevId, id, blocked, dispatch]);

  const onClose = useCallback(() => {
    dispatch(store.appStateMutate((state) => (state.openVideoPlayer = false)));
  }, [dispatch]);

  const onError = useCallback(() => {
    dispatch(
      store.appStateMutate(
        (state) => (state.errorSnackbarMessage = "動画の再生に失敗しました。")
      )
    );
    onClose();
  }, [dispatch, onClose]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth={!fullScreen}
      maxWidth="md"
      PaperProps={{ style: { background: "#222" } }}
    >
      <DialogContent
        style={{
          display: "flex",
          alignItems: "center",
          padding: fullScreen ? "8px" : undefined,
        }}
      >
        <Content>
          <ReactPlayer
            key={url}
            url={url || undefined}
            playing={true}
            muted={muted}
            width="100%"
            height="100%"
            volume={volume}
            playsinline
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                },
              },
            }}
            onError={onError}
          />
        </Content>
      </DialogContent>
      <DialogActions style={{ background: "rgba(0, 0, 0, 0.4)" }}>
        <VolumeBox>
          <IconButton
            color={muted ? "primary" : "default"}
            onClick={toggleMuted}
            size="large"
          >
            <VolumeOffIcon />
          </IconButton>
          <Slider
            disabled={muted}
            value={volume}
            onChange={onChangeVolume}
            max={1}
            min={0}
            step={0.05}
          />
        </VolumeBox>
        <Button onClick={onClose} color="primary">
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Content = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: #000;
  vertical-align: middle;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > .MuiButton-root {
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    bottom: 56px;
    border-radius: 0;
    ${theme.breakpoints.down("sm")} {
      right: 0;
      bottom: 0;
      height: 20px;
      font-size: 10px;
      box-shadow: none;
    }
  }
`;

const VolumeBox = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 200px;
`;

export default memo(VideoPlayer);
