// https://github.com/react-grid-layout/react-draggable/issues/219
export const fixSelectionOnDragStop = () => {
  const selection = window.getSelection();
  if (selection && document.activeElement) {
    const range = document.createRange();
    range.selectNodeContents(document.activeElement);

    selection.removeAllRanges();
    selection.addRange(range);
    selection.extend(document.activeElement);
  }
};
