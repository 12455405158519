import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ClickAction } from "modules/clickAction";
import { memo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FormClickAction = () => {
  const [t] = useTranslation();
  const [autoFocus, setAutoFocus] = useState(false);

  const { control } = useFormContext();

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="select-click-action-label">
          {t("クリックアクション")}
        </InputLabel>
        <Controller
          name="clickAction"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              labelId="select-click-action-label"
              label={t("クリックアクション")}
              fullWidth
              onChange={(event) => {
                setAutoFocus(true);
                setTimeout(() => {
                  onChange(event);
                }, 0);
              }}
              onBlur={onBlur}
              value={value}
              name={name}
              inputRef={ref}
            >
              <MenuItem value="none">{t("使用しない")}</MenuItem>
              {/* <MenuItem value="link">{t("リンクを開く")}</MenuItem> */}
              <MenuItem value="message">{t("チャットに送信")}</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <FormClickActions autoFocus={autoFocus} />
    </>
  );
};

type FormClickActions = {
  autoFocus: boolean;
};

const FormClickActions = ({ autoFocus }: FormClickActions) => {
  const { watch } = useFormContext();
  const type = watch("clickAction");

  switch (type) {
    case "link":
      return <FormClickActionLink autoFocus={autoFocus} />;
    case "message":
      return <FormClickActionMessage autoFocus={autoFocus} />;
    default:
      return null;
  }
};

type FormClickActionDetailProps = {
  autoFocus: boolean;
};

const FormClickActionLink = ({ autoFocus }: FormClickActionDetailProps) => {
  const [t] = useTranslation();
  const { register } = useFormContext();
  return (
    <>
      <Caption>
        {t(
          "パネルをクリックした際に、設定されたURLをブラウザの別タブで開きます。"
        )}
      </Caption>
      <TextField
        variant="standard"
        label="リンクURL"
        type="url"
        fullWidth
        autoFocus={autoFocus}
        {...register("linkUrl")}
      />
    </>
  );
};

const FormClickActionMessage = ({ autoFocus }: FormClickActionDetailProps) => {
  const [t] = useTranslation();
  const { register } = useFormContext();
  return (
    <>
      <Caption>
        {t(
          "パネルをクリックした際に、設定されたテキストをチャットに送信します。"
        )}
      </Caption>
      <TextField
        label="送信するテキスト"
        variant="filled"
        multiline
        maxRows={4}
        fullWidth
        autoFocus={autoFocus}
        {...register("messageText")}
      />
    </>
  );
};

const Caption = styled(Typography).attrs({
  variant: "caption",
  display: "block",
})`
  margin-top: 8px;
  margin-bottom: 16px;
`;

interface FormDataClickAction {
  clickAction: "none" | "link" | "message";
  linkUrl: string;
  messageText: string;
}

export const toClickAction = (
  formData: FormDataClickAction
): ClickAction | null => {
  switch (formData.clickAction) {
    case "link":
      return { type: "link", url: formData.linkUrl };
    case "message":
      return { type: "message", text: formData.messageText };
    default:
      return null;
  }
};

export default memo(FormClickAction);
