import {
  Avatar,
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  MenuItem,
  MenuList,
  Popover,
  PopoverProps,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { createBillingPortalSession } from "api";
import LoadingPromiseMenuItem from "containers/LoadingPromiseMenuItem/LoadingPromiseMenuItem";
import copy from "copy-to-clipboard";
import toCDNUrl from "modules/toCDNUrl";
import { uidToDigest } from "modules/user";
import { MouseEvent, memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import { signOut } from "stores/modules/app.user/operations";
import theme from "theme";
import { getRequiredVerifyEmail } from "stores/modules/app.user/selectors";

type UserMenuProps = PopoverProps & {
  onOpenLogin: () => void;
};

const UserMenu = ({ onOpenLogin, ...props }: UserMenuProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [digest, setDigest] = useState("");
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false);

  const uid = useSelector((state) => getAppState(state, "uid"));
  const photoUrl = useSelector((state) => getAppState(state, "photoUrl"));
  const displayName = useSelector((state) => getAppState(state, "displayName"));
  const isAnonymous = useSelector((state) => getAppState(state, "isAnonymous"));
  const requiredVerifyEmail = useSelector(getRequiredVerifyEmail);

  const onOpenUserSettings = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openUserSettings = true;
      })
    );
  }, [dispatch]);

  const onClickPaymentLog = useCallback(async () => {
    try {
      const { url } = await createBillingPortalSession();
      if (url) {
        window.location.href = url;
      }
    } catch {
      window.alert(t("購入情報がないため、ページを開けませんでした。"));
    }
  }, [t]);

  const onClicknSignOut = useCallback(() => {
    if (window.confirm(t("ログアウトしてよろしいですか？"))) {
      dispatch(signOut());
    }
  }, [t, dispatch]);

  const onCopyDigest = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (copy(digest)) {
        setOpenCopyTooltip(true);
        setTimeout(() => {
          setOpenCopyTooltip(false);
        }, 1000);
      }
    },
    [digest, setOpenCopyTooltip]
  );

  const onOpenCommnadLine = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openRoomCommandLine = true)));
  }, [dispatch]);

  useEffect(() => {
    if (uid) {
      uidToDigest(uid).then(setDigest);
    }
  }, [uid, setDigest]);

  return (
    <Popover {...props}>
      <List disablePadding>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={toCDNUrl(photoUrl) || "/ccfolia.png"} />
          </ListItemAvatar>
          <Box marginY="6px">
            <Typography variant="body1">
              {isAnonymous
                ? t("ゲストユーザー")
                : displayName || t("匿名ユーザー")}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {isAnonymous ? t("SNS未認証") : t("SNS認証済み")}
            </Typography>
            {!isAnonymous && (
              <Tooltip
                title={t("コピーしました")}
                open={openCopyTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Link
                  onClick={onCopyDigest}
                  underline="hover"
                  variant="body2"
                  style={{ cursor: "pointer" }}
                >
                  {digest}
                </Link>
              </Tooltip>
            )}
          </Box>
        </ListItem>
      </List>
      <Divider />
      {!isAnonymous && (
        <>
          <MenuList>
            <MenuItem component="a" href="/home">
              {t("マイルーム")}
            </MenuItem>
            <MenuItem component="a" href="/games">
              {t("ストア")}
            </MenuItem>
            <MenuItem component="a" href="/library">
              {t("ライブラリ")}
            </MenuItem>
          </MenuList>
          <Divider />
        </>
      )}
      <MenuList>
        {isAnonymous ? (
          <MenuItem onClick={onOpenLogin}>{t("ログイン")}</MenuItem>
        ) : (
          <>
            <MenuItem onClick={onOpenUserSettings}>
              {t("アカウント設定")}
              {requiredVerifyEmail && (
                <CircleIcon
                  color="secondary"
                  style={{ fontSize: "8px", marginLeft: "8px" }}
                />
              )}
            </MenuItem>
            <LoadingPromiseMenuItem onClick={onClickPaymentLog}>
              {t("購入履歴")}
            </LoadingPromiseMenuItem>
            <MenuItem onClick={onClicknSignOut}>{t("ログアウト")}</MenuItem>
          </>
        )}
      </MenuList>
      <Divider />
      <MenuList>
        <MenuItem
          component="a"
          href="https://docs.ccfolia.com/"
          target="_blank"
        >
          {t("ヘルプ")}
        </MenuItem>
        <MenuItem component="a" href="/termsOfService.html" target="_blank">
          {t("利用規約")}
        </MenuItem>
        <MenuItem component="a" href="/privacyPolicy.html" target="_blank">
          {t("プライバシーポリシー")}
        </MenuItem>
      </MenuList>
      <Divider />
      <MenuList>
        <MenuItem onClick={onOpenCommnadLine}>{t("開発者コマンド")}</MenuItem>
      </MenuList>
      <Divider />
    </Popover>
  );
};

export default memo(UserMenu);
