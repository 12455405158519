import types from "./types";
import { combineReducers } from "redux";
import {
  createByIdReducer,
  createIndexReducer,
  createGroupByReducer,
} from "../firestoreModuleUtils";
import { UserMedium } from "./records";

const byId = createByIdReducer<UserMedium>()(types);
const allIds = createIndexReducer(types);
const groupByDir = createGroupByReducer<UserMedium>()(types, "dir");

const UserMediaReducer = combineReducers({
  byId,
  allIds,
  groupByDir,
});

export default UserMediaReducer;
