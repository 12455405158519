import actions from "./actions";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import { getMaxZIndex, getRoomItemById, getRoomItemIds } from "./selectors";
import { UpdateItem } from "./";
import { ItemRecord } from "./records";
import { DefaultThunk } from "stores";
import { addCurrentUserFile } from "../entities.user.files/operations";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import mesureImage from "modules/mesureImage";
import {
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { Position } from "react-rnd";
import { getRoomDeckIdByPosition } from "../entities.room.decks/selectors";
import { addRoomDeckItem } from "../entities.room.decks/operations";

const itemConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(item: UpdateItem) {
    return item;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return ItemRecord(data);
  },
};

export const itemsRef = (roomId: string) =>
  collection(db, "rooms", roomId, "items").withConverter(itemConverter);

export const subscribeRoomItems = createSubscribeCollection(
  actions,
  (roomId: string) => query(itemsRef(roomId), orderBy("order"))
);

export const addRoomItem =
  (data: UpdateItem): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const ids = getRoomItemIds(state);
    if (!roomId) return;
    // always new items in the very front
    data.z = getMaxZIndex(state);
    const cellSize = 24; // todo
    if (data.imageUrl && !data.width && !data.height) {
      const image = await mesureImage(data.imageUrl, cellSize, {
        width: data.width || 2,
        height: data.height || 2,
      });
      data.width = image.width;
      data.height = image.height;
      data.x = Math.ceil(-image.width / 2);
      data.y = Math.ceil(-image.height / 2);
    }
    await addDoc(
      itemsRef(roomId),
      ItemRecord({
        ...data,
        order: ids.length,
      })
    );
    return dispatch(orderItems(roomId));
  };

export const reorderItems =
  (roomId: string, order: any): DefaultThunk =>
  (dispatch) => {
    if (order.destination) {
      dispatch(actions.reorder(order.source.index, order.destination.index));
      dispatch(orderItems(roomId));
    }
  };

export const orderItems =
  (roomId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const itemIds = getRoomItemIds(state);
    let batch = writeBatch(db);
    let batchCount = 0;
    itemIds.forEach((itemId, index) => {
      const item = getRoomItemById(state, itemId);
      if (item == null) {
        return;
      }

      if (batchCount > 500) {
        batch.commit();
        batch = writeBatch(db);
        batchCount = 0;
      }
      if (item.order !== index) {
        batchCount++;
        batch.set(
          doc(itemsRef(roomId), itemId),
          { order: index },
          { merge: true }
        );
      }
    });
    batch.commit();
  };

export const importRoomItems =
  (itemsData: { [itemId: string]: UpdateItem }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const itemIds = Object.keys(itemsData);
    const batch = writeBatch(db);
    const ref = itemsRef(roomId);
    itemIds.forEach((itemId) => {
      batch.set(doc(ref, itemId), ItemRecord(itemsData[itemId]));
    });
    return batch.commit();
  };

export const addRoomItemByImageDrop =
  (file: File): DefaultThunk<Promise<void>> =>
  async (dispatch) => {
    const addedFile = (await dispatch(addCurrentUserFile(file, "item"))) as {
      url?: string;
    };
    if (addedFile?.url) {
      const cellSize = 24; // todo
      const image = await mesureImage(addedFile?.url, cellSize, {
        width: 2,
        height: 2,
      });
      const x = -Math.ceil(image.width / 2);
      const y = -Math.ceil(image.height / 2);
      dispatch(
        addRoomItem({
          imageUrl: addedFile.url,
          ...image,
          x,
          y,
        })
      );
    } else {
      alert("File upload is failed");
    }
  };

export const addRoomItemsByImagesDrop =
  (files: File[]): DefaultThunk<Promise<void>> =>
  async (dispatch) => {
    if (files.length > 100) {
      alert("Exceeded the upper limit of 100");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      await dispatch(addRoomItemByImageDrop(files[i]));
    }
  };

export const updateRoomItemLocked =
  (roomId: string, itemId: string, locked: boolean): DefaultThunk =>
  () => {
    if (!roomId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      locked,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItemPosition =
  (roomId: string, itemId: string, { x, y }: { x: number; y: number }) =>
  () => {
    return updateDoc(doc(itemsRef(roomId), itemId), {
      x,
      y,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItemSize =
  (
    roomId: string,
    itemId: string,
    { width, height }: { width: number; height: number }
  ) =>
  () => {
    return updateDoc(doc(itemsRef(roomId), itemId), {
      width,
      height,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItemImage =
  (roomId: string, itemId: string, url: string | null) => () => {
    return updateDoc(doc(itemsRef(roomId), itemId), {
      imageUrl: url,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItem =
  (_: unknown, itemId: string, item: UpdateItem): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

export const dragedItem =
  (itemId: string, position: Position): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const item = getRoomItemById(state, itemId);
    if (!roomId || !item) return;

    const overlappedDeckId = getRoomDeckIdByPosition(state, {
      x: position.x,
      y: position.y,
      width: item.width,
      height: item.height,
      coverImageUrl: item.coverImageUrl,
    });
    if (overlappedDeckId) {
      dispatch(addRoomDeckItem(overlappedDeckId, itemId));
    } else {
      dispatch(updateRoomItem(roomId, itemId, position));
    }
  };

export const updateCurrentRoomItem =
  (item: UpdateItem): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    const itemId = getState().app.state.openRoomPanelDetailId;
    if (!roomId || !itemId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

// Cards
export const takeRoomItem =
  (itemId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName") || "noname";
    const color = getAppState(state, "roomChatColor") || "#cccccc";
    if (!roomId || !itemId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      closed: true,
      owner: uid,
      ownerName: name,
      ownerColor: color,
      updatedAt: Date.now(),
    });
  };

export const closeRoomItem =
  (itemId: string): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId || !itemId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      closed: true,
      owner: null,
      ownerName: null,
      ownerColor: null,
      updatedAt: Date.now(),
    });
  };

export const openRoomItem =
  (itemId: string): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId || !itemId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      closed: false,
      owner: null,
      ownerName: null,
      ownerColor: null,
      updatedAt: Date.now(),
    });
  };

export const deleteRoomItem = (roomId: string, itemId: string) => () => {
  return deleteDoc(doc(itemsRef(roomId), itemId));
};

export const deleteCurrentRoomItem =
  (itemId: string): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    return deleteDoc(doc(itemsRef(roomId), itemId));
  };

export const deleteRoomItems =
  (itemIds: string[]): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const batch = writeBatch(db);
    itemIds.forEach((itemId) => {
      batch.delete(doc(itemsRef(roomId), itemId));
    });
    return batch.commit();
  };

export const closeAllRoomItems = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const roomId = getState().app.state.roomId;
  const itemIds = getRoomItemIds(state);
  if (!roomId) return;
  const batch = writeBatch(db);
  itemIds.forEach((itemId) => {
    const item = getRoomItemById(state, itemId);
    if (item?.active && item?.coverImageUrl) {
      batch.update(doc(itemsRef(roomId), itemId), {
        closed: true,
        owner: null,
      });
    }
  });
  return batch.commit();
};

export const deleteAllRoomItems = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const roomId = getState().app.state.roomId;
  const itemIds = getRoomItemIds(state);
  if (!roomId) return;
  const batch = writeBatch(db);
  itemIds.forEach((itemId) => {
    batch.delete(doc(itemsRef(roomId), itemId));
  });
  return batch.commit();
};
