import React, { memo, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import store from "stores/interfaces";
import { Menu, MenuItem, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const FileListItemMenu = () => {
  const d = useDispatch();
  const [t] = useTranslation();
  const [open, top, left, imageId, image] = useSelector((state) => {
    const imageId = store.getAppState(state, "openImageMenuId");
    return [
      store.getAppState(state, "openImageMenu"),
      store.getAppState(state, "anchorTop"),
      store.getAppState(state, "anchorLeft"),
      imageId,
      store.getUserFileById(state, imageId || ""),
    ] as const;
  }, shallowEqual);
  const onClose = useCallback(() => {
    d(store.appStateMutate((state) => (state.openImageMenu = false)));
  }, []);
  const onDelete = useCallback(() => {
    if (
      imageId &&
      window.confirm(
        t(
          "ファイルを完全に削除します。この画像が参照されていた場合、表示できなくなります。"
        )
      )
    ) {
      d(store.deleteUserFile2(imageId));
    }
    onClose();
  }, [imageId]);
  const onArchive = useCallback(() => {
    if (imageId) {
      d(store.archiveUserFile(imageId));
      onClose();
    }
  }, [imageId]);
  const onShowImage = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openInspector = true;
        state.inspectImageUrl = image?.url || null;
      })
    );
    onClose();
  }, [imageId, image?.url]);
  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ top, left }}
      MenuListProps={{ dense: true }}
      onClose={onClose}
    >
      <MenuItem onClick={onArchive}>{t("アーカイブ")}</MenuItem>
      <MenuItem onClick={onDelete}>{t("削除")}</MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem onClick={onShowImage}>{t("拡大表示")}</MenuItem>
    </Menu>
  );
};

export default memo(FileListItemMenu);
