import types from "./types";
import { AppUser } from "./reducers";

const actions = {
  appUserMutate: (mutate: (state: AppUser) => any) => {
    return {
      type: types.APP_USER_MUTATE,
      mutate,
    };
  },
};

export default actions;
