import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";

import Dialog from "@mui/material/Dialog";
import PanelDetail, { FormData } from "./PanelDetail";
import { FormProvider, useForm } from "react-hook-form";
import { toClickAction } from "./FormClickAction";

type PanelDetailDialogProps = {
  roomId: string;
};

const PanelDetailDialog = ({ roomId }: PanelDetailDialogProps) => {
  const dispatch = useDispatch();
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomPanelDetail")
  );
  const panelId =
    useSelector((state) => store.getAppState(state, "openRoomPanelDetailId")) ||
    "";

  const formMethods = useForm<FormData>({
    defaultValues: {
      width: 1,
      height: 1,
      z: 0,
      memo: "",
      locked: false,
      freezed: false,
      plane: false,
      clickAction: "none",
      linkUrl: "",
      messageText: "",
    },
  });

  const onSubmit = useCallback(
    (values: FormData) => {
      if (panelId) {
        dispatch(
          store.updateRoomItem(roomId, panelId, {
            memo: values.memo || "",
            width: Math.min(~~values.width, 1000),
            height: Math.min(~~values.height, 1000),
            z: Math.min(~~values.z, 999),
            locked: values.locked,
            freezed: values.freezed,
            type: values.plane ? "plane" : "object",
            clickAction: toClickAction(values),
          })
        );
      }
    },
    [roomId, panelId, dispatch]
  );

  const onClose = useCallback(() => {
    formMethods.handleSubmit(onSubmit)();
    onSubmit(formMethods.getValues());
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomPanelDetail = false;
      })
    );
  }, [onSubmit, formMethods.handleSubmit, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <FormProvider {...formMethods}>
        <PanelDetail
          roomId={roomId}
          panelId={panelId}
          onClose={onClose}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </Dialog>
  );
};

export default memo(PanelDetailDialog);
