import { UserHistory } from "./";
import { DefaultRootState } from "react-redux";

const emptyUserHistory: UserHistory = {};

export const getUserHistories = (state: DefaultRootState) => {
  return state.entities.userHistories.byId;
};

export const getUserHistoryById = (state: DefaultRootState, id: string) => {
  return state.entities.userHistories.byId[id] || emptyUserHistory;
};

export const getUserHistoryIds = (state: DefaultRootState) => {
  return state.entities.userHistories.allIds || [];
};
