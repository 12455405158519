import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { uidToDigest } from "modules/user";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAppState } from "stores/modules/app.state/selectors";
import styled from "styled-components";
import theme from "theme";

const AccessDenied = () => {
  const [t] = useTranslation();
  const uid = useSelector((state) => getAppState(state, "uid"));
  const displayName = useSelector((state) => getAppState(state, "displayName"));
  const isAnonymous = useSelector((state) => getAppState(state, "isAnonymous"));
  const photoUrl = useSelector((state) => getAppState(state, "photoUrl"));

  const [digest, setDigest] = useState("");

  useEffect(() => {
    if (uid) {
      uidToDigest(uid).then(setDigest);
    }
  }, [uid, setDigest]);

  return (
    <Container>
      <Paper>
        <Box padding="0 24px" paddingBottom="24px">
          <Typography padding="16px 0" fontSize="20px" fontWeight="bold">
            {t("アクセスが制限されています")}
          </Typography>
          <UserArea>
            <Avatar src={photoUrl || "/ccfolia.png"} />
            {isAnonymous ? (
              <div>
                <Typography noWrap fontWeight="bold">
                  {t("ゲストユーザー")}
                </Typography>
              </div>
            ) : (
              <div>
                <Typography noWrap marginBottom="4px" fontWeight="bold">
                  {displayName || t("ログイン済みユーザー")}
                </Typography>
                <Typography
                  color={theme.palette.text.secondary}
                  fontSize="14px"
                >
                  {digest}
                </Typography>
              </div>
            )}
          </UserArea>
          <Typography fontSize="14px" margin="16px 0">
            {t(
              "現在ご利用のアカウントはこのルームを閲覧する権限がありません。アクセスするには、ルームマスターに権限の付与を申請してください。"
            )}
          </Typography>
          <Button href="/home" variant="outlined" size="large" fullWidth>
            {t("退出する")}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

const Container = styled.div`
  margin: auto auto;
  max-width: 440px;
`;

const UserArea = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  background: #202020;
  padding: 16px 12px;
  border-radius: 4px;
`;

export default memo(AccessDenied);
