import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import { Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

const MediumListOfficialPlaylistTabs = () => {
  const d = useDispatch();
  const tab = useSelector((state) => {
    return store.getAppState(state, "userMediumListOfficialPlaylistTab");
  });
  const playlists = useSelector((state) => {
    return store.getAppState(state, "officialPlaylists");
  });
  const [t] = useTranslation();

  const onChange = useCallback(
    (_, tab) => {
      d(
        store.appStateMutate((state) => {
          state.userMediumListOfficialPlaylistTab = tab;
        })
      );
    },
    [d]
  );

  return (
    <Tabs value={tab} onChange={onChange} variant="scrollable">
      {playlists.map((playlist) => {
        return (
          <MediumListTab
            key={playlist.id}
            label={t(playlist.name)}
            value={playlist.id}
          />
        );
      })}
    </Tabs>
  );
};

const MediumListTab = styled(Tab)`
  min-width: 80px;
`;

export default memo(MediumListOfficialPlaylistTabs);
