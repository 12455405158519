import types from "./types";
import { combineReducers } from "redux";
import {
  createByIdReducer,
  createIndexReducer,
  createGroupByReducer,
} from "../firestoreModuleUtils";
import { Room } from "./records";

const byId = createByIdReducer<Room>()(types);
const allIds = createIndexReducer(types);
const groupByUser = createGroupByReducer<Room>()(types, "owner");

const roomReducer = combineReducers({
  byId,
  allIds,
  groupByUser,
});

export default roomReducer;
