import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { getTermsUpdatedAt } from "api";
import { signOut } from "firebase/auth";
import { auth } from "initializer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import { updateUserAgreedTermsAt } from "stores/modules/entities.user.setting/operations";

const ConfirmTermsAgreement = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const authedUid = useSelector(getAuthedUid);
  const settingsUid = useSelector((state) => state.entities.userSetting.uid);
  const agreedTermsAt = useSelector(
    (state) => state.entities.userSetting.agreedTermsAt
  );
  const [termsUpdatedAt, setTermsUpdatedAt] = useState<number>();

  useEffect(() => {
    getTermsUpdatedAt().then((termsUpdatedAt) => {
      if (termsUpdatedAt) {
        setTermsUpdatedAt(Date.parse(termsUpdatedAt));
      }
    });
  }, []);

  const [open, setOpen] = useState(false);
  const onClickAgree = () => {
    dispatch(updateUserAgreedTermsAt());
    setOpen(false);
  };

  const onClickDisagree = async () => {
    await signOut(auth);
    history.push("/");
  };

  useEffect(() => {
    if (
      termsUpdatedAt == null ||
      settingsUid == null ||
      authedUid == null ||
      settingsUid !== authedUid
    ) {
      return;
    }

    const needAgree =
      agreedTermsAt == null || agreedTermsAt.toMillis() < termsUpdatedAt;
    setOpen(needAgree);
  }, [settingsUid, agreedTermsAt, termsUpdatedAt, authedUid, setOpen]);

  if (!authedUid) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>{t("利用規約の確認")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {t(
            "CCFOLIAをご利用いただくには、利用規約およびプライバシーポリシーに同意していただく必要があります。"
          )}
        </Typography>
        {termsUpdatedAt && (
          <Typography variant="caption" paragraph>
            {t("最終更新日")} {new Date(termsUpdatedAt).toLocaleDateString()}
          </Typography>
        )}
        <Typography component="p" variant="body2">
          <Link href="/termsOfService.html" target="_blank" underline="hover">
            {t("利用規約")}
          </Link>
        </Typography>
        <Typography component="p" variant="body2">
          <Link href="/privacyPolicy.html" target="_blank" underline="hover">
            {t("プライバシーポリシー")}
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDisagree}>{t("同意しない")}</Button>
        <Button
          onClick={onClickAgree}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {t("同意して利用する")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTermsAgreement;
