import React, { memo, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";

import Message from "./Message";
import { List, CircularProgress } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAppDispatch } from "stores";

const Messages = () => {
  const d = useAppDispatch();
  const tab = useSelector((state) => store.getAppState(state, "roomChatTab"));
  const loading = useSelector((state) =>
    store.getAppState(state, "roomChatLoading")
  );
  const initialized = useSelector((state) =>
    store.getAppState(state, "roomChatInitialized")
  );
  const messageIds = useSelector(store.getFilteredRoomMessageIds);

  const edge = useRef(true);
  const loadedMessages = useRef(false);
  const prevScrollTop = useRef(0);

  const parentRef = useRef<HTMLUListElement>(null);

  const virtualizer = useVirtualizer({
    count: messageIds.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 50,
  });

  const items = virtualizer.getVirtualItems();
  const endIndex = virtualizer.range?.endIndex;

  const loadMessages = useCallback(() => {
    if (!loadedMessages.current) {
      d(store.loadRoomMessages()).then((count) => {
        if (count == null) {
          return;
        }

        loadedMessages.current = count === 0;

        if (count > 0) {
          const currentIndex = virtualizer.range?.startIndex || 0;
          virtualizer.scrollToIndex(currentIndex + count, { align: "start" });
        }
      });
    }
  }, [loadedMessages, virtualizer, d]);

  // 初期位置をチャットの末尾にする
  useEffect(() => {
    prevScrollTop.current = 0;

    if (initialized) {
      setTimeout(() => {
        if (virtualizer.options.count > 0) {
          virtualizer.scrollToIndex(virtualizer.options.count - 1, {
            align: "end",
          });
        }
        edge.current = true;
        loadedMessages.current = false;
      }, 0);
    }
  }, [tab, initialized, edge, virtualizer, loadedMessages]);

  // 新しいメッセージが追加された時に末尾へ移動する
  useEffect(() => {
    if (edge.current && messageIds.length > 0) {
      virtualizer.scrollToIndex(messageIds.length - 1, { align: "end" });
      edge.current = true;
    }
  }, [virtualizer, messageIds.length, edge]);

  const callbackMessageLoad = useCallback(
    (count: number | null) => {
      if (count == null) {
        return;
      }

      loadedMessages.current = count === 0;

      if (count > 0) {
        const currentIndex = virtualizer.range?.startIndex || 0;
        virtualizer.scrollToIndex(currentIndex + count, { align: "start" });
      }
    },
    [virtualizer]
  );

  // 古いメッセージの初回ロード
  useEffect(() => {
    if (messageIds.length < 20) {
      loadMessages();
    }
  }, [loadMessages, messageIds.length, callbackMessageLoad]);

  // チャットの末尾にいるかのチェック
  useEffect(() => {
    if (endIndex != null) {
      edge.current = endIndex >= messageIds.length - 2;
    }
  }, [endIndex, edge]);

  // 一定の場所までスクロールしたらメッセージをロードする
  const onScroll = useCallback(() => {
    if (parentRef.current == null) {
      return;
    }

    const isBackforward = prevScrollTop.current > parentRef.current.scrollTop;
    prevScrollTop.current = parentRef.current.scrollTop;
    if (isBackforward) {
      if (parentRef.current.scrollTop < 32) {
        loadMessages();
      }
    }
  }, [loadMessages, prevScrollTop, parentRef]);

  return (
    <Container disablePadding ref={parentRef} onScroll={onScroll}>
      {loading && <MessagesCircularProgress size={24} />}
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {items.map((virtualRow) => (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
            >
              <Message messageId={messageIds[virtualRow.index]} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled(List)`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
`;

const MessagesCircularProgress = styled(CircularProgress)`
  margin-left: -16px;
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 1;
`;

export default memo(Messages);
