import { memo, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getEmoteById } from "stores/modules/app.emotes/selectors";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import StarIcon from "@mui/icons-material/Star";
import SmileIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { removeEmote } from "stores/modules/app.emotes/operations";

const EmoteItem = (props: { emoteId: string }) => {
  const emote = useSelector((state) => getEmoteById(state, props.emoteId));
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(removeEmote(props.emoteId));
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [props.emoteId, dispatch]);

  if (emote == null) {
    return null;
  }

  return (
    <EmoteImageWrap posPercent={emote.posPercent}>
      <EmoteImage kind={emote.kind} />
    </EmoteImageWrap>
  );
};

const EmoteImage = (props: { kind: string }) => {
  switch (props.kind) {
    case "star":
      return <StarIcon style={{ color: "#fff" }} />;
    case "smile":
      return <SmileIcon style={{ color: "#fff" }} />;
    case "thumbup":
    default:
      return <ThumbUpIcon style={{ color: "#fff" }} />;
  }
};

const EmoteImageWrap = styled.div<{ posPercent: number }>`
  position: absolute;
  right: ${(props) => props.posPercent}%;
  bottom: 24px;
  z-index: 103;
  fill: #fff;
  animation: slide-up 0.5s linear 0s 1 alternate forwards running;

  @keyframes slide-up {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    50% {
      transform: translateY(-50px);
      opacity: 1;
    }

    75% {
      transform: translateY(-75px);
      opacity: 1;
    }

    100% {
      transform: translateY(-100px);
      opacity: 0;
    }
  }
`;

export default memo(EmoteItem);
