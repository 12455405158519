import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import { List, Paper } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import PanelListItem from "../PanelListItem";

const PanelList = () => {
  const d = useDispatch();
  const panelIds = useSelector((state) => store.getSortedRoomItemIds(state));
  const roomId = useSelector((state) => store.getAppState(state, "roomId"));
  const onReorder = useCallback(
    (order) => {
      if (roomId) {
        d(store.reorderItems(roomId, order));
      }
    },
    [roomId, d]
  );

  return (
    <List dense>
      <DragDropContext onDragEnd={onReorder}>
        <Droppable
          droppableId="droppable"
          renderClone={(provided, snapshot, rubric) => (
            <Paper
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            />
          )}
        >
          {(provided, _) => (
            <Content {...provided.droppableProps} ref={provided.innerRef}>
              {panelIds.map((panelId, index) => (
                <Draggable key={panelId} draggableId={panelId} index={index}>
                  {(provided, _) => (
                    <DraggableItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <PanelListItem key={panelId} panelId={panelId} />
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Content>
          )}
        </Droppable>
      </DragDropContext>
    </List>
  );
};
const Content = styled.div`
  padding: 2px;
`;

const DraggableItem = styled.div`
  padding: 2px;
`;
export default memo(PanelList);
