import React, { useCallback, memo, SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import Paper from "@mui/material/Paper";
import ChatBoxHeader from "./ChatBoxHeader";
import ChatBoxToolbar from "./ChatBoxToolbar";
import ChatBoxTextField from "./ChatBoxTextField";
import ChatBoxTypingStateBar from "./ChatBoxTypingStateBar";
import { Divider } from "@mui/material";
import { appChatStateMutate } from "stores/modules/app.chat/operations";

type ChatBoxProps = {
  uid: string;
  roomId: string;
  standalone: boolean;
};

const ChatBox = (props: ChatBoxProps) => {
  const dispatch = useDispatch();
  const { uid, roomId } = props;

  const characterId = useSelector((state) => {
    const name = store.getAppState(state, "roomChatName");
    const characterId = store.getUserCharacterIdByName(state, {
      name,
      uid,
    });
    return characterId;
  });

  const onSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      dispatch(store.addMessage(roomId, characterId || null));
    },
    [dispatch, roomId, characterId]
  );

  return (
    <Container square>
      <Form onSubmit={onSubmit} autoComplete="off">
        <ChatBoxHeader />
        <ChatBoxToolbar uid={uid} />
        <ChatBoxTextField
          uid={uid}
          onSubmit={onSubmit}
          standalone={props.standalone}
        />
        <Divider />
        <ChatBoxTypingStateBar />
      </Form>
    </Container>
  );
};

const Container = styled(Paper)`
  position: relative;
`;

const Form = styled.form`
  background: rgba(0, 0, 0, 0.1);
`;

export default memo(ChatBox);
