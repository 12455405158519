import { DefaultRootState } from "react-redux";
import { AppUser } from "./reducers";

export const getUid = (state: DefaultRootState) => {
  return state.app.user.uid;
};

export const getAuthedUid = (state: DefaultRootState): string | null => {
  const uid = state.app.user.uid;
  const isAnonymous = state.app.user.isAnonymous;

  if (!uid || isAnonymous || isAnonymous == null) {
    return null;
  }

  return uid;
};

export const getAppUser = <T extends keyof AppUser>(
  state: DefaultRootState,
  name: T
): AppUser[T] => {
  return state.app.user[name];
};

export const getIsPro = (state: DefaultRootState): boolean => {
  return state.app.user.plan === "ccfolia-pro";
};

export const getRequiredVerifyEmail = (state: DefaultRootState): boolean => {
  return (
    state.app.user.providerData.email != null && !state.app.user.emailVerified
  );
};
