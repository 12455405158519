import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Paper } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import EffectListItem from "./EffectListItem";
import { reorderEffects } from "stores/modules/entities.room.effects/operations";
import { getRoomOrderdEffectIds } from "stores/modules/entities.room.effects/selectors";

type EffectListProps = {
  roomId: string;
};

const EffectList = ({ roomId }: EffectListProps) => {
  const dispatch = useDispatch();
  const effectIds = useSelector(getRoomOrderdEffectIds);

  const onReorder = useCallback(
    (order: DropResult) => {
      dispatch(reorderEffects(roomId, order));
    },
    [roomId, dispatch]
  );

  return (
    <DragDropContext onDragEnd={onReorder}>
      <Droppable
        droppableId="droppable"
        renderClone={(provided, _snapshot, _rubric) => (
          <Paper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          />
        )}
      >
        {(provided, _snapshot) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            component="div"
          >
            {effectIds.map((effectId, index) => (
              <Draggable key={effectId} draggableId={effectId} index={index}>
                {(provided, _snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <EffectListItem roomId={roomId} effectId={effectId} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(EffectList);
