import { createSelector } from "reselect";
import { Item } from "./records";
import { DefaultRootState } from "react-redux";
import { pick, sortBy } from "lodash-es";

export const getRoomItemById = (
  state: DefaultRootState,
  itemId: string
): Item | undefined => {
  return state.entities.roomItems.byId[itemId];
};

export const getRoomItemIds = (state: DefaultRootState): string[] => {
  return state.entities.roomItems.allIds;
};

export const getRoomItems = (state: DefaultRootState) => {
  return state.entities.roomItems.byId;
};

export const getRoomItemIdsByDeckId = (
  state: DefaultRootState,
  deckId: string
): string[] => {
  const items = getRoomItems(state);
  const itemIds = getRoomItemIds(state);
  return itemIds.filter((itemId) => {
    return items[itemId]?.deckId === deckId;
  });
};

export const getRoomNearItemsByPosition = (
  state: DefaultRootState,
  position: { x: number; y: number; width: number; height: number }
): Record<string, Item> => {
  const items = getRoomItems(state);
  const itemIds = getRoomItemIds(state).filter((itemId) => {
    const item = items[itemId];
    return (
      item.active &&
      item.x < position.x + 1 &&
      item.x > position.x - 1 &&
      item.y < position.y + 1 &&
      item.y > position.y - 1 &&
      item.width === position.width &&
      item.height === position.height
    );
  });
  return pick(items, itemIds);
};

export const getSortedRoomItemIds = createSelector(
  [getRoomItemIds, getRoomItems],
  (itemIds, items) => {
    return sortBy(itemIds, [
      (id) => items[id].order,
      (id) => items[id].createdAt,
    ]);
  }
);

export const getActiveRoomItemIds = createSelector(
  [getRoomItemIds, getRoomItems],
  (itemIds, items) => {
    return itemIds
      .filter((itemId) => {
        return items[itemId].active || items[itemId].active === undefined;
      })
      .sort((a, b) => {
        const diff = items[b].z - items[a].z;
        if (diff === 0) {
          return items[a].updatedAt - items[b].updatedAt;
        } else {
          return diff;
        }
      });
  }
);

export const getMaxZIndex = createSelector([getRoomItems], (items) => {
  let maxZIndex = 1;
  for (let key in items) {
    if (items.hasOwnProperty(key)) {
      maxZIndex = Math.max(maxZIndex, items[key].z);
    }
  }
  return Math.min(999, maxZIndex);
});

// export const getMaxZIndex = createSelector(
//   [getRoomItems, getCurrentRoom],
//   (items, room) => {
//     const markers = room?.markers || {};
//     let maxZIndex = 1;

//     for (let key in items) {
//       if (items.hasOwnProperty(key)) {
//         maxZIndex = Math.max(maxZIndex, items[key].z);
//       }
//     }
//     for (let key in markers) {
//       if (markers.hasOwnProperty(key)) {
//         maxZIndex = Math.max(maxZIndex, markers[key].z);
//       }
//     }
//     return Math.min(999, maxZIndex);
//   }
// );
