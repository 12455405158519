import React, { useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import store from "stores/interfaces";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BackspaceIcon from "@mui/icons-material/Backspace";

const ItemMenu = () => {
  const d = useDispatch();
  const [t] = useTranslation();
  const uid = useSelector((state) => store.getAppState(state, "uid"));
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomItemMenu")
  );
  const top = useSelector((state) => store.getAppState(state, "roomPointerY"));
  const left = useSelector((state) => store.getAppState(state, "roomPointerX"));
  const [itemId, item, addableDeckId] = useSelector((state) => {
    const itemId = store.getAppState(state, "openRoomItemMenuId");
    const item = store.getRoomItemById(state, itemId ?? "");
    const addableDeckId = item
      ? store.getRoomDeckIdByPosition(state, {
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
        })
      : undefined;
    return [itemId, item, addableDeckId] as const;
  }, shallowEqual);
  const isOpened = uid === item?.owner || !item?.closed;
  const isGod = useSelector(
    (state) => store.getAppState(state, "role") === "god"
  );
  const _isMonitored = useSelector(store.getCurrentRoomIsMonitored);
  const isMonitored = isGod || _isMonitored;
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomItemMenu = false;
      })
    );
  }, []);
  const onOpenItem = useCallback(() => {
    if (itemId == null) return;
    d(store.openRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onLockItem = useCallback(() => {
    if (itemId == null) return;
    d(store.updateRoomItem(null, itemId, { locked: true }));
    onClose();
  }, [itemId, onClose, d]);
  const onUnlockItem = useCallback(() => {
    if (itemId == null) return;
    d(store.updateRoomItem(null, itemId, { locked: false }));
    onClose();
  }, [itemId, onClose, d]);
  const onRotateItem = useCallback(() => {
    if (itemId == null || item == null) return;
    d(store.updateRoomItem(null, itemId, { angle: item.angle + 45 }));
    onClose();
  }, [itemId, item, onClose, d]);
  const onCloseItem = useCallback(() => {
    if (itemId == null) return;
    d(store.closeRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onGetItem = useCallback(() => {
    if (itemId == null) return;
    d(store.takeRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onBackItem = useCallback(() => {
    if (itemId == null) return;
    if (item?.deckId) {
      d(store.addRoomDeckItem(item?.deckId, itemId));
    }
    onClose();
  }, [itemId, item?.deckId, onClose, d]);
  const onHideItem = useCallback(() => {
    if (itemId == null) return;
    d(
      store.updateRoomItem(null, itemId, {
        active: false,
      })
    );
    onClose();
  }, [itemId, onClose, d]);
  const onCloneItem = useCallback(() => {
    if (item == null) {
      return;
    }
    d(store.addRoomItem(item));
    onClose();
  }, [item, onClose, d]);
  const onCreateDeck = useCallback(() => {
    if (item == null) {
      return;
    }
    d(
      store.addRoomDeckItemFromPanelPosition(
        {
          coverImageUrl: "/grey.png",
        },
        {
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
        }
      )
    );
    onClose();
  }, [item?.x, item?.y, item?.width, item?.height, onClose, d]);
  const onAddDeck = useCallback(() => {
    if (itemId == null || addableDeckId == null) return;
    d(store.addRoomDeckItem(addableDeckId, itemId));
    onClose();
  }, [itemId, addableDeckId, onClose, d]);
  const onDeleteItem = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      if (itemId == null) return;
      d(store.deleteCurrentRoomItem(itemId));
      onClose();
    }
  }, [itemId, onClose, d]);
  const onEditItem = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomPanelDetail = true;
        state.openRoomPanelDetailId = itemId;
      })
    );
    onClose();
  }, [itemId, onClose, d]);
  const onShowImage = useCallback(
    (e) => {
      d(
        store.appStateMutate((state) => {
          state.openInspector = true;
          state.inspectImageUrl =
            isOpened || isMonitored
              ? item?.imageUrl || null
              : item?.coverImageUrl || null;
        })
      );
      onClose();
    },
    [item?.imageUrl, item?.coverImageUrl, isOpened, isMonitored, onClose, d]
  );

  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ left, top }}
      onClose={onClose}
      MenuListProps={{ dense: true }}
      PaperProps={{
        style: {
          width: "20ch",
        },
      }}
      className={"user-select-none"}
    >
      <MenuItem disabled={!isOpened && !isMonitored} onClick={onEditItem}>
        {t("編集")}
      </MenuItem>
      {item?.locked ? (
        <MenuItem onClick={onUnlockItem}>
          {t("配置固定を解除")}
          {item?.type !== "plane" ? (
            <ShortcutKeyInfo
              color="textSecondary"
              variant="caption"
              align="right"
            >
              L
            </ShortcutKeyInfo>
          ) : null}
        </MenuItem>
      ) : (
        <MenuItem onClick={onLockItem}>
          {t("配置固定")}
          {item?.type !== "plane" ? (
            <ShortcutKeyInfo
              color="textSecondary"
              variant="caption"
              align="right"
            >
              L
            </ShortcutKeyInfo>
          ) : null}
        </MenuItem>
      )}
      <MenuItem onClick={onRotateItem}>
        {t("回転する")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            R or Shift+R
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
      <MenuItem onClick={onHideItem}>{t("パネルを非表示")}</MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem disabled={!item?.closed} onClick={onOpenItem}>
        {t("全体に公開する")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            O
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
      <MenuItem
        disabled={item?.closed && item?.owner !== uid}
        onClick={onCloseItem}
      >
        {t("非公開にする")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            T
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
      <MenuItem disabled={item?.owner === uid} onClick={onGetItem}>
        {t("自分だけ見る")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            T
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
      <MenuItem onClick={onShowImage}>
        {t("拡大表示")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            E
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
      <MenuItem disabled={!item?.deckId} onClick={onBackItem}>
        {t("山札に戻す")}
      </MenuItem>
      <MenuItem onClick={onCreateDeck}>{t("山札を作る")}</MenuItem>
      <MenuItem
        disabled={!addableDeckId || item?.deckId === addableDeckId}
        onClick={onAddDeck}
      >
        {t("山札に追加する")}
      </MenuItem>
      <MenuItem disabled={!isOpened} onClick={onCloneItem}>
        {t("複製")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            Ctrl+D
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem onClick={onDeleteItem}>
        {t("削除")}
        {item?.type !== "plane" ? (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            Ctrl+
            <BackspaceIcon
              fontSize="inherit"
              style={{ verticalAlign: "middle" }}
            />
          </ShortcutKeyInfo>
        ) : null}
      </MenuItem>
    </Menu>
  );
};

const ShortcutKeyInfo = styled(Typography)`
  flex: 1;
`;

export default ItemMenu;
