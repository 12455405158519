/**
 * スクリーンパネル
 */

import React, {
  useCallback,
  memo,
  useRef,
  MouseEventHandler,
  TouchEvent,
} from "react";
import styled from "styled-components";
import store from "stores/interfaces";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Position, Rnd, Props as RndProps } from "react-rnd";
import { Chip, Tooltip } from "@mui/material";
import useFieldRnd from "hooks/fieldRnd";
import useLongTap, { LongTap } from "hooks/longTap";
import toCDNUrl from "modules/toCDNUrl";
import { Item as ItemType } from "stores/modules/entities.room.items";
import { DraggableEvent, DraggableEventHandler } from "react-draggable";
import { KeyboardEventHandler } from "react";
import { handleClickAction } from "modules/clickAction";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { useTranslation } from "react-i18next";

const enableProps = {
  bottom: true,
  bottomLeft: true,
  bottomRight: true,
  left: true,
  right: true,
  top: true,
  topLeft: true,
  topRight: true,
};

interface ItemProps {
  itemId: string;
}
const Item = (props: ItemProps) => {
  const { itemId } = props;
  const d = useDispatch();
  const dragging = useRef(false);
  const { item, uid, cellSize, members, isGod, _isMonitored } = useSelector(
    (state) => ({
      item: store.getRoomItemById(state, itemId),
      uid: store.getAppState(state, "uid"),
      cellSize: store.getAppState(state, "roomScreenCellSize"),
      members: store.getSameGroupRoomMemberIds(state),
      isGod: store.getAppState(state, "role") === "god",
      _isMonitored: store.getCurrentRoomIsMonitored(state),
    }),
    shallowEqual
  );
  const isMonitored = isGod || _isMonitored;
  const isMine = item && members.includes(item.owner);
  const isOpened = isMine || !item?.closed;
  const hasClickAction = item?.clickAction != null && isOpened;
  const isRoleAudience = useSelector(getIsRoleAudience);
  const isClicked = useRef(false);
  const [t] = useTranslation();

  // handlers
  const onDrag = useCallback(() => {
    dragging.current = true;
  }, [dragging]);
  const onDragStart = useCallback(
    (e: DraggableEvent) => {
      if (item == null) {
        return;
      }

      if (!item.locked && !isRoleAudience) {
        e.stopPropagation();
      }
      dragging.current = false;
    },
    [dragging, item?.locked, isRoleAudience]
  );
  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (item == null) {
        return;
      }

      if (!item.locked && !isRoleAudience) {
        e.stopPropagation();
      }
      dragging.current = false;
    },
    [item?.locked, isRoleAudience]
  );
  const onContextMenu = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      d(
        store.appStateMutate((state) => {
          state.openRoomItemMenu = true;
          state.openRoomItemMenuId = itemId;
          state.roomPointerY = e.pageY || 0;
          state.roomPointerX = e.pageX || 0;
        })
      );
    },
    [itemId, d, isRoleAudience]
  );
  const onUpdate = useCallback(
    (position: Position) => {
      d(store.dragedItem(itemId, position));
    },
    [itemId, d]
  );
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (item?.clickAction != null && isClicked.current) {
        return;
      }
      isClicked.current = true;

      setTimeout(() => {
        isClicked.current = false;
      }, 350);

      if (
        dragging.current ||
        item?.clickAction == null ||
        !isOpened ||
        isRoleAudience
      ) {
        return;
      }

      handleClickAction(item.clickAction, d);
    },
    [dragging, isOpened, item?.clickAction, d, isRoleAudience]
  );
  const onDoubleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (item?.locked || isRoleAudience) return;
      if (item?.clickAction && isOpened) return;

      if (!item?.coverImageUrl && isOpened) {
        d(
          store.appStateMutate((state) => {
            state.openRoomPanelDetail = true;
            state.openRoomPanelDetailId = itemId;
          })
        );
        return;
      }
      if (isMine) {
        d(store.openRoomItem(itemId));
      } else if (isOpened) {
        d(store.closeRoomItem(itemId));
      } else {
        d(store.takeRoomItem(itemId));
      }
    },
    [
      isOpened,
      isMine,
      itemId,
      item?.locked,
      item?.coverImageUrl,
      item?.clickAction,
      isRoleAudience,
      d,
    ]
  );
  const keyDownTimer = useRef(0);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isRoleAudience) {
        return;
      }

      // Skip Paste
      if (e.keyCode == 86 && (e.metaKey || e.ctrlKey)) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      if (Date.now() - keyDownTimer.current < 100) {
        keyDownTimer.current = Date.now();
        return;
      }
      keyDownTimer.current = Date.now();
      switch (e.keyCode) {
        // Expand
        case 69:
          d(
            store.appStateMutate((state) => {
              state.openInspector = true;
              state.inspectImageUrl =
                isOpened || isMonitored
                  ? item?.imageUrl || null
                  : item?.coverImageUrl || null;
            })
          );
          break;
        // Take
        case 84:
          if (item && itemId) {
            if (item.owner === uid) {
              d(store.closeRoomItem(itemId));
            } else {
              d(store.takeRoomItem(itemId));
            }
          }
          break;
        // Open
        case 79:
          if (item && itemId && item.closed) {
            d(store.openRoomItem(itemId));
          }
          break;
        // Lock
        case 76:
          if (itemId && item) {
            d(store.updateRoomItem(null, itemId, { locked: !item.locked }));
          }
          break;
        // Rotate
        case 82:
          if (itemId && item) {
            const angleUnit = 45;
            const addAngle = e.shiftKey ? -angleUnit : angleUnit;
            d(
              store.updateRoomItem(null, itemId, {
                angle: item.angle + addAngle,
              })
            );
          }
          break;
        // Duplicate
        case 68:
          if (item && (e.metaKey || e.ctrlKey)) {
            d(store.addRoomItem(item));
          }
          break;
        // Delete（Backspace）
        case 8:
          if (itemId && (e.metaKey || e.ctrlKey)) {
            if (window.confirm(t("本当に削除しますか？"))) {
              d(store.deleteCurrentRoomItem(itemId));
            }
          }
          break;
      }
    },
    [uid, itemId, item, isOpened, isMonitored, d, isRoleAudience]
  );

  const onContextMenuTouch = useCallback(
    (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      d(
        store.appStateMutate((state) => {
          state.openRoomItemMenu = true;
          state.openRoomItemMenuId = itemId;
          state.roomPointerY = e.touches[0]?.screenY || 0;
          state.roomPointerX = e.touches[0]?.screenX || 0;
        })
      );
    },
    [itemId, d, isRoleAudience]
  );

  const longTapProps = useLongTap(onContextMenuTouch);
  const rndProps = useFieldRnd(
    {
      x: item?.x,
      y: item?.y,
      width: item?.width,
      height: item?.height,
    },
    onUpdate
  );

  const disableDragging = item?.locked || isRoleAudience;
  const enableResizing = !item?.locked && !item?.freezed && !isRoleAudience;

  if (!item) return null;
  return (
    <MemolizedItem
      isOpened={isOpened}
      isMonitored={isMonitored}
      hasClickAction={hasClickAction}
      item={item}
      rndProps={rndProps}
      longTapProps={longTapProps}
      cellSize={cellSize}
      disableDragging={disableDragging}
      enableResizing={enableResizing}
      onMouseDown={onMouseDown}
      onContextMenu={onContextMenu}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onKeyDown={onKeyDown}
    />
  );
};

type _ItemProps = {
  isOpened: boolean;
  isMonitored: boolean;
  hasClickAction: boolean;
  item: ItemType;
  rndProps: RndProps;
  longTapProps: LongTap;
  cellSize: number;
  disableDragging: boolean;
  enableResizing: boolean;
  onMouseDown: (e: MouseEvent) => void;
  onContextMenu: MouseEventHandler;
  onClick: MouseEventHandler;
  onDoubleClick: MouseEventHandler;
  onDragStart: DraggableEventHandler;
  onDrag: DraggableEventHandler;
  onKeyDown: KeyboardEventHandler;
};

// todo type define
const _Item = ({
  isOpened,
  rndProps,
  longTapProps,
  item,
  cellSize,
  disableDragging,
  enableResizing,
  onMouseDown,
  onContextMenu,
  onClick,
  onDoubleClick,
  onDragStart,
  onDrag,
  onKeyDown,
  isMonitored,
  hasClickAction,
}: _ItemProps) => {
  const imageUrl =
    isOpened || isMonitored
      ? item?.imageUrl || "/blank.gif"
      : item?.coverImageUrl || "/grey.png";
  const coverImageUrl = item?.coverImageUrl || "/grey.png";
  return (
    <Rnd
      {...rndProps}
      onMouseDown={onMouseDown}
      onContextMenu={onContextMenu}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onKeyDown={onKeyDown}
      disableDragging={disableDragging}
      enableResizing={enableResizing ? enableProps : {}}
      style={{
        zIndex: item.z,
      }}
      tabIndex={item.type !== "plane" ? -1 : null}
      // dragGrid={[cellSize * scale, cellSize * scale]}
      resizeGrid={[cellSize, cellSize]}
      className="movable"
    >
      <Tooltip title={isOpened ? item.memo || "" : ""} placement="bottom">
        <ItemContainer
          {...longTapProps}
          locked={item.locked}
          style={{
            transform: `rotate(${item.angle}deg)`,
            cursor: hasClickAction ? "pointer" : undefined,
          }}
        >
          <img
            src={toCDNUrl(imageUrl)}
            draggable={false}
            width="100%"
            height="100%"
            alt=""
          />
          {isMonitored && !isOpened ? (
            <Cover
              src={toCDNUrl(coverImageUrl)}
              draggable={false}
              width="100%"
              height="100%"
            />
          ) : null}
          {item.ownerName ? (
            <ItemChip label={item.ownerName} angle={-item.angle} />
          ) : null}
        </ItemContainer>
      </Tooltip>
    </Rnd>
  );
};

const MemolizedItem = memo(_Item);

const ItemContainer = styled.div<{ locked: boolean }>`
  width: 100%;
  height: 100%;
  &:hover {
    background: ${(p: { locked: boolean }) =>
      p.locked ? "none" : "rgba(0, 0, 0, 0.2)"};
  }
  &:active {
    // transform: scale(1.1) !important;
    // outline: 1px solid #fff;
    // z-index: 99999 !important;
    // box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  }
  img {
    pointer-events: none;
  }
`;

const ItemChip = styled(Chip)<{ angle: number }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
    rotate(${({ angle }: { angle: number }) => angle}deg);
  max-width: 120%;
  background: rgba(0, 0, 0, 0.68);
  transition: opacity 80ms linear;
  opacity: 1;
`;

const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
`;

export default memo(Item);
