import React, { memo, useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";
import { Howl } from "howler";
import StopIcon from "@mui/icons-material/Stop";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { IconButton, Button, Tooltip } from "@mui/material";
import Slider from "@mui/material/Slider";
import toCDNUrl, { isCDNUrl } from "modules/toCDNUrl";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { createHowl } from "modules/sound";

type MediaProps = {
  roomId: string;
  uid: string;
};

const stopPropagation = (e) => e.stopPropagation();

const Media: React.FC<MediaProps> = (props) => {
  const {
    mediaName,
    mediaUrl,
    mediaVolume,
    mediaRepeat,
    soundName,
    soundUrl,
    soundVolume,
    soundRepeat,
    previewUrl,
    previewVolume,
    previewRepeat,
    previewMuted,
    muted,
    volume,
    blocked, // for video ad
  } = useSelector((state) => {
    const room = store.getRoomById(state, props.roomId);
    return {
      mediaName: room.mediaName,
      mediaUrl: room.mediaUrl,
      mediaVolume: room.mediaVolume || 0.5,
      mediaRepeat: room.mediaRepeat !== undefined ? room.mediaRepeat : true,
      soundName: room.soundName,
      soundUrl: room.soundUrl,
      soundVolume: room.soundVolume || 0.5,
      soundRepeat: room.soundRepeat !== undefined ? room.soundRepeat : true,
      previewUrl: state.app.state.previewMediaUrl,
      previewVolume: state.app.state.previewMediaVolume,
      previewRepeat: state.app.state.previewMediaLoop,
      previewMuted: state.app.state.muted,
      muted: state.app.state.muted || state.app.state.previewMediaUrl !== null,
      volume: state.app.state.roomMediaVolume,
      blocked:
        store.getOpenInformation(state) ||
        store.getAppState(state, "openVideoPlayer"),
    };
  }, shallowEqual);
  const isRoleAudience = useSelector(getIsRoleAudience);
  const dispatch = useDispatch();
  const setVolume = React.useCallback(
    (v: number) => {
      dispatch(store.appStateMutate((state) => (state.roomMediaVolume = v)));
    },
    [dispatch]
  );
  const setMuted = useCallback(
    (muted) => {
      dispatch(store.appStateMutate((state) => (state.muted = muted)));
    },
    [dispatch]
  );

  const media = useRef<Howl | null>(null);
  useEffect(() => {
    if (media.current) {
      media.current?.stop();
    }
    if (!mediaUrl || blocked) return;
    media.current = createHowl({
      src: [toCDNUrl(mediaUrl)],
      autoplay: true,
      loop: mediaRepeat,
      volume: Number(mediaVolume) * volume,
      format: ["mp4", "ogg", "wav"],
      html5: !isCDNUrl(mediaUrl),
      mute: muted,
    });
    return () => {
      media.current?.stop();
    };
  }, [mediaUrl, blocked]);
  useEffect(() => {
    if (media.current) {
      media.current.volume(Number(mediaVolume) * volume);
      media.current.mute(muted);
      media.current.loop(mediaRepeat);
    }
  }, [mediaVolume, volume, mediaRepeat, muted]);

  const sound = useRef<Howl | null>(null);
  useEffect(() => {
    if (sound.current) {
      sound.current.stop();
    }
    if (!soundUrl || blocked) return;
    sound.current = createHowl({
      src: [toCDNUrl(soundUrl)],
      autoplay: true,
      loop: soundRepeat,
      volume: Number(soundVolume) * volume,
      html5: !isCDNUrl(soundUrl),
      format: ["mp4", "ogg", "wav"],
      mute: muted,
    });
    return () => {
      sound.current?.stop();
    };
  }, [soundUrl, blocked]);
  useEffect(() => {
    if (sound.current) {
      sound.current.volume(Number(soundVolume) * volume);
      sound.current.mute(muted);
      sound.current.loop(soundRepeat);
    }
  }, [soundVolume, volume, soundRepeat, muted]);

  const preview = useRef<Howl | null>(null);
  useEffect(() => {
    if (preview.current) {
      preview.current.stop();
    }
    if (previewUrl && !blocked) {
      preview.current = createHowl({
        src: [toCDNUrl(previewUrl)],
        autoplay: true,
        loop: previewRepeat,
        volume: Number(previewVolume) * volume,
        html5: !isCDNUrl(previewUrl),
        format: ["mp4", "ogg", "wav"],
        mute: previewMuted,
      });
    }
    return () => {
      preview.current?.stop();
    };
  }, [previewUrl, blocked]);
  useEffect(() => {
    if (preview.current) {
      preview.current.volume(Number(previewVolume) * volume);
      preview.current.loop(previewRepeat);
    }
  }, [previewVolume, volume, previewRepeat]);

  const $ = useEnhance(props);
  return (
    <Container onDoubleClick={stopPropagation}>
      <Controls>
        <VolumeSlider
          value={volume}
          color="primary"
          onChange={(_, value) =>
            setVolume(Array.isArray(value) ? value[0] : value)
          }
          min={0.0}
          max={1}
          step={0.01}
        />
        <Actions>
          <IconButton
            color={muted ? "primary" : "default"}
            size="small"
            onClick={() => setMuted(!muted)}
          >
            <VolumeOffIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={$.onStop} disabled={isRoleAudience}>
            <StopIcon fontSize="small" />
          </IconButton>
          <Tooltip
            title={`${mediaName || "NOTITLE"}\nvol:${mediaVolume} loop:${
              mediaRepeat ? "on" : "off"
            }`}
          >
            <Button
              size="small"
              color={mediaUrl ? "primary" : undefined}
              onClick={$.onClickMedia01}
              disabled={isRoleAudience}
            >
              BGM01
            </Button>
          </Tooltip>
          <Tooltip
            title={`${soundName || "NOTITLE"}\nvol:${soundVolume || 0.5} loop:${
              soundRepeat ? "on" : "off"
            }`}
          >
            <Button
              size="small"
              color={soundUrl ? "primary" : undefined}
              onClick={$.onClickMedia02}
              disabled={isRoleAudience}
            >
              BGM02
            </Button>
          </Tooltip>
        </Actions>
      </Controls>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 64px;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  /* width: 160px; */
  background: transparent;
  transition: background 200ms ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  ${theme.breakpoints.down("sm")} {
    top: 56px;
    left: auto;
    right: 0;
  }
`;

const VolumeSlider = styled(Slider)`
  margin-left: 8px;
  width: 80px;
`;

const Controls = styled.div`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * + * {
    margin-left: 8px;
  }
`;

const Actions = styled.div`
  /* display: flex; */
  /* justify-content: flex-start; */
  margin-left: 8px;
`;

const useEnhance = (props) => {
  const [playing, setPlaying] = useState(true);
  const player1 = useRef<any>(null); // TODO: any
  const player2 = useRef<any>(null); // TODO: any
  const dispatch = useDispatch();

  const onEnd1 = useCallback(() => {
    if (player1.current && !player1.current.props.loop) {
      dispatch(
        store.updateRoom(props.roomId, {
          mediaName: "",
          mediaUrl: null,
          mediaRepeat: true,
          mediaType: "",
          mediaVolume: 0,
        })
      );
    }
  }, [props.roomId, player1]);
  const onEnd2 = useCallback(() => {
    if (player2.current && !player2.current.props.loop) {
      dispatch(
        store.updateRoom(props.roomId, {
          soundName: "",
          soundUrl: null,
          soundRepeat: true,
          soundVolume: 0,
        })
      );
    }
  }, [props.roomId, player2]);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  const onStart = useCallback(() => {
    setPlaying(true);
  }, []);

  const onStop = useCallback(() => {
    dispatch(
      store.updateRoom(props.roomId, {
        mediaName: "",
        mediaUrl: null,
        mediaRepeat: true,
        mediaType: "",
        mediaVolume: 0,
        soundName: "",
        soundUrl: null,
        soundRepeat: true,
        soundVolume: 0,
      })
    );
  }, [props.roomId]);

  const onClickMedia01 = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMedia = true;
        state.openRoomMediaDir = "bgm"; // todo: rename
        state.enabledUserMediaLoader = true;
      })
    );
  }, []);

  const onClickMedia02 = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMedia = true;
        state.openRoomMediaDir = "sound"; // todo: rename
        state.enabledUserMediaLoader = true;
      })
    );
  }, []);

  return {
    onStart,
    onPause,
    onStop,
    onClickMedia01,
    onClickMedia02,
    playing,
    player1,
    player2,
    onEnd1,
    onEnd2,
  };
};

export default memo(Media);
