import React, {
  memo,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
  ReactNode,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { Formik, FormikConfig, FormikProps } from "formik";
import CharacterForm from "../CharacterForm";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Character } from "stores/modules/entities.room.characters";

type Value = Omit<Character, "active">;

const FormikChildren = forwardRef<
  FormikProps<Value>,
  {
    formikBag: FormikProps<Value>;
  } & { children: ReactNode }
>((props, ref) => {
  useImperativeHandle(ref, () => props.formikBag);
  return <>{props.children}</>;
});

const FormikWithRef = forwardRef<
  FormikProps<Value>,
  FormikConfig<Value> & { children: ReactNode }
>(({ children, ...formikProps }, ref) => {
  return (
    <Formik {...formikProps}>
      {(formikBag) => (
        <FormikChildren formikBag={formikBag} ref={ref}>
          {children}
        </FormikChildren>
      )}
    </Formik>
  );
});

type CharacterFormDialogProps = {
  uid: string;
  roomId: string;
};

const CharacterFormDialog: React.FC<CharacterFormDialogProps> = (props) => {
  const formik = useRef<FormikProps<Value>>(null);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomCharacter")
  );
  const { uid, roomId } = props;
  const characterId = useSelector((state) =>
    store.getAppState(state, "openRoomCharacterId")
  );
  const character = useSelector((state) =>
    store.getCharacterById(state, characterId)
  );
  const { active, ...initCharactervalues } = store.CharacterRecord(character);
  const onClose = useCallback(() => {
    if (formik.current?.dirty) {
      formik.current.submitForm();
    } else {
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacter = false;
        })
      );
    }
  }, [dispatch]);
  const onSubmit = useCallback(
    (values) => {
      if (characterId === null) return;
      dispatch(
        store.updateRoomCharacter(characterId, {
          ...values,
          height: values.width,
        })
      );
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacter = false;
        })
      );
    },
    [dispatch, characterId]
  );
  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      if (characterId === null) return;
      dispatch(store.deleteCharacter(roomId, characterId));
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacterId = null;
          state.openRoomCharacter = false;
        })
      );
    }
  }, [t, characterId, dispatch, roomId]);
  const onDuplicate = useCallback(() => {
    if (characterId === null) return;
    dispatch(store.duplicateRoomCharacter(characterId));
  }, [dispatch, characterId]);
  const onActive = useCallback(() => {
    dispatch(
      store.updateCurrentRoomCharacter({
        active: !character.active,
        owner: uid,
      })
    );
  }, [dispatch, character, uid]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }}>{t("キャラクター編集")}</Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormikWithRef
          onSubmit={onSubmit}
          initialValues={initCharactervalues}
          ref={formik}
        >
          <CharacterForm />
        </FormikWithRef>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onDelete} color="secondary">
          {t("削除")}
        </Button>
        <Button fullWidth onClick={onDuplicate} color="primary">
          {t("複製")}
        </Button>
        <Button fullWidth onClick={onActive} color="primary">
          {character.active ? t("盤面から削除") : t("盤面に追加")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CharacterFormDialog);
