import React from "react";
import { useTranslation } from "react-i18next";
import {
  Menu,
  Button,
  MenuItem,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";

const languages = {
  ja: "日本語",
  en: "English",
  ko: "한국어",
  zhTw: "繁體中文",
};

const TranslationMenu: React.FC = () => {
  const [, i18n] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const onClick = (lang) => {
    i18n.changeLanguage(lang);
    setOpen(false);
  };
  const anchorRef = React.useRef(null);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {desktop ? (
        <Button
          ref={anchorRef}
          onClick={() => setOpen(true)}
          startIcon={<TranslateIcon />}
        >
          {languages[i18n.language]} (beta)
        </Button>
      ) : (
        <IconButton ref={anchorRef} onClick={() => setOpen(true)} size="large">
          <TranslateIcon />
        </IconButton>
      )}
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={() => onClick("ja")}>{languages.ja}</MenuItem>
        <MenuItem onClick={() => onClick("en")}>{languages.en} (beta)</MenuItem>
        <MenuItem onClick={() => onClick("ko")}>{languages.ko} (beta)</MenuItem>
        <MenuItem onClick={() => onClick("zhTw")}>
          {languages.zhTw} (beta)
        </MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(TranslationMenu);
