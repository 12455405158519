import { MouseEvent, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import { format } from "date-fns";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { appStateMutate } from "stores/modules/app.state/operations";
import { updateRoomEffect } from "stores/modules/entities.room.effects/operations";

const formatDate = (time: number | Date) => {
  try {
    return format(time, "yyyy/MM/dd/HH:mm");
  } catch (_) {
    return "Invalid Date";
  }
};

type EffectListItemProps = {
  roomId: string;
  effectId: string;
};

const EffectListItem = ({ roomId, effectId }: EffectListItemProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const effect = useSelector((state) =>
    store.getRoomEffectById(state, effectId)
  );

  const onEdit = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomEffect = true;
        state.openRoomEffectId = effectId;
      })
    );
  }, [effectId, dispatch]);
  const onPlay = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      dispatch(
        updateRoomEffect(roomId, effectId, {
          playTime: Date.now(),
        })
      );
    },
    [roomId, effectId]
  );

  return (
    <>
      <ListItemButton onClick={onEdit}>
        <ListItemAvatar>
          <Avatar src={toCDNUrl(effect.imageUrl)} />
        </ListItemAvatar>
        <ListItemText
          primary={effect.name || t("新しいエフェクト")}
          secondary={formatDate(effect.playTime)}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={onPlay} size="large">
            <PlayArrowIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default memo(EffectListItem);
