import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  Divider,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { ReactComponent as CcfoliaProIcon } from "./CcfoliaPro.svg";

const DiceBotInfo = () => {
  const d = useDispatch();
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomDiceBotInfo")
  );
  const diceBotInfoText = useSelector((state) =>
    store.getAppState(state, "openRoomDiceBotInfoText")
  );
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomDiceBotInfo = false;
      })
    );
  }, []);
  const [t] = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("チャットコマンド")}</DialogTitle>
      <Styled.DialogContent>
        <Typography variant="h6" gutterBottom>
          {t("システム専用ダイスコマンド")}
        </Typography>
        <Styled.Typography variant="body2">{diceBotInfoText}</Styled.Typography>
        <Styled.Divider />
        <Typography variant="h6" gutterBottom>
          {t("共通ダイスコマンド")}
        </Typography>
        <Styled.Typography variant="body2">
          {"3D6+1>=9 ：" +
            t("3d6+1で目標値9以上かの判定") +
            "\n" +
            "1D100<=50 ：" +
            t("D100で50％目標の下方ロールの例") +
            "\n" +
            "3U6[5] ：" +
            t("3d6のダイス目が5以上の場合に振り足しして合計する(上方無限)") +
            "\n" +
            "3B6 ：" +
            t("3d6のダイス目をバラバラのまま出力する（合計しない）") +
            "\n" +
            "10B6>=4 ：" +
            t("10d6を振り4以上のダイス目の個数を数える") +
            "\n" +
            "(8/2)D(4+6)<=(5*3)：" +
            t("個数・ダイス・達成値には四則演算も使用可能") +
            "\n" +
            "C(10-4*3/2+2)：" +
            t("C(計算式）で計算だけの実行も可能") +
            "\n" +
            "choice[a,b,c]：" +
            t("列挙した要素から一つを選択表示。ランダム攻撃対象決定などに") +
            "\n" +
            "S3d6 ：" +
            t(
              "各コマンドの先頭に「S」を付けると他人には見えないシークレットロール"
            ) +
            "\n" +
            "3d6/2 ：" +
            t("ダイス出目を割り算（切り捨て）切り上げは /2U、四捨五入は /2R") +
            "\n" +
            "D66 ：" +
            t("D66ダイス。順序はゲームに依存（D66N：そのまま、D66S：昇順）") +
            "\n"}
          <Link
            target="_blank"
            href="https://docs.bcdice.org/"
            underline="hover"
          >
            https://docs.bcdice.org/
          </Link>
        </Styled.Typography>
        <Styled.Divider />
        <Typography variant="h6" gutterBottom>
          {t("スラッシュコマンド")}
        </Typography>
        <Styled.Typography variant="body2">
          /scene {t("[シーン名]")}
          <br />
          {t(
            "指定したシーンに画面を切り替えます。指定されるシーン名は完全に一致していなければいけません。現在はルームマスターのみ使用可能です。"
          )}
          <br />
          <br />
          /save {t("[セーブデータ名]")}
          <br />
          {t(
            "現在のルームの状態を指定したセーブデータにセーブします。指定されるセーブデータ名は完全に一致していなければいけません。現在はルームマスターのみ使用可能です。"
          )}
          <br />
          <br />
          /load {t("[セーブデータ名]")}
          <br />
          {t(
            "指定したセーブデータをロードします。指定されるセーブデータ名は完全に一致していなければいけません。現在はルームマスターのみ使用可能です。"
          )}
          <br />
          <br />
          /pdf [URL]
          <br />
          {t("指定したURLのPDFを表示します。")}
          <br />
          <br />
          /var {t("[ラベル]")} {t("[値]")}
          <br />
          {t(
            "ラベルを指定して、ルーム変数の値を変更します。値を省略した場合、指定したラベルが削除されます。ラベルは完全に一致していなければいけません。現在はルームマスターのみ使用可能です。"
          )}
          <br />
          <br />
          /play [URL]
          <br />
          {t(
            "指定したURLのYouTube動画を再生することができます。現在はルームマスターのみ使用可能です。"
          )}
        </Styled.Typography>
        <Styled.Divider />
        <Typography variant="h6" gutterBottom>
          {t("スラッシュコマンド")}
          <StyledCcfoliaProIcon />
        </Typography>
        <Styled.Typography variant="body2">
          /play [URL]
          <br />
          {t(
            "指定したURLの動画ファイルを再生することができます。現在はルームマスターのみ使用可能です。"
          )}
          <br />
          <br />
          /omikuji
          <br />
          {t("あなたの今日の運勢をダイスで占います。")}
        </Styled.Typography>
      </Styled.DialogContent>
    </Dialog>
  );
};

const Styled = {
  Typography: styled(Typography)`
    white-space: pre-wrap;
  `,
  Divider: styled(Divider)`
    margin: 16px 0;
  `,
  DialogContent: styled(DialogContent)`
    padding-bottom: 16px;
    background: rgba(0, 0, 0, 0.2);
  `,
};

const StyledCcfoliaProIcon = styled(CcfoliaProIcon)`
  margin-left: 8px;
  fill: ${theme.palette.text.primary};
`;

export default memo(DiceBotInfo);
