import { createSelector } from "reselect";
import { Note } from "./records";
import { DefaultRootState } from "react-redux";
import { sortBy } from "lodash-es";

const emptyNote: Note = {
  name: "",
  text: "",
  iconUrl: "",
  owner: "",
  order: 0,
  createdAt: 0,
  updatedAt: 0,
};
// const emptyNote: any = {};
export const getRoomNoteById = (state: DefaultRootState, noteId: string) => {
  return state.entities.roomNotes.byId[noteId] || emptyNote;
};

export const getRoomNoteIds = (state: DefaultRootState) => {
  return state.entities.roomNotes.allIds;
};

export const getRoomNotes = (state: DefaultRootState) => {
  return state.entities.roomNotes.byId;
};

export const getRoomOrderdNoteIds = createSelector(
  [getRoomNoteIds, getRoomNotes],
  (ids, notes) => {
    return sortBy(ids, [(id) => notes[id].order, (id) => notes[id].createdAt]);
  }
);

export const getRoomNoteTexts = createSelector(
  [getRoomNoteIds, getRoomNotes],
  (noteIds, notes) => {
    return noteIds.map((noteId) => {
      return notes[noteId].text;
    });
  }
);
