/**
 * マーカーパネル
 */

import React, {
  MouseEvent,
  MouseEventHandler,
  TouchEvent,
  useCallback,
} from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import store from "stores/interfaces";
import { Rnd } from "react-rnd";
import { Tooltip } from "@mui/material";
import useFieldRnd from "hooks/fieldRnd";
import useLongTap, { LongTap } from "hooks/longTap";
import toCDNUrl from "modules/toCDNUrl";
import { handleClickAction } from "modules/clickAction";

const enableProps = {
  right: true,
  bottom: true,
  bottomRight: true,
};

type MarkerProps = {
  markerId: string;
  roomId: string;
};

const Marker: React.FC<MarkerProps> = (props) => {
  const { markerId, roomId } = props;
  const dispatch = useDispatch();
  const dragging = React.useRef(false);
  const isClicked = React.useRef(false);
  const { cellSize, marker, isRoleAudience } = useSelector((state) => {
    return {
      cellSize: store.getAppState(state, "roomScreenCellSize"),
      marker: store.getRoomMarkerById(state, roomId, markerId),
      isRoleAudience: store.getIsRoleAudience(state),
    };
  }, shallowEqual);

  const onDrag = React.useCallback(() => {
    dragging.current = true;
  }, [dragging]);
  const onDragStart = React.useCallback(
    (e) => {
      if (!marker.locked && !isRoleAudience) {
        e.stopPropagation();
      }
      dragging.current = false;
    },
    [dragging, marker?.locked, isRoleAudience]
  );
  const onMouseDown = React.useCallback(
    (e: MouseEvent) => {
      if (!marker.locked && !isRoleAudience) {
        e.stopPropagation();
      }
      dragging.current = false;
    },
    [dragging, marker?.locked, isRoleAudience]
  );
  const onContextMenu = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomMarkerMenu = true;
          state.openRoomMarkerMenuId = markerId;
          state.roomPointerY = e.pageY || e.touches[0]?.screenY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.screenX || 0;
        })
      );
    },
    [markerId, isRoleAudience, dispatch]
  );
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (marker.clickAction != null && isClicked.current) {
        return;
      }

      isClicked.current = true;

      setTimeout(() => {
        isClicked.current = false;
      }, 350);

      if (dragging.current || marker.clickAction == null || isRoleAudience) {
        return;
      }

      handleClickAction(marker.clickAction, dispatch);
    },
    [dragging, marker.clickAction, isRoleAudience, dispatch]
  );
  const onDoubleClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (isRoleAudience || marker.clickAction != null) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomMarkerDetail = true;
          state.openRoomMarkerDetailId = markerId;
        })
      );
    },
    [markerId, isRoleAudience, marker.clickAction]
  );
  const onUpdate = React.useCallback(
    (position) => {
      dispatch(store.updateRoomMarker(roomId, markerId, position));
    },
    [markerId]
  );
  const longTapProps = useLongTap(onContextMenu);
  const rndProps = useFieldRnd(
    {
      x: marker?.x,
      y: marker?.y,
      width: marker?.width,
      height: marker?.height,
    },
    onUpdate
  );

  const hasClickAction = marker.clickAction != null;
  const disableDragging = marker.locked || isRoleAudience;
  const enableResizing = !marker.locked && !marker.freezed && !isRoleAudience;

  return (
    <__Marker
      marker={marker}
      rndProps={rndProps}
      longTapProps={longTapProps}
      cellSize={cellSize}
      hasClickAction={hasClickAction}
      disableDragging={disableDragging}
      enableResizing={enableResizing}
      onContextMenu={onContextMenu}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onMouseDown={onMouseDown}
    />
  );
};

type _MarkerProps = {
  rndProps: any;
  longTapProps: LongTap;
  marker: any;
  cellSize: number;
  hasClickAction: boolean;
  disableDragging: boolean;
  enableResizing: boolean;
  onContextMenu: (e: MouseEvent | TouchEvent) => void;
  onClick: MouseEventHandler;
  onDoubleClick: (e: MouseEvent | TouchEvent) => void;
  onDragStart: (e: MouseEvent | TouchEvent) => void;
  onDrag: () => void;
  onMouseDown: (e: MouseEvent) => void;
};

const _Marker: React.FC<_MarkerProps> = (props) => {
  const {
    rndProps,
    longTapProps,
    marker,
    cellSize,
    hasClickAction,
    disableDragging,
    enableResizing,
    onContextMenu,
    onClick,
    onMouseDown,
    onDoubleClick,
    onDragStart,
    onDrag,
  } = props;
  const imageUrl = marker.imageUrl || "/blank.gif";
  return (
    <Rnd
      {...rndProps}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
      onDragStart={onDragStart}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onDrag={onDrag}
      disableDragging={disableDragging}
      enableResizing={enableResizing ? enableProps : {}}
      resizeGrid={[cellSize, cellSize]}
      className="movable"
      style={{
        zIndex: marker.z,
      }}
    >
      <Tooltip title={marker.text || ""} placement="bottom">
        <MarkerContainer
          {...longTapProps}
          locked={disableDragging}
          style={{ cursor: hasClickAction ? "pointer" : undefined }}
        >
          <img
            src={toCDNUrl(imageUrl)}
            draggable={false}
            width="100%"
            height="100%"
          />
        </MarkerContainer>
      </Tooltip>
    </Rnd>
  );
};

const __Marker = React.memo(_Marker);

const MarkerContainer = styled.div<{ locked: boolean }>`
  width: 100%;
  height: 100%;
  &:hover {
    background: ${(p: { locked: boolean }) =>
      p.locked ? "none" : "rgba(0, 0, 0, 0.2)"};
  }
  img {
    pointer-events: none;
  }
`;

export default React.memo(Marker);
