import { DefaultThunk } from "stores";
import actions from "./actions";
import { Diceroll } from "./reducers";
import shortid from "shortid";
import { DiceSkinSettings } from "../entities.user.setting";
import { getAppState } from "../app.state/selectors";
import { soundDice } from "modules/sound";
import { wrap } from "comlink";
import { SimulateFunc, Request } from "modules/diceroll/types";

export const addDiceroll = actions.add;
export const removeDiceroll = actions.remove;

export const simulateDiceroll =
  (
    request: Request[],
    diceSkinSettings: DiceSkinSettings,
    preloadPromises: Promise<void>[]
  ): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const width = state.app.state.dicerollAreaWidth;
    const height = state.app.state.dicerollAreaHeight;

    const worldHeight = 20;
    const aspect = height / worldHeight;
    const worldWidth = width / aspect;

    const [simulated] = await Promise.all([
      simulate(request, worldWidth, worldHeight),
      ...preloadPromises,
    ]);

    const muted = getAppState(state, "muted");
    const masterVolume = getAppState(state, "roomMediaVolume");
    if (!muted) {
      soundDice.volume(masterVolume * 0.4);
      soundDice.play();
    }

    const diceroll: Diceroll = {
      id: shortid(),
      simulated,
      diceSkinSettings,
    };

    dispatch(addDiceroll(diceroll));
  };

let cacheSimulateFunc: SimulateFunc | null = null;
const getFunc = (): SimulateFunc => {
  if (cacheSimulateFunc == null) {
    const worker = new Worker(
      new URL("modules/diceroll/simulate.worker", import.meta.url)
    );
    cacheSimulateFunc = wrap<SimulateFunc>(worker);
  }

  return cacheSimulateFunc;
};

const simulate: SimulateFunc = (r, w, h) => {
  return getFunc()(r, w, h);
};
