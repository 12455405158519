import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  AnyAction,
  Store,
  Reducer,
} from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { createBrowserHistory } from "history";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// app
import appState from "./modules/app.state";
import appChat from "./modules/app.chat";
import appUser from "./modules/app.user";
import appEmotes from "./modules/app.emotes";
import appDicerolls from "./modules/app.dicerolls";

// entities
import rooms from "./modules/entities.rooms";
import roomCharacters from "./modules/entities.room.characters";
import roomEffects from "./modules/entities.room.effects";
import roomDecks from "./modules/entities.room.decks";
import roomDices from "./modules/entities.room.dices";
import roomItems from "./modules/entities.room.items";
import roomMembers from "./modules/entities.room.members";
import roomMessages from "./modules/entities.room.messages";
import roomNotes from "./modules/entities.room.notes";
import roomSavedatas from "./modules/entities.room.savedatas";
import roomScenes from "./modules/entities.room.scenes";

import userFiles from "./modules/entities.user.files";
import userMedia from "./modules/entities.user.media";
import userMediumDirectories from "./modules/entities.user.mediumDirectories";
import userHistories from "./modules/entities.user.histories";
import userSetting from "./modules/entities.user.setting";
import {
  DefaultRootState,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";
import { Actions } from "./modules/actions";
import { BatchAction } from "redux-batched-actions";

// Slices (2.0)
import roomSlice from "containers/Room/roomSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";

const appStatePersistConfig = {
  key: "store.app.state",
  storage,
  whitelist: [
    "muted",
    "roomMediaVolume",
    "roomScreenScale",
    "roomChatName",
    "roomChatWideMode",
    "roomChatColor",
    "informationDate",
    "informationVersion",
    "storeVersion",
    "showRoomSystemMessages",
    "userMediumListLibraryTab",
    "registered",
    "officialPlaylistVolume",
    "videoPlayerVolume",
  ],
};

export const history = createBrowserHistory();
export const listener = createListenerMiddleware();

const reducerMap = {
  app: combineReducers({
    state: persistReducer(appStatePersistConfig, appState),
    chat: appChat,
    user: appUser,
    emotes: appEmotes,
    dicerolls: appDicerolls,
    // slices
    room: roomSlice,
    // logs: appLogs
  }),
  entities: combineReducers({
    rooms,
    roomCharacters,
    roomEffects,
    roomDices,
    roomDecks,
    roomItems,
    roomMembers,
    roomMessages,
    roomNotes,
    roomSavedatas,
    roomScenes,
    userFiles,
    userMedia,
    userMediumDirectories,
    userHistories,
    userSetting,
  }),
};
const rootReducer = combineReducers(reducerMap);

declare module "react-redux" {
  interface DefaultRootState extends ReturnType<typeof rootReducer> {}
}

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, listener.middleware))
);

export function registerReducer(newReducers: {
  [key: string]: Reducer;
}): Store {
  Object.assign(reducerMap, newReducers);
  store.replaceReducer(combineReducers(reducerMap));
  return store;
}

export type DefaultThunk<R = void, E = unknown> = ThunkAction<
  R,
  DefaultRootState,
  E,
  Actions | BatchAction
>;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
