import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "stores/interfaces";
import styled from "styled-components";
import { Rnd } from "react-rnd";
import useFieldRnd from "hooks/fieldRnd";
import { Chip } from "@mui/material";
import useLongTap from "hooks/longTap";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

const enableProps = {
  bottom: true,
  bottomLeft: true,
  bottomRight: true,
  left: true,
  right: true,
  top: true,
  topLeft: true,
  topRight: true,
};

type DeckProps = {
  deckId: string;
};
const Deck = ({ deckId }: DeckProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const deck = useSelector((state) => store.getRoomDeckById(state, deckId));
  const itemCount = useMemo(() => {
    if (deck?.items) return Object.keys(deck?.items).length;
    return 0;
  }, [deck?.items]);
  const isRoleAudience = useSelector(getIsRoleAudience);
  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomDeckMenu = true;
          state.openRoomDeckMenuId = deckId;
          state.roomPointerY = e.pageY || e.touches[0]?.screenY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.screenX || 0;
        })
      );
    },
    [deckId, isRoleAudience]
  );
  const onDraw = useCallback(
    (e) => {
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(store.addRandomRoomItemFromRoomDeck(deckId, 1, false));
    },
    [deckId, isRoleAudience]
  );
  const onUpdate = useCallback(
    (position) => {
      dispatch(store.updateRoomDeck(deckId, position));
    },
    [deckId, dispatch]
  );
  const onMouseDown = useCallback((event: MouseEvent) => {
    if (!isRoleAudience) {
      event.stopPropagation();
    }
  }, []);
  const rndProps = useFieldRnd(
    {
      x: deck.x,
      y: deck.y,
      width: deck.width,
      height: deck.height,
    },
    onUpdate
  );
  const disableDragging = deck?.locked || isRoleAudience;
  const enableResizing = !deck?.locked && !deck?.freezed && !isRoleAudience;

  const longTapProps = useLongTap(onContextMenu);
  return (
    <>
      <Rnd
        {...rndProps}
        onContextMenu={onContextMenu}
        onDoubleClick={onDraw}
        onMouseDown={onMouseDown}
        className="movable"
        disableDragging={disableDragging}
        enableResizing={enableResizing ? enableProps : {}}
      >
        <DeckContainer {...longTapProps}>
          {itemCount > 0 ? (
            <img
              src={toCDNUrl(deck.coverImageUrl) || "/grey.png"}
              draggable={false}
            />
          ) : (
            <img src={"/grey.png"} draggable={false} />
          )}
          <ItemChip label={`${Object.keys(deck.items).length}${t("枚")}`} />
        </DeckContainer>
      </Rnd>
    </>
  );
};

const DeckContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const ItemChip = styled(Chip)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.84);
`;

export default Deck;
