import { TouchEvent, useEffect, useCallback, useRef, useMemo } from "react";

export type LongTap = {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
};

export default function useLongTap(onPress: (e: TouchEvent) => void): LongTap {
  const timer = useRef<any | null>(null);
  const handlePressStart = useCallback(
    (e) => {
      e.persist();
      e.preventDefault();
      clearTimeout(timer.current);
      timer.current = setTimeout(() => onPress(e), 480);
    },
    [timer]
  );
  const handlePressEnd = useCallback(
    (e) => {
      clearTimeout(timer.current);
    },
    [timer]
  );
  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, [timer]);
  return useMemo(
    () => ({
      onTouchStart: handlePressStart,
      onTouchMove: handlePressEnd,
      onTouchEnd: handlePressEnd,
    }),
    [handlePressStart, handlePressEnd, handlePressEnd]
  );
}
