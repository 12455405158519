import types from "./types";
import { UserSetting, UserSettingRecord } from "./records";
import { ActionUserSetting } from "./actions";

const initialState: UserSetting = {
  uid: null,
  skipInfomation: false,
  diceSkin: {},
  agreedTermsAt: null,
  markedGamesAt: null,
};

const appUserSettingReducer = (
  state: UserSetting = initialState,
  action: ActionUserSetting
): UserSetting => {
  switch (action.type) {
    case types.update: {
      return UserSettingRecord(action.uid, action.setting);
    }
    default: {
      return state;
    }
  }
};

export default appUserSettingReducer;
