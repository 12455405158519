import types from "./types";
import { combineReducers } from "redux";
import {
  createByIdReducer,
  createIndexReducer,
  createGroupByReducer,
} from "../firestoreModuleUtils";
import { UserFile } from "./records";

const byId = createByIdReducer<UserFile>()(types);
const allIds = createIndexReducer(types);
const groupByDir = createGroupByReducer<UserFile>()(types, "dir");

const userFilesState = combineReducers({
  byId,
  allIds,
  groupByDir,
});

export default userFilesState;
