import React, { useCallback, memo } from "react";
import {
  DefaultRootState,
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";

import { Dialog, DialogContent } from "@mui/material";
import { TwitterPicker as _TwitterPicker } from "react-color";
import * as material from "material-colors";

// const dialogContentStyle = { padding: 0, background: "rgba(0, 0, 0, 0.12)" };
// const primaryTypographyProps = { variant: "caption" };

// TODO: fix @types/react-color
const TwitterPicker = _TwitterPicker as any;

type ColorPickerProps = {
  roomId: string;
  uid: string;
};

const getColorPickerStateSelector =
  (uid: string) => (state: DefaultRootState) => {
    const name = store.getAppState(state, "roomChatName");
    const characterId = store.getUserCharacterIdByName(state, {
      name,
      uid: uid,
    });
    const character = store.getUserCharacterByName(state, {
      name,
      uid: uid,
    });
    const pickedColor = !!character
      ? character.color || "#888888"
      : store.getAppState(state, "roomChatColor");

    return {
      characterId,
      pickedColor,
      open: store.getAppState(state, "openRoomColorPicker"),
    };
  };

type ColorPickerState = ReturnType<
  ReturnType<typeof getColorPickerStateSelector>
>;

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const colorPickerStateSelector = useCallback(
    getColorPickerStateSelector(props.uid),
    [props.uid]
  );
  const state = useSelector(colorPickerStateSelector, shallowEqual);
  const $ = useEnhance(state);
  return (
    <Dialog open={state.open} onClose={$.onClose}>
      <DialogContent style={{ padding: 0 }}>
        <PickerField>
          <TwitterPicker
            color={state.pickedColor}
            onChange={(color: unknown) => console.log(color)}
            onChangeComplete={$.onChange}
            triangle={"hide"}
            styles={{
              default: {
                card: {
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "unset",
                },
                input: {
                  height: "30px",
                },
              },
            }}
            colors={[
              "#222222" /* theme_color  */,
              material.red["500"],
              material.pink["500"],
              material.purple["500"],
              material.deepPurple["500"],
              material.indigo["500"],
              material.blue["500"],
              material.lightBlue["500"],
              material.cyan["500"],
              material.teal["500"],
              material.green["500"],
              material.lightGreen["500"],
              material.lime["500"],
              material.yellow["500"],
              material.amber["500"],
              material.orange["500"],
              material.deepOrange["500"],
              material.brown["500"],
              material.blueGrey["500"],
              material.grey["500"],
              material.grey["300"],
            ]}
          />
        </PickerField>
      </DialogContent>
    </Dialog>
  );
};

const PickerField = styled.div`
  display: block;
  padding-bottom: 8px;
`;

const useEnhance = (props: ColorPickerState) => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomColorPicker = false;
      })
    );
  }, []);

  const onChange = useCallback(
    (color) => {
      if (props.characterId) {
        dispatch(
          store.updateRoomCharacter(props.characterId, {
            color: color.hex,
          })
        );
      }
      dispatch(
        store.appStateMutate((state) => {
          state.roomChatColor = color.hex;
        })
      );
    },
    [props.characterId]
  );

  return {
    onClose,
    onChange,
  };
};

export default memo(ColorPicker);
