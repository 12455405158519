import { Savedata } from "./records";
import { DefaultRootState } from "react-redux";

export const getRoomSavedataById = (
  state: DefaultRootState,
  savedataId: string
): Savedata | undefined => {
  return state.entities.roomSavedatas.byId[savedataId];
};

export const getRoomSavedataIds = (state: DefaultRootState): string[] => {
  return state.entities.roomSavedatas.allIds;
};

export const getRoomSavedataIdByName = (
  state: DefaultRootState,
  savedataName: string
): string | undefined => {
  const ids = getRoomSavedataIds(state);

  for (const id of ids) {
    const savedata = getRoomSavedataById(state, id);
    if (savedata?.name === savedataName) {
      return id;
    }
  }

  return;
};

export const getRoomSavedataByName = (
  state: DefaultRootState,
  savedataName: string
): Savedata | undefined => {
  const ids = getRoomSavedataIds(state);

  for (const id of ids) {
    const savedata = getRoomSavedataById(state, id);
    if (savedata?.name === savedataName) {
      return savedata;
    }
  }

  return;
};

export const getRoomSavedataIdByOrder = (
  state: DefaultRootState,
  order: number
): string | undefined => {
  const ids = getRoomSavedataIds(state);

  for (const id of ids) {
    const savedata = getRoomSavedataById(state, id);
    if (savedata?.order === order) {
      return id;
    }
  }

  return;
};

export const getRoomSavedataByOrder = (
  state: DefaultRootState,
  order: number
): Savedata | undefined => {
  const ids = getRoomSavedataIds(state);

  for (const id of ids) {
    const savedata = getRoomSavedataById(state, id);
    if (savedata?.order === order) {
      return savedata;
    }
  }

  return;
};
