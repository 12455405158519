import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import useLongPress from "hooks/longPress";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { ReactComponent as SelectedIcon } from "./Selected.svg";
import { ReactComponent as NotSelectedIcon } from "./NotSelected.svg";

type FileListItemProps = {
  assetId: string;
  onSelect: (file: string) => void;
  onMounted: (node: HTMLImageElement) => void;
  onUnMounted: (node: HTMLImageElement) => void;
};

const FileListItem = ({
  assetId,
  onSelect,
  onMounted,
  onUnMounted,
}: FileListItemProps) => {
  const dispatch = useDispatch();
  const asset = useSelector((state) => store.getUserFileById(state, assetId));
  const selectingFiles = useSelector((state) =>
    store.getAppState(state, "selectingFiles")
  );
  const selected = useSelector((state) => {
    return store.getAppState(state, "selectedFileIds").includes(assetId);
  });
  const onContextMenu = useCallback(
    (e) => {
      const rect = e.getBoundingClientRect();
      dispatch(
        store.appStateMutate((state) => {
          state.openImageMenu = true;
          state.anchorTop = rect.top;
          state.anchorLeft = rect.left;
          state.openImageMenuId = assetId;
        })
      );
    },
    [assetId, dispatch]
  );
  const longPressProps = useLongPress<HTMLDivElement>(onContextMenu);
  const onClick = useCallback(() => {
    if (selectingFiles) {
      dispatch(
        store.appStateMutate((state) => {
          const index = state.selectedFileIds.indexOf(assetId);
          if (index < 0) {
            state.selectedFileIds.push(assetId);
          } else {
            state.selectedFileIds.splice(index, 1);
          }
        })
      );
      return;
    }

    onSelect(asset.url || "");
  }, [onSelect, dispatch, selectingFiles, assetId, asset.url]);
  const [img, setImg] = React.useState<null | HTMLImageElement>(null);
  React.useEffect(() => {
    if (img && asset.url) {
      onMounted(img);
      return () => onUnMounted(img);
    }
  }, [img, asset.url, onUnMounted, onMounted]);
  return (
    <Tooltip title={asset.name} placement="bottom">
      <FileTile onClick={onClick} {...longPressProps}>
        <img
          ref={(node) => setImg(node)}
          src={"/blank.gif"}
          data-src={toCDNUrl(asset.url)}
          draggable={false}
          alt={asset.name}
        />
        {selectingFiles && <Selected selected={selected} />}
      </FileTile>
    </Tooltip>
  );
};

type SelectedProps = {
  selected: boolean;
};

const Selected = ({ selected }: SelectedProps) => {
  if (selected) {
    return (
      <>
        <Overlay />
        <SelectCircleChecked />
      </>
    );
  } else {
    return <SelectCircleNotChecked />;
  }
};

type FileListItemEmptyProps = {
  onSelect: (file: string) => void;
};

export const FileListItemEmpty = ({ onSelect }: FileListItemEmptyProps) => {
  const [t] = useTranslation();
  const selectingFiles = useSelector((state) =>
    store.getAppState(state, "selectingFiles")
  );

  const onClick = useCallback(() => {
    if (!selectingFiles) {
      onSelect("");
    }
  }, [selectingFiles]);

  return (
    <FileTile onClick={onClick} disabled={selectingFiles}>
      <Typography variant="caption">{t("NOIMAGE")}</Typography>
    </FileTile>
  );
};

const FileTile = styled.div<{ disabled?: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0.5%;
  width: 19%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 160ms ease-out;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  position: relative;
  &:active {
    opacity: 0.5;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(255, 255, 255, 0.5);
`;

const SelectCircleChecked = styled(SelectedIcon)`
  position: absolute;
  top: 4px;
  left: 4px;
`;

const SelectCircleNotChecked = styled(NotSelectedIcon)`
  position: absolute;
  top: 4px;
  left: 4px;
`;

export default FileListItem;
