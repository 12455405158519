import { createSelector } from "reselect";
import { Effect } from "./records";
import { DefaultRootState } from "react-redux";
import { sortBy } from "lodash-es";

const emptyEffect: Effect = {
  name: "",
  soundUrl: null,
  soundVolume: 0.2,
  soundName: "",
  imageUrl: null,
  owner: "",
  order: 0,
  active: false,
  playTime: 0,
  createdAt: 0,
  updatedAt: 0,
};
export const getRoomEffectById = (
  state: DefaultRootState,
  effectId: string
) => {
  return state.entities.roomEffects.byId[effectId] || emptyEffect;
};

export const getRoomEffectIds = (state: DefaultRootState) => {
  return state.entities.roomEffects.allIds;
};

const getRoomEffects = (state: DefaultRootState) =>
  state.entities.roomEffects.byId;

const getProps = (_: unknown, props: { text: string }) => props.text;

export const getActiveRoomEffectIds = createSelector(
  [getRoomEffectIds, getRoomEffects],
  (effectIds, effects) => {
    return effectIds.filter((effectId) => effects[effectId].active);
  }
);

const isLastMatch = (text: string, pattern: string) => {
  // endsWith
  if (!text || !pattern) return false;
  return (
    text.lastIndexOf(pattern) + pattern.length === text.length &&
    pattern.length <= text.length
  );
};

export const getRoomOrderdEffectIds = createSelector(
  [getRoomEffectIds, getRoomEffects],
  (ids, effects) => {
    return sortBy(ids, [
      (id) => effects[id].order,
      (id) => effects[id].createdAt,
    ]);
  }
);

export const getRoomEffectIdsByText = createSelector(
  [getRoomEffectIds, getRoomEffects, getProps],
  (effectIds, effects, text) => {
    return effectIds.filter((effectId) => {
      const effect = effects[effectId];
      return isLastMatch(text, effect.name);
    });
  }
);
