import actions from "./actions";
import { db } from "initializer";
import { shuffle } from "lodash-es";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import { DeckRecord, DeckItemsRecord } from "./records";
import { getRoomDeckById } from "./selectors";
import { ItemRecord } from "../entities.room.items/records";
import {
  addRoomItem,
  itemsRef,
  deleteRoomItem,
  deleteRoomItems,
} from "../entities.room.items/operations";
import {
  getMaxZIndex,
  getRoomItemById,
  getRoomItemIdsByDeckId,
  getRoomNearItemsByPosition,
} from "../entities.room.items/selectors";
import { UpdateDeck, DeckItem } from "./records";
import { DefaultThunk } from "stores";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import {
  DocumentData,
  FieldValue,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import shortid from "shortid";

const deckConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(deck: UpdateDeck): DocumentData {
    return deck;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return DeckRecord(data);
  },
};

export const decksRef = (roomId: string) =>
  collection(db, "rooms", roomId, "decks").withConverter(deckConverter);

export const subscribeRoomDecks = createSubscribeCollection(actions, decksRef);

export const createPlayingCards = (): Record<string, DeckItem> => {
  const types = ["c", "d", "h", "s"];
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  const items: Record<string, DeckItem> = {
    x01: {
      imageUrl: "/images/trump/x01.png",
      memo: "",
    },
    x02: {
      imageUrl: "/images/trump/x02.png",
      memo: "",
    },
  };
  types.forEach((tp) => {
    numbers.forEach((number) => {
      items[`${tp}${("0" + number).slice(-2)}`] = {
        imageUrl: `/images/trump/${tp}${("0" + number).slice(-2)}.png`,
        memo: "",
      };
    });
  });
  return items;
};
export const addRoomDeck =
  (deck: UpdateDeck): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    return addDoc(decksRef(roomId), {
      ...deck,
      x: typeof deck.x === "number" && !isNaN(deck.x) ? deck.x : -1,
      y: typeof deck.y === "number" && !isNaN(deck.y) ? deck.y : -1,
      coverImageUrl: "/images/trump/z01.png",
      items: createPlayingCards(),
    });
  };

export const addRoomDeckItemFromPanelPosition =
  (
    deck: UpdateDeck,
    position: { x: number; y: number; width: number; height: number }
  ): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const items = getRoomNearItemsByPosition(state, position);
    const itemIds = Object.keys(items);
    if (!roomId || itemIds.length < 1) return;
    const batch = writeBatch(db);
    batch.set(doc(decksRef(roomId)), {
      ...deck,
      ...position,
      items: DeckItemsRecord(items),
    });
    itemIds.forEach((itemId) => {
      batch.delete(doc(itemsRef(roomId), itemId));
    });
    return batch.commit();
  };

type MergeProps = {
  src: string;
  dest: string;
};

export const mergeRoomDecks =
  ({ src: sourceDeckId, dest: destDeckId }: MergeProps): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const destDeck = getRoomDeckById(state, destDeckId);
    const sourceDeck = getRoomDeckById(state, sourceDeckId);
    if (!roomId || destDeck == null || sourceDeck == null) {
      return;
    }

    // トランプなどでDeck内のIDが同じ場合があるため、キーをランダムな値に変更する
    const items = transformRandomKeys(sourceDeck.items);

    if (Object.keys(items).length !== 0) {
      setDoc(doc(decksRef(roomId), destDeckId), { items }, { merge: true });
    }
    dispatch(deleteRoomDeck(sourceDeckId));
  };

const transformRandomKeys = <T = unknown>(record: {
  [key: string]: T;
}): { [key: string]: T } => {
  let transformed: Record<string, T> = {};
  for (const key in record) {
    transformed[shortid()] = record[key];
  }

  return transformed;
};

export const deleteRoomDeck =
  (deckId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    return deleteDoc(doc(decksRef(roomId), deckId));
  };

export const updateRoomDeck =
  (deckId: string, deck: UpdateDeck): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    return setDoc(doc(decksRef(roomId), deckId), deck, { merge: true });
  };

export const updateCurrentRoomDeck =
  (deck: UpdateDeck): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const deckId = state.app.state.openRoomDeckDetailId;
    if (!roomId || !deckId) return;
    return setDoc(doc(decksRef(roomId), deckId), deck, { merge: true });
  };

export const addRoomItemFromRoomDeck =
  (deckId: string, itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const deck = getRoomDeckById(state, deckId);
    if (!roomId || !deck) return;
    const item = deck.items[itemId]; // todo
    if (!item) return;
    setDoc(
      doc(decksRef(roomId), deckId),
      {
        items: {
          [itemId]: deleteField(),
        },
      },
      { merge: true }
    );
    dispatch(addRoomItem(item));
  };

export const addRandomRoomItemFromRoomDeck =
  (deckId: string, amount: number, down: boolean): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName");
    const deck = getRoomDeckById(state, deckId);
    const z = getMaxZIndex(state);
    if (!roomId || !uid || !deck) return;
    const itemIds = shuffle(Object.keys(deck.items)).slice(
      0,
      Math.min(amount, 100)
    );
    if (itemIds.length < 1) return;
    const deletedItems: Record<string, FieldValue> = {};
    // const addedItems: UpdateItem[] = [];
    const batch = writeBatch(db);
    const createdAt = Date.now();
    itemIds.forEach((itemId, index) => {
      deletedItems[itemId] = deleteField();
      batch.set(
        doc(itemsRef(roomId)),
        ItemRecord({
          ...deck.items[itemId],
          coverImageUrl: deck.coverImageUrl,
          x: deck.x + 1 + index,
          y: deck.y + 1 + index,
          z,
          width: deck.width,
          height: deck.height,
          deckId,
          closed: true,
          freezed: true,
          owner: down ? null : uid,
          ownerName: down ? null : name || "NONAME",
          createdAt: createdAt + index,
          updatedAt: createdAt + index,
        }),
        { merge: true }
      );
    });
    batch.set(
      doc(decksRef(roomId), deckId),
      { items: deletedItems },
      { merge: true }
    );
    batch.commit();
    // if (itemIds.length === 1) {
    //   const item = deck.items[itemIds[0]];
    //   dispatch(
    //     appStateMutate(state => {
    //       state.openInspector = true;
    //       state.inspectText = item?.memo || null;
    //       state.inspectImageUrl = item?.imageUrl || null;
    //     })
    //   );
    // }
  };

export const addRoomDeckItem =
  (deckId: string, itemId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const item = getRoomItemById(state, itemId);
    if (!roomId || !item) return;
    setDoc(
      doc(decksRef(roomId), deckId),
      {
        items: {
          [itemId]: {
            imageUrl: item.imageUrl,
            memo: item.memo,
          },
        },
      },
      { merge: true }
    );
    deleteRoomItem(roomId, itemId)();
  };

export const addRoomDeckItemsById =
  (deckId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const itemIds = getRoomItemIdsByDeckId(state, deckId);
    if (!roomId || itemIds.length < 1) return;
    const items: {
      [key: string]: { imageUrl: string | null; memo: string };
    } = {};
    itemIds.forEach((itemId) => {
      const item = getRoomItemById(state, itemId);
      if (item) {
        items[itemId] = {
          imageUrl: item.imageUrl,
          memo: item.memo,
        };
      }
    });
    setDoc(doc(decksRef(roomId), deckId), { items }, { merge: true });
    dispatch(deleteRoomItems(itemIds));
  };

export const importRoomDecks =
  (decksData: { [deckId: string]: UpdateDeck }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const deckIds = Object.keys(decksData);
    const batch = writeBatch(db);
    const ref = decksRef(roomId);
    deckIds.forEach((deckId) => {
      batch.set(doc(ref, deckId), DeckRecord(decksData[deckId]));
    });
    return batch.commit();
  };
