import types from "./types";
import { Emote } from "./";

const actions = {
  add: (emote: Emote) => {
    return {
      type: types.add,
      id: emote.id,
      data: emote,
    };
  },
  remove: (id: string) => {
    return {
      type: types.remove,
      id,
    };
  },
};

export default actions;
