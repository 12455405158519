import { ClickAction, ClickActionRecord } from "modules/clickAction";
import { Role } from "../entities.room.members";

export type Marker = {
  _id?: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  locked: boolean;
  freezed: boolean;
  imageUrl: string | null;
  text: string;
  clickAction: ClickAction | null;
};
export type RoomVariable = {
  label: string;
  value: string;
};
export type MessageGroup = MessageGroupPublic | MessageGroupPrivate;

type MessageGroupBase = {
  id: string;
  name: string;
};

type MessageGroupPublic = MessageGroupBase & {
  kind: "public";
};

type MessageGroupPrivate = MessageGroupBase & {
  kind: "private";
  uids: string[];
};

export type UpdateMarker = Partial<Marker>;
export type Room = {
  _id?: string;
  name: string;
  owner: string;
  defaultRole: Role;
  defaultAnonymousRole: Exclude<Role, "subowner"> | null;
  diceBotName: string | null;
  diceBotSystem: string | null;
  backgroundUrl: string | null;
  foregroundUrl: string | null;
  embedUrl: string | null;
  thumbnailUrl: string | null;
  mapType: null;
  fieldWidth: number;
  fieldHeight: number;
  fieldObjectFit: "fill" | "cover";
  alignWithGrid: boolean;
  messageChannels: string[];
  messageGroups: MessageGroup[];
  markers: Record<string, Marker>;
  mediaName: string;
  mediaUrl: string | null;
  mediaType: string;
  mediaRepeat: boolean;
  mediaVolume: number;
  monitored: boolean;
  soundName: string;
  soundUrl: string | null;
  soundRepeat: boolean;
  soundVolume: number;
  sceneId: string | null;
  archived: boolean;
  backgroundColor?: string;
  variables: RoomVariable[];
  createdAt: number;
  updatedAt: number;
  timer: {
    duration: number;
    current: number;
    startTime: number;
  };
  video: {
    id: string | null;
    url: string | null;
  };
  features: {
    timer: boolean;
    hiddenMessage: boolean;
    emote: boolean;
  };
  underConstruction: boolean;
  hidden3dDice: boolean;
  publishedRoomPackageId: string | null;
  parentRoomPackageId: string | null;
  parentProductId: string | null;
  appliedExtentionProductIds: string[];
  initialSavedata: {
    thumbnail: string | null;
    snapshotId: string;
  } | null;
};
export type UpdateRoom = {
  _id?: string;
  name?: string;
  owner?: string;
  defaultRole?: Role;
  defaultAnonymousRole?: Exclude<Role, "subowner"> | null;
  diceBotName?: string | null;
  diceBotSystem?: string | null;
  backgroundUrl?: string | null;
  foregroundUrl?: string | null;
  embedUrl?: string | null;
  thumbnailUrl?: string | null;
  mapType?: null;
  fieldWidth?: number;
  fieldHeight?: number;
  fieldObjectFit?: "fill" | "cover";
  alignWithGrid?: boolean;
  messageChannels?: string[];
  messageGroups?: MessageGroup[];
  markers?: Record<string, UpdateMarker>;
  mediaName?: string;
  mediaUrl?: string | null;
  mediaType?: string;
  mediaRepeat?: boolean;
  mediaVolume?: number;
  monitored?: boolean;
  soundName?: string;
  soundUrl?: string | null;
  soundRepeat?: boolean;
  soundVolume?: number;
  sceneId?: string | null;
  archived?: boolean;
  backgroundColor?: string;
  variables?: RoomVariable[];
  createdAt?: number;
  updatedAt?: number;
  timer?: {
    duration: number;
    current: number;
    startTime: number;
  };
  video?: {
    id: string | null;
    url: string | null;
  };
  features?: {
    timer: boolean;
    hiddenMessage: boolean;
    emote: boolean;
  };
  underConstruction?: boolean;
  hidden3dDice?: boolean;
  publishedRoomPackageId?: string | null;
  parentRoomPackageId?: string | null;
  parentProductId?: string | null;
  appliedExtentionProductIds?: string[];
  initialSavedata?: {
    thumbnail: string | null;
    snapshotId: string;
  } | null;
};
export type Key = {
  _id?: string;
  keys: {
    master: string;
    player: string;
    audience: string;
  };
  owner: string;
  createdAt: number;
  updatedAt: number;
};
export type UpdateKey = {
  _id?: string;
  keys?: {
    master: string;
    player: string;
    audience: string;
  };
  owner?: string;
  createdAt?: number;
  updatedAt?: number;
};

export const MarkerRecord = (data: UpdateMarker): Marker => {
  return {
    _id: data._id,
    x: data?.x ?? 0,
    y: data?.y ?? 0,
    z: data?.z ?? 1,
    width: data?.width ?? 2,
    height: data?.height ?? 2,
    locked: data?.locked ?? false,
    freezed: data?.freezed ?? false,
    text: data?.text ?? "",
    imageUrl: data?.imageUrl ?? null,
    clickAction: ClickActionRecord(data?.clickAction ?? {}),
  };
};

export const MarkersRecord = (
  data: Record<string, UpdateMarker>
): Record<string, Marker> => {
  const items = {};
  if (!data) return items;
  Object.keys(data).forEach((itemId) => {
    items[itemId] = MarkerRecord(data[itemId]);
  });
  return items;
};

export const RoomRecord = (data: UpdateRoom): Room => {
  return {
    name: data.name || "新しい部屋",
    owner: data.owner || "",
    defaultRole: data.defaultRole || "player",
    defaultAnonymousRole: data.defaultAnonymousRole || null,
    diceBotName: data.diceBotName || "",
    diceBotSystem: data.diceBotSystem || null,
    backgroundUrl: data.backgroundUrl || null,
    foregroundUrl: data.foregroundUrl || null,
    embedUrl: data.embedUrl || null,
    thumbnailUrl: data.thumbnailUrl || null,
    mapType: data.mapType || null,
    fieldWidth: data.fieldWidth ?? 40,
    fieldHeight: data.fieldHeight ?? 30,
    fieldObjectFit: data.fieldObjectFit || "fill",
    alignWithGrid: data.alignWithGrid ?? true,
    messageChannels: data.messageChannels || [],
    messageGroups: data.messageGroups || [],
    markers: data.markers !== undefined ? MarkersRecord(data.markers) : {},
    mediaName: data.mediaName || "",
    mediaUrl: data.mediaUrl || null,
    mediaType: data.mediaType || "",
    mediaRepeat: data?.mediaRepeat ?? true,
    mediaVolume: data?.mediaVolume ?? 0.2,
    monitored: data.monitored ?? false,
    soundName: data.soundName || "",
    soundUrl: data.soundUrl || null,
    soundRepeat: data?.soundRepeat ?? false,
    soundVolume: data?.soundVolume ?? 0.2,
    sceneId: data.sceneId || null,
    archived: data.archived || false,
    backgroundColor: data.backgroundColor || "",
    variables: data.variables || [],
    createdAt: data.createdAt || Date.now(),
    updatedAt: data.createdAt || Date.now(),
    timer: {
      duration: data?.timer?.duration || 0,
      current: data?.timer?.current || 0,
      startTime: data?.timer?.startTime || 0,
    },
    video: {
      id: data?.video?.id || null,
      url: data?.video?.url || null,
    },
    features: {
      timer: data?.features?.timer ?? false,
      hiddenMessage: data?.features?.hiddenMessage ?? false,
      emote: data?.features?.emote ?? false,
    },
    underConstruction: data.underConstruction ?? false,
    hidden3dDice: data?.hidden3dDice ?? false,
    publishedRoomPackageId: data.publishedRoomPackageId ?? null,
    parentRoomPackageId: data.parentRoomPackageId ?? null,
    parentProductId: data.parentProductId ?? null,
    appliedExtentionProductIds: data.appliedExtentionProductIds || [],
    initialSavedata: data.initialSavedata || null,
  };
};
