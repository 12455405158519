import { FC, useMemo } from "react";
import styled from "styled-components";
import {
  Avatar,
  Button,
  ButtonProps,
  Container,
  Paper,
  Typography,
  TypographyProps,
} from "@mui/material";
import theme from "theme";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { shuffle } from "lodash-es";
import { useTranslation } from "react-i18next";
import sendEvent from "modules/sendEvent";
import StaticHeader from "containers/StaticHeader";
import Footer from "containers/Footer";

const voices = shuffle([
  {
    name: "むつー",
    caption: "TRPG配信者",
    text: "まだ古びたセッションツールを使っているだって？今すぐ新時代 “ココフォリア”を体感しよう！ 一度は使ったけど辞めてしまっただって？なんてことだ、 日々使いやすくパワーアップしていく進化するツール “ココフォリア”をもう一度触ってみてくれ！ ココフォリアを使っているだって？俺も使ってるよいいよなココフォリア。",
    iconUrl: "/images/icons/mutsu.jpg",
  },
  {
    name: "まだら牛",
    caption: "CoCで登山するシナリオとかの作者",
    text: "動作が軽快。インターフェイスがオシャレ。セッション準備が圧倒的に楽。 ……他にもココフォリアの良いところはたくさんあるが、 何より素晴らしいのは、ユーザーのニーズを積極的に拾い上げて 改良を続けている開発陣の心意気だ。",
    iconUrl: "/images/icons/madaraushi.png",
  },
  {
    name: "ぱぱびっぷ",
    caption: "TRPGシナリオ・動画制作、公開セッションの企画、配信など",
    text: "ココフォリアは「多才な演出」と「簡単で軽快な動作」が両立していて セッション中のプレイヤーと視聴者の熱気を逃がさず保てる、配信に必須のツールです。 また現代的な設計思想に基づき、使用者が「本当に求めているもの」を考え工夫して実装する姿勢が素晴らしい。 将来に渡って信頼して使い続けられるセッションツールだと考えています。",
    iconUrl: "/images/icons/papavip.png",
  },
  {
    name: "ミーゴ",
    caption: "Vtuber",
    text: "TRPGの準備が大変を楽しくするツール『ココフォリア』\nセッションが楽しく、快適に、ソレが詰まったサービスなのだ。 初心者が使っても、直感的にわかる作りだから安心！ まずは難しい事を考えないで、触れてみて！ココフォリア",
    iconUrl: "/images/icons/migo.gif",
  },
]);

const emoji = [
  "☕",
  "🍮",
  "🍫",
  "🍗",
  "🍰",
  "🍺",
  "🍩",
  "🥞",
  "🐓",
  "🎲",
  "🍪",
  "🍚",
  "🍶",
  "🍵",
  "🥨",
  "🍖",
  "🍔",
  "🍭",
  "🍬",
];

const Welcome: FC = () => {
  const [t, i18n] = useTranslation();
  const emo = useMemo(
    () => emoji[Math.floor(Math.random() * emoji.length)],
    []
  );

  return (
    <>
      <StyledWrapper>
        <StaticHeader />
        <StyledContainer>
          <StyledTitlePanel>
            {i18n.language !== "ja" &&
            i18n.language !== "ko" &&
            i18n.language !== "zhTw" ? (
              <Typography
                variant="body2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                {t("機械翻訳で提供されています")}
              </Typography>
            ) : null}
            <StyledTitle
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {t("TRPGオンラインセッションのスタンダードツール")}
              <br />
              {t("トランプやボードゲームまで幅広く対応")}
            </StyledTitle>
            <StyledSubTitle
              variant="h6"
              align="center"
              color="textPrimary"
              gutterBottom
              component="h1"
            ></StyledSubTitle>
            <StyledDescription align="center" color="textSecondary">
              {t(
                "「ココフォリア」はマップや駒を動かしながら、キャラクターのアバターで、"
              )}
              <br />
              {t(
                "ダイス判定やチャットを行うことができる、スマートフォン対応のTRPGサポートツールです。"
              )}
            </StyledDescription>
          </StyledTitlePanel>
          <StyledEnterPanel>
            <StyledEnterButton
              size="large"
              variant="contained"
              fullWidth
              to="/home"
              component={Link}
              color="secondary"
            >
              {t("今すぐはじめる")}
            </StyledEnterButton>
          </StyledEnterPanel>
          <StyledButtonsPanel>
            <StyledButton
              size="large"
              variant="outlined"
              href="https://docs.ccfolia.com/"
              fullWidth
              target="_blank"
              component="a"
              // color="primary"
            >
              {t("公式チュートリアル")}
            </StyledButton>
            <StyledButton
              size="large"
              variant="outlined"
              fullWidth
              href="https://ccfolia.fanbox.cc/"
              target="_blank"
              onClick={() => sendEvent("clickSuponserButton")}
            >
              {t("開発支援（FANBOX）") + emo}
            </StyledButton>
          </StyledButtonsPanel>
        </StyledContainer>
        {/* <StyledSponserBannersPanel>
        <Typography
          variant="h6"
          // color="textPrimary"
          align="center"
          gutterBottom
        >
          {t("オフィシャルスポンサー")}
        </Typography>
        <StyledSponserBanners>
          {[...Array(5)].map((_, i) => (
            <Tooltip title="「ココフォリア」はマップや駒を動かしながら、キャラクターのアバターで、ダイス判定やチャットを行うことができる、スマートフォン対応のTRPGサポートツールです。">
              <StyledSponserBanner>
                <img
                  src={
                    [
                      "/images/sponsers/an0.png",
                      "/images/sponsers/an1.png",
                      "/images/sponsers/an2.png",
                      "/images/sponsers/an3.png",
                      "/images/sponsers/an4.png",
                      "/images/sponsers/an5.png",
                    ][i % 6]
                  }
                />
              </StyledSponserBanner>
            </Tooltip>
          ))}
        </StyledSponserBanners>
      </StyledSponserBannersPanel> */}
        {i18n.language === "ja" ? (
          <StyledUserVoicePanel elevation={6} square>
            <Typography variant="h6" color="textPrimary">
              {t("ココフォリアへの応援メッセージ")}
            </Typography>
            <Slider
              speed={640}
              autoplaySpeed={9600}
              easing="easeInOut"
              dots
              infinite
              autoplay
              arrows={false}
            >
              {voices.map((voice, index) => (
                <StyledSliderContent key={index}>
                  <StyledUserVoiceAvatar src={voice.iconUrl} />
                  <StyledUserVoiceText color="textPrimary">
                    「{voice.text}」
                  </StyledUserVoiceText>
                  <StyledUserVoiceName color="textPrimary">
                    {voice.name}
                  </StyledUserVoiceName>
                  <StyledUserVoiceCaption color="textSecondary">
                    {voice.caption}
                  </StyledUserVoiceCaption>
                </StyledSliderContent>
              ))}
            </Slider>
          </StyledUserVoicePanel>
        ) : null}
        <StyledContainer>
          <StyledTextPanels>
            <StyledTextPanel>
              <Typography
                variant="h6"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                {t("シンプルで高機能")}
              </Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                {t(
                  "「ココフォリア」を使うだけでもある程度雰囲気が出るような画面になっています。"
                )}
                <br />
                <br />
                {t(
                  "Material Design を基に作成されたUIは、スマートフォンやタブレットなど、どんなデバイスからでも簡単に使いこなすことができます。"
                )}
              </Typography>
            </StyledTextPanel>
            <StyledTextPanel>
              <Typography
                variant="h6"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                {t("サーバー要らず")}
              </Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                {t(
                  "アカウント登録によりパーソナルスペースが提供され、自分だけのルームやストレージを誰でも簡単に手に入れることができます。"
                )}
                <br />
                <br />
                {t(
                  "セッションのデータはココフォリアが提供するスケーラブルで高速なデータベースに自動的に保存され、手動で削除しない限り消えることはありません。"
                )}
              </Typography>
            </StyledTextPanel>
            <StyledTextPanel>
              <Typography
                variant="h6"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                {t("チーム開発")}
              </Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                {t(
                  "開発には意欲的なコントリビューターが多数参加し、まるで当然のように製品レベルの品質で開発が行われています。"
                )}
                <br />
                <br />
                {t(
                  "開発チームは多くの開発支援者によって支えられており、開発された機能のほとんどが無料で提供されています。"
                )}
              </Typography>
            </StyledTextPanel>
          </StyledTextPanels>
        </StyledContainer>
        <Footer />
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background-size: cover;
  //min-height: 100vh;
  position: relative;
`;
const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;
const StyledTitlePanel = styled(Container)`
  margin: 0 auto;
  padding-top: 96px;
  padding-bottom: 16px;
`;
const StyledTitle = styled(Typography)`
  font-weight: bold;
  /* letter-spacing: 0.1em; */
  line-height: 1.4;
  /* line-break: strict;
  word-break: keep-all; */
`;
const StyledSubTitle = styled(Typography).attrs<TypographyProps<"h1">>({
  component: "h1",
})`
  /* margin-top: 32px; */
  /* line-break: strict;
  word-break: keep-all; */
`;
const StyledDescription = styled(Typography)`
  /* margin-top: 32px; */
  /* line-break: strict;
  word-break: keep-all; */
`;
const StyledEnterPanel = styled.div`
  display: flex;
  justify-content: center;
  ${theme.breakpoints.down("sm")} {
    display: block;
  }
`;
const StyledButtonsPanel = styled.div`
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  ${theme.breakpoints.down("sm")} {
    display: block;
  }
`;
const StyledButton = styled(Button)<ButtonProps<"a">>`
  margin: 16px;
  display: block;
  max-width: 320px;
  text-align: center;
  ${theme.breakpoints.down("sm")} {
    margin: 16px;
    width: auto;
    max-width: none;
  }
`;
const StyledEnterButton = styled(Button)<ButtonProps<"a">>`
  margin: 32px 16px 0;
  padding: 16px;
  display: block;
  max-width: 672px;
  text-align: center;
  ${theme.breakpoints.down("sm")} {
    margin: 16px;
    width: auto;
    max-width: none;
  }
`;
const StyledTextPanels = styled.div`
  margin: 32px 0;
  display: flex;
  ${theme.breakpoints.down("sm")} {
    display: block;
  }
`;
const StyledTextPanel = styled.div`
  padding: 32px;
  flex: 1;
`;
// const StyledSponserBannersPanel = styled.div`
//   padding: 16px;
//   background: #fff;
// `;
// const StyledSponserBanners = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   ${theme.breakpoints.down("xs")} {
//     // display: block;
//   }
// `;
// const StyledSponserBanner = styled.figure`
//   margin: 8px 16px;
//   width: calc(20% - 32px);
//   ${theme.breakpoints.down("sm")} {
//     width: calc(33% - 32px);
//   }
//   ${theme.breakpoints.down("xs")} {
//     width: calc(50% - 32px);
//   }
//   img {
//     display: block;
//     width: 100%;
//     max-height: 80px;
//   }
// `;
const StyledUserVoicePanel = styled(Paper)`
  // margin-top: 32px;
  padding: 32px 0 32px;
  position: relative;
  z-index: 1;
  text-align: center;
`;
const StyledSliderContent = styled.div`
  max-width: 960px;
  outline: none;
`;
const StyledUserVoiceAvatar = styled(Avatar)`
  margin: 24px auto;
  border: 1px solid #ccc;
  width: 104px;
  height: 104px;
`;
const StyledUserVoiceText = styled(Typography)`
  margin: 16px;
  font-weight: normal;
  font-style: italic;
  font-size: 18px;
  line-break: strict;
  word-break: keep-all;
  white-space: pre-wrap;
  ${theme.breakpoints.down("md")} {
    word-break: normal;
    font-size: 16px;
  }
`;
const StyledUserVoiceName = styled(Typography)`
  margin: 24px 0 0;
  font-size: 24px;
`;
const StyledUserVoiceCaption = styled(Typography)`
  text-shadow: none;
`;

export default Welcome;
