import types from "./types";
import { combineReducers } from "redux";
import { produce } from "immer";
import { enableBatching } from "redux-batched-actions";
import { Actions } from "../actions";
import { DiceSkinSettings } from "../entities.user.setting";

export type DiceType = "d4" | "d6" | "d8" | "d10" | "d12" | "d20" | "d100";

export type SnapshotPosition = [number, number, number];
export type SnapshotQuaternion = [number, number, number, number];

type Snapshot = {
  position: SnapshotPosition;
  quaternion: SnapshotQuaternion;
};

export type ResultSimulation = {
  key: string;
  type: DiceType;
  modelId: string;
  value: number;
  simulatedValue: number;
  steps: Snapshot[];
};

export type Diceroll = {
  id: string;
  simulated: ResultSimulation[];
  diceSkinSettings: DiceSkinSettings;
};

const byId = enableBatching(
  (
    state: {
      [diceRollId: string]: Diceroll;
    } = {},
    action
  ) => {
    switch (action.type) {
      case types.add: {
        if (state[action.id]) {
          return state;
        }
        return produce(state, (draft) => {
          draft[action.id] = action.data;
        });
      }
      case types.remove: {
        return produce(state, (draft) => {
          if (draft[action.id]) {
            delete draft[action.id];
          }
        });
      }
      default: {
        return state;
      }
    }
  }
);

const allIds = enableBatching((state: string[] = [], action: Actions) => {
  switch (action.type) {
    case types.add: {
      const isExists = state.includes(action.id);
      if (isExists) {
        return state;
      }

      return produce(state, (draft) => {
        draft.push(action.id);
      });
    }
    case types.remove: {
      const index = state.findIndex((id) => id === action.id);
      if (index < 0) {
        return state;
      }

      return produce(state, (draft) => {
        draft.splice(index, 1);
      });
    }
    default: {
      return state;
    }
  }
});

const messagesReducer = combineReducers({
  byId,
  allIds,
});

export default messagesReducer;
