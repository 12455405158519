import actions from "./actions";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import {
  getRoomEffectById,
  getRoomEffectIds,
  getRoomEffectIdsByText,
} from "./selectors";
import { UpdateEffect, EffectRecord } from "./records";
import { DefaultThunk } from "stores";
import { DropResult } from "react-beautiful-dnd";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  writeBatch,
} from "firebase/firestore";

export const effectsRef = (roomId: string) =>
  collection(db, "rooms", roomId, "effects");

export const subscribeRoomEffects = createSubscribeCollection(
  actions,
  (roomId: string) => query(effectsRef(roomId), orderBy("order"))
);

export const addRoomEffect =
  (roomId: string, uid: string): DefaultThunk =>
  async (dispatch, getState) => {
    const ids = getRoomEffectIds(getState());
    await addDoc(
      effectsRef(roomId),
      EffectRecord({
        owner: uid,
        order: ids.length,
        active: false,
      })
    );
    dispatch(orderEffects(roomId));
  };
export const updateRoomEffectName =
  (roomId: string, effectId: string, item: UpdateEffect): DefaultThunk =>
  () => {
    return setDoc(
      doc(effectsRef(roomId), effectId),
      {
        name: item.name,
      },
      { merge: true }
    );
  };
export const updateRoomEffect =
  (roomId: string, effectId: string, item: UpdateEffect): DefaultThunk =>
  () => {
    return setDoc(
      doc(effectsRef(roomId), effectId),
      {
        ...item,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };
export const updateCurrentRoomEffect =
  (item: UpdateEffect): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const effectId = state.app.state.openRoomEffectId;
    if (!roomId || !effectId) return null;
    return setDoc(
      doc(effectsRef(roomId), effectId),
      {
        ...item,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };
export const activeRoomEffectByText =
  (roomId: string, text: string): DefaultThunk =>
  (_, getState) => {
    const effectIds = getRoomEffectIdsByText(getState(), { text });
    return effectIds.map((effectId) => {
      return setDoc(
        doc(effectsRef(roomId), effectId),
        {
          playTime: Date.now(),
          updatedAt: Date.now(),
        },
        { merge: true }
      );
    });
  };
export const deleteRoomEffect = (roomId: string, effectId: string) => () => {
  return deleteDoc(doc(effectsRef(roomId), effectId));
};
export const reorderEffects =
  (roomId: string, order: DropResult): DefaultThunk =>
  (dispatch) => {
    if (order.destination) {
      dispatch(actions.reorder(order.source.index, order.destination.index));
      dispatch(orderEffects(roomId));
    }
  };

const orderEffects =
  (roomId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const effectIds = getRoomEffectIds(state);
    effectIds.forEach((effectId, index) => {
      const effect = getRoomEffectById(state, effectId);
      if (effect.order !== index) {
        setDoc(
          doc(effectsRef(roomId), effectId),
          { order: index },
          { merge: true }
        );
      }
    });
  };

export const importRoomEffects =
  (effectsData: { [effectId: string]: UpdateEffect }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    const effectIds = Object.keys(effectsData);
    const batch = writeBatch(db);
    const ref = effectsRef(roomId);
    effectIds.forEach((effectId) => {
      batch.set(doc(ref, effectId), EffectRecord(effectsData[effectId]));
    });
    return batch.commit();
  };
