import types from "./types";
import { produce } from "immer";
import { Actions } from "../actions";

export interface AppChatState {
  inputText: string;
}

const initialState = {
  inputText: "",
};

// Reducers
const appChatReducer = (
  state: AppChatState = initialState,
  action: Actions
): AppChatState => {
  switch (action.type) {
    case types.APP_CHAT_MUTATE: {
      return produce(state, (draft) => {
        action.mutate(draft);
        return draft;
      });
    }
    default: {
      return state;
    }
  }
};

export default appChatReducer;
