import types from "./types";
import { combineReducers } from "redux";
import { createByIdReducer, createIndexReducer } from "../firestoreModuleUtils";
import { Member } from "./records";

const byId = createByIdReducer<Member>()(types);
const allIds = createIndexReducer(types);

const membersReducer = combineReducers({
  byId,
  allIds,
});
export default membersReducer;
