import { DefaultRootState } from "react-redux";
import { Emote } from "./reducers";

export const getEmoteIds = (state: DefaultRootState) => {
  return state.app.emotes.allIds;
};

export const getEmoteById = (
  state: DefaultRootState,
  id: string
): Emote | undefined => {
  return state.app.emotes.byId[id];
};
