import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { Badge, ButtonBase } from "@mui/material";
import toCDNUrl from "modules/toCDNUrl";

const CharacterStatusItem: React.FC<{
  characterId: string;
  variant?: "bar";
  onClick: (e: MouseEvent, characterId: string) => void;
}> = (props) => {
  const [character, uid] = useSelector(
    (state) =>
      [
        store.getCharacterById(state, props.characterId),
        store.getAppState(state, "uid"),
      ] as const,
    shallowEqual
  );
  const onClick = React.useCallback(
    (e) => {
      props.onClick(e, props.characterId);
    },
    [props.characterId, props.onClick]
  );
  if (!character) return null;
  return (
    <CharacterCard>
      <CharacterBadge
        badgeContent={!character.secret ? character.initiative : "??"}
        color="secondary"
      >
        <CharacterCardAvatar onClick={onClick}>
          <CharacterAvatarButton>
            <img
              src={toCDNUrl(character.iconUrl) || "/ccfolia.png"}
              draggable={false}
              alt=""
            />
          </CharacterAvatarButton>
        </CharacterCardAvatar>
      </CharacterBadge>
      {!character.secret || character.owner === uid ? (
        <CharacterCardContent onClick={onClick}>
          <CharacterCardBody variant={props.variant}>
            {character.status
              .filter((_, index) => index < 8)
              .map((state, index) => (
                <State key={index} state={state} />
              ))}
          </CharacterCardBody>
        </CharacterCardContent>
      ) : null}
    </CharacterCard>
  );
};

const State: React.FC<{
  state: {
    max: number;
    value: number;
    label: string;
  };
}> = (props) => {
  const max = ~~props.state.max;
  const label = props.state.label;
  const value = ~~props.state.value;
  return (
    <CharacterCardContentSlider>
      <Caption>
        <Label variant="body2" noWrap>
          {label}
        </Label>
        <Value variant="body2" noWrap>
          <Chalk color={value / max <= 0.8 ? "secondary" : "default"}>
            {value}
          </Chalk>
          /{max}
        </Value>
      </Caption>

      <StateContainer>
        <StateBar></StateBar>
        <StateValue
          style={{ width: `${Math.max(Math.min(value / max, 1), 0) * 100}%` }}
        ></StateValue>
      </StateContainer>
    </CharacterCardContentSlider>
  );
};

const CharacterCard = styled.div`
  margin-bottom: 16px;
  /* margin-right: 24px; */
  display: flex;
  align-items: flex-start;
`;

const CharacterBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 4px;
    right: 4px;
    background: ${theme.palette.grey[100]};
    color: ${theme.palette.grey[800]};
  }
`;

const CharacterAvatarButton = styled(ButtonBase)`
  display: block;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const CharacterCardContent = styled.div`
  flex: 1;
`;

const CharacterCardAvatar = styled(Avatar)`
  border-radius: 4px;
  border: 1px solid ${theme.palette.grey[100]};
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.64);
  img {
    object-position: top;
  }
`;

const CharacterCardBody = styled.div<{ variant?: string }>`
  margin-left: 4px;
  max-width: 210px;
  display: flex;
  flex-wrap: wrap;
  ${theme.breakpoints.down("md")} {
    ${(props) => (props.variant !== "bar" ? "display: none;" : null)};
  }
`;

const Caption = styled.div`
  padding: 1px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Label = styled(Typography)`
  display: block;
  line-height: 1;
  color: ${theme.palette.grey[800]};
  font-weight: 800;
  text-shadow: 1px 1px 0 ${theme.palette.grey[100]},
    -1px -1px 0 ${theme.palette.grey[100]},
    -1px 1px 0 ${theme.palette.grey[100]}, 1px -1px 0 ${theme.palette.grey[100]},
    0px 1px 0 ${theme.palette.grey[100]}, 0-1px 0 ${theme.palette.grey[100]},
    -1px 0 0 ${theme.palette.grey[100]}, 1px 0 0 ${theme.palette.grey[100]};
`;

const Value = styled(Typography)`
  display: block;
  line-height: 1;
  font-weight: 800;
  color: ${theme.palette.grey[800]};
  text-shadow: 1px 1px 0 ${theme.palette.grey[100]},
    -1px -1px 0 ${theme.palette.grey[100]},
    -1px 1px 0 ${theme.palette.grey[100]}, 1px -1px 0 ${theme.palette.grey[100]},
    0px 1px 0 ${theme.palette.grey[100]}, 0-1px 0 ${theme.palette.grey[100]},
    -1px 0 0 ${theme.palette.grey[100]}, 1px 0 0 ${theme.palette.grey[100]};
`;

const Chalk = styled.span`
  color: ${({ color }) =>
    color === "secondary" ? theme.palette.secondary.dark : null};
`;

const CharacterCardContentSlider = styled.div`
  margin: 2px;
  width: 96px;
  position: relative;
  cursor: pointer;
  /* opacity: 0.75; */
`;

const barHeight = 16;
const StateContainer = styled.div`
  /* border: 1px solid #424242; */
  height: ${barHeight}px;
  padding: 0;
  position: relative;
  /* display: block; */
  box-sizing: content-box;
`;

const StateBar = styled.div`
  width: 100%;
  height: ${barHeight}px;
  padding: 0;
  position: absolute;
  /* display: block; */
  border-radius: 1px;
  background: #f5f5f5;
  opacity: 0.38;
`;

const StateValue = styled.div`
  left: 0%;
  margin-top: 0px;
  height: ${barHeight}px;
  background: #f5f5f5;
  /* display: block; */
  position: absolute;
  border-radius: 1px;
`;

export default React.memo(CharacterStatusItem);
