import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appStateMutate } from "stores/modules/app.state/operations";
import {
  getRoomSavedataByOrder,
  getRoomSavedataIdByOrder,
} from "stores/modules/entities.room.savedatas/selectors";
import styled from "styled-components";
import SavedataItem from "./SavedataItem";
import { getAppState } from "stores/modules/app.state/selectors";

const SavedataList = () => {
  return (
    <Savedatas>
      <SavedataContainer slot={0} />
      <SavedataContainer slot={1} />
      <SavedataContainer slot={2} />
    </Savedatas>
  );
};

type SavedataContainerProps = {
  slot: number;
};

const SavedataContainer = ({ slot }: SavedataContainerProps) => {
  const savedataId = useSelector((state) =>
    getRoomSavedataIdByOrder(state, slot)
  );
  const savedata = useSelector((state) => getRoomSavedataByOrder(state, slot));
  const saving = useSelector(
    (state) => getAppState(state, "slotNumberProcessingSave") === slot
  );

  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openSaveConfirm = slot;
        state.saveConformDisabledNameEdit = false;
      })
    );
  }, [slot, dispatch]);

  const handleDelete = useCallback(() => {
    if (savedataId) {
      dispatch(
        appStateMutate((state) => {
          state.openDeleteSavedataConfirm = true;
          state.openDeleteSavedataConfirmId = savedataId;
        })
      );
    }
  }, [savedataId, dispatch]);

  const onDelete = savedata == null ? undefined : handleDelete;
  const name = savedata == null ? "NODATA" : savedata.name;

  return (
    <SavedataItem
      thumbnail={savedata?.thumbnail}
      name={name}
      loading={saving}
      onClick={handleClick}
      onDelete={onDelete}
    />
  );
};

const Savedatas = styled.div`
  // height: 640px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar: {
    display: none;
  }
`;

export default memo(SavedataList);
