import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  ListItemButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

const VALUE_ARRAY = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
] as const;

// for syntax
const lteq = (a: number, b: number) => a <= b;

type DiceDetailContentProps = {
  diceId: string;
};

type DiceSymbolFormValue = {
  faces: number;
  value: number;
  closed: boolean;
};

const DiceDetailContent = forwardRef<
  { onSubmit: () => void },
  DiceDetailContentProps
>(({ diceId }, ref) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const uid = useSelector((state) => store.getAppState(state, "uid"));
  const monitored = useSelector(store.getCurrentRoomIsMonitored);
  const dice = useSelector((state) => store.getRoomDiceById(state, diceId));

  const onSubmit = useCallback(
    (values: DiceSymbolFormValue) => {
      dispatch(store.updateCurrentRoomDice(values));
    },
    [dispatch]
  );

  const onDelete = useCallback(() => {
    if (diceId && window.confirm("本当に削除しますか？")) {
      dispatch(store.deleteRoomDice(diceId));
    }
  }, [diceId]);

  const { handleSubmit, reset, resetField, watch, control } = useForm({
    defaultValues: {
      faces: dice.faces,
      value: dice.value,
      closed: dice.closed,
    },
  });

  useEffect(() => {
    reset({
      faces: dice.faces,
      value: dice.value,
      closed: dice.closed,
    });
  }, [dice.faces, dice.value, dice.closed, reset]);

  const viewable = dice.owner === uid || monitored;

  const value = watch("value");
  const faces = watch("faces");

  const shouldReset = faces < value || (faces !== 0 && value === 0);

  useEffect(() => {
    if (shouldReset) {
      resetField("value", { defaultValue: 1 });
    }
  }, [shouldReset, resetField]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onSubmit: handleSubmit(onSubmit),
      };
    },
    [handleSubmit, onSubmit]
  );

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {viewable && (
        <FormControl variant="filled" fullWidth>
          <InputLabel>{t("ダイス")}</InputLabel>
          <Controller
            name="faces"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                variant="filled"
                autoWidth
                type="number"
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
              >
                <StyledMenuItem value={4}>D4</StyledMenuItem>
                <StyledMenuItem value={6}>D6</StyledMenuItem>
                <StyledMenuItem value={10}>D10</StyledMenuItem>
                <StyledMenuItem value={12}>D12</StyledMenuItem>
                <StyledMenuItem value={20}>D20</StyledMenuItem>
                <StyledMenuItem value={0}>D0 (Blank)</StyledMenuItem>
              </Select>
            )}
          />
        </FormControl>
      )}
      {viewable && faces !== 0 && (
        <FormControl variant="filled" fullWidth>
          <InputLabel>{t("値")}</InputLabel>
          <Controller
            name="value"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                variant="filled"
                autoWidth
                type="number"
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
              >
                {VALUE_ARRAY.filter((num) => lteq(num, faces)).map((num) => (
                  <StyledMenuItem key={num} value={num}>
                    {num}
                  </StyledMenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      )}
      <List disablePadding>
        <ListItem divider>
          <ListItemText primary={t("隠す")} />
          <ListItemSecondaryAction>
            <Controller
              name="closed"
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <Switch
                  onChange={onChange}
                  checked={value}
                  name={name}
                  inputRef={ref}
                />
              )}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItemButton onClick={onDelete}>
          <ListItemText primary={t("削除")} />
        </ListItemButton>
      </List>
    </StyledForm>
  );
});

const StyledForm = styled.form`
  width: 160px;
`;

const StyledMenuItem = styled(MenuItem)`
  width: 240px;
`;

export default memo(DiceDetailContent);
