import { memo, useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Paper,
  ButtonGroup,
  Button,
  Slider,
  Typography,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { ReactComponent as CcfoliaProIcon } from "./CcfoliaPro.svg";
import MediumList from "../MediumList";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import MediumListTabs from "./MediumListTabs";
import MediumListOfficialPlaylist from "containers/MediumListOfficialPlaylist";
import theme from "theme";
import { createHowl } from "modules/sound";

const MediumListDialog = () => {
  const d = useDispatch();
  const [uid, roomId, group, tab, dir, open] = useSelector((state) => {
    return [
      store.getAppState(state, "uid"),
      store.getAppState(state, "roomId"),
      store.getAppState(state, "userMediumListGroup"),
      store.getAppState(state, "userMediumListLibraryTab"),
      store.getAppState(state, "openRoomMediaDir"),
      store.getAppState(state, "openRoomMedia"),
    ] as const;
  }, shallowEqual);
  const isPro = useSelector((state) => {
    return store.getAppState(state, "plan") === "ccfolia-pro";
  });
  const [t] = useTranslation();
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomMedia = false;
      })
    );
  }, []);
  const onStop = useCallback(() => {
    if (dir === "bgm") {
      d(
        store.updateCurrentRoom({
          mediaUrl: null,
          mediaType: "file",
          mediaName: "",
          mediaVolume: 0,
          mediaRepeat: true,
        })
      );
    } else if (dir === "sound") {
      d(
        store.updateCurrentRoom({
          soundUrl: null,
          soundName: "",
          soundVolume: 0,
          soundRepeat: false,
        })
      );
    } else if (dir === "effect") {
      d(
        store.updateCurrentRoomEffect({
          soundUrl: null,
          soundName: "",
        })
      );
    }
  }, [dir, d]);
  const onDrop = useCallback(
    (files) => {
      if (uid == null || roomId == null) return [];
      return files.map((file) => {
        d(store.addUserMedium(uid, roomId, tab, file));
      });
    },
    [uid, roomId, tab, d]
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (uid == null || roomId == null) return [];
      const form = e.currentTarget;
      const formdata = new FormData(form);
      const url = formdata.get("url");
      if (url) {
        createHowl({
          src: [String(url)],
          autoplay: false,
          volume: 1,
          format: ["mp4", "ogg", "wav"],
          mute: true,
          html5: true,
          onload: () => {
            d(store.addUserMediumFromUrl(uid, roomId, tab, String(url)));
          },
          onloaderror: (err) => {
            window.alert(t("利用できないURLです。"));
          },
        });
      }
      form.reset();
    },
    [uid, roomId, tab, d, t]
  );
  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    disabled: group !== "library",
    accept: ["audio/*", ".mp3", ".wav"],
  });

  const onChangeGroup = useCallback(
    (group: "library" | "officialPlaylist") => () => {
      d(
        store.appStateMutate((state) => {
          state.userMediumListGroup = group;
        })
      );
    },
    []
  );

  useEffect(() => {
    if (open) {
      d(store.loadOfficialPlaylists());
    }
  }, [open, d]);

  return (
    <div {...dropzone.getRootProps()}>
      <MediumListDrawer open={open} onClose={onClose} anchor="bottom">
        <AppBar position="static" color="default">
          <Toolbar>
            <ButtonGroup size="small">
              <Button
                onClick={onChangeGroup("library")}
                variant={group === "library" ? "contained" : undefined}
                color="white"
                style={
                  isPro
                    ? {}
                    : {
                        borderTopRightRadius: "inherit",
                        borderBottomRightRadius: "inherit",
                      }
                }
              >
                {t("アップロード")}
              </Button>
              {isPro && (
                <Button
                  onClick={onChangeGroup("officialPlaylist")}
                  variant={
                    group === "officialPlaylist" ? "contained" : undefined
                  }
                  color="white"
                  disabled={!isPro}
                >
                  {t("公式ライブラリ")}
                  {isPro ? (
                    <IconPro active={group === "officialPlaylist"} />
                  ) : (
                    <PlanLabel>FREE</PlanLabel>
                  )}
                </Button>
              )}
            </ButtonGroup>
            <Spacer />
            <IconButton edge="end" onClick={onStop} size="large">
              <StopIcon />
            </IconButton>
            <IconButton edge="end" onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <MediumListTabs />
        </AppBar>
        {group === "library" ? <MediumList /> : <MediumListOfficialPlaylist />}
        {group === "library" ? (
          <FloatBar square>
            <Form onSubmit={onSubmit} autoComplete="off" autoCorrect="off">
              <TextField
                variant="standard"
                fullWidth
                label={t("外部ファイルURL")}
                placeholder="https://..."
                helperText={t("指定したURLのファイルに直接リンクします")}
                type="text"
                name="url"
              />
              <IconButton type="submit" size="large">
                <LibraryAddIcon />
              </IconButton>
              <IconButton onClick={dropzone.open} size="large">
                <AttachFileIcon />
              </IconButton>
            </Form>
          </FloatBar>
        ) : (
          <FloatVolumeSlider />
        )}
      </MediumListDrawer>
      <input {...dropzone.getInputProps()} disabled={false} />
    </div>
  );
};

const FloatVolumeSlider = () => {
  const dispatch = useDispatch();
  const officialPlaylistVolume = useSelector((state) =>
    store.getAppState(state, "officialPlaylistVolume")
  );

  const onChange = useCallback(
    (event: Event, value: number | number[]) => {
      if (typeof value === "number") {
        dispatch(
          store.appStateMutate(
            (state) => (state.officialPlaylistVolume = value)
          )
        );
      }
    },
    [dispatch]
  );

  return (
    <VolumeBar square>
      <Typography variant="caption">Volume Setting</Typography>
      <VolumeBox>
        <VolumeDownIcon />
        <Slider
          value={officialPlaylistVolume}
          onChange={onChange}
          max={1}
          min={0}
          step={0.05}
        />
        <VolumeUpIcon />
      </VolumeBox>
    </VolumeBar>
  );
};

const MediumListDrawer = styled(Drawer)`
  z-index: ${theme.zIndex.modal};

  .MuiDrawer-paper {
    margin: auto;
    max-width: 640px;
    height: 75%;
  }
`;

const Form = styled.form`
  padding: 8px 16px 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;

const Spacer = styled.div`
  flex: 1;
`;

const PlanLabel = styled.small`
  margin-left: 2px;
  font-size: 10px;
`;

const FloatBar = styled(Paper)`
  padding-bottom: 8px;
  background: #222;
`;

const VolumeBar = styled(Paper)`
  padding: 16px;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const VolumeBox = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 200px;
`;

const IconPro = styled(CcfoliaProIcon)<{ active: boolean }>`
  margin-left: 4px;
  fill: ${(props) =>
    props.active ? theme.palette.grey["900"] : theme.palette.grey["100"]};
`;

export default memo(MediumListDialog);
