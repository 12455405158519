import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  List,
  ListItemText,
  DialogActions,
  Button,
  ListItemButton,
  ListItem,
  ListItemSecondaryAction,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import { updateCurrentRoom } from "stores/modules/entities.rooms/operations";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import styled from "styled-components";

type FormValues = {
  fieldWidth: number;
  fieldHeight: number;
};

const FIELD_SIZE_MIN = 0;
const FIELD_SIZE_MAX = 1000;

const clampFieldSize = (size: number): number => {
  if (size < FIELD_SIZE_MIN) {
    return FIELD_SIZE_MIN;
  } else if (size > FIELD_SIZE_MAX) {
    return FIELD_SIZE_MAX;
  }

  return size;
};

const FieldDetail = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) => getAppState(state, "openRoomFieldEdit"));
  const initialValues = useSelector((state) => {
    const room = getCurrentRoom(state);
    if (!room) return null;
    return {
      fieldWidth: room.fieldWidth,
      fieldHeight: room.fieldHeight,
    };
  });

  const isFieldCover = useSelector((state) => {
    return getCurrentRoom(state)?.fieldObjectFit === "cover";
  });

  const { register, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      fieldWidth: initialValues?.fieldWidth || 0,
      fieldHeight: initialValues?.fieldHeight || 0,
    },
  });

  useEffect(() => {
    if (
      initialValues?.fieldWidth != null &&
      initialValues?.fieldHeight != null
    ) {
      reset({
        fieldWidth: initialValues.fieldWidth,
        fieldHeight: initialValues.fieldHeight,
      });
    }
  }, [initialValues?.fieldWidth, initialValues?.fieldHeight, reset]);

  const onSubmit = useCallback(
    ({ fieldWidth, fieldHeight }: FormValues) => {
      dispatch(
        updateCurrentRoom({
          fieldWidth: clampFieldSize(fieldWidth),
          fieldHeight: clampFieldSize(fieldHeight),
        })
      );
      dispatch(appStateMutate((state) => (state.openRoomFieldEdit = false)));
    },
    [dispatch]
  );

  const onSelectBackground = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "background";
        state.openRoomImageSelectTarget = "background/set";
      })
    );
  }, [dispatch]);

  const onSelectForeground = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "foreground";
        state.openRoomImageSelectTarget = "foreground/set";
      })
    );
  }, [dispatch]);

  const onChangeFieldCover = useCallback(
    (_: unknown, checked: boolean) => {
      dispatch(
        updateCurrentRoom({
          fieldObjectFit: checked ? "cover" : "fill",
        })
      );
    },
    [dispatch]
  );

  const onClose = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <AppBar position="sticky" elevation={0} color="default">
        <Toolbar variant="dense">
          <Typography variant="subtitle2">{t("フィールド設定")}</Typography>
          <StyledSpacer />
          <IconButton onClick={onClose} edge="end" size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ paddingBottom: "0" }}>
        <StyledForm>
          <TextField
            label={t("横幅")}
            variant="filled"
            type="number"
            margin="dense"
            fullWidth
            inputProps={{ min: "0", max: "1000" }}
            {...register("fieldWidth")}
          />
          <TextField
            label={t("縦幅")}
            variant="filled"
            type="number"
            margin="dense"
            fullWidth
            inputProps={{ min: "0", max: "1000" }}
            {...register("fieldHeight")}
          />
        </StyledForm>
        <List>
          <ListItem>
            <ListItemText
              style={{ paddingRight: "32px" }}
              primary={t("画像を自動トリミングする")}
              secondary={t("画像の縦横比を変えずに表示します")}
            />
            <ListItemSecondaryAction>
              <Switch onChange={onChangeFieldCover} checked={isFieldCover} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <List>
          <ListItemButton dense onClick={onSelectBackground}>
            <ListItemText
              primary={t("背景選択")}
              secondary={t("画面全体とパネルに表示される画像を設定します")}
            />
          </ListItemButton>
          <ListItemButton dense onClick={onSelectForeground}>
            <ListItemText
              primary={t("前景選択")}
              secondary={t("パネルのみ表示される画像を設定します")}
            />
          </ListItemButton>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} fullWidth color="primary">
          {t("適用")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledForm = styled.div`
  display: flex;
  .MuiTextField-root + .MuiTextField-root {
    margin-left: 16px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default memo(FieldDetail);
