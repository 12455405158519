import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getCalclatedMessageCount } from "stores/modules/app.state/selectors";

export const useMessageCount = () => {
  const effectiveState = useSelector(getCalclatedMessageCount);
  const isFocused = useRef(true);
  const count = useRef(0);

  useEffect(() => {
    const handlerFocus = () => {
      isFocused.current = true;
      count.current = 0;
      updateDocumentTitle(0);
    };

    const handlerBlur = () => {
      isFocused.current = false;
    };

    window.addEventListener("focus", handlerFocus);
    window.addEventListener("blur", handlerBlur);

    return () => {
      window.removeEventListener("focus", handlerFocus);
      window.removeEventListener("blur", handlerBlur);
    };
  }, []);

  useEffect(() => {
    if (!isFocused.current) {
      updateDocumentTitle(++count.current);
    }
  }, [effectiveState]);
};

export const updateDocumentTitle = (count: number) => {
  const currentTitle = window.document.title;
  const trimmedTitle = currentTitle.replace(/^\(\d+\) /, "");
  window.document.title = count ? `(${count}) ${trimmedTitle}` : trimmedTitle;
};
