import React, { memo } from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";

import Marker from "./Marker";

type MarkersProps = {
  roomId: string;
};

const Markers: React.FC<MarkersProps> = (props) => {
  const markerIds = useSelector((state) => {
    const roomId = store.getAppState(state, "roomId") ?? "";
    return store.getRoomMarkerIds(state, { roomId });
  });
  return (
    <>
      {markerIds.map((markerId) => (
        <Marker key={markerId} roomId={props.roomId} markerId={markerId} />
      ))}
    </>
  );
};

export default memo(Markers);
