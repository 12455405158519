import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const style = { padding: 0 };

type NoteDetailProps = {
  roomId: string;
};

type FormData = {
  name: string;
  text: string;
};

const NoteDetail = ({ roomId }: NoteDetailProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const open = useSelector((state) => store.getAppState(state, "openRoomNote"));
  const noteId = useSelector(
    (state) => store.getAppState(state, "openRoomNoteId") || ""
  );
  const note = useSelector((state) => store.getRoomNoteById(state, noteId));

  const onSubmit = useCallback(
    (values: FormData) => {
      dispatch(
        store.updateRoomNote(roomId, noteId, {
          name: values.name,
          text: values.text,
        })
      );
    },
    [roomId, noteId, dispatch]
  );
  const { register, handleSubmit, getValues, reset } = useForm<FormData>({
    defaultValues: {
      name: "",
      text: "",
    },
  });

  useEffect(() => {
    reset({ name: note.name, text: note.text });
  }, [note.name, note.text]);

  const onClose = useCallback(() => {
    onSubmit(getValues());
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomNote = false;
      })
    );
  }, [onSubmit, getValues]);

  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteRoomNote(roomId, noteId));
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomNote = false;
        })
      );
    }
  }, [roomId, noteId, t, dispatch]);

  const onSend = useCallback(() => {
    const values = getValues();
    dispatch(
      store.addMessage(
        roomId,
        null,
        {
          name: values.name,
          text: values.text,
          iconUrl: note.iconUrl || null,
        },
        false
      )
    );
  }, [roomId, note.iconUrl, getValues, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent style={style}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="filled"
            label={t("タイトル")}
            fullWidth
            maxRows={24}
            rows={12}
            {...register("name")}
          />
          <TextField
            variant="filled"
            label={t("テキスト")}
            multiline
            fullWidth
            maxRows={24}
            rows={12}
            {...register("text")}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onDelete} color="secondary">
          {t("削除")}
        </Button>
        <Button fullWidth onClick={onSend}>
          {t("送信")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Form = styled.form`
  display: block;
`;

export default memo(NoteDetail);
