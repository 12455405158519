import types from "./types";
import { UpdateUserSetting } from "./records";

type ActionKey = "update";

type ActionsUserSetting = {
  update: {
    type: "entities/userSetting/update";
    uid: string;
    setting: UpdateUserSetting;
  };
};

export type ActionUserSetting<K extends ActionKey = ActionKey> =
  ActionsUserSetting[K];

const actions = {
  update: (uid: string, setting: UpdateUserSetting) => {
    return {
      type: types.update,
      uid,
      setting,
    };
  },
};

export default actions;
