import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";
import { useTranslation } from "react-i18next";
import { Drawer, AppBar, Toolbar, IconButton, Typography } from "@mui/material";

import LastPageIcon from "@mui/icons-material/LastPage";

import ChatBox from "./ChatBox";
import Messages from "./Messages";
import ChatMenu from "./ChatMenu";
import { appStateMutate } from "stores/modules/app.state/operations";
import ChatMembers from "./ChatMembers";

type ChatProps = {
  uid: string;
  roomId: string;
  standalone?: boolean;
};

const Chat = ({ uid, roomId, standalone = false }: ChatProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const open = useSelector(
    (state) => store.getAppState(state, "openRoomDisplays") === "chat"
  );
  const menuWidth = useSelector(store.getMenuWidth);
  const width = standalone ? "100%" : menuWidth;

  const onClose = useCallback(() => {
    if (standalone) {
      window.close();
    } else {
      dispatch(
        appStateMutate((state) => {
          state.openRoomDisplays = null;
        })
      );
    }
  }, [standalone, dispatch]);

  return (
    <ChatDrawer
      open={open}
      onClose={onClose}
      width={width}
      anchor="right"
      variant="persistent"
    >
      <AppBar color="default" position="sticky">
        <Toolbar>
          <IconButton edge="start" onClick={onClose} size="large">
            <LastPageIcon />
          </IconButton>
          <Spacer />
          <Typography variant="subtitle2">{t("ルームチャット")}</Typography>
          <Spacer />
          <ChatMenu roomId={roomId} uid={uid} standalone={standalone} />
        </Toolbar>
        <ChatMembers />
      </AppBar>
      <Messages />
      <ChatBox roomId={roomId} uid={uid} standalone={standalone} />
    </ChatDrawer>
  );
};

const ChatDrawer = styled(Drawer)<{ width: string }>`
  > .MuiPaper-root {
    width: ${({ width }) => width};
    ${theme.breakpoints.down("sm")} {
      max-width: 100%;
      width: 100%;
      border-left: none;
    }
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

export default memo(Chat);
