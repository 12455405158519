import types from "./types";
import { combineReducers } from "redux";
import {
  createByIdReducer,
  createIndexReducer,
  // createGroupByReducer
} from "../firestoreModuleUtils";
import { Note } from "./records";

const byId = createByIdReducer<Note>()(types);
const allIds = createIndexReducer(types);

const notesReducer = combineReducers({
  byId,
  allIds,
});

export default notesReducer;
