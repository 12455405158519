import {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Tabs,
  Tab,
  IconButton,
  TabScrollButton,
  TabScrollButtonProps,
  Typography,
  Popover,
  MenuList,
  MenuItem,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import {
  getUserMediumDirectories,
  getUserMediumDirectory,
} from "stores/modules/entities.user.mediumDirectories/selectors";
import {
  addUserMediumDirectory,
  removeUserMediumDirectory,
  setInitialDirectories,
  updateUserMediumDirectory,
} from "stores/modules/entities.user.mediumDirectories/operations";
import { useAppDispatch } from "stores";
import { useForm } from "react-hook-form";

const LIMIT_TABS = 10;

const MediumListLibraryTabs = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();

  const tab = useSelector((state) => {
    return store.getAppState(state, "userMediumListLibraryTab");
  });
  const draggingUserMediumId = useSelector((state) => {
    return store.getAppState(state, "draggingUserMediumId");
  });
  const tabIds = useSelector(getUserMediumDirectory);
  const tabs = useSelector((state) => getUserMediumDirectories(state));

  const [menuTabId, setMenuTabId] = useState<string | null>(null);
  const anchorEl = useRef<Element | null>(null);

  const [edittingNameTabId, setEdittingNameTabId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (tabs.length === 0) {
      d(setInitialDirectories());
    }
  }, [tabs.length]);

  useEffect(() => {
    if (!tabIds.includes(tab) && tabIds.length > 0) {
      d(store.focusMediumDirectory(tabIds[0]));
    }
  }, [tab, tabIds, d]);

  const onChange = useCallback(
    (event, value) => {
      if (edittingNameTabId === value) {
        return;
      }

      d(store.focusMediumDirectory(value));
    },
    [d, edittingNameTabId]
  );

  const onClickTab = useCallback(
    (value: string) => (event: MouseEvent) => {
      if (edittingNameTabId === value) {
        return;
      }

      if (value === tab) {
        setMenuTabId(value);
        anchorEl.current = event.currentTarget;
      }
    },
    [tab, edittingNameTabId, anchorEl, setMenuTabId]
  );

  const onOver = useCallback(
    (tab) => {
      if (draggingUserMediumId) {
        d(store.changeDirMedia(draggingUserMediumId, tab));
        d(
          store.appStateMutate((state) => {
            state.draggingUserMediumId = null;
          })
        );
      }
    },
    [draggingUserMediumId, d]
  );
  const addDirectory = useCallback(async () => {
    const id = await d(addUserMediumDirectory());
    if (id) {
      d(store.focusMediumDirectory(id));
    }
  }, [d]);

  const disabledAddButton = tabIds.length >= LIMIT_TABS;
  const labelAddButton = disabledAddButton
    ? t("タブ数の上限に達しました")
    : t("タブを追加");

  return (
    <>
      <Tabs
        value={tab}
        onChange={onChange}
        variant="scrollable"
        ScrollButtonComponent={ScrollButton}
      >
        {tabs.map(({ id, name }) => {
          const editting = edittingNameTabId === id;
          const label = editting ? (
            <TabLabelInput
              id={id}
              name={name}
              onEdited={() => setEdittingNameTabId(null)}
            />
          ) : (
            <TabLabel name={name} selected={tab === id} />
          );

          return (
            <MediumListTab
              key={id}
              label={label}
              value={id}
              onMouseUp={() => onOver(id)}
              onTouchEnd={() => onOver(id)}
              onClick={onClickTab(id)}
              disableRipple={editting}
            />
          );
        })}
        <Tooltip title={labelAddButton}>
          <span>
            <IconButton
              onClick={addDirectory}
              disabled={disabledAddButton}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Tabs>
      <MenuPopover
        directoryId={menuTabId}
        anchorEl={anchorEl.current}
        onClose={() => setMenuTabId(null)}
        onRequestEdit={setEdittingNameTabId}
      />
    </>
  );
};

type TabLabelProps = {
  name: string;
  selected: boolean;
};

const TabLabel = ({ name, selected }: TabLabelProps) => {
  const [t] = useTranslation();

  return (
    <InnerTab style={{ width: "100%" }}>
      <Typography variant="inherit" noWrap style={{ width: "100%" }}>
        {name || t("BGM")}
      </Typography>
      {selected && (
        <ArrowDropDownIcon fontSize="small" style={{ marginRight: "-12px" }} />
      )}
    </InnerTab>
  );
};

type TabLabelInputProps = {
  id: string;
  name: string;
  onEdited: () => void;
};

const TabLabelInput = ({ id, name, onEdited }: TabLabelInputProps) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {
      name,
    },
  });

  const onSubmit = ({ name }: { name: string }, event: any) => {
    event.stopPropagation();
    if (focused.current) {
      dispatch(updateUserMediumDirectory(id, { name }));
      ref.current?.blur();
      onEdited();
    }
  };

  const focused = useRef<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
      focused.current = true;
    }, 0);
  }, [ref, focused]);

  return (
    <InnerTab
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onBlur={handleSubmit(onSubmit)}
        autoComplete="off"
        autoCorrect="off"
      >
        <TextField
          inputProps={{ style: { padding: "8px" } }}
          variant="outlined"
          size="small"
          inputRef={ref}
          margin="none"
          {...register("name")}
        />
      </form>
    </InnerTab>
  );
};

const ScrollButton = (props: TabScrollButtonProps) => {
  const draggingUserMediumId = useSelector((state) => {
    return store.getAppState(state, "draggingUserMediumId");
  });

  const onHover = (event: MouseEvent<HTMLButtonElement>) => {
    if (draggingUserMediumId && props.onClick) {
      props.onClick(event);
    }
  };

  return <TabScrollButton onMouseEnter={onHover} {...props} />;
};

type MenuPopoverProps = {
  directoryId: string | null;
  anchorEl: Element | null;
  onClose: () => void;
  onRequestEdit: (directoryId: string) => void;
};

const MenuPopover = ({
  directoryId,
  anchorEl,
  onClose,
  onRequestEdit,
}: MenuPopoverProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const tabLength = useSelector(
    (state) => store.getUserMediumDirectory(state).length
  );
  const isLastOne = tabLength <= 1;
  const index = useSelector((state) =>
    store.getUserMediumDirectory(state).indexOf(directoryId || "")
  );

  const deleteDirectory = useCallback(() => {
    if (
      directoryId &&
      !isLastOne &&
      window.confirm(
        t(
          "このタブを本当に削除しますか？ 削除するとタブに含まれるファイルも全て削除されます。"
        )
      )
    ) {
      onClose();
      dispatch(removeUserMediumDirectory(directoryId));
    }
  }, [directoryId, isLastOne, onClose, dispatch]);

  const requestEditName = useCallback(() => {
    if (directoryId) {
      onClose();
      onRequestEdit(directoryId);
    }
  }, [directoryId, onRequestEdit]);

  const moveLeft = useCallback(() => {
    dispatch(store.swapUserMediumDirectory(index, index - 1));
  }, [index, dispatch]);
  const moveRight = useCallback(() => {
    dispatch(store.swapUserMediumDirectory(index, index + 1));
  }, [index, dispatch]);

  return (
    <Popover
      open={directoryId != null}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <MenuList>
        <MenuItem onClick={requestEditName}>{t("名前を変更")}</MenuItem>
        <MenuItem onClick={deleteDirectory} disabled={isLastOne}>
          {t("削除")}
        </MenuItem>
      </MenuList>
      <Divider />
      <MenuList>
        <MenuItem onClick={moveLeft} disabled={index === 0}>
          {t("左に移動")}
        </MenuItem>
        <MenuItem onClick={moveRight} disabled={index >= tabLength - 1}>
          {t("右に移動")}
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

const MediumListTab = styled(Tab)`
  min-width: 80px;
`;

const InnerTab = styled.span`
  display: flex;
  flex-wrap: none;
  align-items: center;
  text-transform: none;
`;

export default memo(MediumListLibraryTabs);
