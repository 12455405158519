import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "stores/interfaces";
import {
  Box,
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { useTranslation } from "react-i18next";
import { formatRelative } from "date-fns";
import jaLocale from "date-fns/locale/ja";
import toCDNUrl from "modules/toCDNUrl";
import styled from "styled-components";

const formatRelativeLocale = {
  lastWeek: "先週 eeee p",
  yesterday: "昨日 p",
  today: "今日 p",
  tomorrow: "明日 p",
  nextWeek: "翌週のeeee p",
  other: "P",
};
jaLocale.formatRelative = (token, _date, _baseDate, _options) => {
  return formatRelativeLocale[token];
}; // override

const toDateString = (timestamp, now) => {
  return formatRelative(timestamp, now, {
    locale: jaLocale,
  });
};

type RoomListItemProps = {
  roomId: string;
};

const RoomListItem = ({ roomId }: RoomListItemProps) => {
  const d = useDispatch();
  const [t] = useTranslation();
  const room = useSelector((state) => store.getRoomById(state, roomId));
  const onDelete = useCallback(
    (event) => {
      event.preventDefault();
      if (room.publishedRoomPackageId) {
        window.alert(
          t("このルームは公開リンクが有効になっているため削除できません")
        );
        return;
      }

      if (window.confirm(t("本当に削除しますか？"))) {
        d(store.archiveRoom(roomId));
      }
    },
    [roomId, room.publishedRoomPackageId, d, t]
  );
  const isPublishedRoom = room.publishedRoomPackageId != null;
  const hasParentRoomPackage = room.parentRoomPackageId != null;
  const now = Date.now();
  if (!room) return null;

  return (
    <StyledCard>
      <CardActionArea component="a" href={`/rooms/${roomId}`}>
        <StyledCardMedia
          image={toCDNUrl(
            room.foregroundUrl || room.backgroundUrl || "/ccfolia.png"
          )}
        />
      </CardActionArea>
      <CardActions>
        {isPublishedRoom && (
          <Tooltip title={t("ルームデータを公開中")}>
            <LanguageIcon color="primary" fontSize="small" />
          </Tooltip>
        )}
        {hasParentRoomPackage && (
          <Tooltip title={t("公開ルームデータから作成したルーム")}>
            <WidgetsIcon color="disabled" fontSize="small" />
          </Tooltip>
        )}
        <Typography variant="subtitle2" noWrap>
          {room.name}&nbsp;
          <Typography variant="caption" color="textSecondary">
            {toDateString(room.updatedAt, now)}
          </Typography>
        </Typography>
        <Box flex={1} />
        <Tooltip
          title={
            isPublishedRoom
              ? t("このルームは公開リンクが有効になっているため削除できません")
              : ""
          }
        >
          <span>
            <IconButton
              onClick={onDelete}
              size="small"
              disabled={isPublishedRoom}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </CardActions>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  position: relative;
`;

const StyledCardMedia = styled(CardMedia)`
  padding-top: 56.25%;
  background-color: rgba(0, 0, 0, 0.2);
`;

export default memo(RoomListItem);
