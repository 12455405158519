import { FormEventHandler, useCallback, useEffect } from "react";
import { connect, DefaultRootState, ResolveThunks } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  AppBar,
  Toolbar,
  DialogContent,
  Switch,
  Button,
  TextField,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { DeckRecord } from "stores/modules/entities.room.decks";

export type FormData = {
  width: number;
  height: number;
  locked: boolean;
  freezed: boolean;
  plane: boolean;
};

type OriginProps = {
  roomId: string;
  uid: string;
  deckId: string;
  onClose: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
};
type Props = OriginProps &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

const DeckDetail = (props: Props) => {
  const $ = useEnhance(props);
  const [t] = useTranslation();
  if (!props.deck) return null;
  return (
    <>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="subtitle2">{t("デッキ設定")}</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={props.onSubmit}>
          <Group>
            <TextField
              fullWidth
              variant="standard"
              label={t("横幅")}
              margin="dense"
              type="number"
              {...$.register("width", { valueAsNumber: true })}
            />
            <TextField
              fullWidth
              variant="standard"
              label={t("縦幅")}
              margin="dense"
              type="number"
              {...$.register("height", { valueAsNumber: true })}
            />
          </Group>
          <List>
            <ListItem dense>
              <ListItemText
                primary={t("配置固定")}
                secondary={t("現在の位置とサイズでオブジェクトを固定")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="locked"
                  control={$.control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary={t("サイズ固定")}
                secondary={t("現在のサイズのみを固定します")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="freezed"
                  control={$.control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Button onClick={$.onCoverImageSelect} variant="outlined" fullWidth>
            {t("背面画像選択")}
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={$.onDelete} fullWidth color="secondary">
          {t("削除")}
        </Button>
        <Button onClick={props.onClose} fullWidth color="primary">
          {t("保存")}
        </Button>
      </DialogActions>
    </>
  );
};

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 16px;
`;

const useEnhance = (props: Props) => {
  const { control, register, reset } = useFormContext<FormData>();
  const [t] = useTranslation();

  useEffect(() => {
    reset({
      width: props.deck.width,
      height: props.deck.height,
      locked: props.deck.locked,
      freezed: props.deck.freezed,
    });
  }, [
    reset,
    props.deck.width,
    props.deck.height,
    props.deck.locked,
    props.deck.freezed,
  ]);

  const onCoverImageSelect = useCallback(() => {
    props.appStateMutate((state) => {
      state.openRoomImageSelect = true;
      state.openRoomImageSelectDir = "cover";
      state.openRoomImageSelectTarget = "deck/updateCoverImage";
    });
  }, []);

  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      props.deleteRoomDeck(props.deckId);
      props.appStateMutate((state) => {
        state.openRoomDeckDetail = false;
      });
    }
  }, [props.deckId]);

  return {
    control,
    register,
    reset,
    onDelete,
    onCoverImageSelect,
  };
};

const blankItem = DeckRecord({});

const mapStateToProps = (state: DefaultRootState, props: OriginProps) => {
  return {
    deck: store.getRoomDeckById(state, props.deckId) || blankItem,
  };
};

const mapDispatchToProps = {
  ...store,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeckDetail);
