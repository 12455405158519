import React, { useCallback, useState, useRef, useEffect } from "react";
import { connect, DefaultRootState, ResolveThunks } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  ButtonBase,
  Slider,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { EffectRecord } from "stores/modules/entities.room.effects";

const style = { background: "rgba(0, 0, 0, 0.2)" };

type OriginProps = {
  roomId: string;
};
type Props = OriginProps &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

const EffectDetail = (props: Props) => {
  const $ = useEnhance(props);
  const [t] = useTranslation();
  return (
    <Dialog open={props.open} onClose={$.onClose} fullWidth maxWidth="xs">
      <DialogContent style={style}>
        <Form onSubmit={$.onSubmit}>
          <TextField
            variant="filled"
            label={t("タイトル")}
            fullWidth
            margin="dense"
            defaultValue={props.effect.name}
            maxRows={24}
            rows={12}
            {...$.register("name")}
          />
          <Typography variant="caption" display="block">
            {t(
              "カットインはチャットの末尾に反応して起動します。カットインタイトルを「成功」とした場合、発言の末尾に「成功」という文字が入った時にカットインが起動して、サウンドや画像が再生されます。"
            )}
          </Typography>
        </Form>
        <ImageButton onClick={$.onSelectImage}>
          {props.effect.imageUrl ? (
            <img src={toCDNUrl(props.effect.imageUrl)} />
          ) : (
            <Blank>
              <Typography>{t("NOIMAGE")}</Typography>
            </Blank>
          )}
        </ImageButton>
        <Actions>
          <Button onClick={$.onOpen} fullWidth>
            {props.effect.soundName || t("音源を選択")}
          </Button>
          <Volume>
            <Typography variant="caption">{t("Volume")}</Typography>
            <Slider
              value={$.volume}
              color="primary"
              onChange={$.onChangeVolume}
              min={0.0}
              max={1}
              step={0.01}
            />
          </Volume>
        </Actions>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={$.onDelete} color="secondary">
          {t("削除")}
        </Button>
        <Button fullWidth onClick={$.onPlay} color="primary">
          {t("再生")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Form = styled.form`
  display: block;
`;

const ImageButton = styled(ButtonBase)`
  margin: 16px auto;
  width: 50%;
  position: relative;
  display: block;
  background: rgba(0, 0, 0, 0.12);
  border: 2px solid #fff;
  img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
`;

const Blank = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const Volume = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

const Actions = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

type FormData = {
  name: string;
};

const useEnhance = (props: Props) => {
  const [volume, setVolume] = useState(props.effect?.soundVolume || 0.15);
  useEffect(() => {
    if (props.effect) {
      setVolume(props.effect.soundVolume);
    }
  }, [props.effectId]);
  const timer = useRef<number | null>(null);
  const onChangeVolume = useCallback(
    (_, value) => {
      setVolume(value);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => {
        if (props.effectId) {
          props.updateRoomEffect(props.roomId, props.effectId, {
            soundVolume: value,
          });
        }
      }, 300);
    },
    [props.roomId, props.effectId, setVolume, timer]
  );
  const onSubmit = useCallback(
    (values: FormData) => {
      if (props.effectId) {
        props.updateRoomEffect(props.roomId, props.effectId, {
          name: values.name,
        });
      }
    },
    [props.roomId, props.effectId]
  );
  const { register, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      name: "",
    },
  });
  useEffect(() => {
    reset({
      name: props.effect.name,
    });
  }, [props.effect.name]);

  const onClose = useCallback(() => {
    if (props.effectId) {
      const effectName = getValues();
      props.updateRoomEffectName(props.roomId, props.effectId, {
        name: effectName.name,
      });
    }
    props.appStateMutate((state) => {
      state.openRoomEffect = false;
    });
  }, [onSubmit, getValues]);
  const onDelete = useCallback(() => {
    if (props.effectId && window.confirm("本当に削除しますか？")) {
      props.deleteRoomEffect(props.roomId, props.effectId);
      props.appStateMutate((state) => {
        state.openRoomEffect = false;
      });
    }
  }, [props.roomId, props.effectId]);
  const onOpen = useCallback(() => {
    props.appStateMutate((state) => {
      state.openRoomMedia = true;
      state.openRoomMediaDir = "effect";
      state.enabledUserMediaLoader = true;
    });
  }, []);
  const onSelectImage = useCallback(() => {
    props.appStateMutate((state) => {
      state.openRoomImageSelect = true;
      state.openRoomImageSelectDir = "effect";
      state.openRoomImageSelectTarget = "effect/set";
    });
  }, []);
  const onPlay = useCallback(() => {
    if (props.effectId) {
      props.updateRoomEffect(props.roomId, props.effectId, {
        playTime: Date.now(),
      });
    }
  }, [props.roomId, props.effectId]);

  return {
    register,
    onClose,
    onSubmit: handleSubmit(onSubmit),
    onOpen,
    onPlay,
    onDelete,
    onSelectImage,
    volume,
    onChangeVolume,
  };
};

const blankEffect = EffectRecord({});

const mapStateToProps = (state: DefaultRootState, props: OriginProps) => {
  const open = store.getAppState(state, "openRoomEffect");
  const effectId = store.getAppState(state, "openRoomEffectId");
  return {
    effectId,
    effect:
      effectId != null ? store.getRoomEffectById(state, effectId) : blankEffect,
    open,
  };
};

const mapDispatchToProps = {
  ...store,
};

export default connect(mapStateToProps, mapDispatchToProps)(EffectDetail);
