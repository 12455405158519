import actions from "./actions";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import {
  UpdateUserMediumDirectory,
  UserMediumDirectory,
  UserMediumDirectoryRecord,
} from "./records";
import { DefaultThunk } from "stores";
import { getUid } from "../app.user/selectors";
import {
  getUserMediumDirectory,
  getUserMediumDirectoryById,
} from "./selectors";
import { deleteUserMediumByDirectory } from "../entities.user.media/operations";
import { focusMediumDirectory } from "../app.state/operations";
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

const mediumConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(character: UpdateUserMediumDirectory) {
    return character;
  },
  fromFirestore(snapshot, options): UserMediumDirectory {
    const data = snapshot.data(options)!;
    return UserMediumDirectoryRecord(data);
  },
};

const directoryRef = (uid: string) =>
  collection(db, "users", uid, "mediumDirectories").withConverter(
    mediumConverter
  ) as CollectionReference<UserMediumDirectory>;

export const subscribeUserMediumDirectory = createSubscribeCollection(
  actions,
  (uid: string) => query(directoryRef(uid), orderBy("order"))
);

export const addUserMediumDirectory =
  (): DefaultThunk<Promise<string | undefined>> => async (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (!uid) return;

    const ids = getUserMediumDirectory(state);
    const lastDirectory = getUserMediumDirectoryById(
      state,
      ids[ids.length - 1]
    );
    if (lastDirectory == null) return;

    const now = Date.now();
    const directory = await addDoc(directoryRef(uid), {
      name: "",
      order: lastDirectory.order + 1,
      createdAt: now,
      updatedAt: now,
    });

    return directory.id;
  };

export const updateUserMediumDirectory =
  (directoryId: string, update: UpdateUserMediumDirectory): DefaultThunk =>
  async (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (!uid) return;

    updateDoc(doc(directoryRef(uid), directoryId), update);
  };

export const swapUserMediumDirectory =
  (indexSrc: number, indexDist: number): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (!uid) return;

    const ids = getUserMediumDirectory(state);
    const idSrc = ids[indexSrc];
    const idDist = ids[indexDist];
    const src = getUserMediumDirectoryById(state, idSrc);
    const dist = getUserMediumDirectoryById(state, idDist);
    if (src == null || dist == null) {
      return;
    }

    const now = Date.now();
    const batch = writeBatch(db);
    batch.update(doc(directoryRef(uid), idSrc), {
      order: dist.order,
      updatedAt: now,
    });
    batch.update(doc(directoryRef(uid), idDist), {
      order: src.order,
      updatedAt: now,
    });
    batch.commit();

    dispatch(actions.reorder(indexSrc, indexDist));
  };

export const removeUserMediumDirectory =
  (directoryId: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (!uid) return;

    const ids = getUserMediumDirectory(state);
    const index = ids.indexOf(directoryId);
    if (index < 0) {
      return;
    }

    const nextId = index === ids.length - 1 ? ids[index - 1] : ids[index + 1];
    dispatch(focusMediumDirectory(nextId));

    await dispatch(deleteUserMediumByDirectory(uid, directoryId));
    deleteDoc(doc(directoryRef(uid), directoryId));
  };

const INITIAL_DIRECTORIES: Record<string, UpdateUserMediumDirectory> = {
  bgm01: {
    name: "BGM01",
    order: 1,
  },
  bgm02: {
    name: "BGM02",
    order: 2,
  },
  bgm03: {
    name: "BGM03",
    order: 3,
  },
  bgm04: {
    name: "BGM04",
    order: 4,
  },
  bgm05: {
    name: "BGM05",
    order: 5,
  },
  se01: {
    name: "SE01",
    order: 6,
  },
  etc: {
    name: "ETC",
    order: 7,
  },
};

export const setInitialDirectories = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const uid = getUid(state);
  if (!uid) return;

  const batch = writeBatch(db);
  for (const id in INITIAL_DIRECTORIES) {
    batch.set(
      doc(directoryRef(uid), id),
      UserMediumDirectoryRecord(INITIAL_DIRECTORIES[id])
    );
  }
  batch.commit();
};
