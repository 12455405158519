import { DefaultRootState } from "react-redux";

export const getMediumById = (state: DefaultRootState, mediumId: string) => {
  return state.entities.userMedia.byId[mediumId];
};
export const getUserMediumIds = (state: DefaultRootState, dir: string) => {
  return state.entities.userMedia.groupByDir[dir] || [];
};
export const getCurrentUserMediumIds = (state: DefaultRootState): string[] => {
  const dir = state.app.state.userMediumListLibraryTab;
  return state.entities.userMedia.groupByDir[dir] || [];
};
export const getUserMedia = (state: DefaultRootState) => {
  return state.entities.userMedia.byId;
};
