import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  createFreeProductPurchase,
  createProductCheckoutUrl,
  getGamesPurchase,
} from "api";
import { memo, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import useSWR, { useSWRConfig } from "swr";
import sendEvent from "modules/sendEvent";
import {
  RefererData,
  setRefererBuyClick,
  setRefererInternal,
} from "modules/referer";

type BuyButtonProps = {
  productId: string;
  roomPackageId: string | undefined;
  parentProductId: string | undefined;
  hasAvailableExtention: boolean;
  externalUrl: string | undefined;
  isFree: boolean;
  referer: RefererData;
  onRequestCreateRoom: () => void;
  onRequestLogin: () => void;
  onOpenPurchased: () => void;
  notAvailable: boolean;
};

const BuyButton = ({
  productId,
  parentProductId,
  externalUrl,
  isFree,
  referer,
  onRequestLogin,
  onOpenPurchased,
  notAvailable,
}: BuyButtonProps) => {
  const [t] = useTranslation();
  const { mutate } = useSWRConfig();
  const uid = useSelector(getAuthedUid);

  const [processing, setProcessing] = useState(false);

  const { data: isPurcased, isLoading } = useSWR(
    uid && productId ? ["getGamesPurchase", productId, uid] : null,
    async ([_key, productId, _uid]) => {
      const { purchased } = await getGamesPurchase(productId);
      return purchased;
    }
  );

  const { data: isPurcasedParent, isLoading: isLoadingParent } = useSWR(
    uid && parentProductId ? ["getGamesPurchase", parentProductId, uid] : null,
    async ([_key, parentProductId, _uid]) => {
      const { purchased } = await getGamesPurchase(parentProductId);
      return purchased;
    }
  );

  const onClickBuy = useCallback(() => {
    if (!productId) {
      return;
    }

    sendClickBuyEvent({ productId, afterAction: "checkout" });
    setProcessing(true);
    createProductCheckoutUrl(productId)
      .then(({ url }) => {
        setRefererBuyClick({ productId, data: referer });
        window.location.href = url;
      })
      .catch(() => {
        setProcessing(false);
        window.alert(t("決済ページの取得に失敗しました"));
      });
  }, [productId, referer, setProcessing, t]);

  const onClickGet = useCallback(() => {
    if (!productId) {
      return;
    }

    sendClickBuyEvent({ productId, afterAction: "getfree" });
    setProcessing(true);
    createFreeProductPurchase(productId)
      .then(({ purchased }) => {
        setProcessing(false);
        if (purchased) {
          mutate(["getGamesPurchase", productId, uid], true);
          onOpenPurchased();
        }
      })
      .catch(() => {
        setProcessing(false);
        window.alert(t("処理に失敗しました"));
      });
  }, [productId, uid, setProcessing, onOpenPurchased, mutate, t]);

  const onClickLogin = useCallback(() => {
    sendClickBuyEvent({ productId, afterAction: "loginform" });
    onRequestLogin();
  }, [productId, onRequestLogin]);

  const buttonLabel = useMemo(() => {
    if (notAvailable) {
      return t("販売終了");
    } else if (isFree) {
      return t("無料で入手する");
    } else {
      return t("購入する");
    }
  }, [notAvailable, isFree, t]);

  if (processing) {
    return (
      <Button variant="outlined" size="large" fullWidth disabled>
        <CircularProgress size={18} sx={{ marginRight: "8px" }} />
        {isFree ? t("無料で入手する") : t("購入する")}
      </Button>
    );
  }

  if (externalUrl) {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        endIcon={<OpenInNewIcon />}
        href={externalUrl}
        target="_blank"
        onClick={() => {
          sendClickBuyEvent({ productId, afterAction: "external" });
        }}
      >
        {t("購入する")}
      </Button>
    );
  }

  if (
    (!uid && parentProductId) ||
    (parentProductId && isPurcasedParent === false)
  ) {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          disabled
        >
          {buttonLabel}
        </Button>
        <AlertBox parentProductId={parentProductId} />
      </>
    );
  }

  if (!uid) {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        onClick={onClickLogin}
        disabled={notAvailable}
      >
        {buttonLabel}
      </Button>
    );
  }

  if (isLoading && isLoadingParent) {
    return (
      <Button variant="outlined" size="large" fullWidth disabled>
        <CircularProgress size={18} sx={{ marginRight: "8px" }} />
        {t("購入情報を取得中")}
      </Button>
    );
  }

  if (isPurcased) {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        component={RouterLink}
        to={`/library/${parentProductId || productId}`}
      >
        {t("ライブラリに移動")}
      </Button>
    );
  }

  if (isFree) {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        onClick={onClickGet}
        disabled={notAvailable}
      >
        {buttonLabel}
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      fullWidth
      onClick={onClickBuy}
      disabled={notAvailable}
    >
      {buttonLabel}
    </Button>
  );
};

type AlertBoxProps = {
  parentProductId: string;
};

const AlertBox = ({ parentProductId }: AlertBoxProps) => {
  const [t] = useTranslation();

  const onClickParentProductButton = useCallback(() => {
    setRefererInternal({
      productId: parentProductId,
      referer: "product-page-parent-product-button",
    });
  }, [parentProductId]);

  const onClickParentProductTextLink = useCallback(() => {
    setRefererInternal({
      productId: parentProductId,
      referer: "product-page-parent-product-text-link",
    });
  }, [parentProductId]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        fullWidth
        sx={{ marginTop: "16px" }}
        component={RouterLink}
        to={`/games/${parentProductId}`}
        onClick={onClickParentProductButton}
      >
        {t("ゲーム本体はこちら")}
      </Button>
      <Box display="flex" alignItems="center" marginTop="8px">
        <ErrorIcon color="secondary" />
        <Typography
          paragraph
          variant="body2"
          color="textSecondary"
          margin="0"
          marginLeft="8px"
        >
          <Trans>
            ゲーム本体が未購入です。追加・拡張コンテンツで遊ぶにはゲーム本体が必要です。
            <Link
              component={RouterLink}
              underline="hover"
              to={`/games/${parentProductId}`}
              color="secondary"
              onClick={onClickParentProductTextLink}
            >
              ゲーム本体
            </Link>
            を購入してください。
          </Trans>
        </Typography>
      </Box>
    </>
  );
};

type ClickBuyEventParams = {
  productId: string;
  afterAction: "checkout" | "external" | "loginform" | "getfree";
};

const sendClickBuyEvent = ({ productId, afterAction }: ClickBuyEventParams) => {
  sendEvent("clickBuy", { product_id: productId, after_action: afterAction });
};

export default memo(BuyButton);
