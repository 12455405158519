import actions from "./actions";
import { db } from "initializer";
import sendEvent from "modules/sendEvent";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import { uploadFile, deleteFile } from "modules/firebase-utils/uploader";
import { getMediumById, getUserMediumIds } from "./selectors";
import { UserMediumRecord } from "./records";
import { UpdateUserMedium, UserMedium } from "./";
import { DefaultThunk } from "stores";
import { DropResult } from "react-beautiful-dnd";
import { getUid } from "../app.user/selectors";
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

const mediumConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(character: UpdateUserMedium) {
    return character;
  },
  fromFirestore(snapshot, options): UserMedium {
    const data = snapshot.data(options)!;
    return UserMediumRecord(data);
  },
};

const mediumRef = (uid: string) =>
  collection(db, "users", uid, "media").withConverter(
    mediumConverter
  ) as CollectionReference<UserMedium>;

export const subscribeUserMedia = createSubscribeCollection<
  UserMedium,
  "userMedia",
  { uid: string; dirs: string[] }
>(actions, ({ uid, dirs }) =>
  query(mediumRef(uid), where("dir", "in", dirs), orderBy("order"))
);

const LIMIT_USER_MEDIUM_SIZE = 10 * 1024 * 1024; // 10 MiB

export const addUserMedium =
  (uid: string, roomId: string, dir: string, file: File): DefaultThunk =>
  (dispatch, getState) => {
    sendEvent("addUserMedium");

    if (file.size >= LIMIT_USER_MEDIUM_SIZE) {
      window.alert(
        "ファイルサイズが10MBを超えているため、アップロードに失敗しました。"
      );
      return;
    }

    const ids = getUserMediumIds(getState(), dir);
    dispatch(orderMedia(uid, dir));
    return uploadFile(file, {
      collectionRef: mediumRef(uid),
      data: UserMediumRecord({
        owner: uid,
        roomId,
        dir,
        volume: 0.5,
        loop: true,
        order: ids.length,
        external: false,
      }),
    });
  };

export const addUserMediumFromUrl =
  (uid: string, roomId: string, dir: string, url: string): DefaultThunk =>
  (dispatch, getState) => {
    sendEvent("addUserMedium");
    const ids = getUserMediumIds(getState(), dir);
    dispatch(orderMedia(uid, dir));
    return addDoc(
      mediumRef(uid),
      UserMediumRecord({
        owner: uid,
        roomId,
        dir,
        volume: 0.5,
        loop: true,
        order: ids.length,
        url,
        name: "新規メディア",
        uploaded: false,
        archived: false,
        contentType: null,
        size: 0,
        updatedAt: Date.now(),
        createdAt: Date.now(),
        external: true,
      })
    );
  };

export const updateUserMedium =
  (uid: string, mediumId: string, values: UpdateUserMedium) => () => {
    updateDoc(doc(mediumRef(uid), mediumId), {
      ...values,
    });
  };

export const deleteUserMedium =
  (uid: string, mediumId: string, external?: boolean) => () => {
    sendEvent("deleteUserMedium");
    if (external) {
      return deleteDoc(doc(mediumRef(uid), mediumId));
    }
    return deleteFile(doc(mediumRef(uid), mediumId));
  };

export const deleteUserMediumByDirectory =
  (uid: string, directoryId: string): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const ids = getUserMediumIds(state, directoryId);
    if (ids.length === 0) {
      return;
    }

    const promises: Promise<void>[] = [];
    const batch = writeBatch(db);
    for (const id of ids) {
      const medium = getMediumById(state, id);
      if (medium.external) {
        batch.delete(doc(mediumRef(uid), id));
      } else {
        promises.push(deleteFile(doc(mediumRef(uid), id)));
      }
    }
    await Promise.all([...promises, batch.commit()]);
  };

export const reorderMedia =
  (_uid: string, dir: string, order: DropResult): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (uid && order.destination) {
      dispatch(
        actions.groupReorder(
          "dir",
          dir,
          order.source.index,
          order.destination.index
        )
      );
      dispatch(orderMedia(uid, dir));
    }
  };

export const orderMedia =
  (uid: string, dir: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const mediumIds = getUserMediumIds(state, dir);
    const batch = writeBatch(db);
    mediumIds.forEach((mediumId, index) => {
      const medium = getMediumById(state, mediumId);
      if (medium.order !== index) {
        batch.set(
          doc(mediumRef(uid), mediumId),
          { order: index },
          { merge: true }
        );
      }
    });
    batch.commit();
  };

export const changeDirMedia =
  (mediumId: string, dir: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const mediumIds = getUserMediumIds(state, dir);
    if (!uid || !mediumId) return;
    dispatch(actions.groupRemove(mediumId)); // remove once local state
    let idLength = mediumIds.length;
    if (idLength === 0) {
      const snapshot = await getDocs(
        query(mediumRef(uid), where("dir", "==", dir))
      );
      idLength = snapshot.size;
    }
    setDoc(
      doc(mediumRef(uid), mediumId),
      { order: idLength, dir },
      { merge: true }
    );
  };
