import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAppState } from "stores/modules/app.state/selectors";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getRoomSavedataByOrder } from "stores/modules/entities.room.savedatas/selectors";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { saveRoom } from "stores/modules/entities.room.savedatas/operations";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { useAppDispatch } from "stores";

const SaveConfirm = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { name: "" },
  });

  const slot = useSelector((state) => getAppState(state, "openSaveConfirm"));
  const disabledNameEdit = useSelector((state) =>
    getAppState(state, "saveConformDisabledNameEdit")
  );
  const name = useSelector((state) => {
    const slot = getAppState(state, "openSaveConfirm");
    if (slot == null) {
      return "";
    }

    const savedata = getRoomSavedataByOrder(state, slot);
    return savedata?.name || "";
  });
  const thumbnail = useSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.foregroundUrl || undefined;
  });
  const open = slot != null;

  useEffect(() => {
    reset({ name });
  }, [reset, name, slot]);

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openSaveConfirm = null;
      })
    );
  }, [dispatch]);

  const onSubmit = useCallback(
    async (data: { name: string }) => {
      if (slot != null) {
        onClose();
        dispatch(
          appStateMutate((state) => (state.slotNumberProcessingSave = slot))
        );
        await dispatch(saveRoom(slot, data.name)).finally(() => {
          dispatch(
            appStateMutate((state) => (state.slotNumberProcessingSave = null))
          );
        });
        if (disabledNameEdit) {
          dispatch(
            appStateMutate(
              (state) => (state.errorSnackbarMessage = `セーブしました。`)
            )
          );
        }
      }
    },
    [slot, disabledNameEdit, dispatch, onClose]
  );

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Typography variant="subtitle1">
            {t("このスロットにセーブデータを保存しますか？")}
          </Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          <StyledAvatar src={thumbnail} />
          {disabledNameEdit ? (
            <Typography display="block">{name}</Typography>
          ) : (
            <TextField
              autoFocus
              fullWidth
              label={t("セーブデータ名")}
              variant="filled"
              disabled={disabledNameEdit}
              {...register("name")}
            />
          )}
        </DialogBody>
        <DialogActions>
          <Button fullWidth color="secondary" onClick={onClose}>
            {t("キャンセル")}
          </Button>
          <Button fullWidth color="primary" type="submit">
            {t("保存")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DialogBody = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  padding-bottom: 0;
`;

const StyledAvatar = styled(Avatar)`
  .MuiAvatar-fallback {
    visibility: hidden;
  }
`;

export default memo(SaveConfirm);
