import React, { memo, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";
import { Popover } from "@mui/material";
import DiceDetailContent from "./DiceDetailContent";

const DiceDetail = () => {
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomDiceDetail")
  );
  const x = useSelector((state) => store.getAppState(state, "roomPointerX"));
  const y = useSelector((state) => store.getAppState(state, "roomPointerY"));
  const diceId = useSelector((state) =>
    store.getAppState(state, "openRoomDiceDetailId")
  );

  const ref = useRef<{ onSubmit: () => void }>(null);

  const onClose = useCallback(() => {
    ref.current?.onSubmit();
  }, [ref]);

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: y, left: x }}
    >
      <DiceDetailContent ref={ref} diceId={diceId || ""} />
    </Popover>
  );
};

export default memo(DiceDetail);
