import types from "./types";
import { combineReducers } from "redux";
import { createByIdReducer, createIndexReducer } from "../firestoreModuleUtils";
import { Deck } from "./records";

const byId = createByIdReducer<Deck>()(types);
const allIds = createIndexReducer(types);

const tmpReducer = combineReducers({
  byId,
  allIds,
});

export default tmpReducer;
