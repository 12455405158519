import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

type UseObserveSigninErrorProps = {
  openLoginDialog: () => void;
};

export const useObserveSigninError = ({
  openLoginDialog,
}: UseObserveSigninErrorProps) => {
  const location = useLocation();
  const history = useHistory();
  const [t] = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authCode = params.get("auth_code");
    if (authCode) {
      history.replace(location.pathname);

      if (authCode == "notfound") {
        window.alert(
          t(
            "このSNSアカウントに紐づいているCCFOLIAのアカウントがありませんでした。アカウントをお持ちでない方は「新規アカウント作成」をご利用ください。"
          )
        );
      } else {
        window.alert(t("ログインに失敗しました。"));
      }

      openLoginDialog();
    }
  }, [location.search, location.pathname, history, openLoginDialog, t]);
};
