import { Badge, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import D4Icon from "./icons/D4Icon";
import D6Icon from "./icons/D6Icon";
import { useDispatch, useSelector } from "react-redux";
import { getAppChatState } from "stores/modules/app.chat/selectors";
import { produce } from "immer";
import { appChatStateMutate } from "stores/modules/app.chat/operations";
import D8Icon from "./icons/D8Icon";
import D10Icon from "./icons/D10Icon";
import D12Icon from "./icons/D12Icon";
import D20Icon from "./icons/D20Icon";
import D100Icon from "./icons/D100Icon";
import styled from "styled-components";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

export const REGEXP_DICE_NOTICE =
  /^(\d*)D(4|6|8|10|12|20|100)(\+(\d*)D(4|6|8|10|12|20|100))*($|\s)/i;

const DiceSelect = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const inputText = useSelector((state) => getAppChatState(state, "inputText"));
  const isRoleAudience = useSelector(getIsRoleAudience);

  const counts = useMemo(() => {
    const counted: Record<number, number> = {};
    const m = inputText.match(REGEXP_DICE_NOTICE);
    if (m) {
      const notations = m[0].trim().toUpperCase().split("+");
      for (const notation of notations) {
        const [ns, xs] = notation.split("D");
        const n = parseInt(ns, 10) || 1;
        const x = parseInt(xs, 10);

        counted[x] ||= 0;
        counted[x] += n;
      }
    }
    return counted;
  }, [inputText]);

  const handleClick = (side: number) => () => {
    const nextCounts = produce(counts, (draft) => {
      draft[side] ||= 0;
      draft[side]++;
    });

    const notations = [4, 6, 8, 10, 12, 20, 100]
      .map((v) => {
        if (nextCounts[v]) {
          return `${nextCounts[v]}D${v}`;
        } else {
          return null;
        }
      })
      .filter((v) => v);

    const diceText = notations.join("+");
    const m = inputText.match(REGEXP_DICE_NOTICE);
    let newText = inputText;
    if (!m) {
      newText = diceText + " " + inputText;
    } else if (m[0].length === inputText.length) {
      newText = diceText;
    } else {
      newText = diceText + " " + inputText.slice(m[0].length);
    }

    dispatch(
      appChatStateMutate((state) => {
        state.inputText = newText;
      })
    );
  };

  const onReset = useCallback(() => {
    dispatch(
      appChatStateMutate((state) => {
        state.inputText = state.inputText.replace(REGEXP_DICE_NOTICE, "");
      })
    );
  }, [dispatch]);

  return (
    <>
      <Tooltip title="D4" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(4)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[4]} color="secondary">
            <D4Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="D6" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(6)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[6]} color="secondary">
            <D6Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="D8" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(8)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[8]} color="secondary">
            <D8Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="D10" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(10)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[10]} color="secondary">
            <D10Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="D12" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(12)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[12]} color="secondary">
            <D12Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="D20" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(20)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[20]} color="secondary">
            <D20Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="D100" placement="top">
        <StyledIconButton
          size="small"
          onClick={handleClick(100)}
          disabled={isRoleAudience}
        >
          <Badge badgeContent={counts[100]} color="secondary">
            <D100Icon />
          </Badge>
        </StyledIconButton>
      </Tooltip>
      {Object.keys(counts).length > 0 && (
        <Tooltip title={t("ダイス選択をリセット")} placement="top">
          <StyledIconButton size="small" onClick={onReset}>
            <CloseIcon />
          </StyledIconButton>
        </Tooltip>
      )}
    </>
  );
};

const StyledIconButton = styled(IconButton)`
  padding: 3px;
`;

export default memo(DiceSelect);
