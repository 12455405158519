import actions from "./actions";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import { getRoomNoteIds, getRoomNoteById } from "./selectors";
import { Note, NoteRecord, UpdateNote } from "./";
import { DefaultThunk } from "stores";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { DropResult } from "react-beautiful-dnd";

export const notesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "notes");

export const subscribeRoomNotes = createSubscribeCollection(
  actions,
  (roomId: string) => query(notesRef(roomId), orderBy("order"))
);

export const addRoomNote =
  (roomId: string, uid: string): DefaultThunk =>
  (dispatch, getState) => {
    const ids = getRoomNoteIds(getState());
    dispatch(orderNotes(roomId));
    const note: Note = {
      name: "",
      text: "",
      iconUrl: "",
      owner: uid,
      order: ids.length,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    return addDoc(notesRef(roomId), note);
  };

export const updateRoomNote =
  (roomId: string, noteId: string, item: UpdateNote) => () => {
    return setDoc(
      doc(notesRef(roomId), noteId),
      {
        ...item,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const deleteRoomNote = (roomId: string, noteId: string) => () => {
  return deleteDoc(doc(notesRef(roomId), noteId));
};

export const reorderNotes =
  (roomId: string, order: DropResult): DefaultThunk =>
  (dispatch) => {
    if (order.destination) {
      dispatch(actions.reorder(order.source.index, order.destination.index));
      dispatch(orderNotes(roomId));
    }
  };

const orderNotes =
  (roomId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const noteIds = getRoomNoteIds(state);
    noteIds.forEach((noteId, index) => {
      const note = getRoomNoteById(state, noteId);
      if (note.order !== index) {
        setDoc(
          doc(notesRef(roomId), noteId),
          { order: index },
          { merge: true }
        );
      }
    });
  };

export const importRoomNotes =
  (notesDate: { [noteId: string]: UpdateNote }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const noteIds = Object.keys(notesDate);
    const batch = writeBatch(db);
    const ref = notesRef(roomId);
    noteIds.forEach((noteId) => {
      batch.set(doc(ref, noteId), NoteRecord(notesDate[noteId]));
    });
    return batch.commit();
  };
