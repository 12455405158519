import types from "./types";
import { combineReducers } from "redux";
import { createByIdReducer, createIndexReducer } from "../firestoreModuleUtils";
import { Item } from "./records";

const byId = createByIdReducer<Item>()(types);
const allIds = createIndexReducer(types);

const itemReducer = combineReducers({
  byId,
  allIds,
});
export default itemReducer;
