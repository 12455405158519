import actions from "./actions";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils";
import {
  getRoomSceneById,
  getRoomSceneIds,
  getRoomSceneOrderedIds,
} from "./selectors";
import { batchActions } from "redux-batched-actions";
import { getRoomById } from "../entities.rooms/selectors";
import { updateRoom } from "../entities.rooms/operations";
import { Marker, UpdateMarker } from "../entities.rooms/index";
import { UpdateScene } from "./";
import { Scene, SceneRecord } from "./records";
import { DefaultThunk } from "stores";
import { Action } from "redux";
import { addMessage } from "../entities.room.messages/operations";
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  DocumentData,
  FirestoreDataConverter,
  orderBy,
  query,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

const sceneConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(scene: UpdateScene): DocumentData {
    return scene;
  },
  fromFirestore(snapshot, options): Scene {
    const data = snapshot.data(options)!;
    return SceneRecord(data);
  },
};

export const scenesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "scenes").withConverter(sceneConverter);

export const subscribeRoomScenes = createSubscribeCollection(
  actions,
  (roomId: string) => query(scenesRef(roomId), orderBy("order"))
);

// todo: delete
export const addScene =
  (roomId: string, sceneId: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const room = getRoomById(state, roomId);
    const selectedScene = getRoomSceneById(state, sceneId);
    const items = getRoomSceneIds(state);
    await addDoc(scenesRef(roomId), {
      ...SceneRecord({
        backgroundUrl: room.backgroundUrl,
        foregroundUrl: room.foregroundUrl,
        fieldObjectFit: room.fieldObjectFit,
        fieldWidth: room.fieldWidth,
        fieldHeight: room.fieldHeight,
        mediaName: room.mediaName,
        mediaUrl: room.mediaUrl,
        mediaRepeat: room.mediaRepeat ?? true,
        mediaType: room.mediaType,
        mediaVolume: room.mediaVolume,
        soundName: room.soundName,
        soundUrl: room.soundUrl,
        soundRepeat: room.soundRepeat || false,
        soundVolume: room.soundVolume,
        markers: room.markers,
        order:
          selectedScene?.order === null ? items.length : selectedScene?.order,
      }),
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
    return dispatch(fixOrderScenes(roomId));
  };

export const addRoomScene =
  (roomId: string): DefaultThunk =>
  async (dispatch, getState) => {
    const sceneIds = getRoomSceneIds(getState());
    dispatch(orderScenes(roomId));
    const scene = await addDoc(scenesRef(roomId), {
      ...SceneRecord({}),
      order: sceneIds.length,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
    await dispatch(
      updateRoom(roomId, {
        sceneId: scene.id,
      })
    );
    return scene;
  };

export const updateRoomScene =
  (roomId: string, sceneId: string, scene: UpdateScene) => () => {
    return setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        ...scene,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const reorderScenes =
  (roomId: string, order: any): DefaultThunk =>
  (dispatch, getState) => {
    if (order.destination) {
      const sceneIds = getRoomSceneOrderedIds(getState());
      dispatch(actions.sort(sceneIds));
      dispatch(actions.reorder(order.source.index, order.destination.index));
      dispatch(orderScenes(roomId));
    }
  };

export const orderScenes =
  (roomId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const sceneIds = getRoomSceneIds(state);
    const batchedActions: Action[] = [];
    sceneIds.forEach((sceneId, index) => {
      const scene = getRoomSceneById(state, sceneId);
      if (scene.order !== index) {
        setDoc(
          doc(scenesRef(roomId), sceneId),
          { order: index },
          { merge: true }
        );
        batchedActions.push(
          actions.update(
            sceneId,
            SceneRecord({
              ...scene,
              order: index,
            })
          )
        );
      }
    });
    dispatch(batchActions(batchedActions));
  };

export const fixOrderScenes =
  (roomId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const sceneIds = getRoomSceneOrderedIds(state);
    sceneIds.forEach((sceneId, index) => {
      const scene = getRoomSceneById(state, sceneId);
      if (scene.order !== index) {
        setDoc(
          doc(scenesRef(roomId), sceneId),
          { order: index },
          { merge: true }
        );
      }
    });
    dispatch(actions.sort(sceneIds));
  };

export const updateRoomSceneMarker =
  (roomId: string, sceneId: string, markerId: string, data: UpdateMarker) =>
  () => {
    return setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        markers: {
          [markerId]: {
            ...data,
          },
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const upsertRoomSceneMarker =
  (roomId: string, sceneId: string, markers: Record<string, Marker>) => () => {
    return updateDoc(doc(scenesRef(roomId), sceneId), {
      markers,
      updatedAt: Date.now(),
    });
  };

export const addRoomSceneMarker =
  (roomId: string, sceneId: string, data: UpdateMarker) => async () => {
    const markerId = Date.now().toString(16);
    await setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        markers: {
          [markerId]: {
            x: data.x || -1,
            y: data.y || -1,
            z: 1,
            width: data.width || 2,
            height: data.height || 2,
            locked: false,
            imageUrl: data.imageUrl || null,
            text: "",
          },
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
    return {
      id: markerId,
    };
  };

export const deleteRoomSceneMarker =
  (roomId: string, sceneId: string, markerId: string) => () => {
    return setDoc(
      doc(scenesRef(roomId), sceneId),
      {
        markers: {
          [markerId]: deleteField(),
        },
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateSceneFromRoom =
  (roomId: string, sceneId: string): DefaultThunk =>
  (_, getState) => {
    const room = getRoomById(getState(), roomId);
    return updateDoc(doc(scenesRef(roomId), sceneId), {
      backgroundUrl: room.backgroundUrl || null,
      foregroundUrl: room.foregroundUrl || null,
      fieldObjectFit: room.fieldObjectFit,
      fieldWidth: room.fieldWidth,
      fieldHeight: room.fieldHeight,
      mediaName: room.mediaName || "",
      mediaUrl: room.mediaUrl || null,
      mediaRepeat: "mediaRepeat" in room ? room.mediaRepeat : true,
      mediaType: room.mediaType || "",
      mediaVolume: room.mediaVolume || 0.5,
      soundName: room.soundName || "",
      soundUrl: room.soundUrl || null,
      soundRepeat: room.soundRepeat || false,
      soundVolume: room.soundVolume || 0.5,
      markers: room.markers || {},
      updatedAt: Date.now(),
    });
  };

export const deleteScene = (roomId: string, sceneId: string) => () => {
  return deleteDoc(doc(scenesRef(roomId), sceneId));
};

export const importRoomScenes =
  (scenesData: { [id: string]: UpdateScene }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    const sceneIds = Object.keys(scenesData);
    const batch = writeBatch(db);
    const ref = scenesRef(roomId);
    sceneIds.forEach((sceneId) => {
      batch.set(doc(ref, sceneId), SceneRecord(scenesData[sceneId]));
    });
    return batch.commit();
  };

export type ApplySceneOptions = {
  withoutBgm?: boolean;
};

export const applyScene =
  (
    roomId: string,
    sceneId: string,
    options?: ApplySceneOptions
  ): DefaultThunk =>
  (dispatch, getState) => {
    if (!roomId) {
      return;
    }

    const scene = getRoomSceneById(getState(), sceneId);
    if (options?.withoutBgm) {
      dispatch(
        updateRoom(roomId, {
          fieldWidth: scene.fieldWidth,
          fieldHeight: scene.fieldHeight,
          backgroundUrl: scene.backgroundUrl,
          foregroundUrl: scene.foregroundUrl,
          fieldObjectFit: scene.fieldObjectFit,
          markers: scene.markers || {}, // todo: migration
        })
      );
    } else {
      dispatch(
        updateRoom(roomId, {
          fieldWidth: scene.fieldWidth,
          fieldHeight: scene.fieldHeight,
          backgroundUrl: scene.backgroundUrl,
          foregroundUrl: scene.foregroundUrl,
          fieldObjectFit: scene.fieldObjectFit,
          mediaName: scene.mediaName,
          mediaType: scene.mediaType,
          mediaUrl: scene.mediaUrl,
          mediaVolume: scene.mediaVolume || 0.5, // todo: migration
          mediaRepeat: scene.mediaRepeat,
          soundName: scene.soundName,
          soundUrl: scene.soundUrl,
          soundVolume: scene.soundVolume || 0.5, // todo: migration
          soundRepeat: scene.soundRepeat,
          markers: scene.markers || {}, // todo: migration
        })
      );
    }
    if (scene.text) {
      dispatch(
        addMessage(roomId, null, {
          name: scene.name,
          text: scene.text,
          channel: "main",
        }, false)
      );
    }
  };

// secret function
const loadImage = (url: string) => {
  const image = new Image();
  image.src = url;
};
const loadAudio = (url: string) => {
  const req = new XMLHttpRequest();
  req.open("GET", url);
  req.send();
};

export const preloadSceneResources = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const sceneIds = getRoomSceneIds(state);
  sceneIds.forEach((sceneId) => {
    const scene = getRoomSceneById(state, sceneId);
    if (scene.backgroundUrl) loadImage(scene.backgroundUrl);
    if (scene.foregroundUrl) loadImage(scene.foregroundUrl);
    if (scene.mediaUrl) loadAudio(scene.mediaUrl);
    if (scene.soundUrl) loadAudio(scene.soundUrl);
  });
  window.alert("LAUNCH SCENE RESOURCES PRELOADER");
};
