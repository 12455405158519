import { Button, Dialog, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import styled from "styled-components";
import GeneralSettings from "./GeneralSettings";
import RoomDataSettings from "./RoomDataSettings";
import theme from "theme";
import RoleSettings from "./RoleSettings";

type Tabs = "general" | "roomdata" | "role";

const RoomSettingsDialog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector((state) =>
    getAppState(state, "openRoomSettingsDialog")
  );
  const [selected, setSelected] = useState<Tabs>("general");
  const [visible, setVisible] = useState(false);
  const refMainArea = useRef<HTMLDivElement>(null);

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomSettingsDialog = false;
      })
    );
  }, [dispatch]);

  const onChangeTab = (tab: Tabs) => () => {
    setSelected(tab);
    setVisible(true);
    setTimeout(() => {
      if (refMainArea.current) {
        refMainArea.current.scrollTo({ top: 0 });
      }
    }, 0);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{ style: { backgroundColor: "#2a2a2a", overflow: "hidden" } }}
    >
      <Wrap>
        <NavHeader data-visible={visible}>
          {visible ? (
            <>
              <IconButton onClick={() => setVisible(false)}>
                <NavigateBeforeIcon />
              </IconButton>
              <NavTitle variant={selected} />
            </>
          ) : (
            t("ルーム設定")
          )}
        </NavHeader>
        <SideArea data-visible={visible}>
          <Nav>
            <SubHead>{t("ルーム")}</SubHead>
            <UL>
              <Item
                role="button"
                data-active={selected === "general"}
                onClick={onChangeTab("general")}
              >
                {t("概要")}
              </Item>
              <Item
                role="button"
                data-active={selected === "roomdata"}
                onClick={onChangeTab("roomdata")}
              >
                {t("ルームデータ")}
              </Item>
            </UL>
            <Divider style={{ margin: "16px 0", marginLeft: "12px" }} />
            <SubHead>{t("メンバー")}</SubHead>
            <UL>
              <Item
                role="button"
                data-active={selected === "role"}
                onClick={onChangeTab("role")}
              >
                {t("メンバーリスト")}
              </Item>
            </UL>
          </Nav>
        </SideArea>
        <MainArea data-visible={visible} ref={refMainArea}>
          <Main>
            <Settings variant={selected} />
          </Main>
        </MainArea>
        <FooterClose fullWidth onClick={onClose}>
          {t("閉じる")}
        </FooterClose>
        <FloatingCloseButton size="large" onClick={onClose}>
          <CloseIcon />
        </FloatingCloseButton>
      </Wrap>
    </Dialog>
  );
};

type NavTitleProps = {
  variant: Tabs;
};

const NavTitle = ({ variant }: NavTitleProps) => {
  const [t] = useTranslation();
  switch (variant) {
    case "general":
      return <>{t("ルームの概要")}</>;
    case "roomdata":
      return <>{t("ルームデータ")}</>;
    case "role":
      return <>{t("メンバーリスト")}</>;
  }
};

type SettingsProps = {
  variant: Tabs;
};

const Settings = ({ variant }: SettingsProps) => {
  switch (variant) {
    case "general":
      return <GeneralSettings />;
    case "roomdata":
      return <RoomDataSettings />;
    case "role":
      return <RoleSettings />;
  }
};

const Wrap = styled.div`
  display: flex;
  height: 100%;
  background-color: #2a2a2a;

  ${theme.breakpoints.down("md")} {
    display: block;
  }
`;

const FloatingCloseButton = styled(IconButton)`
  position: absolute;
  top: 48px;
  right: max((100% - 240px - 760px) / 2 - 16px, 16px);

  ${theme.breakpoints.down("md")} {
    display: none;
  }
`;

const SideArea = styled.div`
  flex: 1 0 240px;
  align-items: flex-start;
  height: 100%;
  background-color: #202020;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  justify-content: flex-end;

  ${theme.breakpoints.down("md")} {
    flex: unset;
    width: 100%;
    height: calc(100% - 64px - 64px);
    background-color: transparent;

    &[data-visible="true"] {
      display: none;
    }
  }
`;

const Nav = styled.div`
  width: 240px;
  padding: 96px 0;
  padding-right: 16px;
  box-sizing: border-box;

  ${theme.breakpoints.down("md")} {
    padding: 40px calc(40px - 12px);
    width: 100%;
  }
`;

const NavHeader = styled.div`
  font-family: ${theme.typography.fontFamily};
  display: none;
  width: 100%;
  height: 64px;
  background-color: #1e1e1e;
  padding: 0 40px;

  &[data-visible="true"] {
    padding: 0;
  }

  ${theme.breakpoints.down("md")} {
    display: flex;
    align-items: center;
  }
`;

const FooterClose = styled(Button)`
  display: none;
  border-box: box-sizing;
  height: 64px;

  ${theme.breakpoints.down("md")} {
    display: block;
  }
`;

const SubHead = styled.div`
  margin-bottom: 4px;
  padding: 0 12px;
  font-family: ${theme.typography.fontFamily};
  font-size: 12px;
  font-weight: bold;
  color: #acacac;
`;

const UL = styled.ul`
  list-style-type: none;
`;

const Item = styled.li`
  font-family: ${theme.typography.fontFamily};
  margin: 0;
  padding: 0 12px;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &[data-active="true"] {
    cursor: unset;
    background-color: rgba(255, 255, 255, 0.06);
  }

  ${theme.breakpoints.down("md")} {
    &[data-active="true"] {
      cursor: pointer;
      background-color: unset;
    }

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
`;

const MainArea = styled.div`
  flex: 1 1 760px;
  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;

  ${theme.breakpoints.down("md")} {
    flex: unset;
    width: 100%;
    height: calc(100% - 64px - 64px);

    &[data-visible="false"] {
      display: none;
    }
  }
`;

const Main = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 60px 0 100px;
  padding: 0 40px;
  box-sizing: border-box;

  ${theme.breakpoints.down("md")} {
    width: 100%;
    max-width: unset;
    margin-top: 40px;
    padding: 0 24px;
  }
`;

export default RoomSettingsDialog;
