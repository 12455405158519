import types from "./types";
import { combineReducers } from "redux";
import { createGroupByString } from "../firestoreModuleUtils";
import { produce } from "immer";
import { enableBatching } from "redux-batched-actions";
import { Message } from "./records";
import { Actions } from "../actions";
import { Timestamp } from "firebase/firestore";
// const byId = enableBatching((state = {}, action) => {
const byId = enableBatching(
  (
    state: {
      [messageId: string]: Message;
    } = {},
    action
  ) => {
    switch (action.type) {
      case types.init: {
        return {};
      }
      case types.add: {
        if (state[action.id]) {
          return state;
        }
        return produce(state, (draft) => {
          draft[action.id] = action.data;
        });
      }
      case types.update: {
        return produce(state, (draft) => {
          draft[action.id] = action.data;
        });
      }
      case types.remove: {
        return produce(state, (draft) => {
          if (draft[action.id]) {
            draft[action.id].removed = true;
          }
        });
      }
      default: {
        return state;
      }
    }
  }
);

// const allIds = enableBatching((state = [], action) => {
const allIds = enableBatching((state: string[] = [], action: Actions) => {
  switch (action.type) {
    case types.init: {
      return [];
    }
    case types.add: {
      const isExists = state.includes(action.id);
      if (isExists) {
        return state;
      } else {
        return produce(state, (draft) => {
          if (action.reversed) {
            draft.unshift(action.id);
          } else {
            draft.push(action.id);
          }
          if (draft.length > 10000) {
            draft.shift();
          }
        });
      }
    }
    case types.update: {
      const isExists = state.includes(action.id);
      if (isExists) {
        return state;
      } else {
        return produce(state, (draft) => {
          draft.push(action.id);
        });
      }
    }
    default: {
      return state;
    }
  }
});

// const groupByChannel = enableBatching((state = {}, action) => {
const groupByChannel = enableBatching(
  (
    state: {
      [groupId: string]: string[];
    } = {},
    action: Actions
  ) => {
    const field = "channel";
    switch (action.type) {
      case types.init: {
        return {};
      }
      case types.add: {
        const groupBy = createGroupByString(field, action.data);
        if (!groupBy) return state;
        const isExists = state[groupBy] && state[groupBy].includes(action.id);
        if (isExists) {
          return state;
        } else {
          return produce(state, (draft) => {
            if (!draft[groupBy]) {
              draft[groupBy] = [];
            }
            if (action.reversed) {
              draft[groupBy].unshift(action.id);
            } else {
              draft[groupBy].push(action.id);
            }
          });
        }
      }
      case types.update: {
        const groupBy = createGroupByString(field, action.data);
        if (!groupBy) return state;
        const isExists = state[groupBy] && state[groupBy].includes(action.id);
        if (isExists) {
          return state;
        } else {
          return produce(state, (draft) => {
            if (!draft[groupBy]) {
              draft[groupBy] = [];
            }
            draft[groupBy].push(action.id);
          });
        }
      }
      default: {
        return state;
      }
    }
  }
);

const startTime = Timestamp.now();
const targetChannels = ["main", "info"];
// const addedIds = enableBatching((state = [], action) => {
const addedIds = enableBatching((state: string[] = [], action) => {
  switch (action.type) {
    case types.init: {
      return [];
    }
    case types.add: {
      if (action.data.createdAt !== null && action.data.createdAt < startTime) {
        return state;
      }
      const isExists = state.includes(action.id);
      const isSystemMessage = action.data.type === "system";
      const isTargetMessage = targetChannels.includes(action.data.channel);

      if (isExists || isSystemMessage || !isTargetMessage) {
        return state;
      } else {
        return produce(state, (draft) => {
          draft.push(action.id);
        });
      }
    }
    case types.SHIFT: {
      return produce(state, (draft) => {
        draft.shift();
      });
    }
    default: {
      return state;
    }
  }
});

const messagesReducer = combineReducers({
  byId,
  allIds,
  addedIds,
  groupByChannel,
});

export default messagesReducer;
