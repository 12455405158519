import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { auth } from "initializer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReauthenticateDialog from "./ReauthenticateDialog";
import { useForm } from "react-hook-form";
import { updatePassword } from "firebase/auth";

type ChangePasswordDialogProps = {
  open: boolean;
  onClose: () => void;
  onOpenSnackbar: (text: string) => void;
};

type FormParams = {
  password: string;
  passwordConfirm: string;
};

const ChangePasswordDialog = ({
  open,
  onClose,
  onOpenSnackbar,
}: ChangePasswordDialogProps) => {
  const [t] = useTranslation();
  const reauthed = useRef(false);
  const [openMain, setOpenMain] = useState(false);
  const [openReauth, setOpenReauth] = useState(false);

  const onReauthenticated = useCallback(() => {
    reauthed.current = true;
    setOpenReauth(false);
    setOpenMain(true);
  }, [reauthed, setOpenMain, setOpenReauth]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<FormParams>({
    defaultValues: { password: "", passwordConfirm: "" },
    reValidateMode: "onChange",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    reset();
    setErrorMessage("");
    if (!open) {
      setOpenMain(false);
      setOpenReauth(false);
    } else if (reauthed.current) {
      setOpenReauth(false);
      setOpenMain(true);
    } else {
      setOpenMain(false);
      setOpenReauth(true);
    }
  }, [open, reauthed, setOpenMain, setOpenReauth, reset]);

  const onSubmit = useCallback(
    ({ password, passwordConfirm }: FormParams) => {
      if (!auth.currentUser) {
        return;
      }

      if (password !== passwordConfirm) {
        setErrorMessage(
          t("再入力したパスワードが新しいパスワードと一致しませんでした。")
        );
        return;
      }

      updatePassword(auth.currentUser, password)
        .then(() => {
          onClose();
          reset();
          onOpenSnackbar(t("パスワードを変更しました。"));
        })
        .catch((error) => {
          if (error?.code === "auth/requires-recent-login") {
            reauthed.current = false;
            setOpenMain(false);
            setOpenReauth(true);
            reset();
            window.alert(t("再認証が必要です。"));
            return;
          }
          setErrorMessage(t("不明なエラーが発生しました。") + error?.code);
        });
    },
    [t, reset, onReauthenticated, setErrorMessage, setOpenMain, setOpenReauth]
  );

  return (
    <>
      <Dialog open={openMain} onClose={onClose} maxWidth="sm">
        <DialogTitle>
          {t("パスワードを変更")}
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", right: 6, top: 6 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: 0 }}>
            <TextField
              variant="standard"
              type="password"
              label={t("新しいパスワード")}
              helperText={t("6文字以上")}
              fullWidth
              style={{ marginBottom: "16px" }}
              {...register("password", { required: true, minLength: 6 })}
            />
            <TextField
              variant="standard"
              type="password"
              label={t("新しいパスワード（確認）")}
              fullWidth
              style={{ marginBottom: "16px" }}
              {...register("passwordConfirm", { required: true, minLength: 6 })}
            />
            {errorMessage && (
              <Typography color="error" variant="body2" paragraph>
                {errorMessage}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} fullWidth color="secondary">
              {t("キャンセル")}
            </Button>
            <Button type="submit" disabled={!isValid} fullWidth color="primary">
              {t("変更")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ReauthenticateDialog
        open={openReauth}
        onCancelled={onClose}
        onReauthenticated={onReauthenticated}
      />
    </>
  );
};

export default ChangePasswordDialog;
