import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import styled from "styled-components";
import theme from "theme";
import { Typography, Paper, IconButton, Box } from "@mui/material";
import Draggable from "react-draggable";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AlarmIcon from "@mui/icons-material/Alarm";
import LoopIcon from "@mui/icons-material/Loop";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import { Howl } from "howler";
import alerm from "assets/alerm.mp3";
import { createSelector } from "reselect";
import { registerListener, socket } from "modules/socket";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";

const pad = (duration: number) => {
  return ("0" + duration).slice(-2);
};

const sound = new Howl({
  src: [alerm],
  autoplay: false,
  loop: false,
  volume: 0.2,
});

const stopPropagation = (e) => e.stopPropagation();

const selector = createSelector(
  [store.getCurrentRoomTimer, (state) => store.getAppState(state, "muted")],
  (timer, muted) => {
    return {
      timer,
      muted,
    };
  }
);

export type TimerRef = {
  start: (durationMs: number) => void;
  pause: () => void;
};

const Timer = forwardRef<TimerRef, { roomId: string }>(({ roomId }, ref) => {
  const d = useDispatch();

  // States
  const { timer, muted } = useSelector(selector);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [durationMs, setDurationMs] = useState<number>(timer.duration * 1000);
  const [elapsedMs, setElapsedMs] = useState<number>(
    (timer.duration - timer.current) * 1000
  );
  const playing = useRef<boolean>(false);

  useEffect(() => {
    setDurationMs(timer.duration * 1000);
  }, [timer.duration]);

  useEffect(() => {
    sound.mute(muted);
  }, [muted]);

  const now = Date.now();
  const currentTimeMs = startTime
    ? durationMs - elapsedMs - (now - startTime)
    : durationMs - elapsedMs;
  const currentTime = Math.ceil(currentTimeMs / 1000);
  const ended = currentTime <= 0;
  const paused = startTime == null;

  // Periodic works
  const [frame, tick] = useState(0);
  useEffect(() => {
    if (!paused && !ended) {
      playing.current = !ended;
      const timer = setTimeout(() => {
        tick((frame) => frame + 1);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [frame, paused, ended, playing]);

  useEffect(() => {
    if (ended && playing.current) {
      sound.play();
      playing.current = false;
    }
  }, [ended, playing]);

  // Operations
  const startTimerLocal = useCallback(
    (durationMs: number, elapsedMs: number) => {
      playing.current = false;
      setDurationMs(durationMs);
      setElapsedMs(elapsedMs);

      setStartTime(Date.now());
      playing.current = true;
    },
    [setDurationMs, setElapsedMs, setStartTime, playing]
  );

  const startTimerGlobal = useCallback(
    (durationMs: number, elapsedMs: number) => {
      startTimerLocal(durationMs, elapsedMs);

      socket.emit("startTimer", {
        roomId,
        durationMs,
        elapsedMs,
      });
    },
    [startTimerLocal, roomId]
  );

  const pauseTimerLocal = useCallback(
    (durationMs: number, elapsedMs: number) => {
      setDurationMs(durationMs);
      setElapsedMs(elapsedMs);

      setStartTime(null);
      playing.current = false;
    },
    [setDurationMs, setElapsedMs, setStartTime, playing]
  );

  // Click handlers
  const onStart = useCallback(() => {
    startTimerGlobal(durationMs, elapsedMs);
  }, [startTimerGlobal, durationMs, elapsedMs]);

  const onPause = useCallback(() => {
    const newElapsedMs = ended ? 0 : durationMs - currentTimeMs;

    pauseTimerLocal(durationMs, newElapsedMs);

    socket.emit("pauseTimer", {
      roomId,
      durationMs,
      elapsedMs: newElapsedMs,
    });

    d(
      store.updateCurrentRoom({
        timer: {
          duration: Math.floor(durationMs / 1000),
          startTime: 0,
          current: currentTime,
        },
      })
    );
  }, [d, pauseTimerLocal, durationMs, currentTimeMs, currentTime, ended]);

  const onReset = useCallback(() => {
    startTimerGlobal(durationMs, 0);
  }, [startTimerGlobal, durationMs]);

  const onOpen = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomTimerSetting = true;
      })
    );
  }, [d]);

  // Forwarding
  useImperativeHandle(
    ref,
    () => ({
      start: (durationMs: number) => {
        startTimerGlobal(durationMs, 0);
      },
      pause: () => {
        onPause();
      },
    }),
    [startTimerGlobal, onPause]
  );

  // Socket listeners
  useEffect(() => {
    const lisner = ({
      durationMs,
      elapsedMs,
    }: {
      durationMs: number;
      elapsedMs: number;
    }) => {
      startTimerLocal(durationMs, elapsedMs);
    };
    return registerListener("startTimer", lisner);
  }, [startTimerLocal]);

  useEffect(() => {
    const lisner = ({
      durationMs,
      elapsedMs,
    }: {
      durationMs: number;
      elapsedMs: number;
    }) => {
      pauseTimerLocal(durationMs, elapsedMs);
    };
    return registerListener("pauseTimer", lisner);
  }, [pauseTimerLocal]);

  // Prepare display
  const sec = currentTime % 60;
  const min = Math.floor(currentTime / 60) % 60;
  const hour = Math.floor(currentTime / 60 / 60);

  return (
    <Draggable>
      <TimerConteiner elevation={5} onDoubleClick={stopPropagation}>
        <Box pl={1} pr={1}>
          <Typography variant="h2">
            {ended ? "00:00:00" : `${pad(hour)}:${pad(min)}:${pad(sec)}`}
          </Typography>
        </Box>
        <Controls>
          <IconButton onClick={onOpen} size="large">
            <AlarmIcon />
          </IconButton>
          {paused ? (
            <IconButton onClick={onStart} size="large">
              <PlayCircleFilledIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton onClick={onPause} size="large">
              <PauseCircleFilledIcon fontSize="large" />
            </IconButton>
          )}
          <IconButton onClick={onReset} disabled={!ended} size="large">
            <LoopIcon />
          </IconButton>
        </Controls>
      </TimerConteiner>
    </Draggable>
  );
});

const TimerConteiner = styled(Paper)`
  position: absolute;
  top: ${64 + 38}px;
  right: 0;
  overflow: hidden;
  ${theme.breakpoints.down("sm")} {
    top: ${56 + 38}px;
  }
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.74);
  transition: opacity 200ms linear;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export default memo(Timer);
