import { DefaultRootState } from "react-redux";
import { AnyAction } from "redux";
import { BatchAction, batchActions } from "redux-batched-actions";
import { ThunkAction } from "redux-thunk";
import { DocumentReference, Query, onSnapshot } from "firebase/firestore";
import { Action, ActionGenerators } from "./types";

type QueryCreator<T, U> = (
  props: T,
  state: DefaultRootState
) => Query<U> | null;
type DocSelector<T> = (
  props: T,
  state: DefaultRootState
) => DocumentReference | null;

export const createSubscribeCollection =
  <T, Name extends string, P>(
    actions: ActionGenerators<T, Name>,
    queryCreator: QueryCreator<P, T>,
    reversed: boolean = false
  ) =>
  (
    props: P
  ): ThunkAction<
    () => void,
    DefaultRootState,
    undefined,
    BatchAction | Action<T, Name> | AnyAction
  > =>
  (dispatch, getState) => {
    const query = queryCreator(props, getState());
    if (!query) return () => {};
    const unsubscribe = onSnapshot(query, (snapshot) => {
      // const t = Date.now();
      const changes = snapshot.docChanges();
      if (reversed) {
        changes.reverse();
      }
      const bachedActions = changes.map(({ type, doc }) => {
        switch (type) {
          case "added": {
            return actions.add(doc.id, {
              _id: doc.id,
              ...doc.data({ serverTimestamps: "previous" }),
            });
          }
          case "modified": {
            return actions.update(doc.id, {
              _id: doc.id,
              ...doc.data({ serverTimestamps: "previous" }),
            });
          }
          case "removed": {
            return actions.remove(doc.id);
          }
        }
      });
      dispatch(batchActions(bachedActions));
    });
    return () => {
      unsubscribe();
      dispatch(actions.init());
    };
  };

export const createSubscribeDocument =
  <T, Name extends string, P>(
    actions: ActionGenerators<T, Name>,
    docSelector: DocSelector<P>
  ) =>
  (
    props: P
  ): ThunkAction<
    () => void,
    DefaultRootState,
    undefined,
    BatchAction | Action<T, Name> | AnyAction
  > =>
  (dispatch, getState) => {
    const query = docSelector(props, getState());
    if (!query) return () => {};
    return onSnapshot(query, (doc) => {
      return dispatch(
        actions.update(doc.id, {
          _id: doc.id,
          ...(doc.data({ serverTimestamps: "previous" }) as T),
        })
      );
    });
  };
