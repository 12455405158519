import React, { memo } from "react";
import { Form, useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const SceneEdit = () => {
  const { handleChange, handleBlur, values } = useFormikContext<{
    name: string;
    text: string;
  }>();
  const [t] = useTranslation();
  return (
    <Form>
      <TextField
        label={t("シーンタイトル")}
        name="name"
        variant="filled"
        fullWidth
        autoFocus
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
      />
      <TextField
        label={t("シーン切替メッセージ")}
        multiline
        name="text"
        variant="filled"
        fullWidth
        rows={4}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.text}
      />
    </Form>
  );
};

export default memo(SceneEdit);
