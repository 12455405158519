import React, { useState, memo, useMemo, useEffect, FC } from "react";
import { DefaultRootState, useSelector } from "react-redux";
import { Typography, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDiceBotVersion } from "api";
import { createSelector } from "@reduxjs/toolkit";
import { Member } from "../../roomSlice";

const TIMEOUT = 180000;

const selector = createSelector(
  [
    (state: DefaultRootState) => {
      return state.app.room.members.ids;
    },
    (state: DefaultRootState) => {
      return state.app.room.members.entities;
    },
    (state: DefaultRootState) => {
      return state.app.state.roomChatTab;
    },
    (state: DefaultRootState) => {
      return state.app.user.uid;
    },
  ],
  (memberIds, members, tab, uid): Member[] => {
    return memberIds
      .filter((memberId) => {
        const member = members[memberId];
        return member?.typing === tab && member?.uid !== uid;
      })
      .map((memberId) => {
        return members[memberId];
      }) as Member[];
  }
);

const ChatBoxTypingStateBarContainer = () => {
  const [dicebotVersion, setDicebotVersion] = useState("");
  useEffect(() => {
    getDiceBotVersion().then(setDicebotVersion);
  }, []);

  const _members = useSelector(selector);
  const [timestamp, tick] = useState(Date.now());
  useEffect(() => {
    const timer = setTimeout(() => {
      tick(Date.now());
    }, TIMEOUT);
    return () => clearTimeout(timer);
  }, [_members]);

  const members = useMemo(() => {
    return _members.filter((member) => {
      return timestamp - Number(member.updatedAt) < TIMEOUT;
    });
  }, [_members, timestamp]);

  return (
    <ChatBoxTypingStateBar members={members} dicebotVersion={dicebotVersion} />
  );
};

interface ChatBoxTypingStateBarProps {
  members: Member[];
  dicebotVersion: string;
}

const ChatBoxTypingStateBar: FC<ChatBoxTypingStateBarProps> = ({
  members,
  dicebotVersion,
}) => {
  const [t] = useTranslation();
  if (!members || members.length < 1) {
    return (
      <Box p={2} pt={0.5} pb={1}>
        <Typography noWrap variant="caption" style={{ color: "#646464" }}>
          Dicebot engine :&nbsp;
          {dicebotVersion ? (
            <Link
              href="https://docs.bcdice.org/"
              target="_blank"
              color="textSecondary"
              style={{ textDecoration: "underline", color: "#646464" }}
              underline="hover"
            >
              BCDice{`@${dicebotVersion}`}
            </Link>
          ) : (
            "Loading..."
          )}
        </Typography>
      </Box>
    );
  }
  return (
    <Box p={2} pt={0.5} pb={1}>
      {members.length > 1 ? (
        <Typography variant="caption">
          {`${members.length}` + t("人が入力中") + "..."}
        </Typography>
      ) : (
        <Typography noWrap variant="caption">
          {members[0].isAnonymous
            ? t("ゲスト")
            : members[0].displayName || t("匿名")}
          {t("が入力中")}...
        </Typography>
      )}
    </Box>
  );
};

export default memo(ChatBoxTypingStateBarContainer);
