import React, { useCallback } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  DialogTitle,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import { Formik } from "formik";

const ExportRoomDialog = () => {
  const [t] = useTranslation();
  const open = useSelector((state) =>
    store.getAppState(state, "openRoomExport")
  );
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values) => {
      dispatch(store.exportRoomDataForPreset(values));
      dispatch(store.appStateMutate((state) => (state.openRoomExport = false)));
    },
    [dispatch]
  );
  const onClose = useCallback(() => {
    dispatch(store.appStateMutate((state) => (state.openRoomExport = false)));
  }, [dispatch]);
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        room: true,
        items: true,
        characters: true,
        decks: true,
        notes: true,
        scenes: true,
        effects: true,
        savedatas: true,
      }}
    >
      {({ values, submitForm, setFieldValue }) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>{t("エクスポート設定")}</DialogTitle>
          <DialogContent style={{ background: "rgba(0, 0, 0, 0.4)" }}>
            <Box pt={2} pb={2}>
              <Typography variant="body2" color="textPrimary">
                {t(
                  "注：出力時に全ての音源ファイルは除外されます。出力データを配布する場合は、配布する権利のないデータが含まれていないことを必ず確認してください。また、出力されたデータは今後のアップデートにより動作しなくなる場合があります。"
                )}
                {t(
                  "出力された画像データは共有ストレージを参照します。ユーザーごとのファイル一覧にアップロードされません。"
                )}
              </Typography>
            </Box>
            <Divider />
            <List>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("フィールド設定")}
                  secondary={t("背景・前景・マーカーなど")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="room"
                    checked={values.room}
                    onChange={(_, checked) => setFieldValue("room", checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("マイキャラクター")}
                  secondary={t("所有権のあるキャラクター")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="characters"
                    checked={values.characters}
                    onChange={(_, checked) =>
                      setFieldValue("characters", checked)
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("スクリーンパネル")}
                  secondary={t("全てのスクリーンパネル")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="items"
                    checked={values.items}
                    onChange={(_, checked) => setFieldValue("items", checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("カードデッキ")}
                  secondary={t("全てのカードデッキ")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="decks"
                    checked={values.decks}
                    onChange={(_, checked) => setFieldValue("decks", checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("シナリオテキスト")}
                  secondary={t("全てのシナリオテキスト")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="notes"
                    checked={values.notes}
                    onChange={(_, checked) => setFieldValue("notes", checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("シーン")}
                  secondary={t("音源の設定を除くシーンリスト")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="scenes"
                    checked={values.scenes}
                    onChange={(_, checked) => setFieldValue("scenes", checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("カットイン")}
                  secondary={t("音源の設定を除くカットイン効果")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="effects"
                    checked={values.effects}
                    onChange={(_, checked) => setFieldValue("effects", checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={t("セーブデータ")}
                  secondary={t("全てのセーブデータ")}
                />
                <ListItemSecondaryAction>
                  <Switch
                    name="savedatas"
                    checked={values.savedatas}
                    onChange={(_, checked) =>
                      setFieldValue("savedatas", checked)
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button fullWidth color="primary" onClick={submitForm}>
              {t("エクスポート")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default React.memo(ExportRoomDialog);
