import { createSelector } from "reselect";
import { getAppState } from "../app.state/selectors";
import { UserFile } from "./records";
import { DefaultRootState } from "react-redux";

const emptyUserFile: UserFile = {
  contentType: "",
  dir: "item",
  hash: "",
  name: "",
  owner: "",
  roomId: "",
  size: 0,
  uploaded: false,
  url: "",
  archived: false,
  createdAt: 0,
  updatedAt: 0,
};

export const getUserFiles = (state: DefaultRootState) => {
  return state.entities.userFiles.byId;
};

export const getUserFileById = (state: DefaultRootState, id: string) => {
  return state.entities.userFiles.byId[id] || emptyUserFile;
};

export const getUserFileIds = (state: DefaultRootState) => {
  return state.entities.userFiles.allIds || [];
};

export const getUserFileIdsByDir = (state: DefaultRootState, dir: string) => {
  return state.entities.userFiles.groupByDir[dir] || [];
};

export const getUserFileIdsByCurrentDir = (state: DefaultRootState) => {
  const dir = getAppState(state, "openRoomImageSelectDir");
  return (dir && state.entities.userFiles.groupByDir[dir]) ?? [];
};

export const _getUserFileIdsByDir = (
  state: DefaultRootState,
  { dir }: { dir: string }
) => {
  return state.entities.userFiles.groupByDir[dir] || [];
};

export const getHashProps = (_: unknown, props: { hash: number | string }) => {
  return props.hash;
};

export const getUserFileByHash = createSelector(
  [getUserFiles, _getUserFileIdsByDir, getHashProps],
  (files, fileIds, hash) => {
    const id = fileIds.find((fileId) => {
      return files[fileId].hash === hash;
    });
    if (id === undefined) return null;
    return files[id];
  }
);

const getRoomId = (state: DefaultRootState) => getAppState(state, "roomId");
const getFilteredOption = (state: DefaultRootState) =>
  getAppState(state, "onlyFilteredFiles");

export const getCurrentUserFileIds = createSelector(
  [getUserFiles, getUserFileIdsByCurrentDir, getFilteredOption, getRoomId],
  (files, fileIds, filtered, roomId) => {
    if (filtered) {
      return fileIds.filter((fileId) => files[fileId].roomId === roomId);
    } else {
      return fileIds;
    }
  }
);

export const getFilteredUserFileIdsByDir = createSelector(
  [getUserFiles, getUserFileIdsByDir, getFilteredOption, getRoomId],
  (files, fileIds, filtered, roomId) => {
    if (filtered) {
      return fileIds
        .filter((fileId) => files[fileId].roomId === roomId)
        .sort((a, b) => {
          return files[a].updatedAt < files[b].updatedAt ? 1 : -1;
        });
    } else {
      return Array.from(fileIds).sort((a, b) => {
        return files[a].updatedAt < files[b].updatedAt ? 1 : -1;
      });
    }
  }
);

export const getUserFileCount = createSelector(
  [getUserFileIds],
  (fileIds) => fileIds.length
);
