import React, { useEffect } from "react";
import { DefaultRootState, ResolveThunks, connect } from "react-redux";
import store from "stores/interfaces";

import Room from "./Room";
import Loader from "./Loader";

type OriginProps = {
  roomId: string;
};
type Props = OriginProps &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

const Login = (props: Props) => {
  useEnhance(props);
  if (!props.uid) {
    return <Loader loading={true} />;
  }
  return <Room roomId={props.roomId} uid={props.uid} />;
};

const useEnhance = (props: Props) => {
  useEffect(() => {
    return props.subscribeUserState();
  }, []);
  return {};
};

const mapStateToProps = (state: DefaultRootState, _: OriginProps) => {
  return {
    uid: store.getAppState(state, "uid"),
  };
};

const mapDispatchToProps = {
  ...store,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
