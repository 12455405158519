import { createSelector } from "reselect";
import { DefaultRootState } from "react-redux";
import { getUid } from "../app.user/selectors";
import { Member, Role } from "./records";
import { getCurrentRoom } from "../entities.rooms/selectors";

export const getRoomMembers = (state: DefaultRootState) => {
  return state.entities.roomMembers.byId;
};

export const getRoomMemberById = (
  state: DefaultRootState,
  uid: string
): Member | undefined => {
  return state.entities.roomMembers.byId[uid];
};

export const getRoomMemberIds = (state: DefaultRootState) => {
  return state.entities.roomMembers.allIds;
};

export const getRoomMembersAnonymousCounts = (state: DefaultRootState) => {
  const ids = getRoomMemberIds(state);
  let count = 0;
  for (const id of ids) {
    const member = getRoomMemberById(state, id);
    if (member?.isAnonymous) {
      count++;
    }
  }
  return count;
};

const getRoomChatTab = (state: DefaultRootState) => {
  return state.app.state.roomChatTab;
};

export const getTypingRoomMembers = createSelector(
  [getRoomMemberIds, getRoomMembers, getUid, getRoomChatTab],
  (memberIds, members, uid, roomChatTab) => {
    return memberIds
      .filter((memberId) => {
        const member = members[memberId];
        return uid !== memberId && member.typing === roomChatTab;
      })
      .map((memberId) => {
        return members[memberId];
      });
  }
);

export const getSameGroupRoomMemberIds = createSelector(
  [getRoomMemberIds, getRoomMembers, getUid],
  (memberIds, members, uid) => {
    const currentMember = members[uid || ""];
    if (!currentMember?.group) return [uid];
    return memberIds.filter((memberId) => {
      const member = members[memberId];
      return currentMember.group === member.group;
    });
  }
);

export const getRole = (state: DefaultRootState): Role | "owner" | null => {
  const uid = getUid(state);
  const room = getCurrentRoom(state);
  const member = getRoomMemberById(state, uid || "");
  if (uid == null || room == null) {
    return null;
  }

  if (room.owner === uid) {
    return "owner";
  }

  if (member == null) {
    return null;
  }

  if (member.isAnonymous) {
    if (room.defaultAnonymousRole) {
      return room.defaultAnonymousRole;
    } else if (room.defaultRole !== "subowner") {
      return room.defaultRole;
    } else {
      return "player";
    }
  }

  return member.role || room.defaultRole;
};

export const getIsRoleAudience = (state: DefaultRootState): boolean => {
  const role = getRole(state);
  return role === "audience";
};

export const getIsRoleDenied = (state: DefaultRootState): boolean => {
  const role = getRole(state);
  return role === "denied";
};

export const getHasEditableRole = (state: DefaultRootState): boolean => {
  const role = getRole(state);
  return role === "owner" || role === "subowner";
};
