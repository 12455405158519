import types from "./types";
import { combineReducers } from "redux";
import { produce } from "immer";
import { enableBatching } from "redux-batched-actions";
import { Actions } from "../actions";

export type Emote = {
  id: string;
  kind: string;
  posPercent: number;
};

const byId = enableBatching(
  (
    state: {
      [emoteId: string]: Emote;
    } = {},
    action
  ) => {
    switch (action.type) {
      case types.add: {
        if (state[action.id]) {
          return state;
        }
        return produce(state, (draft) => {
          draft[action.id] = action.data;
        });
      }
      case types.remove: {
        return produce(state, (draft) => {
          if (draft[action.id]) {
            delete draft[action.id];
          }
        });
      }
      default: {
        return state;
      }
    }
  }
);

const allIds = enableBatching((state: string[] = [], action: Actions) => {
  switch (action.type) {
    case types.add: {
      const isExists = state.includes(action.id);
      if (isExists) {
        return state;
      }

      return produce(state, (draft) => {
        draft.push(action.id);
      });
    }
    case types.remove: {
      const index = state.findIndex((id) => id === action.id);
      if (index < 0) {
        return state;
      }

      return produce(state, (draft) => {
        draft.splice(index, 1);
      });
    }
    default: {
      return state;
    }
  }
});

const messagesReducer = combineReducers({
  byId,
  allIds,
});

export default messagesReducer;
