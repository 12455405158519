import { useState, useEffect, useRef, useCallback } from "react";

export default function useLongPress<RefElement>(
  callback: (target: RefElement) => void,
  ms = 500
) {
  const targetEl = useRef<RefElement>(null);
  const [startLongPress, setStartLongPress] = useState(false);
  const onStartPress = useCallback((e) => {
    setStartLongPress(true);
  }, []);
  const onEndPress = useCallback((e) => {
    setStartLongPress(false);
  }, []);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(() => {
        if (targetEl.current) {
          callback(targetEl.current);
        }
      }, ms);
    } else {
      clearTimeout(timerId);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [startLongPress, targetEl.current]);

  return {
    onMouseDown: onStartPress,
    onMouseUp: onEndPress,
    onMouseLeave: onEndPress,
    onTouchStart: onStartPress,
    onTouchEnd: onEndPress,
    ref: targetEl,
  };
}
