import { memo } from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";
import { format } from "date-fns";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";

type MemberListItemProps = {
  memberId: string;
};

const MemberListItem = ({ memberId }: MemberListItemProps) => {
  const [t] = useTranslation();

  const member = useSelector((state) =>
    store.getRoomMemberById(state, memberId)
  );

  if (member == null) {
    return null;
  }

  return (
    <ListItem>
      <ListItemText
        primary={member.displayName || t("匿名ユーザー")}
        secondary={`${format(member.updatedAt, "yyyy/MM/dd/HH:mm")} ${
          member?.group || ""
        }`}
      />
      <ListItemSecondaryAction>
        <Tooltip title={t("退出させる（準備中）")}>
          <IconButton size="large">
            <TransferWithinAStationIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default memo(MemberListItem);
