import { Avatar, AvatarGroup, Toolbar } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";
import { getRoomMemberById } from "stores/modules/entities.room.members/selectors";
import { getCurrentMessageGroup } from "stores/modules/entities.rooms/selectors";

const ChatMembers = () => {
  const group = useSelector(getCurrentMessageGroup);
  if (!group || group.kind === "public") {
    return null;
  }

  return (
    <Toolbar variant="dense" sx={{ minHeight: "40px" }}>
      <AvatarGroup
        max={10}
        componentsProps={{
          additionalAvatar: {
            sx: { width: 24, height: 24, fontSize: "12px" },
          },
        }}
      >
        {group.uids.map((memberId) => (
          <MemberAvatar key={memberId} memberId={memberId} />
        ))}
      </AvatarGroup>
    </Toolbar>
  );
};

type MemberAvatarProps = {
  memberId: string;
};

const MemberAvatar = ({ memberId }: MemberAvatarProps) => {
  const member = useSelector((state) => getRoomMemberById(state, memberId));
  if (!member) {
    return null;
  }

  return (
    <Avatar
      sx={{ width: 24, height: 24, fontSize: "12px" }}
      alt={member.displayName}
      src={member.photoUrl || "/ccfolia.png"}
      imgProps={{ draggable: false }}
    />
  );
};

export default memo(ChatMembers);
