import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "stores/interfaces";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { getAppState } from "stores/modules/app.state/selectors";
import { getHasEditableRole } from "stores/modules/entities.room.members/selectors";

const CharacterMenu = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const uid = useSelector((state) => getAppState(state, "uid"));
  const open = useSelector((state) =>
    getAppState(state, "openRoomCharacterMenu")
  );
  const top = useSelector((state) => getAppState(state, "roomPointerY"));
  const left = useSelector((state) => getAppState(state, "roomPointerX"));
  const characterId = useSelector((state) =>
    getAppState(state, "openRoomCharacterMenuId")
  );
  const character = useSelector((state) =>
    store.getCharacterById(state, characterId ?? "")
  );
  const hasEditableRole = useSelector(getHasEditableRole);

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomCharacterMenu = false;
      })
    );
  }, [dispatch]);
  const onChangeOwn = useCallback(() => {
    if (characterId == null || uid == null) return;
    dispatch(
      store.updateRoomCharacter(characterId, {
        owner: uid,
      })
    );
    onClose();
  }, [characterId, dispatch, uid, onClose]);
  const onChangeActive = useCallback(() => {
    if (characterId == null) return;
    dispatch(
      store.updateRoomCharacter(characterId, {
        active: false,
        owner: uid,
      })
    );
    onClose();
  }, [characterId, dispatch, uid, onClose]);
  const onDuplicate = useCallback(() => {
    if (characterId == null) return;
    dispatch(store.duplicateRoomCharacter(characterId));
    onClose();
  }, [characterId, dispatch, onClose]);
  const onDelete = useCallback(() => {
    if (characterId == null) return;
    dispatch(store.deleteCurrentRoomItem(characterId));
    onClose();
    if (window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteRoomCharacter(characterId));
    }
  }, [characterId, dispatch, onClose, t]);
  const onEdit = useCallback(() => {
    if (characterId == null) return;
    if (uid === character.owner || !character.secret || hasEditableRole) {
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacter = true;
          state.openRoomCharacterId = characterId;
        })
      );
    }
    onClose();
  }, [
    character.owner,
    character.secret,
    characterId,
    dispatch,
    hasEditableRole,
    onClose,
    uid,
  ]);
  const onRotate = useCallback(() => {
    if (characterId == null) return;
    dispatch(
      store.updateRoomCharacter(characterId, { angle: character.angle + 45 })
    );
    onClose();
  }, [characterId, dispatch, character.angle, onClose]);
  const onCopyId = useCallback(() => {
    if (characterId == null) return;
    copy(characterId);
    onClose();
  }, [characterId, onClose]);

  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ left, top }}
      onClose={onClose}
      MenuListProps={{ dense: true }}
      PaperProps={{
        style: {
          width: "18ch",
        },
      }}
      className={"user-select-none"}
    >
      <MenuItem
        onClick={onEdit}
        disabled={
          uid !== character.owner && character.secret && !hasEditableRole
        }
      >
        {t("編集")}
      </MenuItem>
      <MenuItem onClick={onRotate}>
        {t("回転する")}
        <ShortcutKeyInfo color="textSecondary" variant="caption" align="right">
          R or Shift+R
        </ShortcutKeyInfo>
      </MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem onClick={onChangeActive}>
        {t("しまう")}
        <ShortcutKeyInfo color="textSecondary" variant="caption" align="right">
          S
        </ShortcutKeyInfo>
      </MenuItem>
      <MenuItem onClick={onChangeOwn}>
        {t("自分の駒にする")}
        <ShortcutKeyInfo color="textSecondary" variant="caption" align="right">
          T
        </ShortcutKeyInfo>
      </MenuItem>
      <MenuItem onClick={onDuplicate}>
        {t("複製")}
        <ShortcutKeyInfo color="textSecondary" variant="caption" align="right">
          Ctrl+D
        </ShortcutKeyInfo>
      </MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem onClick={onDelete}>
        {t("削除")}
        <ShortcutKeyInfo color="textSecondary" variant="caption" align="right">
          Ctrl+
          <BackspaceIcon
            fontSize="inherit"
            style={{ verticalAlign: "middle" }}
          />
        </ShortcutKeyInfo>
      </MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem onClick={onCopyId}>{t("Copy Id (for dev)")}</MenuItem>
    </Menu>
  );
};

const ShortcutKeyInfo = styled(Typography)`
  flex: 1;
`;

export default memo(CharacterMenu);
