import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import store from "stores/interfaces";
import toCDNUrl from "modules/toCDNUrl";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

const Field = () => {
  const dispatch = useDispatch();
  const {
    fieldWidth,
    fieldHeight,
    backgroundUrl,
    foregroundUrl,
    fieldObjectFit,
    cellSize,
  } = useSelector((state) => {
    const room = store.getCurrentRoom(state);
    if (!room) {
      return {
        fieldWidth: 0,
        fieldHeight: 0,
        backgroundUrl: null,
        foregroundUrl: null,
        fieldObjectFit: "fill",
        cellSize: store.getAppState(state, "roomScreenCellSize"),
      };
    }
    return {
      fieldWidth: room.fieldWidth,
      fieldHeight: room.fieldHeight,
      backgroundUrl: room.backgroundUrl,
      foregroundUrl: room.foregroundUrl,
      fieldObjectFit: room.fieldObjectFit,
      cellSize: store.getAppState(state, "roomScreenCellSize"),
    };
  }, shallowEqual);
  const isRoleAudience = useSelector(getIsRoleAudience);
  const displaySize = React.useMemo(() => {
    return {
      width: fieldWidth * cellSize,
      height: fieldHeight * cellSize,
      x: ~~(fieldWidth / 2) * -cellSize,
      y: ~~(fieldHeight / 2) * -cellSize,
    };
  }, [fieldWidth, fieldHeight, cellSize]);
  const onSelectForeground = (e) => {
    e.stopPropagation();
    if (isRoleAudience) {
      return;
    }
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "foreground";
        state.openRoomImageSelectTarget = "foreground/set";
      })
    );
  };
  const noImage = !backgroundUrl && !foregroundUrl;
  return (
    <div
      style={{
        position: "absolute",
        left: displaySize.x,
        top: displaySize.y,
        width: displaySize.width,
        height: displaySize.height,
        backgroundImage: noImage
          ? `linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px)`
          : "none",
        backgroundSize: `${cellSize}px ${cellSize}px`,
        boxSizing: "border-box",
        outline: noImage ? "4px solid rgba(0, 0, 0, 0.2)" : "none",
      }}
      onDoubleClick={onSelectForeground}
    >
      {backgroundUrl ? (
        <img
          src={toCDNUrl(backgroundUrl)}
          width={displaySize.width}
          height={displaySize.height}
          draggable={false}
          alt=""
          style={{
            objectFit: fieldObjectFit,
          }}
        />
      ) : null}
      {foregroundUrl ? (
        <img
          src={toCDNUrl(foregroundUrl)}
          width={displaySize.width}
          height={displaySize.height}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            objectFit: fieldObjectFit,
          }}
          draggable={false}
          alt=""
        />
      ) : null}
    </div>
  );
};

export default React.memo(Field);
