import React from "react";

const PopupWindow: React.FC<{
  url: string;
  name: string;
  width: number | string;
  height: number | string;
  onClose?: () => void;
}> = (props) => {
  React.useEffect(() => {
    const win = window.open(
      props.url,
      props.name,
      `resizable,scrollbars,status,width=${props.width},height=${
        props.height
      },left=${window.innerWidth * 0.5 - ~~props.width * 0.5},top=${
        window.innerHeight * 0.5 - ~~props.height * 0.5
      }`
    );
    win?.addEventListener(
      "beforeunload",
      () => props.onClose && props.onClose()
    );
    return () => win?.close();
  }, []);
  return null;
};

export default PopupWindow;
