import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";

type PanelListItemProps = {
  panelId: string;
};

const PanelListItem = ({ panelId }: PanelListItemProps) => {
  const d = useDispatch();
  const [t] = useTranslation();
  const panel = useSelector((state) => store.getRoomItemById(state, panelId));
  const onEdit = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomPanelDetailId = panelId;
        state.openRoomPanelDetail = true;
      })
    );
  }, [panelId, d]);
  const onRemove = useCallback(() => {
    d(
      store.updateRoomItem(null, panelId, {
        active: false,
      })
    );
  }, [panelId, d]);
  const onAdd = useCallback(() => {
    d(
      store.updateRoomItem(null, panelId, {
        active: true,
      })
    );
  }, [panelId, d]);
  if (!panel) return null;
  return (
    <ListItem button onClick={onEdit}>
      <ListItemAvatar>
        <Avatar src={toCDNUrl(panel.imageUrl)} />
      </ListItemAvatar>
      <ListItemText
        primary={panel.memo || t("NOTEXT")}
        primaryTypographyProps={{ noWrap: true, display: "block" }}
        secondary={`[${panel.z}] ${panel.width} × ${panel.height}`}
        secondaryTypographyProps={{ noWrap: true }}
      />
      <ListItemSecondaryAction>
        {panel.active || panel.active === undefined ? (
          <Tooltip title={t("パネルを非表示")}>
            <IconButton onClick={onRemove} size="large">
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("パネルを表示")}>
            <IconButton onClick={onAdd} size="large">
              <VisibilityOffIcon />
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default memo(PanelListItem);
