import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import theme from "theme";

const InviteDialog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    getAppState(state, "openRoomInviteDialog")
  );
  const onClose = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openRoomInviteDialog = false)));
  }, [dispatch]);

  const roomId = useSelector((state) => getAppState(state, "roomId"));
  const roomUrl = useMemo(() => {
    return window.location.origin + "/rooms/" + roomId;
  }, [roomId]);

  const [openTooltip, setOpenTooltip] = useState(false);
  const onClickCopy = useCallback(() => {
    if (copy(roomUrl)) {
      setOpenTooltip(true);
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    } else {
      window.alert(t("コピーに失敗しました"));
    }
  }, [roomUrl, setOpenTooltip, t]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }} fontSize="20px" fontWeight="bold">
            {t("友達をルームに招待する")}
          </Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          paragraph
        >
          {t(
            "※ このURLを知っている人は、誰でもこのルームにアクセスが可能になります。そのため、不特定多数が見る可能性のある場所に書き込むことは推奨いたしません。"
          )}
        </Typography>
        <Divider />
        <Box mt={1}>
          <TextField
            value={roomUrl}
            label={t("ルーム招待URL")}
            type="text"
            variant="filled"
            fullWidth
            margin="dense"
            InputProps={{ readOnly: true }}
          />
          <Typography variant="caption" paragraph>
            {t("ルームID")} {roomId}
          </Typography>
          <Tooltip
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={t("コピーしました")}
          >
            <Button
              size="large"
              variant="outlined"
              color="white"
              fullWidth
              startIcon={<ContentCopyIcon />}
              onClick={onClickCopy}
            >
              {t("リンクをコピー")}
            </Button>
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteDialog;
