import types from "./types";
import { combineReducers } from "redux";
import { createByIdReducer, createIndexReducer } from "../firestoreModuleUtils";
import { DiceItem } from "./records";

const byId = createByIdReducer<DiceItem>()(types);
const allIds = createIndexReducer(types);

const diceItemReducer = combineReducers({
  byId,
  allIds,
});

export default diceItemReducer;
