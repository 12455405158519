import { useCallback, useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Popover,
  Button,
  Paper,
  TextField,
  IconButton,
  Typography,
  TextFieldProps,
  PopoverProps,
} from "@mui/material";
import FormikMuiSlider from "components/FormikMuiSlider";

import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Formik, Form, Field, useField, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { UserMediumRecord } from "stores/modules/entities.user.media";

type MediumListItemMenuProps = {
  mediumId: string | undefined;
  uid: string | null;
  anchorEl: PopoverProps["anchorEl"];
  open: boolean;
  onClose: () => void;
};

const FormikTextField = memo(
  ({ name, ...props }: TextFieldProps & { name: string }) => {
    const inputProps = { ...props };
    const [field] = useField({ name, type: inputProps.type });
    return <TextField variant="standard" {...field} {...inputProps} />;
  }
);

const MediumListItemMenu = ({
  mediumId,
  uid,
  anchorEl,
  open,
  onClose,
}: MediumListItemMenuProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [volume, setVolume] = useState(0.5);
  const [loop, setLoop] = useState(true);

  const medium =
    useSelector((state) => store.getMediumById(state, mediumId || "")) ||
    blankMedium;
  const isPreview = useSelector(
    (state) => state.app.state.previewMediaUrl !== null
  );

  useEffect(() => {
    if (medium) {
      setVolume(medium.volume);
      setLoop(medium.loop);
    }
  }, [medium, setVolume, setLoop]);

  const onSubmit = useCallback(
    (values) => {
      if (uid && mediumId) {
        dispatch(store.updateUserMedium(uid, mediumId, values));
        onClose();
      }
    },
    [uid, mediumId, onClose, dispatch]
  );
  const onDelete = useCallback(() => {
    if (uid && mediumId && window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteUserMedium(uid, mediumId, !!medium?.external));
      onClose();
    }
  }, [uid, mediumId, medium?.external, onClose, t, dispatch]);

  const handleClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.previewMediaUrl = null;
      })
    );
    onClose();
  }, [onClose, dispatch]);
  const onPreview = useCallback(() => {
    if (medium) {
      dispatch(
        store.appStateMutate((state) => {
          state.previewMediaUrl = isPreview ? null : medium.url;
          state.previewMediaVolume = volume;
          state.previewMediaLoop = loop;
        })
      );
    }
  }, [isPreview, medium, volume, loop, dispatch]);
  const onChangeVolume = useCallback(
    (_, value) => {
      setVolume(value);
      if (isPreview) {
        dispatch(
          store.appStateMutate((state) => {
            state.previewMediaVolume = value;
          })
        );
      }
    },
    [isPreview, setVolume, dispatch]
  );
  const onChangeLoop = useCallback(
    (value) => {
      setLoop(value);
      if (isPreview) {
        dispatch(
          store.appStateMutate((state) => {
            state.previewMediaLoop = value;
          })
        );
      }
    },
    [isPreview, setLoop, dispatch]
  );

  if (!medium) return null;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: medium.name,
        volume: medium.volume,
        loop: medium.loop,
      }}
    >
      {({ submitForm, values }) => (
        <FormPopover
          open={open}
          anchorEl={anchorEl || null}
          onClose={handleClose}
          onSubmit={submitForm}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MediumEditContent>
            <Form>
              <FormikTextField
                label={t("name")}
                variant="filled"
                margin="dense"
                name="name"
                fullWidth
              />
              <VolumeBox>
                <VolumeDownIcon />
                <Field name="volume" type="number">
                  {({ field, form }: FieldProps) => (
                    <VolumeSlider
                      field={field}
                      form={form}
                      sliderProps={{ max: 1, min: 0.0, step: 0.05 }}
                      onChange={onChangeVolume}
                    />
                  )}
                </Field>
                <Typography>{values.volume}</Typography>
                <Field
                  name={t("loop")}
                  type="checkbox"
                  render={({ field, form }) => (
                    <IconButton
                      onClick={() => {
                        form.setFieldValue(field.name, !field.value);
                        onChangeLoop(!field.value);
                      }}
                      size="small"
                      color={field.value ? "primary" : "default"}
                    >
                      <RepeatIcon />
                    </IconButton>
                  )}
                />
              </VolumeBox>
              <Actions>
                <Button
                  fullWidth
                  color={isPreview ? "secondary" : "primary"}
                  onClick={onPreview}
                >
                  {isPreview ? t("試聴停止") : t("試聴")}
                </Button>
                <Button fullWidth color="secondary" onClick={onDelete}>
                  {t("削除")}
                </Button>
                <Button fullWidth color="primary" type="submit">
                  {t("保存")}
                </Button>
              </Actions>
            </Form>
          </MediumEditContent>
        </FormPopover>
      )}
    </Formik>
  );
};

type FormPopoverProps = Omit<PopoverProps, "onClose" | "onSubmit"> & {
  onSubmit: () => void;
  onClose: () => void;
};

const FormPopover = ({ onClose, onSubmit, ...other }: FormPopoverProps) => {
  const onCloseAndSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [onSubmit, onClose]);
  return <Popover onClose={onCloseAndSubmit} {...other} />;
};

const MediumEditContent = styled(Paper)`
  width: 240px;
  padding: 8px 16px;
`;

const VolumeBox = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const VolumeSlider = styled(FormikMuiSlider)`
  margin: 0 8px;
`;

const Actions = styled.div`
  display: flex;
`;

const blankMedium = UserMediumRecord({});

export default memo(MediumListItemMenu);
