import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import { AppBar, Toolbar, Tabs, Tab, Badge, Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { getHasEditableRole } from "stores/modules/entities.room.members/selectors";
import theme from "theme";
import { getJoinedMessageGroups } from "stores/modules/entities.rooms/selectors";
import { CHAT_SYSTEM_TABS } from "stores/modules/app.state/selectors";

const ChatBoxHeader = () => {
  const d = useDispatch();
  const [t] = useTranslation();
  const tab = useSelector((state) => store.getAppState(state, "roomChatTab"));
  const newMessageCounts = useSelector((state) =>
    store.getAppState(state, "roomChatTabs")
  );
  const messageCounts = useSelector((state) =>
    store.getAppState(state, "roomChatMessageCounts")
  );
  const directMessageChannels = useSelector(
    store.getRoomMemberDirectMessageTabs
  );
  const groups = useSelector(getJoinedMessageGroups);
  const hasEditableRole = useSelector(getHasEditableRole);

  useEffect(() => {
    const groupTabs = groups.map((group) => group.id);
    const dmTabs = directMessageChannels.map((dm) => dm.key);
    const searchTabs = [...CHAT_SYSTEM_TABS, ...groupTabs, ...dmTabs];

    if (!searchTabs.includes(tab)) {
      d(
        store.appStateMutate((state) => {
          state.roomChatTab = "main";
          state.roomChatTabs["main"] = 0;
        })
      );
    }
  }, [tab, groups, directMessageChannels, d]);

  const onChangeTab = useCallback(
    (_, value) => {
      if (value) {
        d(
          store.appStateMutate((state) => {
            state.roomChatTab = value;
            state.roomChatTabs[value] = 0;
          })
        );
      } else {
        d(
          store.appStateMutate((state) => {
            state.openRoomMessageChannel = true;
          })
        );
      }
    },
    [d]
  );
  const isPrivateTab = useMemo(() => {
    const isSystemTab = CHAT_SYSTEM_TABS.includes(tab);
    const isPublicTab = groups.some(
      (group) => group.id == tab && group.kind == "public"
    );
    return !isSystemTab && !isPublicTab;
  }, [tab]);

  return (
    <StyledAppBar position="static" elevation={0}>
      <Toolbar variant="dense" disableGutters>
        <Tabs
          variant="scrollable"
          scrollButtons
          value={tab}
          onChange={onChangeTab}
          textColor={isPrivateTab ? "secondary" : "inherit"}
          allowScrollButtonsMobile
        >
          <StyledTab
            label={
              <Badge
                // variant="dot"
                color="secondary"
                badgeContent={newMessageCounts["main"]}
                invisible={!newMessageCounts["main"]}
              >
                {t("メイン")}
              </Badge>
            }
            value="main"
          />
          <StyledTab
            label={
              <Badge
                variant="dot"
                color="secondary"
                invisible={!newMessageCounts["info"]}
              >
                {t("情報")}
              </Badge>
            }
            value="info"
          />
          <StyledTab
            label={
              <Badge
                variant="dot"
                color="secondary"
                invisible={!newMessageCounts["other"]}
              >
                {t("雑談")}
              </Badge>
            }
            value="other"
          />
          {directMessageChannels
            .filter((dm) => {
              return !!messageCounts[dm.key] || dm.key === tab;
            })
            .map((dm) => (
              <StyledTab
                key={dm.key}
                label={
                  <Badge
                    variant="dot"
                    color="secondary"
                    invisible={!newMessageCounts[dm.key]}
                  >
                    {dm.name || t("匿名さん")}
                  </Badge>
                }
                value={dm.key}
              />
            ))}
          {groups.map((group) => (
            <StyledTab
              key={group.id}
              label={
                <Badge
                  variant="dot"
                  color="secondary"
                  invisible={!newMessageCounts[group.id]}
                >
                  <Box display="flex" alignItems="center">
                    {group.kind === "private" && (
                      <LockIcon sx={{ fontSize: "14px", marginRight: "4px" }} />
                    )}
                    {group.name}
                  </Box>
                </Badge>
              }
              value={group.id}
            />
          ))}
          <StyledTab
            label={<AddIcon fontSize="small" />}
            value={null}
            disabled={!hasEditableRole}
          />
        </Tabs>
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  background: #212121;
`;

const StyledTab = styled(Tab)`
  min-width: 48px;

  &.MuiTab-textColorSecondary.Mui-selected {
    color: ${theme.palette.secondary.main};
  }
`;

export default memo(ChatBoxHeader);
