import { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import styled from "styled-components";
import sendEvent from "modules/sendEvent";

const MediumList = () => {
  const tab = useSelector((state) => {
    return store.getAppState(state, "userMediumListOfficialPlaylistTab");
  });
  const playlists = useSelector((state) => {
    return store.getAppState(state, "officialPlaylists");
  });
  const isPro = useSelector((state) => {
    return store.getAppState(state, "plan") === "ccfolia-pro";
  });

  const playlist = playlists.find((playlist) => playlist.id === tab);

  if (playlist == null) {
    return null;
  }

  const disabled = !playlist.free && !isPro;

  return (
    <Container>
      <List component="div">
        {playlist.sounds.map((sound) => (
          <div key={sound.id}>
            <MediumListItem
              id={sound.id}
              name={sound.name}
              provider={sound.provider}
              url={sound.url}
              volume={sound.volume}
              repeat={sound.repeat}
              disabled={disabled}
            />
          </div>
        ))}
      </List>
    </Container>
  );
};

const MediumListItem = (props: {
  id: string;
  name: string;
  provider: string;
  url: string;
  volume: number;
  repeat: boolean;
  disabled?: boolean;
}) => {
  const dispatch = useDispatch();
  const dir = useSelector((state) =>
    store.getAppState(state, "openRoomMediaDir")
  );
  const officialPlaylistVolume = useSelector((state) =>
    store.getAppState(state, "officialPlaylistVolume")
  );

  const onPlay = useCallback(() => {
    if (props.url) {
      sendEvent("clickOfficialSound", {
        event_category: props.name,
        event_label: props.id,
      });
      if (dir === "bgm") {
        dispatch(
          store.updateCurrentRoom({
            mediaUrl: props.url,
            mediaType: "file",
            mediaName: props.name,
            mediaVolume: props.volume * officialPlaylistVolume,
            mediaRepeat: props.repeat,
          })
        );
      } else if (dir === "sound") {
        dispatch(
          store.updateCurrentRoom({
            soundUrl: props.url,
            soundName: props.name,
            soundVolume: props.volume * officialPlaylistVolume,
            soundRepeat: props.repeat,
          })
        );
      } else if (dir === "effect") {
        dispatch(
          store.updateCurrentRoomEffect({
            soundUrl: props.url,
            soundName: props.name,
            soundVolume: props.volume * officialPlaylistVolume,
          })
        );
      }
    }
  }, [
    dispatch,
    props.id,
    props.url,
    props.name,
    props.repeat,
    props.volume,
    dir,
    officialPlaylistVolume,
  ]);

  return (
    <ListItem button onClick={onPlay} component="div" disabled={props.disabled}>
      <ListItemAvatar>
        <Avatar>
          <Avatar>
            <LibraryMusicIcon />
          </Avatar>
        </Avatar>
      </ListItemAvatar>
      <ListItemTextWrapped primary={props.name} secondary={props.provider} />
    </ListItem>
  );
};

const ListItemTextWrapped = styled(ListItemText)`
  word-wrap: break-word;
`;

const Container = styled.div`
  padding: 0;
  paddingbottom: 96;
  flex: 1;
  overflow: auto;
`;

export default memo(MediumList);
