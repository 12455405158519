import React, { useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import store from "stores/interfaces";

const ErrorSnackbar = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const errorMessage = useSelector((state) =>
    store.getAppState(state, "errorSnackbarMessage")
  );
  const prevCode = useRef<string>("");
  if (errorMessage != null) {
    prevCode.current = errorMessage;
  }

  const closeSnackbar = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => (state.errorSnackbarMessage = null))
    );
  }, [dispatch]);

  const message = errorMessage || prevCode.current;

  return (
    <Snackbar
      key={message}
      open={errorMessage != null}
      autoHideDuration={2000}
      message={t(message)}
      onClose={closeSnackbar}
      action={
        <IconButton color="secondary" size="small" onClick={closeSnackbar}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export default ErrorSnackbar;
