import types from "./types";
import { createActions } from "../firestoreModuleUtils";
import { Message } from "./records";

const actions = {
  ...createActions<Message>()(types),
  add: (id: string, data: Message, reversed = false) => {
    return {
      type: types.add,
      id,
      data,
      reversed,
    };
  },
  shiftAddedRoomMessage: () => {
    return { type: types.SHIFT };
  },
};

export default actions;
