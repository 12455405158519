import { memo } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Control, Controller, FieldArrayWithId } from "react-hook-form";
import { getRoomMemberById } from "stores/modules/entities.room.members/selectors";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import styled from "styled-components";

type FormData = {
  name: string;
  kind: "public" | "private";
  members: { id: string; checked: boolean }[];
};

type CheckboxMemberProps = {
  memberId: string;
  field: FieldArrayWithId<FormData, "members", "id">;
  index: number;
  disabled: boolean;
  control: Control<FormData>;
};

const ChatTabCheckboxMember = ({
  memberId,
  field,
  index,
  disabled,
  control,
}: CheckboxMemberProps) => {
  const [t] = useTranslation();
  const member = useSelector((state) => getRoomMemberById(state, memberId));
  const authedUid = useSelector(getAuthedUid);

  const isMe = memberId === authedUid;

  if (member == null || member.isAnonymous) {
    return null;
  }

  return (
    <ListItemButton
      dense
      sx={{ padding: "2px 4px" }}
      disabled={disabled || isMe}
    >
      <FormControlLabel
        key={field.id}
        disabled={disabled || isMe}
        sx={{
          width: "100%",
          "& > .MuiFormControlLabel-label": {
            maxWidth: "100%",
            flexGrow: 1,
          },
        }}
        control={
          <Controller
            name={`members.${index}.checked`}
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                checked={value}
                name={name}
                inputRef={ref}
              />
            )}
          />
        }
        label={
          <EllipsisBox>
            <Avatar alt="" src={member.photoUrl || "/ccfolia.png"} />
            <EllipsisTypography>
              {member.displayName || t("匿名ユーザー")}
            </EllipsisTypography>
          </EllipsisBox>
        }
      />
    </ListItemButton>
  );
};

const EllipsisBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "8px 0",
  userSelect: "none",
  width: "100%",
  overflow: "hidden",
});

const EllipsisTypography = styled(Typography)({
  marginLeft: "8px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "10px",
  flexGrow: 1,
});

export default memo(ChatTabCheckboxMember);
