import { useState } from "react";
import { FieldHookConfig, useField } from "formik";

export default function useFastField<Val>(
  props: string | FieldHookConfig<Val>
) {
  const [field, meta] = useField(props);
  const [value, setValue] = useState(field.value);
  const { onBlur, onChange } = field;

  field.value = value;
  field.onChange = (e) => {
    if (e && e.currentTarget) {
      setValue(e.currentTarget.value);
    }
  };
  field.onBlur = (e) => {
    onChange(e);
    onBlur(e);
  };

  return [field, meta] as const;
}
