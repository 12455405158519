import { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import store from "stores/interfaces";
import { Divider, Menu, MenuItem, Typography, styled } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";

const DeckMenu = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [open, top, left, deckId] = useSelector((state) => {
    return [
      store.getAppState(state, "openRoomDeckMenu"),
      store.getAppState(state, "roomPointerY"),
      store.getAppState(state, "roomPointerX"),
      store.getAppState(state, "openRoomDeckMenuId"),
    ] as const;
  }, shallowEqual);

  const refDraeMenuAnchor = useRef<HTMLLIElement>(null);
  const [drawMenuAnchorEl, setDrawMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openDrawMenu = drawMenuAnchorEl != null;
  const onOpenDrawMenu = useCallback(() => {
    if (open) {
      setDrawMenuAnchorEl(refDraeMenuAnchor.current);
    }
  }, [open, refDraeMenuAnchor, setDrawMenuAnchorEl]);
  const onCloseDrawMenu = useCallback(() => {
    setDrawMenuAnchorEl(null);
  }, [setDrawMenuAnchorEl]);

  const belowDeckId = useSelector((state) =>
    store.getRoomNearDeckId(state, deckId || "")
  );
  const onClose = useCallback(() => {
    onCloseDrawMenu();
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDeckMenu = false;
      })
    );
  }, [dispatch, onCloseDrawMenu]);
  const onEditDeck = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDeckDetail = true;
        state.openRoomDeckDetailId = deckId;
      })
    );
    onClose();
  }, [deckId, onClose, dispatch]);
  const onDraw = useCallback(
    (amount: number) => {
      if (deckId == null) return;
      dispatch(store.addRandomRoomItemFromRoomDeck(deckId, amount, false));
      onClose();
    },
    [deckId, onClose, dispatch]
  );
  const onDownDraw = useCallback(() => {
    if (deckId == null) return;
    dispatch(store.addRandomRoomItemFromRoomDeck(deckId, 1, true));
    onClose();
  }, [deckId, onClose, dispatch]);
  const onReset = useCallback(() => {
    if (deckId == null) return;
    dispatch(store.addRoomDeckItemsById(deckId));
    onClose();
  }, [deckId, onClose, dispatch]);
  const onMerge = useCallback(() => {
    if (belowDeckId == null || deckId == null) return;
    dispatch(store.mergeRoomDecks({ src: deckId, dest: belowDeckId }));
    onClose();
  }, [deckId, belowDeckId, onClose, dispatch]);
  const onDelete = useCallback(
    (e) => {
      if (deckId == null) return;
      e.stopPropagation();
      if (window.confirm(t("本当に削除しますか？"))) {
        dispatch(store.deleteRoomDeck(deckId));
      }
      onClose();
    },
    [deckId, onClose, dispatch, t]
  );

  return (
    <>
      <Menu
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ left, top }}
        onClose={onClose}
        MenuListProps={{ dense: true }}
        className={"user-select-none"}
      >
        <MenuItem onClick={onEditDeck}>{t("編集")}</MenuItem>
        <MenuItem onClick={() => onDraw(1)}>{t("1枚引く")}</MenuItem>
        <MenuItem
          ref={refDraeMenuAnchor}
          onClick={onOpenDrawMenu}
          onMouseOver={onOpenDrawMenu}
          onMouseLeave={onCloseDrawMenu}
        >
          {t("複数枚引く")}
          <MenuItemNavIcon>
            <ArrowRightIcon fontSize="small" />
          </MenuItemNavIcon>
        </MenuItem>
        <MenuItem onClick={onDownDraw}>{t("裏向きのまま1枚引く")}</MenuItem>
        <MenuItem onClick={onReset}>{t("全て集める")}</MenuItem>
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        <MenuItem disabled={!belowDeckId} onClick={onMerge}>
          {t("山札を合わせる")}
        </MenuItem>
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        <MenuItem onClick={onDelete}>{t("削除")}</MenuItem>
      </Menu>
      <Menu
        open={openDrawMenu}
        anchorEl={drawMenuAnchorEl}
        onClose={onCloseDrawMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{ dense: true, sx: { pointerEvents: "auto" } }}
        hideBackdrop
        sx={{ pointerEvents: "none" }}
        onMouseEnter={onOpenDrawMenu}
        onMouseLeave={onCloseDrawMenu}
      >
        {Array.from({ length: 10 }, (_, index) => (
          <MenuItem onClick={() => onDraw(index + 1)}>{index + 1} 枚</MenuItem>
        ))}
      </Menu>
    </>
  );
};

const MenuItemNavIcon = styled(Typography)`
  display: inline-flex;
  align-self: center;
  justify-content: end;
  flex: 1;
  width: 100%;
  margin-right: -8px;
`;

export default DeckMenu;
