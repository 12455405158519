import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import store from "stores/interfaces";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import FileListItem, { FileListItemEmpty } from "../FileListItem";
import FileListItemMenu from "../FileListItemMenu";
import { UserFile } from "stores/modules/entities.user.files";

const range = 100;
const imageInView = (img: HTMLImageElement) => {
  const rect = img.getBoundingClientRect();
  const isInView =
    -range < rect.bottom && rect.top < window.innerHeight + range;
  const src = img.dataset["src"];
  const isLoaded = isInView && !!src;
  if (isLoaded) {
    img.src = src;
  }
  return isLoaded;
};

type FileListProps = {
  onSelect: (file: string) => void;
  dir: UserFile["dir"] | null;
};

const FileList = ({ onSelect, dir }: FileListProps) => {
  const [t] = useTranslation();
  const assetIds = useSelector(
    (state) => (dir ? store.getFilteredUserFileIdsByDir(state, dir) : []),
    shallowEqual
  );
  const containerRef = React.useRef<HTMLDivElement>(null);
  const imagesRef = React.useRef<HTMLImageElement[]>([]);
  const timerRef = React.useRef<number | null>(null);
  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [dir, containerRef]);
  const onScroll = React.useCallback(() => {
    if (timerRef.current) return;
    timerRef.current = window.setTimeout(() => {
      imagesRef.current = imagesRef.current.filter((img) => {
        return !imageInView(img);
      });
      timerRef.current = null;
    }, 200);
  }, [imagesRef]);
  const onMounted = React.useCallback(
    (img: HTMLImageElement) => {
      if (!imageInView(img)) {
        imagesRef.current.push(img);
      }
    },
    [imagesRef]
  );
  const onUnMounted = React.useCallback(
    (img) => {
      const i = imagesRef.current.indexOf(img);
      i > -1 && imagesRef.current.splice(i, 1);
    },
    [imagesRef]
  );
  return (
    <Tiles ref={containerRef} onScroll={onScroll}>
      <FileListItemEmpty onSelect={onSelect} />
      {assetIds.map((assetId, i) => (
        <FileListItem
          onMounted={onMounted}
          onUnMounted={onUnMounted}
          key={assetId}
          assetId={assetId}
          onSelect={onSelect}
        />
      ))}
      {assetIds.length < 1 ? (
        <EmptyText variant="body2">
          {t("ドラッグアンドドロップでファイルを追加することができます。")}
        </EmptyText>
      ) : null}
      <FileListItemMenu />
    </Tiles>
  );
};

const EmptyText = styled(Typography)`
  &.MuiTypography-root {
    padding: 16px;
  }
`;

const Tiles = styled.div`
  padding: 1%;
  height: 640px;
  // background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar: {
    display: none;
  }
`;

export default memo(FileList);
