import types from "./types";
import { combineReducers } from "redux";
import {
  createByIdReducer,
  createIndexReducer,
  // createGroupByReducer
} from "../firestoreModuleUtils";
import { Scene } from "./records";

const byId = createByIdReducer<Scene>()(types);
const allIds = createIndexReducer(types);

const sceneReducer = combineReducers({
  byId,
  allIds,
});

export default sceneReducer;
