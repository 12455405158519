import { MouseEvent, TouchEvent, memo, useCallback, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import store from "stores/interfaces";
import { DiceItem } from "stores/modules/entities.room.dices";
import { Rnd } from "react-rnd";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFieldRnd from "hooks/fieldRnd";
import useLongTap, { LongTap } from "hooks/longTap";
import { getAppState } from "stores/modules/app.state/selectors";
import { getRoomDiceById } from "stores/modules/entities.room.dices/selectors";
// import { getCurrentRoomIsMonitored } from "stores/modules/entities.rooms/selectors";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

const stopPropagation = (e: MouseEvent) => e.stopPropagation();

type DiceSymbolProps = {
  diceId: string;
};

const DiceSymbol = (props: DiceSymbolProps) => {
  const { diceId } = props;
  const dispatch = useDispatch();
  const dragging = useRef(false);

  const cellSize = useSelector((state) =>
    getAppState(state, "roomScreenCellSize")
  );
  const dice = useSelector((state) => getRoomDiceById(state, diceId));
  // const monitored = useSelector(getCurrentRoomIsMonitored);
  const isRoleAudience = useSelector(getIsRoleAudience);

  const onDrag = useCallback(() => {
    if (isRoleAudience) {
      return;
    }

    dragging.current = true;
  }, [dragging, isRoleAudience]);
  const onDragStart = useCallback(() => {
    if (isRoleAudience) {
      return;
    }

    dragging.current = false;
  }, [dragging, isRoleAudience]);
  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomDiceDetail = true;
          state.openRoomDiceDetailId = diceId;
          state.roomPointerY = e.pageY || e.touches[0]?.screenY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.screenX || 0;
        })
      );
    },
    [diceId, isRoleAudience, dispatch]
  );
  const onUpdate = useCallback(
    (dice) => {
      dispatch(store.updateRoomDice(diceId, dice));
    },
    [diceId, dispatch]
  );
  const longTapProps = useLongTap(onContextMenu);
  const rndProps = useFieldRnd(
    {
      x: dice?.x,
      y: dice?.y,
      width: dice?.width,
      height: dice?.height,
    },
    onUpdate
  );

  return (
    <__Dice
      dice={dice}
      // monitored={monitored}
      monitored={false}
      rndProps={rndProps}
      longTapProps={longTapProps}
      cellSize={cellSize}
      disableDragging={isRoleAudience}
      onContextMenu={onContextMenu}
      onDragStart={onDragStart}
      onDrag={onDrag}
    />
  );
};

type _DiceProps = {
  cellSize: number;
  dice: DiceItem;
  monitored: boolean;
  longTapProps: LongTap;
  rndProps: any;
  disableDragging: boolean;
  onContextMenu: (e: MouseEvent | TouchEvent) => void;
  onDragStart: (e: MouseEvent | TouchEvent) => void;
  onDrag: () => void;
};
const diceTypes = [0, 4, 6, 8, 10, 12, 20];
const enableProps = {
  right: true,
  bottom: true,
  bottomRight: true,
};
const _Dice = (props: _DiceProps) => {
  const {
    cellSize,
    dice,
    monitored,
    longTapProps,
    rndProps,
    disableDragging,
    onContextMenu,
    onDragStart,
    onDrag,
  } = props;
  const [t] = useTranslation();
  if (diceTypes.indexOf(~~dice.faces) < 0) return <div />;
  return (
    <Rnd
      {...rndProps}
      onContextMenu={onContextMenu}
      onDragStart={onDragStart}
      onDrag={onDrag}
      disableDragging={disableDragging}
      enableResizing={disableDragging ? {} : enableProps}
      onMouseDown={disableDragging ? undefined : stopPropagation}
      resizeGrid={[cellSize, cellSize]}
      minWidth={cellSize}
      minHeight={cellSize}
      lockAspectRatio={true}
      style={{
        zIndex: 10000,
      }}
    >
      {!dice.closed || monitored ? (
        <Tooltip
          title={`${dice.name}${t("のダイス")} [${dice.value}]`}
          placement="bottom"
        >
          <DiceContainer {...longTapProps} onDoubleClick={stopPropagation}>
            <img
              src={`/images/${dice.faces}_dice/${dice.faces}_dice[${dice.value}].png`}
              draggable={false}
              width="100%"
              height="100%"
            />
            {dice.closed && monitored && (
              <BadgeClosed src="/images/unknown.png" draggable={false} />
            )}
          </DiceContainer>
        </Tooltip>
      ) : (
        <Tooltip title={`${dice.name}${t("のダイス")}`} placement="bottom">
          <DiceContainer {...longTapProps} onDoubleClick={stopPropagation}>
            <img
              src="/images/unknown.png"
              draggable={false}
              width="100%"
              height="100%"
            />
          </DiceContainer>
        </Tooltip>
      )}
    </Rnd>
  );
};

const __Dice = memo(_Dice);

const DiceContainer = styled.div`
  width: 100%;
  height: 100%;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  img {
    pointer-events: none;
  }
`;

const BadgeClosed = styled.img`
  width: 40%;
  height: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default memo(DiceSymbol);
