import React, { memo, ReactNode } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import store from "stores/interfaces";
import styled from "styled-components";
import theme from "theme";
import { useDropzone } from "react-dropzone";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { useAppSelector } from "stores";

type FrameProps = {
  children: ReactNode;
};

const Frame = ({ children }: FrameProps) => {
  const d = useDispatch();
  const [openRight, openLeft, width] = useSelector((state) => {
    return [
      !!store.getAppState(state, "openRoomDisplays"), // todo
      !!store.getAppState(state, "openRoomControls"),
      store.getMenuWidth(state),
    ] as const;
  }, shallowEqual);
  const pdfOpen = useAppSelector((state) => state.app.state.openPDFViewer);
  const pdfWidth = useAppSelector(
    (state) => state.app.state.openPDFViewerWidth
  );
  const isRoleAudience = useSelector(getIsRoleAudience);

  const onSelectBackground = React.useCallback(() => {
    if (isRoleAudience) {
      return;
    }

    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "background";
        state.openRoomImageSelectTarget = "background/set";
      })
    );
  }, [d, isRoleAudience]);
  const onPaste = React.useCallback(
    (e) => {
      e.preventDefault();
      if (isRoleAudience) {
        return;
      }

      const text = e.clipboardData.getData("text/plain");
      d(store.importRoomCharacterFromClipboardText(text));
    },
    [d, isRoleAudience]
  );
  const onDrop = React.useCallback(
    (files) => {
      const images = files.filter((file) => /^image\/.+/.test(file.type));
      const zip = files.find((file) => /^application\/.+/.test(file.type));
      d(store.addRoomItemsByImagesDrop(images));
      if (zip) d(store.importRoomDataFromZipFile(zip));
    },
    [d]
  );
  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    accept: ["image/*", "application/*"],
    disabled: isRoleAudience,
  });

  return (
    <StyledFrame
      style={{ right: pdfOpen ? pdfWidth : openRight || openLeft ? width : 0 }}
      onDoubleClick={onSelectBackground}
      onPaste={onPaste}
      {...dropzone.getRootProps()}
    >
      {children}
    </StyledFrame>
  );
};

const StyledFrame = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
  ${theme.breakpoints.down("sm")} {
    left: 0;
    right: 0;
  }
`;

export default memo(Frame);
