import types from "./types";
import { produce, setAutoFreeze } from "immer";
import { providers } from "initializer";
import { Actions } from "../actions";
import { UserInfo } from "@firebase/auth-types";

export interface AppUser {
  uid: string | null;
  isAnonymous: boolean | null;
  displayName: string | null;
  photoUrl: string | null;
  email: string | null;
  emailVerified: boolean;
  providerData: Record<keyof typeof providers, UserInfo | null>;
  plan: "free" | "ccfolia-pro";
}

setAutoFreeze(false);

const initialState: AppUser = {
  uid: null,
  isAnonymous: null,
  displayName: null,
  photoUrl: null,
  email: null,
  emailVerified: false,
  providerData: { email: null, google: null, twitter: null },
  plan: "free",
};

// Reducers

const appUserReducer = (
  state: AppUser = initialState,
  action: Actions
): AppUser => {
  switch (action.type) {
    case types.APP_USER_MUTATE: {
      return produce(state, (draft) => {
        action.mutate(draft);
        return draft;
      });
    }
    default: {
      return state;
    }
  }
};

export default appUserReducer;
