import { restrictToNumber, restrictToString } from "modules/restrict";

export type Note = {
  _id?: string;
  name: string;
  text: string;
  iconUrl: string;
  owner: string;
  order: number;
  createdAt: number;
  updatedAt: number;
};
export type UpdateNote = {
  _id?: string;
  name?: string;
  text?: string;
  iconUrl?: string;
  owner?: string;
  order?: number;
  createdAt?: number;
  updatedAt?: number;
};

export const NoteRecord = (note: UpdateNote): Note => {
  return {
    name: restrictToString(note.name, ""),
    text: restrictToString(note.text, ""),
    iconUrl: restrictToString(note.iconUrl, ""),
    owner: restrictToString(note.owner, ""),
    order: restrictToNumber(note.order, 0),
    createdAt: restrictToNumber(note.createdAt, Date.now()),
    updatedAt: restrictToNumber(note.updatedAt, Date.now()),
  };
};
