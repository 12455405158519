import { produce, castDraft } from "immer";
import { enableBatching } from "redux-batched-actions";
import { Action, ActionTypes } from "./types";

type ByIdState<T = any> = {
  [id: string]: T;
};
const initialByIdState: ByIdState = {};

type IndexState = string[];
const initialIndexState: IndexState = [];

type GroupByState = {
  [id: string]: string[];
};
const initialGroupByState: GroupByState = {};

export const createByIdReducer =
  <T extends Record<string, unknown>>() =>
  <Name extends string>(types: ActionTypes<Name>) => {
    return enableBatching(
      (state: ByIdState<T> = initialByIdState, _action: Action<T, Name>) => {
        switch (_action.type) {
          case types.init:
            return {};
          case types.add: {
            const action = _action as Action<T, Name, "add">; // TODO: fix type
            return produce(state, (draft) => {
              draft[action.id] = castDraft(action.data);
            });
          }
          case types.update: {
            const action = _action as Action<T, Name, "update">; // TODO: fix type
            return produce(state, (draft) => {
              draft[action.id] = castDraft(action.data);
            });
          }
          case types.remove: {
            const action = _action as Action<T, Name, "remove">; // TODO: fix type
            return produce(state, (draft) => {
              delete draft[action.id];
            });
          }
          default:
            return state;
        }
      }
    );
  };

export const createIndexReducer = <Name extends string>(
  types: ActionTypes<Name>
) => {
  return enableBatching(
    (state: IndexState = initialIndexState, _action: Action<string, Name>) => {
      switch (_action.type) {
        case types.init:
          return [];
        case types.add: {
          const action = _action as Action<string, Name, "add">; // TODO: fix type
          if (state.includes(action.id)) {
            return state;
          } else {
            return produce(state, (draft) => {
              draft.push(action.id);
            });
          }
        }
        case types.update: {
          const action = _action as Action<string, Name, "update">; // TODO: fix type
          if (state.includes(action.id)) {
            return state;
          } else {
            return produce(state, (draft) => {
              draft.push(action.id);
            });
          }
        }
        case types.remove: {
          const action = _action as Action<string, Name, "remove">; // TODO: fix type
          const index = state.indexOf(action.id);
          return produce(state, (draft) => {
            draft.splice(index, 1);
          });
        }
        case types.reorder: {
          const action = _action as Action<string, Name, "reorder">; // TODO: fix type
          return produce(state, (draft) => {
            const [removed] = draft.splice(action.startIndex, 1);
            draft.splice(action.endIndex, 0, removed);
            return draft;
          });
        }
        case types.sort: {
          const action = _action as Action<string, Name, "sort">; // TODO: fix type
          if (action.ids.length === state.length) {
            return action.ids;
          } else {
            return state;
          }
        }
        default:
          return state;
      }
    }
  );
};

export const createGroupByString = (
  field: string | string[],
  data: Record<string, unknown>,
  // defaultValue
  defaultValue = null
) => {
  if (typeof field === "string" && data[field] !== undefined) {
    return "" + data[field];
  } else if (Array.isArray(field)) {
    return field
      .reduce((current, target, index) => {
        current[index] = data[target];
        return current;
      }, [] as unknown[])
      .join(".");
  } else {
    return defaultValue;
  }
};

export const createGroupByReducer =
  <T extends Record<string, unknown>>() =>
  <Name extends string>(
    types: ActionTypes<Name>,
    field: string,
    defaultValue = null
  ) => {
    return enableBatching(
      (state: GroupByState = initialGroupByState, _action: Action<T, Name>) => {
        switch (_action.type) {
          case types.init:
            return {};
          case types.add: {
            const action = _action as Action<T, Name, "add">; // TODO: fix type
            const groupBy = createGroupByString(
              field,
              action.data,
              defaultValue
            );
            if (!groupBy) return state;

            const isExists =
              state[groupBy] && state[groupBy].includes(action.id);
            if (isExists) return state;

            return produce(state, (draft) => {
              if (!draft[groupBy]) {
                draft[groupBy] = [];
              }
              draft[groupBy].push(action.id);
            });
          }
          case types.update: {
            const action = _action as Action<T, Name, "update">; // TODO: fix type
            const groupBy = createGroupByString(
              field,
              action.data,
              defaultValue
            );
            if (!groupBy) return state;

            const isExists =
              state[groupBy] && state[groupBy].includes(action.id);
            if (isExists) return state;

            return produce(state, (draft) => {
              Object.keys(state).forEach((key) => {
                const index = state[key].indexOf(action.id);
                if (index > -1) {
                  draft[key].splice(index, 1);
                }
              });
              if (!draft[groupBy]) {
                draft[groupBy] = [];
              }
              draft[groupBy].push(action.id);
            });
          }
          case types.remove: {
            const action = _action as Action<T, Name, "remove">; // TODO: fix type
            return produce(state, (draft) => {
              Object.keys(state).forEach((key) => {
                const index = state[key].indexOf(action.id);
                if (index > -1) {
                  draft[key].splice(index, 1);
                }
              });
            });
          }
          case types.groupRemove: {
            const action = _action as Action<T, Name, "groupRemove">; // TODO: fix type
            return produce(state, (draft) => {
              Object.keys(state).forEach((key) => {
                const index = state[key].indexOf(action.id);
                if (index > -1) {
                  draft[key].splice(index, 1);
                }
              });
            });
          }
          case types.groupReorder: {
            const action = _action as Action<T, Name, "groupReorder">; // TODO: fix type
            if (field !== action.field) return state;
            return produce(state, (draft) => {
              if (
                draft[action.key][action.startIndex] &&
                draft[action.key][action.endIndex]
              ) {
                const [removed] = draft[action.key].splice(
                  action.startIndex,
                  1
                );
                draft[action.key].splice(action.endIndex, 0, removed);
              }
              return draft;
            });
          }
          default:
            return state;
        }
      }
    );
  };
