import React from "react";
import { Slider, SliderProps } from "@mui/material";
import { FieldProps } from "formik";

type FormikMuiSliderProps = Omit<SliderProps, "onChange"> &
  Omit<FieldProps, "meta"> & {
    sliderProps: SliderProps;
    onChange: (event: Event, value: number | number[]) => void;
  };

const FormikMuiSlider = ({
  field,
  form,
  sliderProps,
  ...props
}: FormikMuiSliderProps) => {
  const onChange = React.useCallback(
    (_, value) => {
      form.setFieldValue(field.name, value);
    },
    [field.name, form.setFieldValue]
  );
  return (
    <Slider
      onChangeCommitted={onChange}
      defaultValue={field.value}
      {...sliderProps}
      {...props}
    />
  );
};

export default FormikMuiSlider;
