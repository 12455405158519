import React from "react";
import { useSelector } from "react-redux";
import store from "stores/interfaces";
import Item from "containers/Item";

const Panels: React.FC = () => {
  const itemIds = useSelector(store.getActiveRoomItemIds);
  return (
    <>
      {itemIds.map((itemId) => (
        <Item key={itemId} itemId={itemId} />
      ))}
    </>
  );
};

export default React.memo(Panels);
