import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  Toolbar,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAppState } from "stores/modules/app.state/selectors";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getRoomSavedataById } from "stores/modules/entities.room.savedatas/selectors";
import styled from "styled-components";
import { loadRoom } from "stores/modules/entities.room.savedatas/operations";

const LoadConfirm = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) => {
    return getAppState(state, "openLoadConfirm");
  });

  const savedataId = useSelector((state) => {
    return getAppState(state, "openLoadConfirmId");
  });

  const savedata = useSelector((state) => {
    return getRoomSavedataById(state, savedataId);
  });

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openLoadConfirm = false;
      })
    );
  }, [dispatch]);

  const onLoad = useCallback(() => {
    if (savedata != null && savedataId != null) {
      dispatch(
        appStateMutate((state) => {
          state.openLoadConfirm = false;
          state.openLoadDialog = false;
          state.openRoomSettings = false;
        })
      );
      dispatch(loadRoom(savedataId));
    }
  }, [savedataId, savedata, dispatch, onClose]);

  return (
    <Dialog open={open && savedata != null} maxWidth="sm" fullWidth>
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Typography variant="subtitle1">
            {t("ルームをこの状態に戻しますか？")}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogBody>
        <StyledAvatar src={savedata?.thumbnail || undefined} />
        <Typography display="block">
          {savedata?.name || t("名前未設定")}
        </Typography>
      </DialogBody>
      <DialogActions>
        <Button fullWidth color="secondary" onClick={onClose}>
          {t("キャンセル")}
        </Button>
        <Button fullWidth color="primary" onClick={onLoad}>
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogBody = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 32px 24px 16px 24px;
`;

const StyledAvatar = styled(Avatar)`
  .MuiAvatar-fallback {
    visibility: hidden;
  }
`;

export default memo(LoadConfirm);
