import { createSelector } from "reselect";
import {
  getRoomMemberIds,
  getRoomMembers,
} from "../entities.room.members/selectors";
import { createDirectMessageChannelKey } from "./operations";
import version from "version";
import { DefaultRootState } from "react-redux";
import { AppState } from "./reducers";
import { getUid } from "../app.user/selectors";
import { AppUser } from "../app.user";
import { getJoinedMessageGroups } from "../entities.rooms/selectors";

// TODO: 一時的にgetAppUserの処理を統合、時期を見て分割
export const getAppState = <T extends keyof AppState | keyof AppUser>(
  state: DefaultRootState,
  name: T
): T extends keyof AppState
  ? AppState[T]
  : T extends keyof AppUser
  ? AppUser[T]
  : never => {
  if (name in state.app.state)
    return state.app.state[name as keyof AppState] as never;
  return state.app.user[name as keyof AppUser] as never;
};

export const getMenuWidth = (state: DefaultRootState) => {
  return state.app.state.roomChatWideMode ? "600px" : "375px";
};
export const getMenuWidthSize = (state: DefaultRootState) => {
  return state.app.state.roomChatWideMode ? 480 : 360;
};

export const getIsOwner = (state: DefaultRootState) => {
  const roomId = state.app.state.roomId;
  if (!roomId) return false;
  const room = state.entities.rooms.byId[roomId];
  const uid = getUid(state);
  if (!room) return false;
  return room.owner === uid;
};

export const getOpenInformation = (state: DefaultRootState) => {
  return (
    (state.app.state.informationVersion !== version ||
      state.app.state.launchedTime > state.app.state.informationDate) &&
    !(
      state.entities.userSetting.skipInfomation &&
      state.app.user.plan === "ccfolia-pro"
    )
  );
};

export const getNotifications = (state: DefaultRootState): string[] => {
  return state.app.state.notifications;
};

export const getRoomMemberDirectMessageTabs = createSelector(
  [getUid, getRoomMemberIds, getRoomMembers],
  (uid, memberIds, members) => {
    if (uid === null) return [];
    return memberIds.map((memberId) => {
      const member = members[memberId];
      const key = createDirectMessageChannelKey([uid, memberId]);
      const name =
        member.displayName.length > 5
          ? member.displayName.slice(0, 4) + ".."
          : member.displayName;
      return {
        key,
        name,
      };
    });
  }
);

export const CHAT_SYSTEM_TABS = ["main", "info", "other"];

export const getRoomChatMessageCounts = (state: DefaultRootState) =>
  getAppState(state, "roomChatMessageCounts");

export const getCalclatedMessageCount = createSelector(
  [
    getJoinedMessageGroups,
    getRoomMemberDirectMessageTabs,
    getRoomChatMessageCounts,
  ],
  (groups, dms, counts) => {
    const count = Object.keys(counts).reduce((acc, key) => {
      if (
        CHAT_SYSTEM_TABS.includes(key) ||
        groups.some((group) => group.id === key) ||
        dms.some((dm) => dm.key === key)
      ) {
        return acc + counts[key];
      }
      return acc;
    }, 0);
    return count;
  }
);
