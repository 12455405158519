import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingSubmitButton from "containers/Login/LoadingSubmitButton";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import { auth, providers } from "initializer";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GoogleLoginButton from "containers/LoginButton/GoogleLoginButton";
import XLoginButton from "containers/LoginButton/XLoginButton";
import store from "stores/interfaces";
import styled from "styled-components";

type ReauthenticateDialogProps = {
  open: boolean;
  onCancelled: () => void;
  onReauthenticated: () => void;
};

const ReauthenticateDialog = ({
  open,
  onCancelled,
  onReauthenticated,
}: ReauthenticateDialogProps) => {
  const [t] = useTranslation();
  const providerData = useSelector((state) =>
    store.getAppState(state, "providerData")
  );
  const { errorMessage, onError } = useFirebaseAuthError();

  const onClickTwitter = useCallback(() => {
    if (auth.currentUser) {
      reauthenticateWithPopup(auth.currentUser, providers.twitter)
        .then(onReauthenticated)
        .catch(onError);
    }
  }, []);

  const onClickGoogle = useCallback(() => {
    if (auth.currentUser) {
      reauthenticateWithPopup(auth.currentUser, providers.google)
        .then(onReauthenticated)
        .catch(onError);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onCancelled} maxWidth="xs">
      <DialogTitle>
        {t("アカウントの再認証")}
        <IconButton
          onClick={onCancelled}
          style={{ position: "absolute", right: 6, top: 6 }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "8px" }}>
        <Typography color="textSecondary" variant="body2" paragraph>
          {t(
            "メールアドレスやパスワードを変更するには、アカウントの再認証が必要です。"
          )}
        </Typography>
        {errorMessage && (
          <Typography color="error" variant="body2" paragraph>
            {errorMessage}
          </Typography>
        )}
        {providerData.email && (
          <ReauthenticateWithPassword onReauthenticated={onReauthenticated} />
        )}
        {(providerData.twitter || providerData.google) && (
          <Typography variant="subtitle2" marginBottom="8px">
            {t("SNSアカウントで再認証する")}
          </Typography>
        )}
        {providerData.twitter && (
          <XLoginButton
            style={{ margin: 0, marginBottom: "8px" }}
            onClick={onClickTwitter}
          >
            {t("X（旧Twitter）で再認証")}
          </XLoginButton>
        )}
        {providerData.google && (
          <GoogleLoginButton
            style={{ margin: 0, marginBottom: "8px" }}
            onClick={onClickGoogle}
          >
            {t("Googleで再認証")}
          </GoogleLoginButton>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelled} fullWidth color="secondary">
          {t("キャンセル")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type FormParams = {
  password: string;
};

type ReauthenticateWithPasswordProps = {
  onReauthenticated: () => void;
};

const ReauthenticateWithPassword = ({
  onReauthenticated,
}: ReauthenticateWithPasswordProps) => {
  const [t] = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormParams>({
    defaultValues: { password: "" },
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => store.getAppUser(state, "email"));
  const { errorMessage, onError } = useFirebaseAuthError();

  const onSubmit = useCallback(
    ({ password }: FormParams) => {
      if (!auth.currentUser || !email) {
        return;
      }

      setLoading(true);
      reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(email, password)
      )
        .then(onReauthenticated)
        .catch(onError)
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, onReauthenticated]
  );

  return (
    <PasswordArea>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="subtitle2">
          {t("パスワードで再認証する")}
        </Typography>
        <TextField
          variant="standard"
          type="password"
          label={t("パスワード")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("password", { required: true, minLength: 6 })}
        />
        {errorMessage && (
          <Typography color="error" variant="body2" paragraph>
            {errorMessage}
          </Typography>
        )}
        <LoadingSubmitButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isValid}
          loading={loading}
        >
          {t("再認証")}
        </LoadingSubmitButton>
      </form>
    </PasswordArea>
  );
};

const useFirebaseAuthError = () => {
  const [t] = useTranslation();
  const [errorCode, setErrorCode] = useState<string>("");
  const onError = useCallback(
    (error: any) => {
      if (typeof error?.code === "string") {
        setErrorCode(error.code);
      } else {
        setErrorCode("unknown-error");
      }
    },
    [setErrorCode]
  );
  const errorMessage: string = useMemo(() => {
    if (!errorCode) {
      return "";
    }

    switch (errorCode) {
      case "auth/user-mismatch":
        return t("再認証に失敗しました。連携されていないアカウントです。");
      case "auth/wrong-password":
        return t("再認証に失敗しました。パスワードに誤りがあります。");
      case "auth/popup-blocked":
        return t(
          "認証用ポップアップがブロックされたため、再認証に失敗しました"
        );
      case "auth/cancelled-popup-request":
      case "auth/popup-closed-by-user":
        return "";
      default:
        return t("不明なエラーが発生しました。") + ` : ${errorCode}`;
    }
  }, [errorCode]);

  return { errorMessage, onError };
};

const PasswordArea = styled.div`
  margin: 32px auto;
  box-sizing: border-box;
`;

export default ReauthenticateDialog;
