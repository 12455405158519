import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Dialog,
  AppBar,
  DialogContent,
  Typography,
  Link,
  Divider,
  Button,
  IconButton,
  Avatar,
  Toolbar,
  TextField,
  Box,
  Container,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorIcon from "@mui/icons-material/Error";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "stores";
import {
  signInWithEmail,
  signInWithProvider,
  signUpWithEmail,
  signUpWithProvider,
} from "stores/modules/app.user/operations";
import GoogleLoginButton from "containers/LoginButton/GoogleLoginButton";
import XLoginButton from "containers/LoginButton/XLoginButton";
import theme from "theme";
import LoadingSubmitButton from "containers/Login/LoadingSubmitButton";

interface LoginProps {
  open: boolean;
  onClose: () => void;
  onBeforeSnsLogin: () => void;
}

const LoginDialog = ({ open, onClose, onBeforeSnsLogin }: LoginProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      fullScreen
      PaperComponent={StyledPaper}
    >
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" style={{ flex: 1 }}>
            Account
          </Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <ClearIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <LoginDialogBody onBeforeSnsLogin={onBeforeSnsLogin} />
    </Dialog>
  );
};

type LoginDialogBodyProps = {
  onBeforeSnsLogin: () => void;
};

const LoginDialogBody = ({ onBeforeSnsLogin }: LoginDialogBodyProps) => {
  const [mode, setMode] = useState<"login" | "signup" | null>(null);

  switch (mode) {
    case "login":
      return (
        <LoginContent
          changeMode={setMode}
          onBeforeSnsLogin={onBeforeSnsLogin}
        />
      );
    case "signup":
      return (
        <SignupContent
          changeMode={setMode}
          onBeforeSnsLogin={onBeforeSnsLogin}
        />
      );
    default:
      return <SwitchLogin onClick={setMode} />;
  }
};

type SwitchLoginProps = {
  onClick: (value: "login" | "signup") => void;
};

const SwitchLogin = ({ onClick }: SwitchLoginProps) => {
  const [t] = useTranslation();

  return (
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Avatar
        src="/ccfolia.png"
        style={{ width: 128, height: 128, margin: "16px auto" }}
      />
      <Container maxWidth="sm">
        <Typography paragraph variant="body1" margin="24px 0">
          {t(
            "ゲームの購入にはココフォリアのアカウントが必要です。ココフォリアのアカウントをお持ちですか？"
          )}
        </Typography>
        <Box
          display="flex"
          gap="16px"
          paddingBottom="160px"
          sx={{ flexDirection: { xs: "column", sm: "row" } }}
        >
          <Button variant="outlined" fullWidth onClick={() => onClick("login")}>
            <div>
              <Typography fontWeight="bold">
                {t("アカウントをお持ちの方")}
              </Typography>
              <Typography fontWeight="normal" fontSize="smaller">
                {t("ログイン")}
              </Typography>
            </div>
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => onClick("signup")}
          >
            <div>
              <Typography fontWeight="bold">
                {t("アカウントをお持ちでない方")}
              </Typography>
              <Typography fontWeight="normal" fontSize="smaller">
                {t("アカウントを作成")}
              </Typography>
            </div>
          </Button>
        </Box>
      </Container>
    </DialogContent>
  );
};

type LoginContentProps = {
  changeMode: (mode: "login" | "signup") => void;
  onBeforeSnsLogin: () => void;
};

const LoginContent = ({ changeMode, onBeforeSnsLogin }: LoginContentProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  return (
    <DialogContent>
      <Avatar
        src="/ccfolia.png"
        style={{ width: 128, height: 128, margin: "16px auto" }}
      />
      <Typography variant="h4" align="center" marginTop="24px" gutterBottom>
        {t("ログイン")}
      </Typography>
      <Container maxWidth="sm">
        <Box display="flex" alignItems="center" marginTop="32px">
          <ErrorIcon color="primary" />
          <Typography
            paragraph
            variant="body2"
            color="textSecondary"
            margin="0"
            marginLeft="8px"
          >
            <Trans>
              ゲームストアの商品の購入にはCCFOLIAのアカウント登録が必要です。アカウントをお持ちでない方は
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  changeMode("signup");
                }}
              >
                アカウントを作成
              </Link>
              してください。
            </Trans>
          </Typography>
        </Box>
        <Typography variant="subtitle2" marginTop="40px" marginBottom="8px">
          {t("SNS認証でログインする")}
        </Typography>
        <ButtonArea>
          <XLoginButton
            onClick={() => {
              onBeforeSnsLogin();
              dispatch(signInWithProvider("twitter"));
            }}
          >
            {t("Signin with X")}
          </XLoginButton>
          <GoogleLoginButton
            onClick={() => {
              onBeforeSnsLogin();
              dispatch(signInWithProvider("google"));
            }}
          >
            {t("Signin with Google")}
          </GoogleLoginButton>
        </ButtonArea>
        <Divider />
        <SigninFormWithPassword onBeforeLogin={onBeforeSnsLogin} />
      </Container>
    </DialogContent>
  );
};

type SignupContentProps = {
  changeMode: (mode: "login" | "signup") => void;
  onBeforeSnsLogin: () => void;
};

const SignupContent = ({
  changeMode,
  onBeforeSnsLogin,
}: SignupContentProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  return (
    <DialogContent>
      <Avatar
        src="/ccfolia.png"
        style={{ width: 128, height: 128, margin: "16px auto" }}
      />
      <Typography variant="h4" align="center" marginTop="24px" gutterBottom>
        {t("アカウント作成")}
      </Typography>
      <Container maxWidth="sm">
        <Box display="flex" alignItems="center" marginTop="32px">
          <ErrorIcon color="primary" />
          <Typography
            paragraph
            variant="body2"
            color="textSecondary"
            margin="0"
            marginLeft="8px"
          >
            <Trans>
              ゲームストアの商品の購入にはCCFOLIAのアカウント登録が必要です。アカウントをお持ちの方は
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  changeMode("login");
                }}
              >
                ログイン
              </Link>
              してください。
            </Trans>
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          fontSize="18px"
          marginTop="40px"
          marginBottom="8px"
        >
          {t("SNS認証でアカウントを新規作成する")}
        </Typography>
        <ButtonArea>
          <XLoginButton
            onClick={() => {
              onBeforeSnsLogin();
              dispatch(signUpWithProvider("twitter"));
            }}
          >
            {t("Link with X")}
          </XLoginButton>
          <GoogleLoginButton
            onClick={() => {
              onBeforeSnsLogin();
              dispatch(signUpWithProvider("google"));
            }}
          >
            {t("Link with Google")}
          </GoogleLoginButton>
        </ButtonArea>
        <Typography
          paragraph
          variant="body2"
          color="textSecondary"
          marginBottom="24px"
        >
          <Trans>
            ※ アカウントを作成すると、
            <Link href="/termsOfService.html" target="_blank" underline="hover">
              利用規約
            </Link>
            と
            <Link href="/privacyPolicy.html" target="_blank" underline="hover">
              プライバシーポリシー
            </Link>
            に同意したことになります。
          </Trans>
        </Typography>
        <Divider />
        <SignupFormWithPassword onBeforeLogin={onBeforeSnsLogin} />
      </Container>
    </DialogContent>
  );
};

const useFirebaseAuthError = () => {
  const [t] = useTranslation();
  const [errorCode, setErrorCode] = useState<string>("");
  const onError = useCallback(
    (error: any) => {
      if (typeof error?.code === "string") {
        setErrorCode(error.code);
      }
    },
    [setErrorCode]
  );
  const errorMessage: string = useMemo(() => {
    if (!errorCode) {
      return "";
    }

    switch (errorCode) {
      case "auth/email-already-in-use":
        return t("このメールアドレスはすでに使われています。");
      case "auth/invalid-email":
        return t("メールアドレスの形式に誤りがあります。");
      case "auth/wrong-password":
      case "auth/user-not-found":
      case "auth/invalid-login-credentials":
        return t(
          "ログインに失敗しました。メールアドレスかパスワードに誤りがあります。"
        );
      default:
        return t("不明なエラーが発生しました。") + ` : ${errorCode}`;
    }
  }, [errorCode, t]);

  return { errorMessage, onError };
};

type FormParams = {
  email: string;
  password: string;
};

type SignupFormParams = {
  email: string;
  password: string;
  agree: boolean;
};

type SignupFormWithPasswordProps = {
  onBeforeLogin: () => void;
};

const SignupFormWithPassword = ({
  onBeforeLogin,
}: SignupFormWithPasswordProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<SignupFormParams>({
    defaultValues: { email: "", password: "", agree: false },
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const { errorMessage, onError } = useFirebaseAuthError();

  const onSubmit = useCallback(
    ({ email, password }: SignupFormParams) => {
      setLoading(true);
      dispatch(signUpWithEmail(email, password))
        .then(() => {
          onBeforeLogin();
          window.location.reload();
        })
        .catch(onError)
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, setLoading, onBeforeLogin, onError]
  );

  return (
    <PasswordArea>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="subtitle2" fontSize="18px" marginBottom="8px">
          {t("メールアドレスでアカウントを作成する")}
        </Typography>
        <TextField
          variant="standard"
          type="email"
          label={t("メールアドレス")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("email", { required: true })}
        />
        <TextField
          variant="standard"
          type="password"
          label={t("パスワード")}
          helperText={t("6文字以上")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("password", { required: true, minLength: 6 })}
        />
        {errorMessage && (
          <Typography color="error" variant="body2" paragraph>
            {errorMessage}
          </Typography>
        )}
        <Box padding="16px" paddingTop="0">
          <FormControlLabel
            control={
              <Controller
                name="agree"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Checkbox
                    onChange={onChange}
                    checked={value}
                    name={name}
                    inputRef={ref}
                  />
                )}
              />
            }
            label={
              <Trans>
                <Link
                  href="/termsOfService.html"
                  target="_blank"
                  underline="hover"
                >
                  利用規約
                </Link>
                と
                <Link
                  href="/privacyPolicy.html"
                  target="_blank"
                  underline="hover"
                >
                  プライバシーポリシー
                </Link>
                に同意する。
              </Trans>
            }
          />
          <Typography paragraph variant="body2" color="textSecondary">
            {t(
              "※ 連携されたアカウントはログインのためだけに利用されます。ココフォリアがユーザーの同意なくアカウント上のコンテンツの閲覧・作成・変更を行うことはありません。"
            )}
          </Typography>
        </Box>
        <LoadingSubmitButton
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          fullWidth
          disabled={!isValid}
          loading={loading}
        >
          {t("アカウントを作成")}
        </LoadingSubmitButton>
      </form>
    </PasswordArea>
  );
};

type SigninFormWithPasswordProps = {
  onBeforeLogin: () => void;
};

const SigninFormWithPassword = ({
  onBeforeLogin,
}: SigninFormWithPasswordProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormParams>({
    defaultValues: { email: "", password: "" },
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const { errorMessage, onError } = useFirebaseAuthError();

  const onSubmit = useCallback(
    ({ email, password }: FormParams) => {
      setLoading(true);
      dispatch(signInWithEmail(email, password))
        .then(() => {
          onBeforeLogin();
          window.location.reload();
        })
        .catch(onError)
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, setLoading, onBeforeLogin, onError]
  );

  return (
    <PasswordArea>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="subtitle2" marginBottom="8px">
          {t("メールアドレスでログインする")}
        </Typography>
        <TextField
          variant="standard"
          type="email"
          label={t("メールアドレス")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("email", { required: true })}
        />
        <TextField
          variant="standard"
          type="password"
          label={t("パスワード")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("password", { required: true, minLength: 6 })}
        />
        {errorMessage && (
          <Typography color="error" variant="body2" paragraph>
            {errorMessage}
          </Typography>
        )}
        <LoadingSubmitButton
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          fullWidth
          disabled={!isValid}
          loading={loading}
        >
          {t("ログイン")}
        </LoadingSubmitButton>
        <Link
          href="/reset-password"
          variant="body2"
          align="center"
          display="block"
          target="_blank"
          style={{ marginTop: "8px" }}
        >
          {t("パスワードを忘れた場合")}
        </Link>
      </form>
    </PasswordArea>
  );
};

const ButtonArea = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;

  & > button + button {
    margin-left: 8px;
  }

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;

    & > button {
      margin-bottom: 8px;
    }

    & > button + button {
      margin-left: 0;
    }
  }
`;

const PasswordArea = styled.div`
  margin: 40px auto 32px;
  box-sizing: border-box;
`;

const StyledPaper = styled(Paper)`
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
`;

export default memo(LoginDialog);
